import React, { useEffect, useState } from "react";
import { Box, Grid, Snackbar, IconButton, Typography } from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateCustomInput,
  CreateCustomTextArea,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import {
  AccessTimeOutlined,
  Close,
  DescriptionOutlined,
  EmailOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { colors } from "../../../theme/theme";

const CreateDailyGoal = () => {
  const params = useParams();

  const [confirmation, setconfirmation] = useState(false);

  const [goalName, setgoalName] = useState("");
  const [goalDescription, setgoalDescription] = useState("");
  const [goalStartDate, setgoalStartDate] = useState();
  const [goalEndDate, setgoalEndDate] = useState();
  const [goalFrequency, setgoalFrequency] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const location = useLocation();
  const { userData } = useSelector((state) => state.user);

  const CreateGoal = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/patient_goal/createPatientGoal`,
        {
          patientId: location?.state?.id,
          name: goalName,
          description: goalDescription,
          frequency: goalFrequency,
          status: "pending",
          startDate: goalStartDate,
          endDate: goalEndDate,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        setgoalDescription("");
        setgoalName("");
        setgoalFrequency("");
        setgoalStartDate(null);
        setgoalEndDate(null);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  // const GetPatientById = async () => {
  //   setloading(true);
  //   await axios
  //     .get(`${BASE_URL}/user/getSpecificUser?userId=${location?.state?.id}`, {
  //       withCredentials: true,
  //     })
  //     .then((res) => {
  //       setloading(false);
  //       const singlePatient = res.data?.specificUser;
  //       setpatientData((prevData) => ({
  //         ...prevData,
  //         fullName: singlePatient?.name,
  //         email: singlePatient?.email,
  //         mobile: singlePatient?.mobile,
  //         gender: singlePatient?.gender,
  //         avatar: singlePatient?.avatar,
  //       }));
  //     })
  //     .catch((e) => {
  //       setloading(false);
  //     });
  // };

  // useEffect(() => {
  //   GetPatientById();
  // }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer
        supervisor={userData?.role === "supervisor" ? true : false}
        provider={userData?.role === "doctor" ? true : false}
      />

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Goal Created Successfully!"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title={"Create New Goal"} />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={"Create New Goal"}
                  btnTitle={"Create"}
                  onClick={CreateGoal}
                  cancelFunc={() => navigate(-1)}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={goalName}
                        setValue={(value) => setgoalName(value)}
                        placeholder="Goal Name"
                        Icon={TaskAltOutlined}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CreateCustomTextArea
                        value={goalDescription}
                        setValue={(value) => setgoalDescription(value)}
                        placeholder="Goal Description"
                        Icon={DescriptionOutlined}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={goalFrequency}
                        setValue={(value) => setgoalFrequency(value)}
                        placeholder="Goal Frequency"
                        Icon={AccessTimeOutlined}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Typography variant="subtitle1" gutterBottom>
                          Start Date
                        </Typography>
                        <DatePicker
                          value={goalStartDate}
                          onChange={(newValue) => {
                            setgoalStartDate(newValue);
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "7px",
                            backgroundColor: "#FAFAFA",
                            fontFamily: "FiraSans",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: colors.text,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(241, 242, 244, 1)",
                            },
                            "& input::placeholder": {
                              color: colors.text,
                              opacity: 1,
                              fontFamily: "FiraSans",
                            },
                            "& .MuiSvgIcon-root": {
                              fill: "black !important",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Typography variant="subtitle1" gutterBottom>
                          End Date
                        </Typography>
                        <DatePicker
                          value={goalEndDate}
                          onChange={(newValue) => {
                            setgoalEndDate(newValue);
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "7px",
                            backgroundColor: "#FAFAFA",
                            fontFamily: "FiraSans",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: colors.text,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(241, 242, 244, 1)",
                            },
                            "& input::placeholder": {
                              color: colors.text,
                              opacity: 1,
                              fontFamily: "FiraSans",
                            },
                            "& .MuiSvgIcon-root": {
                              fill: "black !important",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateDailyGoal;

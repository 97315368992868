import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Backdrop,
  CircularProgress,
  Alert,
  Typography,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "../../../components/AppBar";
import ChecklistIcon from "@mui/icons-material/Checklist";
import {
  CreateCustomInput,
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import {
  Add,
  AddCircleOutline,
  Delete,
  Edit,
  EditNote,
  RemoveCircleOutline,
  Wc,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { colors } from "../../../theme/theme";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { activityTypes, customerStatus } from "../../../assets/data/DummyData";
import { RedModal } from "../../../components/ConfirmationModal";

const CreateActivitySupervisor = () => {
  const params = useParams();

  const [confirmation, setconfirmation] = useState(false);

  const [activityType, setactivityType] = useState("");

  const [activityForm, setactivityForm] = useState({
    activityName: "",
    status: "",
    activityDesc: "",
    doc: "",
  });
  const [formDoc, setformDoc] = useState("");

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [scheduledOn, setscheduledOn] = useState(null);

  const handleStartDateChange = (newValue) => {
    setscheduledOn(newValue);
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.user);

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const CreateAct = async () => {
    if (activityType === "") {
      seterror(true);
      seterrorMsg("Please select activity type");
      return;
    } else if (
      activityType === "Daily Activity" &&
      activityForm.activityName === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
      return;
    } else if (
      activityType === "Daily Check-In Questions" &&
      question === "" &&
      answers.length === 0
    ) {
      seterror(true);
      seterrorMsg("Please add a question and options.");
      return;
    } else {
      seterror(false);
      setloading(true);
      await axiosInstance
        .post(
          `${BASE_URL}/activity/createActivity`,
          {
            selectedSites: [
              {
                siteId: userData?.siteId,
                customerId: userData?.customerId,
              },
            ],
            activityName:
              activityType === "Daily Check-In Questions"
                ? question
                : activityForm.activityName,
            activityType: activityType,
            activitydescription: activityForm.activityDesc,
            document_url: formDoc,
            createdBy: userData?.id,
            options:
              activityType === "Daily Check-In Questions" ? answers : null,
            scheduledOn: "",
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setactivityForm({
            activityName: "",
            activityDesc: "",
            activityType: "",
            doc: "",
          });

          setTimeout(() => {
            navigate(-1);
          }, 2000);
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const UpdateAct = async () => {
    if(!location?.state?.id)
      {
        return;
      }
  
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/activity/editActivity`,
        {
          activityId: location?.state?.id,
          selectedSites: {
            siteId: userData?.siteId,
            customerId: userData?.customerId,
          },
          deleteActivitySite: [],
          activityName: activityType === "Daily Check-In Questions" ? question : activityForm.activityName,
          activityType: activityType,
          activitydescription: activityForm.activityDesc,
          document_url: formDoc,
          createdBy: userData?.id,
          options: answers,
          scheduledOn: location?.state?.isChangeScheduled ? scheduledOn : "",
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        GetActById();

        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetActById = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/activity/getActivityDetails?activityId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        const singleAct = res.data?.activitySite;

        setactivityForm({
          activityName: singleAct?.activityName,
          activityDesc: singleAct?.activitydescription,
          activityType: singleAct?.activityType,
          status: singleAct?.status?.toUpperCase(),
        });
        setformDoc(singleAct?.document_url);
        setactivityType(singleAct?.activityType);
        setQuestion(singleAct?.activityName);
        setAnswers(singleAct?.options);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetActById();
    }
  }, []);

  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([""]);

  const handleAddAnswer = () => {
    setAnswers([...answers, ""]);
  };

  const handleRemoveAnswer = (index) => {
    const newAnswers = [...answers];
    newAnswers.splice(index, 1);
    setAnswers(newAnswers);
  };
  const [deleteModalState, setdeleteModalState] = React.useState(false);

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer
        admin={location?.state?.admin === true ? true : false}
        supervisor={location?.state?.provider === true ? false : true}
        provider={!location?.state?.provider === true ? false : true}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? "Activity Created Successfully!"
            : "Activity Updated Successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <RedModal
        open={deleteModalState}
        onClose={() => {setdeleteModalState(false);navigate(-1)}}
        onClick={() => {
          setdeleteModalState(false);
          if(params?.type === "update") {CreateAct()}else{UpdateAct()};
        }}
        title="Confirmation"
        mainText={"Do you want to save it"}
        subText={""}
        
        actionText={"Yes"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "create"
              ? "Create New Activity"
              : "Update Activity"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Activity"
                      : "Update Activity"
                  }
                  btnTitle={params?.type === "create" ? "Create" : "Update"}
                  onClick={params?.type === "create" ? CreateAct : UpdateAct}
                  cancelFunc={() => setdeleteModalState(true)}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  {location?.state?.isChangeScheduled ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            sx={{
                              height: "50px",
                              borderRadius: "7px",
                              backgroundColor: "#FAFAFA",
                              elevation: 0,
                              fontFamily: "FiraSans",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: colors.text,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EBEFF5",
                                height: "55px",
                                p: 0,
                              },
                              "& input::placeholder": {
                                color: colors.text,
                                opacity: 1,
                                fontFamily: "FiraSans",
                                height: "50px",
                              },
                              ".MuiSvgIcon-root": {
                                fill: "black !important",
                              },
                              width: "100%",
                            }}
                            value={scheduledOn}
                            onChange={handleStartDateChange}
                            PopperProps={{
                              placement: "top-end",
                            }}
                            renderInput={(props) => (
                              <DemoContainer
                                {...props}
                                sx={{ height: "50px" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <CreateDropDown
                          value={activityType}
                          setValue={setactivityType}
                          title="Activity Type"
                          Icon={ChecklistIcon}
                          data={activityTypes}
                        />
                      </Grid>

                      {activityType === "Daily Activity" ? (
                        <>
                          <Grid item xs={12}>
                            <CreateCustomInput
                              value={activityForm.activityName}
                              setValue={(value) =>
                                setactivityForm((prevData) => ({
                                  ...prevData,
                                  activityName: value,
                                }))
                              }
                              placeholder="Activity Name"
                              Icon={ChecklistIcon}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <CreateDropDown
                              value={activityForm.status}
                              setValue={(value) =>
                                setactivityForm((prevData) => ({
                                  ...prevData,
                                  status: value,
                                }))
                              }
                              title={"Status"}
                              data={customerStatus}
                              noIcon
                            />
                          </Grid>
                        </>
                      ) : activityType === "Daily Check-In Questions" ? (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              placeholder="Daily Check-In Question"
                              value={question}
                              onChange={(e) => setQuestion(e.target.value)}
                              fullWidth
                            />
                          </Grid>
                          {answers.map((answer, index) => (
                            <Grid
                              key={index}
                              item
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item xs={11}>
                                <TextField
                                  label={`Option ${index + 1}`}
                                  value={answer}
                                  onChange={(e) =>
                                    handleAnswerChange(index, e.target.value)
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  aria-label="remove-answer"
                                  onClick={() => handleRemoveAnswer(index)}
                                >
                                  <RemoveCircleOutline />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                          <Grid item xs={12}>
                            <IconButton
                              aria-label="add-answer"
                              onClick={handleAddAnswer}
                            >
                              <AddCircleOutline />
                            </IconButton>
                          </Grid>
                        </>
                      ) : null}

                      {/* <Grid item xs={12}>
                            <CreateCustomTextArea
                              value={activityForm.activityDesc}
                              setValue={(value) =>
                                setactivityForm((prevData) => ({
                                  ...prevData,
                                  activityDesc: value,
                                }))
                              }
                              placeholder="Activity Description"
                              Icon={EditNote}
                            />
                          </Grid> */}
                      {/* <Grid item xs={12}>
                      <UploadBtn
                        onClick={setformDoc}
                        formDoc={formDoc}
                        setloading={setloading}
                      />
                    </Grid> */}
                    </Grid>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateActivitySupervisor;

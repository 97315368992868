import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { colors } from "../theme/theme";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import { CreateCustomTextArea } from "./CreatePagesComponents";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  // backdropFilter: "blur(.5px) sepia(.5%)",
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

export const GreenModal = ({
  open,
  onClose,
  onClick,
  title,
  mainText,
  subText,
}) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontSize: "18px",
          fontWeight: "500",
          fontFamily: "FiraSans",
          color: colors.text,
        }}
        id="customized-dialog-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: colors.text,
            fontFamily: "FiraSans",
            px: 2.5,
            pt: 4,
            textAlign: "center",
          }}
        >
          {mainText}
        </Typography>
        <Typography
          gutterBottom
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: colors.lightText,
            fontFamily: "FiraSans",
            px: 2.5,
            maxWidth: "70%",
            pb: 4,
            pt: 2,
            textAlign: "center",
          }}
        >
          {subText}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: "40px",
            border: "1px solid #c8c8c8",
            backgroundColor: "#FFF",
            boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
            textTransform: "none",
            borderRadius: "8px",
            color: colors.text,
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "FiraSans",
            "&:hover": {
              backgroundColor: "#FAFAFA",
            },
            px: 2.5,
          }}
          autoFocus
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          sx={{
            height: "40px",
            border: "none",
            boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
            backgroundColor: "#138418",
            textTransform: "none",
            borderRadius: "8px",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "FiraSans",
            "&:hover": {
              backgroundColor: "#138418",
            },
            px: 2.5,
          }}
          autoFocus
          onClick={() => {
            onClose();
            onClick();
          }}
        >
          Send Request
          <SendOutlinedIcon sx={{ fontSize: "20px", color: "#fff", ml: 2 }} />
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export const RedModal = ({
  open,
  onClose,
  onClick,
  title,
  mainText,
  subText,
  actionText,
  textBox,
  text,
  setText,
}) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: colors.text,
            fontFamily: "FiraSans",
            px: 2.5,
            pt: textBox ? 1 : 4,
            textAlign: "center",
          }}
        >
          {mainText}
        </Typography>
        <Typography
          gutterBottom
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: colors.lightText,
            fontFamily: "FiraSans",
            px: 2.5,
            maxWidth: "70%",
            pb: 4,
            pt: 2,
            textAlign: "center",
          }}
        >
          {subText}
        </Typography>
        {textBox && (
          <CreateCustomTextArea
            text={text}
            setText={setText}
            placeholder="Reason for deny the request(s)* "
            noIcon
            value={text}
            setValue={setText}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          disableElevation
          sx={{
            height: "40px",
            border: "1px solid #c8c8c8",
            backgroundColor: "transparent",
            textTransform: "none",
            borderRadius: "8px",
            color: colors.text,
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "FiraSans",
            "&:hover": {
              backgroundColor: "#FAFAFA",
            },
            px: 2.5,
          }}
          autoFocus
          onClick={onClose}
        >
          Discard
        </Button>

        <Button
          variant="contained"
          disableElevation
          sx={{
            height: "40px",
            border: "1px solid #c8c8c8",
            backgroundColor:
              actionText !== "Delete" ? colors.primary : "#F34141",
            textTransform: "none",
            borderRadius: "8px",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "FiraSans",
            "&:hover": {
              backgroundColor:
                actionText !== "Delete" ? colors.primary : "#F34141",
            },
            px: 2.5,
          }}
          autoFocus
          onClick={(e) => {
            onClose(e);
            onClick();
          }}
        >
          {actionText === "Delete" && (
            <DeleteOutlineOutlinedIcon
              sx={{ fontSize: "20px", color: "#fff" }}
            />
          )}
          {actionText ? actionText : "Delete"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export const ConfirmationModal = ({
  open,
  onClose,
  onClick,
  title,
  mainText,
  subText,
}) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          fontSize: "18px",
          fontWeight: "500",
          fontFamily: "FiraSans",
          color: colors.text,
        }}
        id="customized-dialog-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            height: "80px",
            width: "80px",
            borderRadius: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "5px solid #138418",
            mt: 4,
            mx: 4,
          }}
        >
          <CheckSharpIcon
            sx={{ fontSize: "60px", width: "60px", color: "#138418" }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "500",
            color: colors.text,
            fontFamily: "FiraSans",
            px: 2.5,
            pt: 1,
            textAlign: "center",
            mb: 4,
            mx: 4,
          }}
        >
          {mainText}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: "40px",
            border: "1px solid #c8c8c8",
            backgroundColor: "#FAFAFA",
            boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
            textTransform: "none",
            borderRadius: "8px",
            color: colors.text,
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "FiraSans",
            "&:hover": {
              backgroundColor: "#FAFAFA",
            },
            padding: "10px 50px",
          }}
          autoFocus
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

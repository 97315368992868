import { Alert, Snackbar } from "@mui/material";
import React from "react";

export const ErrorSnackbar = ({ error, handleCloseError, errorMsg }) => {
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    handleCloseError(false);
  };

  return (
    <Snackbar
      open={error}
      autoHideDuration={2000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleCloseError}
        severity="error"
        variant="filled"
        sx={{ width: "100%" }}
      >
        {errorMsg}
      </Alert>
    </Snackbar>
  );
};

export const ConfirmationSnackbar = ({
  snackbarState,
  handleSnackbarClose,
  action,
  text,
}) => {
  return (
    <Snackbar
      open={snackbarState}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      message={text}
      action={action}
      sx={{
        "& .MuiSnackbarContent-root": {
          backgroundColor: "white",
          color: "black",
        },
      }}
    />
  );
};

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import { colors } from "../../../theme/theme";
import { roles } from "../../../assets/data/DummyData";
import {
  Close,
  MoreVert,
  PersonOutlineOutlined,
  SendOutlined,
} from "@mui/icons-material";
import AppBar from "../../../components/AppBar";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { useSelector } from "react-redux";
import { CreateDropDown } from "../../../components/CreatePagesComponents";
import { useNavigate } from "react-router-dom";
import { RedModal } from "../../../components/ConfirmationModal";
import axios from "axios";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import moment from "moment";

const DropDown = ({ value, setValue, title, data }) => {
  const [open, setopen] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(value);

    setopen(false);
  };

  return (
    <FormControl fullWidth sx={{}}>
      <Select
        multiple
        displayEmpty
        value={value}
        onChange={handleChange}
        open={open}
        onClose={() => setopen(false)}
        onOpen={() => setopen(true)}
        MenuProps={{
          onClose: () => setopen(false),
        }}
        input={<OutlinedInput />}
        InputProps={{
          disableUnderline: true,
          sx: {
            fontFamily: "FiraSans",
            fontSize: "14px",
            fontWeight: 400,
            color: "#000",
          },
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <em
                style={{
                  fontStyle: "normal",
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                {title}
              </em>
            );
          }
          return selected.join(", ");
        }}
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "50px",
          borderRadius: "7px",
          backgroundColor: "transparent",
          elevation: 0,
          fontFamily: "FiraSans",
          fontSize: "14px",
          fontWeight: "400",
          color: colors.text,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#EBEFF5",
          },
          "& input::placeholder": {
            color: colors.text,
            opacity: 1,
            fontFamily: "FiraSans",
          },
          ".MuiSvgIcon-root ": {
            fill: "black !important",
          },
        }}
      >
        {roles.map((option) => (
          <MenuItem
            style={{
              textTransform: "capitalize",
            }}
            key={option.value}
            value={option.value}
            onClick={option.action}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ScheduledAppointments = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const [deleteModalState, setdeleteModalState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmation, setConfirmation] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const appointmentsColumns = [
    // {
    //   field: "names",
    //   headerName: "Name",
    //   flex: isSmallScreen ? 0.5 : 2.5,
    //   renderCell: (params) => (
    //     <Box
    //       sx={{
    //         displag: "flex",
    //       }}
    //     >
    //       <Typography
    //         sx={{
    //           fontSize: "14px",
    //           fontWeight: "500",
    //           color: colors.text,
    //           fontFamily: "FiraSans",
    //           whiteSpace: "pre-wrap",
    //           wordWrap: "break-word",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           display: "-webkit-box",
    //           WebkitLineClamp: 2,
    //           WebkitBoxOrient: "vertical",
    //         }}
    //       >
    //         {params.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    {
      field: "patient",
      headerName: "Patient",
      flex: 1.25,
      renderCell: (params) => {
        const patient = params?.row?.patient;
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {patient?.avatar !== "" && (
              <img
                src={patient?.avatar}
                alt=""
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                textDecoration: "underline",
                ml: 1,
              }}
            >
              {patient?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "date",
      headerName: "Scheduled Date & Time",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {moment(params?.row?.date).format("YYYY-MM-DD hh:mm A")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.25,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              handleClick(e);
              setappointmentId(params?.row?.id);
            }}
          >
            <MoreVert sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const navigate = useNavigate();

  const { allAssignedPatients } = useSelector((state) => state.provider);
  const { userData } = useSelector((state) => state.user);

  const patientData = allAssignedPatients?.map((patient) => ({
    label: patient?.patients?.name,
    value: patient?.patients?.name,
    id: patient?.patients?.id,
  }));

  const [selectedPatient, setselectedPatient] = useState("");
  const [patientId, setpatientId] = useState("");
  const [appointmentId, setappointmentId] = useState("");

  const [search, setsearch] = useState("");
  const [loading, setloading] = useState(false);
  const [allAppointments, setallAppointments] = useState([]);

  const GetAppointments = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/appointment/getAllAppointmentByDoctorId?doctorId=${userData?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallAppointments(res?.data?.allAppointments);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetAppointments();
  }, []);

  const DeleteAppointment = async () => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/appointment/updateStatus`,
        {
          status: "deleted",
          appointmentId: appointmentId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdeleteModalState(false);
        setConfirmation(true);
        GetAppointments();
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const FilterAppointments = async () => {
    const filtered = allAssignedPatients?.filter((val) => {
      return patientId !== "" && val?.patient?.id === patientId;
    });

    setallAppointments(filtered);
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer provider />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={() => setConfirmation(false)}
        message="Appointment Deleted Successfully!"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      {/* Delete Modal */}
      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={() => {
          setdeleteModalState(false);
          DeleteAppointment();
        }}
        title="Confirmation"
        mainText="Are you sure want to delete this appointment?"
        subText="Please ensure that once the appointment is deleted, it cannot be recovered."
        actionText={"Delete"}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Scheduled Appointments" />

        {/* Filter */}
        {/* <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              p: 2,
              m: 2,
              mb: 0,
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={9}>
                <CreateDropDown
                  value={selectedPatient}
                  setValue={(e) => {
                    setselectedPatient(e);
                    const patient = allAssignedPatients.find(
                      (patient) => patient?.patients?.name === e
                    )?.patients?.id;
                    setpatientId(patient);
                  }}
                  title="Choose Patient"
                  Icon={PersonOutlineOutlined}
                  data={patientData || []}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  disableElevation
                  variant="contained"
                  sx={{
                    height: "47.5px",
                    width: { xs: "100%", sm: "100%" },
                    backgroundColor: "#FAFAFA",
                    textTransform: "none",
                    borderRadius: "8px",
                    border: "1px solid #EBEFF5",
                    // boxShadow: "0px 2px 5px 0px #676E7614",
                    // "&:hover": {
                    //   backgroundColor: "#FAFAFA",
                    // },
                    color: "inherit",
                    fontFamily: "FiraSans-bold",
                  }}
                  onClick={FilterAppointments}
                >
                  Find Patient <SendOutlined sx={{ ml: 1, fontSize: "20px" }} />
                </Button>
              </Grid>
            </Grid>
          </Box> */}

        {/* Data Grid */}
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Scheduled Appointments"
                onClick={() =>
                  navigate("/provider/scheduled-appointments/create")
                }
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  allAppointments?.filter((val) =>
                    val?.patient?.name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={appointmentsColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    borderBottom: "1px solid #EBEFF5",
                    color: "#24292E",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={(e) => {
                    handleClose(e);
                    navigate("/provider/scheduled-appointments/update", {
                      state: {
                        id: appointmentId,
                      },
                    });
                  }}
                >
                  Reschedule
                </MenuItem>
                <MenuItem
                  sx={{
                    color: "#F34141",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={(e) => {
                    handleClose(e);
                    setdeleteModalState(true);
                  }}
                >
                  Delete Appointment
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ScheduledAppointments;

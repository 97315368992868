import React, { useEffect, useState } from "react";
import {
  Box,
  Snackbar,
  Select,
  MenuItem,
  Menu,
  Grid,
  CircularProgress,
  Backdrop,
  Alert,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RowBtns from "../../../components/RowBtns";
import { customerStatus, siteStatus } from "../../../assets/data/DummyData";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import HighlightAltSharpIcon from "@mui/icons-material/HighlightAltSharp";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import {
  CreateCustomInput,
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import {
  AssignmentTurnedInOutlined,
  EmailOutlined,
  Groups3Outlined,
  MailOutline,
  MapOutlined,
  PhoneOutlined,
  Warning,
} from "@mui/icons-material";
import { UploadBtn } from "../../../components/CustomBtn";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { getAllSupervisorForAdmin } from "../../../redux/dispatchers/admin.dispatcher";
import { RedModal } from "../../../components/ConfirmationModal";
const CreateCustomerSites = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);
  const { allSupervisors } = useSelector((state) => state.admin);
  const filteredSupervisors = allSupervisors?.filter((val) => {
    return (val?.verified === true )
  });
  
  const supervisors = filteredSupervisors?.map((sv) => ({
    label: sv?.name,
    value: sv?.name,
    id: sv?.id,
  }));

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const [confirmation, setconfirmation] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [loading, setloading] = useState(false);
  const [showerror, setshowerror] = useState(false);
  const [showbackuperror, setshowbackuperror] = useState(false);
  const [formData, setFormData] = useState({
    site_name: "",
    email: "",
    phone: "",
    address: "",
    totalBeds: "",
    totalFacilities: "",
    status: "",
    country: "",
    countryCode: "",
    city: "",
    supervisor: "",
    backupSupervisorId: "",
    state: "",
    postalCode:""
  });
  const [customerId, setcustomerId] = useState("");

  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [activeSupervisor, setActiveSupervisor] = useState(false);

  const CreateCustomerSite = async () => {
    if (
      formData.site_name === "" ||
      formData.address === "" ||
      formData.country === "" ||
      formData.phone === "" ||
      formData.email === "" ||
      formData.status === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/site/createSiteUsingAdmin`,
          {
            customerId: userData?.customerId,
            site_name: formData.site_name,
            email: formData.email,
            mobile: formData.phone,
            address: formData.address,
            beds: formData.totalBeds,
            facilities: formData.totalFacilities,
            status: formData.status,
            created_by: userData?.name,
            country: formData.country,
            countryCode: formData.countryCode?.toLocaleLowerCase(),
            supervisorId:
              formData.supervisor !== ""
                ? supervisors?.find((sup) => sup.value === formData.supervisor)
                    ?.id
                : null,
            backupSupervisorId: formData.backupSupervisorId !== ""
            ? supervisors?.find((sup) => sup.value === formData.backupSupervisorId)
                ?.id
            : null,
            state: formData.state,
            city: formData.city,
            postalCode:formData.postalCode
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setTimeout(() => {
            navigate("/admin/customer-sites");
          }, 2000);
          setFormData({
            site_name: "",
            email: "",
            phone: "",
            address: "",
            totalBeds: "",
            totalFacilities: "",
            status: "",
            country: "",
            countryCode: "",
            postalCode:"",
            
          });
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const UpdateCustomerSite = async () => {
    setloading(true);
    if(params?.type === "update" && !location?.state?.id)
    {
      setloading(false);
      return;
    }
    if(params?.type === "create")
    {
      if (
        formData.site_name === "" ||
        formData.address === "" ||
        formData.country === "" ||
        formData.phone === "" ||
        formData.email === "" ||
        formData.status === ""
      ) {
        seterror(true);
      setloading(false);
        seterrorMsg("Please fill all the fields");
        return;
      }
    }
    if(activeSupervisor && formData?.status==='Inactive')
    {
      seterror(true);
      seterrorMsg("You cannot mark this site inactive. This site has already active Supervisors.");
      setloading(false);
      return;
    }
    await axios
      .post(
        `${BASE_URL}/site/editSite`,
        {
          siteId: location?.state?.id,
          site_name: formData.site_name,
          email: formData.email,
          mobile: formData.phone,
          address: formData.address,
          beds: formData.totalBeds,
          facilities: formData.totalFacilities,
          status: formData.status,
          countryCode: formData.countryCode?.toLocaleLowerCase(),
          country: formData.country,
          city: formData.city,
          state: formData.state,
          supervisorId:
              formData.supervisor !== ""
                ? supervisors?.find((sup) => sup.value === formData.supervisor)
                    ?.id
                : null,
                postalCode:formData.postalCode,
          backupSupervisorId: formData.backupSupervisorId !== ""
          ? supervisors?.find((sup) => sup.value === formData.backupSupervisorId)
              ?.id
          : null,

        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate("/admin/customer-sites");
        }, 2000);
        setFormData({
          site_name: "",
          email: "",
          phone: "",
          address: "",
          totalBeds: "",
          totalFacilities: "",
          status: "",
          country: "",
          countryCode: "",
          postalCode:""
        });
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const [countries, setCountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [states, setstates] = useState([]);
  const [countryCode, setcountryCode] = useState("");

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://api.countrystatecity.in/v1/countries",
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const transformedArray = response.data.map((country) => ({
        label: country.name,
        value: country.name,
        code: country.iso2,
      }));
      setCountries(transformedArray);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const fetchStatesByCountries = async (name) => {
    const country = countries?.find((country) => country.value === name);
    const code = params?.type === "update" ? name : country?.code;
    try {
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${code}/states`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const transformedArray = response.data.map((state) => ({
        label: state.name,
        value: state.name,
        code: state.iso2,
      }));
      setcountryCode(country?.code);

      setstates(transformedArray);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const fetchCitiesByStatesAndCountries = async (code) => {
    const statesCode = states?.find((state) => state.value === code);
    const counCode =
      params?.type === "update" ? formData?.countryCode : countryCode;

    try {
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${counCode}/states/${statesCode?.code}/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const transformedArray = response.data.map((country) => ({
        label: country.name,
        value: country.name,
      }));
      setcities(transformedArray);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const GetCustomerSiteById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/site/getOneSite?siteId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleSite = res.data?.oneSite;
        setActiveSupervisor(res?.data?.activeSupervisor);

        setcustomerId(singleSite?.customerId);
        setFormData({
          site_name: singleSite?.site_name,
          email: singleSite?.email,
          phone: singleSite?.mobile,
          totalBeds: singleSite?.beds,
          totalFacilities: singleSite?.facilities,
          status: singleSite?.status,
          country: singleSite?.country,
          countryCode: singleSite?.countryCode,
          city: singleSite?.city,
          address: singleSite?.address,
          supervisor: singleSite?.user?.name,
          state: singleSite?.state,
          backupSupervisorId:singleSite?.backupSupervisor?.name,
          postalCode:singleSite?.postalCode
        });
        //fetchStatesByCountries(singleSite?.countryCode);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
   fetchCountries();

    if (params?.type === "update" || params?.type === "view") {
      GetCustomerSiteById();
    }
    dispatch(getAllSupervisorForAdmin(userData?.customerId));

  }, []);
  const [deleteModalState, setdeleteModalState] = React.useState(false);

  const navigateToEditPage = async () => {
    navigate("/admin/customer-sites/update", {
      state: { id: location?.state?.id },
    })

  }



  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          params?.type === "update"
            ? "Site Updated Successfully!"
            : "Site Created Successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
  
      <RedModal
          open={deleteModalState}
          onClose={() => {setdeleteModalState(false);navigate("/admin/customer-sites")}}
          onClick={() => {
            setdeleteModalState(false);
            if(params?.type === "update") {UpdateCustomerSite()}else{CreateCustomerSite()};
          }}
          title="Confirmation"
          mainText={"Do you want to save it"}
          subText={""}
          
          actionText={"Yes"}
        />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "update"
              ? "Update"
              : params?.type === "view"
              ? "View"
              : "Create"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "update"
                      ? "Update Customer Site"
                      : params?.type === "view"
                      ? "View Customer Site"
                      : "Create New Customer Site"
                  }
                  btnTitle={params?.type === "update" ? "Update" : params?.type === "view" ? "Edit":"Create"}
                  onClick={() => {
                    if (params?.type === "update") {
                      UpdateCustomerSite();
                    } else if(params?.type === "view") {
                      navigateToEditPage()
                    } else {
                      CreateCustomerSite();
                    }
                  }}
                  noBtns={params?.type === "view" ? false : false}
                  cancelFunc={() => {params?.type === "view"?navigate("/admin/customer-sites"):setdeleteModalState(true)}}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CreateCustomInput
                        value={formData?.site_name}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            site_name: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Site Name*"
                        Icon={BusinessCenterOutlinedIcon}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        type="email"
                        value={formData.email}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Email*"
                        Icon={MailOutline}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.phone}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            phone: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Phone*"
                        Icon={PhoneOutlined}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CreateDropDown
                        value={formData.status}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            status: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        title="Status*"
                        data={customerStatus}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        type="number"
                        value={formData.totalBeds}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            totalBeds: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="No. of Beds"
                        Icon={AssignmentTurnedInOutlined}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomFormInput
                        value={formData.address}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            address: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Site Address*"
                        Icon={MapOutlined}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.totalFacilities}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            totalFacilities: value,
                          }))
                        }
                        placeholder="Total Facilities"
                        Icon={Groups3Outlined}
                      />
                    </Grid> */}
                                        
            
                    <Grid item xs={12} sm={4}>
                      {/* <CustomFormInput
                        value={formData.country}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            country: value,
                          }));
                        }}
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Country*"
                        noIcon
                      /> */}

                      <CreateDropDown
                        value={formData.country}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            country: value,
                            countryCode: countries?.find(
                              (country) => country.value === value
                            )?.code,
                          }));
                          fetchStatesByCountries(value);
                        }}
                        title="Country*"
                        data={countries}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* <CustomFormInput
                        value={formData.state}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            state: value,
                          }));
                        }}
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Province/State*"
                        noIcon
                      /> */}

                      <CreateDropDown
                        value={formData.state}
                        setValue={(value) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            state: value,
                          }));
                          fetchCitiesByStatesAndCountries(value);
                        }}
                        title="Province/State"
                        data={states}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* <CustomFormInput
                        value={formData.city}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            city: value,
                          }));
                        }}
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="City*"
                        noIcon
                      /> */}

                      <CreateDropDown
                        value={formData.city}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            city: value,
                          }));
                        }}
                        title="City"
                        data={cities}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.postalCode}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            postalCode: value,
                          }))
                        }
                        placeholder="Postal Code"
                        Icon={AssignmentTurnedInOutlined}
                      />
                    </Grid>


                    <Grid item xs={12} sm={12}>
                      <CreateDropDown
                        value={formData.supervisor}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            supervisor: value,
                          }));
                          filteredSupervisors.map(sup=>{
                            if(sup.name===value)
                            {
                              if(sup.site!=null)
                              {
                                setshowerror(true);
                              }
                              else{
                                setshowerror(false);
                              }
                            }
                          })
                        }}
                        readOnly={params?.type === "view" ? true : false}
                        title="Supervisor"
                        data={supervisors}
                        Icon={PeopleOutlinedIcon}
                      />
                      {showerror && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                                        
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "red",
                              fontFamily: "FiraSans",
                            }}
                          >
                          <Warning style={{fontSize:"14px",fontWeight:"500",color:"red"}}></Warning> &nbsp;
                          Assigning this supervisor to this site will remove him from the other site</Typography>
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <CreateDropDown
                        value={formData.backupSupervisorId}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            backupSupervisorId: value,
                          }));
                          filteredSupervisors.map(sup=>{
                            if(sup.name===value)
                            {
                              if(sup.backupSupervisor!=null)
                              {
                                setshowbackuperror(true);
                              }
                              else{
                                setshowbackuperror(false);
                              }
                            }
                          })

                        }}
                        readOnly={params?.type === "view" ? true : false}
                        title="Backup Supervisor"
                        data={supervisors}
                        Icon={PeopleOutlinedIcon}
                      />
                      {showbackuperror && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                                        
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "red",
                              fontFamily: "FiraSans",
                            }}
                          >
                          <Warning style={{fontSize:"14px",fontWeight:"500",color:"red"}}></Warning> &nbsp;
                          Assigning this supervisor to this site as backup supervisor will remove him from the other site as backup supervisor</Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateCustomerSites;

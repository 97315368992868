import React from "react";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { colors } from "../theme/theme";
import InputBase from "@mui/material/InputBase";
import { DataGrid } from "@mui/x-data-grid";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  color: colors.text,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
  [theme.breakpoints.up("xs")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "25ch",
    },
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
    },
    [theme.breakpoints.up("xs")]: {
      width: "15ch",
    },
    border: "1px solid #f1f1f1",
    borderRadius: "8px",
  },
  "::placeholder": {
    color: colors.text,
  },
}));

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  // height: "90vh",
  "& .odd": {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
  },
  "& .even": {
    backgroundColor: "#FAFAFA",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
}));

import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  useMediaQuery,
  Snackbar,
  Menu,
  MenuItem,
} from "@mui/material";
import AppBar from "../../../components/AppBar";
import { GreenModal, RedModal } from "../../../components/ConfirmationModal";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { useLocation, useNavigate } from "react-router-dom";
import {
  colors,
  getCustomerStatusBackgroundColor,
  getStatusTextColor,
} from "../../../theme/theme";
import {
  Close,
  EditOutlined,
  MoreVert,
  VisibilityOutlined,
} from "@mui/icons-material";
import moment from "moment";
import { BASE_URL } from "../../../constants/config";
import axios from "axios";

const SitesOfSingleCustomer = () => {
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width:650px)");

  const customerSitesColumns = [
    {
      field: "site_name",
      headerName: "Site Name",
      flex: 3,
      minWidth: smallScreen ? 500 : 0,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/vendor/customer-sites/profile", {
                state: { id: params.row.id },
              })
            }
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        </div>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      flex: 3,
      minWidth: smallScreen ? 500 : 0,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={`https://flagcdn.com/w40/${params?.row?.countryCode}.png`}
            alt=""
          />
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/vendor/customer-sites/profile", {
                state: { id: params.row.id },
              })
            }
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: smallScreen ? 200 : 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getCustomerStatusBackgroundColor(
                params?.value?.toLocaleLowerCase()
              ),
              color: getStatusTextColor(params?.value?.toLocaleLowerCase()),
              borderRadius: "8px",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {moment(params.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1.5,
      minWidth: smallScreen ? 200 : 100,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() =>
              navigate("/vendor/customer-sites/profile", {
                state: { id: params.row.id },
              })
            }
          >
            <VisibilityOutlined sx={{ fontSize: "20px" }} />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate("/vendor/customer-sites/update", {
                state: { id: params.row.id },
              })
            }
          >
            <EditOutlined sx={{ fontSize: "20px" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              handleClick(e);
              setsiteId(params.row.id);
            }}
          >
            <MoreVert sx={{ fontSize: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteModalState, setdeleteModalState] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const location = useLocation();
  const [search, setSearch] = useState("");

  const [siteId, setsiteId] = useState("");
  const [loading, setloading] = useState(false);
  const [snackbarState, setsnackbarState] = useState(false);

  const UpdateStatus = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/site/updateSiteStatus`,
        {
          status: "inactive",
          siteId: siteId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsnackbarState(true);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const [sites, setsites] = useState([]);

  const GetSites = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/site/getAllSites?customerId=${location?.state?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsites(res.data?.allSites);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetSites();
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarState}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={"Site has been blocked successfully."}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={() => {
          setdeleteModalState(false);
          UpdateStatus();
        }}
        title="Confirmation"
        mainText="Are you sure want to close this site?"
        subText="Please ensure that once the site is closed, it cannot be recovered."
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            fontFamily: "FiraSans",
            color: "#F37373",
          }}
          onClick={() => {
            handleClose();
            setdeleteModalState(true);
          }}
        >
          Delete Site
        </MenuItem>
      </Menu>

      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <AppBar title="Customer Sites" />

        {/* Data Grids */}
        <Grid container spacing={2} p={2}>
          {/* First Box */}
          <Grid item xs={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                width: "100%",
                overflowX: "auto",
              }}
            >
              <TitleWithSearchBar
                title="Customer Sites"
                onClick={() => {
                  navigate("/vendor/customer-sites/create", {
                    state: {
                      customerId: location?.state?.customerId,
                      customerName: location?.state?.customerName,
                    },
                  });
                }}
                search={search}
                setSearch={setSearch}
              />

              <StyledDataGrid
                rows={
                  sites?.filter((val) =>
                    val?.address
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={customerSitesColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                getRowId={(row) => row?.id}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SitesOfSingleCustomer;

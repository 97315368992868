import axiosInstance, { BASE_URL } from "../../constants/config";

export const getAllDoctorsForSupervisor = (siteId) => async (dispatch) => {
  try {
    dispatch({
      type: "allDoctorsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/user/getDoctorsBySiteId?siteId=${siteId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allDoctorsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allDoctorsFailure",
      payload: error,
    });
  }
};

export const getAllPatientsForSupervisor = (siteId) => async (dispatch) => {
  try {
    dispatch({
      type: "allPatientsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/user/getAllPatientsBySiteId?siteId=${siteId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allPatientsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allPatientsFailure",
      payload: error,
    });
  }
};

export const getAllFormsForSupervisor = (siteId) => async (dispatch) => {
  try {
    dispatch({
      type: "allFormsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/form/getFormDetailsBySiteId?siteId=${siteId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allFormsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allFormsFailure",
      payload: error,
    });
  }
};

export const getAllQuestionsForSupervisor = (siteId) => async (dispatch) => {
  try {
    dispatch({
      type: "allQuestionsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/question/getAllQuestionBySiteId?siteId=${siteId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allQuestionsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allQuestionsFailure",
      payload: error,
    });
  }
};

export const clearSupervisor = () => async (dispatch) => {
  try {
    dispatch({
      type: "clearSupervisor",
    });
  } catch (error) {}
};

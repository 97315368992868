import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllSupervisorForAdmin } from "../../../redux/dispatchers/admin.dispatcher";
import {
  Close,
  EmailOutlined,
  People,
  PeopleAltOutlined,
  PhoneIphoneOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { RedModal } from "../../../components/ConfirmationModal";
import { getAllDoctorsForSupervisor } from "../../../redux/dispatchers/supervisor.dispatcher";

const SupervisorUsers = (supervisor = false, admin = false, doctor = false) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const usersColumn = [
    {
      field: "serialNumber",
      headerName: "S.No.",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 3.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params?.row?.avatar !== "" && (
            <img
              src={params.row.avatar}
              alt=""
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
          )}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params?.value}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "assignedDoctor",
      headerName: "Supervisor",
      flex: 1.25,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <EmailOutlined
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          /> */}
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 1,
            }} 
            onClick={() => console.log()}
          >
            {params?.row?.isDoctor && params?.row?.assignedData
            ? allSupervisors.filter(sup=>sup?.id===params?.row?.assignedData?.supervisorId)[0]?.name
            :""}
            
          </Typography>
        </Box>
      ),
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   flex: 1.25,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <EmailOutlined
    //         style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
    //       />
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //           ml: 1,
    //         }}
    //       >
    //         {params?.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "mobile",
    //   headerName: "Phone",
    //   flex: 1.25,
    //   minWidth: isSmallScreen ? 150 : 0,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <PhoneIphoneOutlined
    //         style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
    //       />
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //           ml: 1,
    //         }}
    //       >
    //         {params?.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //           ml: 0.5,
    //           textDecoration: "underline",
    //         }}
    //       >
    //         {params?.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "doctorType",
    //   headerName: "Doctor Type",
    //   flex: 1.25,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //           whiteSpace: "pre-line",
    //         }}
    //       >
    //         {params?.row?.isDoctor
    //           ? params?.value !== null
    //             ? params?.value
    //             : "N/A"
    //           : "N/A"}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1.25,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PeopleAltOutlined
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 1,
            }}
          >
            {params?.row?.isDoctor ? "Provider" : null}
          </Typography>
        </Box>
      ),
    },
    // {
    //   field: "createdAt",
    //   headerName: "Created At",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <div
    //       style={{
    //         fontWeight: "400",
    //         fontSize: "14px",
    //         color: "#596066",
    //       }}
    //     >
    //       {moment(params.value).format("DD/MM/YYYY")}
    //     </div>
    //   ),
    // },
    {
      field: "verified",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params.value ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              setuserId(params.row.id);
              setuserName(params.row.name);
              setrole(
                params.row?.role === "patient"
                  ? "patient"
                  : params?.row?.isDoctor
                  ? "doctor"
                  : ""
              );
              handleClick(e);
              setuserStatus(params.row.verified);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const dispatch = useDispatch();
  const { userData, userRole } = useSelector((state) => state.user);
    const [allDoctors, setAllDoctors] = useState([]);

//  const { allDoctors } = useSelector((state) => state.supervisor);
  const GetAllDoctors = async () => {
    setloading(true);
      await axios
        .get(
          `${BASE_URL}/user/getDoctorsByCustomerId?customerId=${userData?.customerId}`,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setAllDoctors(res.data?.allUsers);
        })
        .catch((e) => {
          setloading(false);
        });
};

const [search, setsearch] = useState("");
const { allSupervisors } = useSelector((state) => state.admin);

  useEffect(() => {
    GetAllDoctors();
    dispatch(getAllSupervisorForAdmin(userData?.customerId));
    //dispatch(getAllDoctorsForSupervisor(userData?.siteId));
  }, []);

  const [deleteModalState, setdeleteModalState] = React.useState(false);
  const [userId, setuserId] = useState("");
  const [userName, setuserName] = useState("");
  const [role, setrole] = useState("");
  const [loading, setloading] = useState(false);
  const [snackbarState, setsnackbarState] = useState(false);

  const [userStatus, setuserStatus] = useState("");
  const [newUserStatus, setnewUserStatus] = useState("");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const UpdateStatus = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUserStatus`,
        {
          status: newUserStatus ? "active" : "inactive",
          userId: userId,
          verified: newUserStatus,
          isDoctor: true,
          isSupervisor: false,
          isAdmin: false,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsnackbarState(true);
        dispatch(getAllDoctorsForSupervisor(userData?.siteId));
      })
      .catch((err) => {
        setloading(false);
      });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarState}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={"User status updated successfully"}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={() => {
          setdeleteModalState(false);
          UpdateStatus();
        }}
        title="Confirmation"
        mainText={userStatus ? "Make user inactive?" : "Make user active?"}
        subText={"Are you sure you want to change the status of this user?"}
        actionText={userStatus ? "Mark Inactive" : "Mark Active"}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Users" />

        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                minHeight: "200px",
              }}
            >
              <TitleWithSearchBar
                title="Users"
                onClick={() => navigate("/supervisor/users/create")}
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  allDoctors?.filter((val) =>
                    val?.name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={usersColumn}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5,10, { value: allDoctors?.length, label: 'All' }]}
                autoHeight
                slotProps={{
                  noRowsOverlay: {
                    style: { minHeight: "200px" },
                  },
                }}
              />
            </Box>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                sx={{
                  borderBottom: "1px solid #EBEFF5",
                  color: "#24292E",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                }}
                onClick={() => {
                  navigate("/supervisor/users/view", {
                    state: { id: userId },
                  });
                }}
              >
                View User Record
              </MenuItem>
              <MenuItem
                sx={{
                  borderBottom: "1px solid #EBEFF5",
                  color: "#24292E",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                }}
                onClick={() => {
                  navigate("/supervisor/users/update", {
                    state: { id: userId },
                  });
                }}
              >
                Edit User
              </MenuItem>
              <MenuItem
                sx={{
                  color: "#F34141",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                }}
                onClick={() => {
                  handleClose();
                  setdeleteModalState(true);
                  setnewUserStatus(!userStatus);
                }}
              >
                {userStatus ? "Mark as Inactive" : "Mark as Active"}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SupervisorUsers;

import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { colors } from "../../theme/theme";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MenuItem from "@mui/material/MenuItem";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Link, useNavigate } from "react-router-dom";
import { roles } from "../../assets/data/DummyData";
import { Backdrop, CircularProgress, useMediaQuery } from "@mui/material";
import { CustomPasswordInput, CustomInput } from "../../components/CustomInput";
import Select from "@mui/material/Select";
import CustomBtn from "../../components/CustomBtn";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import { useUser } from "../../constants/context";
import { ConfirmationSnackbar, ErrorSnackbar } from "../../components/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUserData,
  getUserById,
  setUserRole,
} from "../../redux/dispatchers/login";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width:800px)");

  const [role, setrole] = React.useState("");
  const [open, setopen] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setrole(value);

    setopen(false);
  };

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const [confirmation, setconfirmation] = useState(false);
  const [confirmationMsg, setconfirmationMsg] = useState("");

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const { userData } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email === "") {
      seterror(true);
      seterrorMsg("All fields are required");
    } else {
      setloading(true);
      seterror(false);
      await axios
        .get(
          `${BASE_URL}/user/sendPasswordResetLink?email=${email}`        )
        .then((res) => {
          setloading(false);
          seterror(false);
          setconfirmation(true);
          setconfirmationMsg("Reset Password Link has been provided to your email.");
          setTimeout(() => {
            setconfirmation(false);
          }, 4000);

        //   navigate('/reset-password')
        })
        .catch((e) => {
          setloading(false);
          seterror(true);
          seterrorMsg(e.response?.data?.message);
        });
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", backgroundColor: "#F7F8FA" }}
    >
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ErrorSnackbar
        error={error}
        handleCloseError={seterror}
        errorMsg={errorMsg}
      />
      <ConfirmationSnackbar
        snackbarState={confirmation}
        text={confirmationMsg}
        handleSnackbarClose={setconfirmation}
      />
      <Grid
        item
        xs={false}
        sm={5}
        md={6.5}
        sx={{
          backgroundImage: `url(${require("../../assets/images/login/banner2.png")})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "40%",
            backgroundImage:
              "linear-gradient(180deg, rgba(8, 13, 27, 0) 0%, rgba(8, 13, 27, 0.9) 100%)", // Adjusted alpha value to make it darker
          }}
        />
        <img
          src={require("../../assets/images/logo_md.png")}
          alt="Logo"
          style={{
            position: "absolute",
            top: "7.5%",
            left: "7.5%",
          }}
        />
        <Typography
          sx={{
            fontWeight: "500",
            color: "#fff",
            fontSize: smallScreen ? "20px" : "28px",
            fontFamily: "FiraSans",
            position: "absolute",
            bottom: "10%",
            marginLeft: "7.5%",
          }}
        >
          Empowering Mental Wellness:
          <Typography
            sx={{
              fontWeight: "800",
              color: "#fff",
              fontSize: smallScreen ? "34px" : "44px",
              fontFamily: "FiraSans",
            }}
          >
            Transforming Lives Through Compassionate Psychiatry Care
          </Typography>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        md={5.5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#F7F8FA",
          position: "relative",
        }}
      >
        <Box
          sx={{
            my: smallScreen ? 0 : 6,
            mx: smallScreen ? 0 : 9,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            fontFamily="FiraSans"
            fontWeight={"bold"}
            color={colors.textColor}
            fontSize={34}
          >
            Forgot Password
          </Typography>
          {/* <Typography
            component="h1"
            variant="body1"
            fontFamily="FiraSans"
            fontWeight={"400"}
            color={colors.subText}
            fontSize={18}
          >
            We’ve already met!
          </Typography> */}
          <Box component="form" noValidate sx={{ mt: 4, px: 8 }}>
            <CustomInput
              value={email}
              setValue={setemail}
              placeholder="Email Address"
              Icon={MailOutlineIcon}
            />
            {/* <CustomPasswordInput
              mT
              mB
              value={password}
              setValue={setpassword}
            /> */}

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "20%",
                backgroundImage: `url(${require("../../assets/images/login/bottom.png")})`,
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomBtn
                text="Submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin(e);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop:"10px"
              }}
            >
               Already have an account 
                <Link to="/login" variant="body2" style={{color:"green"}}>
                 &nbsp; Sign In
                </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

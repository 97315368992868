import axios from "axios";
import axiosInstance, { BASE_URL } from "../../constants/config";
import { useNavigate } from "react-router-dom";

export const getCustomerById =
  (customerId = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "customerByIdRequest",
      });
      const { data } = await axiosInstance.get(
        `${BASE_URL}/customer/getSpecificCustomer?customerId=${customerId}`,
        {
          withCredentials: true,
        }
      );
      dispatch({
        type: "customerByIdSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "customerByIdFailure",
        payload: error,
      });
    }
  };

export const getAllCustomers = () => async (dispatch) => {
  try {
    dispatch({
      type: "allCustomerRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/customer/getAllCustomers`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allCustomerSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allCustomerFailure",
      payload: error,
    });
  }
};

export const getAllCustomerSites = () => async (dispatch) => {
  try {
    dispatch({
      type: "allCustomerSitesRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/site/getAllSitesWithoutCustomerId`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allCustomerSitesSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allCustomerSitesFailure",
      payload: error,
    });
  }
};

export const clearVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: "clearVendor",
    });
  } catch (error) {}
};

import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { colors, getCustomerStatusBackgroundColor, getStatusBackgroundColor, getStatusTextColor } from "../theme/theme";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { GreenModal, RedModal } from "./ConfirmationModal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Search,
  SearchIconWrapper,
  StyledDataGrid,
  StyledInputBase,
} from "./StyledSearchDataGrid";
import moment from "moment";
import TitleWithSearchBar from "./TitleWithSearchBar";
import { PeopleAltOutlined } from "@mui/icons-material";

const DataGridTable = ({
  half,
  margin,
  type,
  rowData,
  pageSize,
  title,
  access,
  createBtn = true,
  setopenModal,
  onRequest,
  hasAccess = false,
  requestPopup,
  setrequestPopup,
  search,
  setSearch,
  fullWidth,
  onCreate,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentRolePath = location?.pathname?.split("/")[1];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmation, setconfirmation] = useState(false);

  const [deleteModalState, setdeleteModalState] = useState(false);

  const [snackbarState, setsnackbarState] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const customerColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={params.row.avatar}
            alt=""
            style={{
              height: "30px",
              width: "30px",
              borderRadius: "50%",
            }}
          /> */}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params?.value}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.25,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlinedIcon
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 0.5,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "verified",
      headerName: "Status",
      flex: 1.25,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getStatusBackgroundColor(
                params?.value === true ? "active" : "Inactive"
              ),
              color: getStatusTextColor(params?.value === true ? "active" : "Inactive"),
              borderRadius: "8px",
            }}
          >
              {params?.value === true ? "Active" : "Inactive"}
          </Typography>
        </Box>
        </div>
      ),
    },

    // {
    //   field: "phone",
    //   headerName: "Phone",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <PhoneIphoneOutlinedIcon
    //         style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
    //       />
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //         }}
    //       >
    //         {params?.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "age",
    //   headerName: "Age",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //         }}
    //       >
    //         {params?.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //           ml: 0.5,
    //           textDecoration: "underline",
    //         }}
    //       >
    //         {params?.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "created_at",
    //   headerName: "Created On/By",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Box>
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //         }}
    //       >
    //         {params?.value}
    //       </Typography>

    //       {/* <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //         }}
    //       >
    //         {params.row.createdBy}
    //       </Typography> */}
    //     </Box>
    //   ),
    // },
    // {
    //   field: "actions",
    //   headerName: "",
    //   flex: 1,
    //   align: "center",
    //   renderCell: (params) => (
    //     <>
    //       <IconButton>
    //         <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton>
    //       <IconButton
    //         onClick={(e) => {
    //           handleClick(e);
    //         }}
    //       >
    //         <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton>
    //     </>
    //   ),
    // },
  ];

  const providerPatientColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={params.row.avatar}
            alt=""
            style={{
              height: "30px",
              width: "30px",
              borderRadius: "50%",
            }}
          /> */}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params?.row?.patients?.name}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.25,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlinedIcon
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 0.5,
            }}
          >
            {params?.row?.patients?.email }
          </Typography>
        </Box>
      ),
    },
    {
      field: "verified",
      headerName: "Status",
      flex: 1.25,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getStatusBackgroundColor(
                params?.row?.patients?.verified === true ? "active" : "Inactive"
              ),
              color: getStatusTextColor(params?.row?.patients?.verified === true ? "active" : "Inactive"),
              borderRadius: "8px",
            }}
          >
              {params?.row?.patients?.verified === true ? "Active" : "Inactive"}
          </Typography>
        </Box>
        </div>
      ),
    },
  ];

  const sitesColumns = [
    {
      field: "site_name",
      headerName: "Site Name",
      flex: 2,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={`https://flagcdn.com/w40/${params?.row?.countryCode}.png`}
            alt=""
          /> */}
          <div
            style={{
              marginLeft: 10,
              fontWeight: "400",
              color: colors.text,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexWrap: "wrap",
            }}
          >
            {params.value}
          </div>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.25,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getCustomerStatusBackgroundColor(
                params?.value?.toLocaleLowerCase()
              ),
              color: getStatusTextColor(params?.value?.toLocaleLowerCase()),
              borderRadius: "8px",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
        </div>
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 0.5,
    //   align: "center",
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         onClick={() =>
    //           navigate("/admin/customer-sites/update", {
    //             state: { id: params.row.id },
    //           })
    //         }
    //       >
    //         <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton>
    //       {/* <IconButton
    //         onClick={(e) => {
    //           handleClick(e);
    //         }}
    //       >
    //         <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton> */}
    //     </>
    //   ),
    // },
  ];

  const custColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={params.row.image}
            alt=""
            style={{ height: 32, width: 32 }}
          /> */}
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params.row?.organization_name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EmailOutlinedIcon
                style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#596066",
                  fontFamily: "FiraSans",
                  ml: 0.5,
                }}
              >
                {params.row?.email}
              </Typography>
            </Box>
          </Box>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",

              py: 1,
            }}
            style={{
              textAlign: "center",
              backgroundColor: getCustomerStatusBackgroundColor(
                params?.value?.toLocaleLowerCase()
              ),
              color: getStatusTextColor(params?.value?.toLocaleLowerCase()),

              borderRadius: "8px",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 0.5,
    //   align: "center",
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         onClick={() =>
    //           navigate("/admin/customer-sites/update", {
    //             state: { id: params.row.id },
    //           })
    //         }
    //       >
    //         <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton>
    //       {/* <IconButton
    //         onClick={(e) => {
    //           handleClick(e);
    //         }}
    //       >
    //         <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton> */}
    //     </>
    //   ),
    // },
  ];

  const formColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2.5,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={require("../assets/icons/pdf.png")}
            alt=""
            style={{ height: 32, width: 32 }}
          /> */}
          <Typography
            style={{
              marginLeft: 10,
              fontWeight: "400",
              color: colors.text,
              cursor: "pointer",
              fontSize: 14,
            }}
            onClick={() => window.open(params?.row?.form?.documentOne)}
          >
            {params.row?.name}
          </Typography>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.25,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getCustomerStatusBackgroundColor(
                params?.value?.toLocaleLowerCase()
              ),
              color: getStatusTextColor(params?.value?.toLocaleLowerCase()),
              borderRadius: "8px",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
        </div>
      ),
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <>
    //                     {!params?.row?.isAssigned && params?.row?.type!='Form' && (
    //                       <IconButton
    //                       onClick={() =>
    //                         navigate("/admin/activities/update", {
    //                           state: { id: params?.row?.id },
    //                         })}
    //                           >
    //                       <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
    //                     </IconButton>
    //             )}
    //       {/* <IconButton
    //         onClick={() => {
    //           navigate("/admin/forms/update", {
    //             state: { id: params.row?.form?.id },
    //           });
    //         }}
    //       >
    //         <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton> */}
    //       {/* <IconButton
    //         onClick={(e) => {
    //           handleClick(e);
    //         }}
    //       >
    //         <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton> */}
    //     </>
    //   ),
    // },
  ];

  const questionColumns = [
    {
      field: "question",
      headerName: "Names",
      flex: isSmallScreen ? 0.5 : 2.5,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ContactSupportIcon
            sx={{
              color: "#FDB302",
              height: "42px",
              width: "42px",
            }}
          />
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params.row?.question?.questionName}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#596066",
                  fontFamily: "FiraSans",
                }}
              >
                {moment(params.row?.question?.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "",
    //   flex: isSmallScreen ? 0.25 : 1,
    //   align: "center",
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         onClick={() => {
    //           navigate("/admin/questions/update", {
    //             state: { id: params.row?.question?.id },
    //           });
    //         }}
    //       >
    //         <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton>
    //       {/* <IconButton
    //         onClick={(e) => {
    //           handleClick(e);
    //         }}
    //       >
    //         <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton> */}
    //     </>
    //   ),
    // },
  ];

  const supervisorColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params?.row?.avatar !== "" && (
            <img
              src={params.row.avatar}
              alt=""
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
          )}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params?.value}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PeopleAltOutlined
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 1,
            }}
          >
            {params?.row?.isSupervisor ? "Supervisor" : null}
            {params?.row?.isDoctor && params?.row?.isSupervisor ? ", " : null}
            {params?.row?.isDoctor ? "Provider" : null}
            {params?.row?.isDoctor && params?.row?.isAdmin ? ", " : null}
            {params?.row?.isAdmin ? "Admin" : null}
          </Typography>
        </Box>
      ),
    },
    {
      field: "verified",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params.value ? "Active" : "Inactive"}
        </div>
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "",
    //   flex: 1,
    //   align: "center",
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         onClick={() =>
    //           navigate("/admin/users/update", {
    //             state: { id: params?.row?.id },
    //           })
    //         }
    //       >
    //         <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton>
    //       {/* <IconButton
    //         onClick={(e) => {
    //           handleClick(e);
    //         }}
    //       >
    //         <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton> */}
    //     </>
    //   ),
    // },
  ];

  const doctorsColumn = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={params.row.avatar}
            alt=""
            style={{
              height: "30px",
              width: "30px",
              borderRadius: "50%",
            }}
          /> */}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params?.value}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.25,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlinedIcon
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 0.5,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "verified",
      headerName: "Status",
      flex: 1.25,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getStatusBackgroundColor(
                params?.value === true ? "active" : "Inactive"
              ),
              color: getStatusTextColor(params?.value === true ? "active" : "Inactive"),
              borderRadius: "8px",
            }}
          >
              {params?.value === true ? "Active" : "Inactive"}
          </Typography>
        </Box>
        </div>
      ),
    },

    // {
    //   field: "created_at",
    //   headerName: "Created On/By",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Box>
    //       <Typography
    //         sx={{
    //           fontSize: "12px",
    //           fontWeight: "400",
    //           color: "#596066",
    //           fontFamily: "FiraSans",
    //         }}
    //       >
    //         {params?.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "actions",
    //   headerName: "",
    //   flex: 0.25,
    //   align: "center",
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         onClick={(e) => {
    //           handleClick(e);
    //         }}
    //       >
    //         <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
    //       </IconButton>
    //     </>
    //   ),
    // },
  ];

  const activityColumns = [
    {
      field: "names",
      headerName: "Name",
      flex: isSmallScreen ? 0.5 : 2.5,
      renderCell: (params) => (
        <Box
          sx={{
            displag: "flex",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: colors.text,
              fontFamily: "FiraSans",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2, // Limit number of lines to 2
              WebkitBoxOrient: "vertical",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "scheduled",
      headerName: "Created On/By",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.25,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];


  const assignedSites = [
    {
      field: "site_name",
      headerName: "Name",
      flex: 0.25,
      renderCell: (params) => (
        <Box
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      flex: 0.25,
      
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.25,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 0.25,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box
      mb={2}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
      }}
    >
      <GreenModal
        open={requestPopup}
        onClose={() => setrequestPopup(false)}
        onClick={() => {
          onRequest();
        }}
        title="Need request access"
        mainText="Requesting access to troubleshoot the customer's site."
        subText="Please ensure that you’ll have access only if the customer grants you access."
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={() => setsnackbarState(true)}
        title="Delete Customer"
        mainText="Are you sure you want to delete this customer?"
        subText="This action cannot be undone."
      />

      <Snackbar
        open={snackbarState}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Your request has been successfully submitted to the customer."
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <TitleWithSearchBar
        search={search}
        noSearch={true}
        noBtn={true}
        setSearch={setSearch}
        title={title}
        fullWidth={fullWidth}
        onClick={onCreate}
      />

      <StyledDataGrid
        rows={rowData}
        columns={
          type === "forms"
            ? formColumns
            : type === "employees"
            ? customerColumns
            : type === "questions"
            ? questionColumns
            : type === "supervisors"
            ? supervisorColumns
            : type === "doctors"
            ? doctorsColumn
            : type === "activity"
            ? activityColumns
            : type==="customers"
            ? custColumns
            : type==="providerpatients"?
            providerPatientColumns:
            type=='assignedSite' 
            ? assignedSites:
             sitesColumns
        }
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: pageSize },
          },
        }}
        sx={{
          borderRadius: "12px",
          border: "none",
        }}
        pageSizeOptions={[5, 10]}
        getRowClassName={(params) => (params.row.id % 2 === 0 ? "even" : "odd")}
        autoHeight
        autoLayout
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {type === "employees" ? (
          <>
            <MenuItem
              sx={{
                borderBottom: "1px solid #EBEFF5",
                color: "#24292E",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
            >
              Edit Role & permission
            </MenuItem>
            <MenuItem
              sx={{
                color: "#F34141",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
              onClick={() => {
                handleClose();
                setdeleteModalState(true);
              }}
            >
              Delete
            </MenuItem>
          </>
        ) : type === "forms" ? (
          <>
            <MenuItem
              sx={{
                borderBottom: "1px solid #EBEFF5",
                color: "#24292E",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
              onClick={() => {
                handleClose();
                navigate("/vendor/customers/create");
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              sx={{
                color: "#F34141",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
              onClick={() => {
                handleClose();
                setdeleteModalState(true);
              }}
            >
              Delete
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              sx={{
                borderBottom: "1px solid #EBEFF5",
                color: "#24292E",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
            >
              Edit Site
            </MenuItem>
            <MenuItem
              sx={{
                borderBottom: "1px solid #EBEFF5",
                color: "#24292E",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
              onClick={() => setrequestPopup(true)}
            >
              Request Access Patients
            </MenuItem>
            <MenuItem
              sx={{
                borderBottom: "1px solid #EBEFF5",
                color: "#24292E",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
              onClick={() => setrequestPopup(true)}
            >
              Request Access Dcotors
            </MenuItem>
            <MenuItem
              sx={{
                borderBottom: "1px solid #EBEFF5",
                color: "#24292E",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
              onClick={() => setrequestPopup(true)}
            >
              Request Access Supervisors
            </MenuItem>
            <MenuItem
              sx={{
                color: "#F34141",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "FiraSans",
              }}
              onClick={() => {
                handleClose();
                setdeleteModalState(true);
              }}
            >
              Delete
            </MenuItem>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default DataGridTable;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import Grid from "@mui/material/Grid";
import { colors } from "../../../theme/theme";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  OutlinedInput,
  CircularProgress,
  Backdrop,
  Alert,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CreateCustomInput,
  CreateCustomTextArea,
  CreateDropDown,
  CreateHeader,
} from "../../../components/CreatePagesComponents";
import { Close, EditNote, FeedOutlined } from "@mui/icons-material";
import { UploadBtn } from "../../../components/CustomBtn";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { formTypes } from "../../../assets/data/DummyData";
import { getAllFormsForAdmin } from "../../../redux/dispatchers/admin.dispatcher";

const CreateFormsInAdmin = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [confirmation, setconfirmation] = useState(false);

  const [formDoc, setformDoc] = useState("");
  const [customerSite, setCustomerSite] = useState([]);
  const [editCustomerSite, seteditCustomerSite] = useState([]);
  const [deletedCustomerSites, setdeletedCustomerSites] = useState([]);

  const [formData, setFormData] = useState({
    formName: "",
    formDesc: "",
    formType: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const { siteData } = useSelector((state) => state.admin);
  const { userData } = useSelector((state) => state.user);

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const customerDropdownData = siteData?.map((customer) => ({
    label: customer?.site_name,
    value: customer?.site_name,
    siteId: customer?.id,
    customerId: customer?.customerId,
  }));

  const GetFormById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/form/getOneForm?formId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleForm = res.data?.allForm[0];
        seteditCustomerSite(
          singleForm?.form_sites?.map((item) => ({
            label: item?.site?.site_name,
            value: item?.site?.site_name,
            siteId: item?.siteId,
            customerId: item?.customerId,
          }))
        );
        setFormData({
          formName: singleForm?.form_name,
          formDesc: singleForm?.formDescription,
          formType: singleForm?.formType,
        });
        setformDoc(singleForm?.documentOne);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const CreateForm = async () => {
    if (formData.formName === "" || customerSite === "" || formDoc === "") {
      seterror(true);
      seterrorMsg("Please fill all the fields");
      return;
    } else {
      seterror(false);
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/form/createForm`,
          {
            allSites: customerSite?.map((item) => ({
              siteId: item.id,
              customerId: userData?.customerId,
            })),
            form_name: formData.formName,
            formType: formData.formType,
            formDescription: formData.formDesc,
            documentOne: formDoc,
            status: "active",
            created_by: userData?.name,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setFormData({
            formName: "",
            formDesc: "",
            formType: "",
          });
          setformDoc("");
          setCustomerSite("");
          navigate("/admin/forms");
          dispatch(getAllFormsForAdmin(userData?.customerId));
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const EditForm = async () => {
    seterror(false);
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/form/editForm`,
        {
          formId: location?.state?.id,
          allSites: customerSite?.map((item) => ({
            siteId: item?.id,
            customerId: item?.customerId,
          })),
          deleteFormSite: deletedCustomerSites?.map((item) => ({
            siteId: item?.siteId,
          })),
          form_name: formData.formName,
          formType: formData.formType,
          formDescription: formData.formDesc,
          documentOne: formDoc,
          status: "active",
          created_by: userData?.name,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setFormData({
          formName: "",
          formDesc: "",
          formType: "",
        });
        setformDoc("");
        setCustomerSite("");
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetFormById();
    }
  }, []);

  const [open, setOpen] = React.useState(false);

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const filteredCustomerSites = siteData
    ?.filter((val) => {
      return !editCustomerSite?.some((id) => id.siteId === val.id);
    })
    ?.map((site) => ({
      label: site.site_name,
      value: site.site_name,
      siteId: site.id,
      customerId: site.customerId,
    }));

  const handleDeleteSite = (siteId) => {
    const index = editCustomerSite.findIndex((item) => item.siteId === siteId);

    if (index !== -1) {
      const deletedSite = editCustomerSite.splice(index, 1)[0];

      const exists = deletedCustomerSites.some(
        (item) => item.siteId === deletedSite.siteId
      );

      if (!exists) {
        seteditCustomerSite([...editCustomerSite]);
        setdeletedCustomerSites([...deletedCustomerSites, deletedSite]);
      }
    }
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? `Form has been created successfully!`
            : `Form has been updated successfully!`
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={params?.type === "update" ? "Update Forms" : "Create Forms"}
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Form"
                      : "Update Form"
                  }
                  btnTitle={params?.type === "create" ? "Create" : "Update"}
                  onClick={params?.type === "create" ? CreateForm : EditForm}
                  cancelFunc={() => navigate("/admin/forms")}
                />
                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {params?.type === "update" ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            {editCustomerSite?.map((item) => (
                              <Stack
                                direction="row"
                                sx={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  borderRadius: "12px",
                                  backgroundColor: "#FAFAFA",
                                  padding: "0px",
                                  border: "1px solid #EBEFF5",
                                  mr: 2,
                                  px: 2,
                                  py: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.label}
                                </Typography>
                                <IconButton
                                  sx={{ height: "15px", width: "15px", ml: 1 }}
                                  onClick={() => handleDeleteSite(item.siteId)}
                                >
                                  <Close sx={{ fontSize: "15px" }} />
                                </IconButton>
                              </Stack>
                            ))}
                          </Box>
                          <FormControl fullWidth>
                            <Select
                              multiple
                              displayEmpty
                              value={personName}
                              onChange={handleChange}
                              open={open}
                              onClose={() => setOpen(false)}
                              onOpen={() => setOpen(true)}
                              MenuProps={{
                                onClose: () => setOpen(false),
                              }}
                              input={<OutlinedInput />}
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  fontFamily: "FiraSans",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  color: "#000",
                                },
                              }}
                              renderValue={(selected) => {
                                if (selected?.length === 0) {
                                  return (
                                    <em
                                      style={{
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        color: "#000",
                                      }}
                                    >
                                      Select Customer Site
                                    </em>
                                  );
                                }

                                return selected?.join(", ");
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                              sx={{
                                height: "50px",
                                borderRadius: "7px",
                                backgroundColor: "#FAFAFA",
                                elevation: 0,
                                fontFamily: "FiraSans",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: colors.text,
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#EBEFF5",
                                },
                                "& input::placeholder": {
                                  color: colors.text,
                                  opacity: 1,
                                  fontFamily: "FiraSans",
                                },
                                ".MuiSvgIcon-root ": {
                                  fill: "black !important",
                                },
                              }}
                            >
                              {filteredCustomerSites?.map((option) => (
                                <MenuItem
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                  key={option.value}
                                  value={option.value}
                                  onClick={() => {
                                    setCustomerSite((prevSelectedIds) => {
                                      const selectedId = siteData?.find(
                                        (website) =>
                                          website.id === option.siteId
                                      );

                                      if (
                                        prevSelectedIds.includes(selectedId)
                                      ) {
                                        return prevSelectedIds.filter(
                                          (id) => id !== selectedId
                                        );
                                      } else {
                                        return [...prevSelectedIds, selectedId];
                                      }
                                    });
                                    setOpen(false);
                                  }}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      ) : (
                        <FormControl fullWidth>
                          <Select
                            multiple
                            displayEmpty
                            value={personName}
                            onChange={handleChange}
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            MenuProps={{
                              onClose: () => setOpen(false),
                            }}
                            input={<OutlinedInput />}
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                fontFamily: "FiraSans",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#000",
                              },
                            }}
                            renderValue={(selected) => {
                              if (selected?.length === 0) {
                                return (
                                  <em
                                    style={{
                                      fontStyle: "normal",
                                      fontSize: "14px",
                                      color: "#000",
                                    }}
                                  >
                                    Select Customer Site
                                  </em>
                                );
                              }

                              return selected?.join(", ");
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              height: "50px",
                              borderRadius: "7px",
                              backgroundColor: "#FAFAFA",
                              elevation: 0,
                              fontFamily: "FiraSans",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: colors.text,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EBEFF5",
                              },
                              "& input::placeholder": {
                                color: colors.text,
                                opacity: 1,
                                fontFamily: "FiraSans",
                              },
                              ".MuiSvgIcon-root ": {
                                fill: "black !important",
                              },
                            }}
                          >
                            {customerDropdownData?.map((option) => (
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                }}
                                key={option.value}
                                value={option.value}
                                onClick={() => {
                                  setCustomerSite((prevSelectedIds) => {
                                    const selectedId = siteData?.find(
                                      (website) => website.id === option.siteId
                                    );

                                    if (prevSelectedIds.includes(selectedId)) {
                                      return prevSelectedIds.filter(
                                        (id) => id !== selectedId
                                      );
                                    } else {
                                      return [...prevSelectedIds, selectedId];
                                    }
                                  });
                                  setOpen(false);
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <CreateCustomInput
                        value={formData.formName}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            formName: value,
                          }))
                        }
                        placeholder="Form Name"
                        Icon={FeedOutlined}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CreateDropDown
                        value={formData.formType}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            formType: value,
                          }))
                        }
                        title="Form Type"
                        data={formTypes}
                        Icon={FeedOutlined}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CreateCustomTextArea
                        value={formData.formDesc}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            formDesc: value,
                          }))
                        }
                        placeholder="Form Description"
                        Icon={EditNote}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <UploadBtn
                        onClick={setformDoc}
                        type={formData?.formType}
                        formDoc={formDoc}
                        setloading={setloading}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateFormsInAdmin;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import FilterBox from "../../../components/FilterBox";
import { colors } from "../../../theme/theme";
import {
  Search,
  SearchIconWrapper,
  StyledDataGrid,
  StyledInputBase,
} from "../../../components/StyledSearchDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { dummyQuestionData } from "../../../assets/data/DummyData";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { Close } from "@mui/icons-material";
import { RedModal } from "../../../components/ConfirmationModal";
import moment from "moment";
import InactiveSwitch from "../../../components/InactiveSwitch";
import { getAllQuestionsForSupervisor } from "../../../redux/dispatchers/supervisor.dispatcher";

const QuestionsProvider = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const questionsColumn = [
    {
      field: "question",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ContactSupportIcon
            sx={{
              color: "#FDB302",
              height: "42px",
              width: "42px",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.text,
              fontFamily: "FiraSans",
              ml: 1,
              textDecoration: "underline",
              cursor: params?.row?.question?.document_url
                ? "pointer"
                : "default",
            }}
            onClick={() => {
              if (params?.row?.question?.document_url) {
                window.open(params?.row?.question?.document_url);
              }
            }}
          >
            {params.row?.question?.questionName}
          </Typography>
        </Box>
      ),
    },
    {
      field: "customer",
      headerName: "Customer Name",
      flex: 1.25,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              textDecoration: "underline",
            }}
          >
            {params.row?.site?.site_name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created On/By",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            marginLeft: 10,
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {moment(params.row?.question?.createdAt).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              navigate("/provider/questions/update", {
                state: { id: params.row?.question?.id, provider: true },
              });
              setquestionId(params.row?.question?.id);
            }}
          >
            <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setquestionId(params.row?.question?.id);
              setquestionStatus(params.row?.question?.status);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const { allQuestions } = useSelector((state) => state.supervisor);

  const [questionId, setquestionId] = useState("");

  const { userData } = useSelector((state) => state.user);

  const [status, setstatus] = useState("");
  const [questionStatus, setquestionStatus] = useState("");

  useEffect(() => {
    dispatch(getAllQuestionsForSupervisor(userData?.siteId));
  }, []);

  const [deleteModalState, setdeleteModalState] = useState(false);
  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setdeleteConfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const DeleteQues = async () => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/question/updateQuestionStatus`,
        {
          questionId: questionId,
          status: status,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdeleteModalState(false);
        setdeleteConfirmation(true);
        dispatch(getAllQuestionsForSupervisor(userData?.siteId));
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const [search, setsearch] = useState("");

  const [activeSwitch, setactiveSwitch] = useState(true);

  const handleactiveSwitch = () => {
    setactiveSwitch(!activeSwitch);
  };

  const filteredQuestions = allQuestions?.filter(
    (val) => val?.question?.status === (activeSwitch ? "active" : "inactive")
  );

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer provider />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={deleteConfirmation}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={`Question ${
          status === "deleted" ? "delete" : "marked as " + status
        } successfully!`}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={DeleteQues}
        title={
          status === "deleted" ? "Delete Question" : "Change Question Status"
        }
        actionText={status === "deleted" ? "Delete" : "Change"}
        mainText={`Are you sure you want to ${
          status === "deleted" ? status : "mark this question as " + status
        }`}
        subText={status === "deleted" ? "This action cannot be undone." : ""}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Questions" />

        {/* <FilterBox type="questions" /> */}
        <Grid container p={2}>
          <Grid item xs={12} mb={2}>
            <InactiveSwitch
              activeSwitch={activeSwitch}
              handleactiveSwitch={handleactiveSwitch}
              title={"Questions"}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Questions"
                onClick={() => {
                  navigate("/provider/questions/create", {
                    state: { provider: true },
                  });
                }}
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  filteredQuestions?.filter((val) =>
                    val?.question?.questionName
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={questionsColumn}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    borderBottom: "1px solid #EBEFF5",
                    color: "#24292E",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();
                    navigate("/provider/questions/update", {
                      state: { id: questionId, provider: true },
                    });
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  sx={{
                    color: status === "active" ? "#F34141" : "#000",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                    borderBottom: "1px solid #EBEFF5",
                  }}
                  onClick={(e) => {
                    setdeleteModalState(true);
                    if (questionStatus === "active") {
                      setstatus("inactive");
                    } else {
                      setstatus("active");
                    }
                    handleClose(e);
                  }}
                >
                  {questionStatus === "active"
                    ? "Mark as Inactive"
                    : "Mark as Active"}
                </MenuItem>
                <MenuItem
                  sx={{
                    color: "#F34141",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={(e) => {
                    handleClose(e);
                    setdeleteModalState(true);
                    setstatus("deleted");
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default QuestionsProvider;

export const colors = {
  primary: "#3BB247",

  green: "#138418",

  textColor: "#333742",
  subText: "#777981",

  text: "#030315",
  lightText: "#596066",

  primaryBtn: "#138418",
};

export const getStatusBackgroundColor = (status) => {
  switch (status) {
    case "new":
      return "#F0F0F0";
    case "closed":
      return "#F37373";
    case "opened":
      return "#6AD09D";
    case "active":
      return "#73AFF3";
    case "suspended":
      return "#FBBC55";
    case "Inactive":
      return "#FBBC55";

    default:
      return "#fff";
  }
};

export const getCustomerStatusBackgroundColor = (status) => {
  switch (status) {
    case "initial setup":
      return "#F0F0F0";
    case "set to go live":
      return "#F0F0F0";

    case "inactive":
      return "#73AFF3";
    case "q/a":
      return "#FBBC55";
    case "active":
      return "#6AD09D";
    case "closed":
      return "#F37373";
    case "opened":
      return "#6AD09D";
    case "suspended":
      return "#FBBC55";
    default:
      return "#fff";
  }
};

export const getStatusTextColor = (status) => {
  switch (status) {
    case "new":
      return "#000";
    case "initial setup":
      return "#222";
    case "set to go live":
      return "#222";

    case "low":
      return "#1A9882";
    case "in stock":
      return "#1A9882";
    case "high":
      return "#EB3D4D";
    case "out of stock":
      return "#EB3D4D";
    case "medium":
      return "#F86624";
    default:
      return "#fff";
  }
};

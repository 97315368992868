import { createContext, useContext, useState } from "react";

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [navigationId, setnavigationId] = useState(0);

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [formSites, setformSites] = useState([]);

  const [userRole, setuserRole] = useState("");

  const [userRoleLoading, setuserRoleLoading] = useState(false);

  return (
    <UserContext.Provider
      value={{
        setnavigationId,
        navigationId,
        toggleDrawer,
        state,
        userData,
        setUserData,
        setformSites,
        formSites,
        userRole,
        setuserRole,
        userRoleLoading,
        setuserRoleLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}

import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import {
  Checklist,
  ChecklistOutlined,
  EditOutlined,
  EmailOutlined,
  Feed,
  FeedOutlined,
  Person2Outlined,
  PhoneIphoneOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import InactiveSwitch from "../../../components/InactiveSwitch";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const CustomerProfileBoxComponent = ({ patientData }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            height: "100px",
            borderRadius: "20px",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 4,
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            {patientData?.avatar !== "" && (
              <img
                alt=""
                src={patientData?.avatar}
                style={{
                  height: "75px",
                  width: "75px",
                  borderRadius: "360px",
                  objectFit: "cover",
                }}
              />
            )}

            {/* Name */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: colors.text,
                  fontFamily: "FiraSans",
                }}
              >
                {patientData?.fullName}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "12px",
                  color: colors.lightText,
                  fontFamily: "FiraSans",
                }}
              >
                #12456855{" "}
              </Typography> */}
            </Box>
          </Stack>

          {/* Email */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <EmailOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              {patientData?.email}
            </Typography>
          </Box>

          {/* Phone */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PhoneIphoneOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              {patientData?.mobile}
            </Typography>
          </Box>

          {/* Profession */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Person2Outlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              {patientData?.gender}
            </Typography>
          </Box>

          {/* Hospital */}
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocalHospitalOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              St. Joseph Hospital{" "}
            </Typography>
          </Box> */}

          {/* Btns */}
          <Stack direction={"row"}>
            <IconButton
              onClick={() => {
                navigate("/supervisor/users/update", {
                  state: { id: patientData?.id },
                });
              }}
            >
              <EditOutlined
                sx={{ color: colors.lightText, fontSize: "20px" }}
              />
            </IconButton>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

const PatientInsights = ({isForm = true}) => {
  const [navigationId, setnavigationId] = useState(0);

  const subNav = [
    // {
    //   id: 0,
    //   title: "Daily Check-Ins",
    //   icon: navigationId === 0 ? <DoneAll /> : <DoneAllOutlined />,
    // },
    // {
    //   id: 1,
    //   title: "Responses",
    //   icon: navigationId === 1 ? <Forum /> : <ForumOutlined />,
    // },
    {
      id: 0,
      title: "Activities",
      icon: navigationId === 2 ? <Checklist /> : <ChecklistOutlined />,
    },
    {
      id: 1,
      title: "Patient Documents",
      icon: navigationId === 3 ? <Feed /> : <FeedOutlined />,
    },
  ];

  const activitiesColumn = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.text,
              fontFamily: "FiraSans",
              ml: 1,
              textDecoration: "underline",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Activity Type",
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 1,
                // whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params?.value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: "Assigned By",
      flex: 1.25,
      renderCell: (params) => {
        const assignedBy = params?.row?.assignedBy;
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {assignedBy?.avatar !== "" && (
              <img
                src={assignedBy?.avatar}
                alt=""
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                textDecoration: "underline",
                ml: 1,
              }}
            >
              {assignedBy?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              if (params.row.type === "Form") {
                GetFormDetails(
                  params.row.id,
                  params?.row?.startDate,
                  params?.row?.endDate
                );
                setassignedFormName(params.row.name);
              } else {
                GetActivtityDetails(
                  params.row.id,
                  params?.row?.startDate,
                  params?.row?.endDate
                );
                setassignedActivityName(params.row.name);
              }
            }}
          >
            <VisibilityOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const ActivityDetailsColumn = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "name",
      headerName: "Question",
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.text,
              fontFamily: "FiraSans",
              ml: 1,
              textDecoration: "underline",
            }}
          >
            {params.row?.activity?.activityName}
          </Typography>
        </Box>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1.25,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
              }}
            >
              {moment(params?.value).format("MM/DD/YYYY")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "answer",
      headerName: "Response",
      flex: 1.25,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
              }}
            >
              {params?.value || "Not answered yet"}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const formsColumn = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "formName",
      headerName: "Form Name",
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <img
            src={require("../../../assets/icons/pdf.png")}
            style={{
              height: "30px",
              width: "30px",
              marginRight: "10px",
            }}
            alt=""
          /> */}
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.text,
              fontFamily: "FiraSans",
              ml: 1,
              textDecoration: "underline",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1.25,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
              }}
            >
              {moment(params?.value).format("MM/DD/YYYY")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              setnavigationId(4);
              setassignedFormName(params.row?.formName);
              setassignedFormQuestions(params?.row?.questionAndAnswers);
            }}
          >
            <VisibilityOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const questionsColumn = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "question",
      headerName: "Question",
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.text,
              fontFamily: "FiraSans",
              ml: 1,
              textDecoration: "underline",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "answer",
      headerName: "Response",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            marginLeft: 10,
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params?.value || "Not answered yet"}
        </div>
      ),
    },
  ];

  const patientDocumentColumn = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    // {
    //   field: "documentName",
    //   headerName: "Document Name",
    //   flex: 2,
    //   renderCell: (params) => (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography
    //         sx={{
    //           fontSize: "14px",
    //           fontWeight: "400",
    //           color: colors.text,
    //           fontFamily: "FiraSans",
    //           ml: 1,
    //           textDecoration: "underline",
    //         }}
    //       >
    //         {params.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    {
      field: "document_url",
      headerName: "View Document",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            marginLeft: 10,
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
            textDecoration: "underline",
          }}
          onClick={() => window.open(params?.value, "_blank")}
        >
          View Document
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params?.value}
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [patientData, setpatientData] = useState({});
  const [patientDownloadForms, setpatientDownloadForm] = useState([]);
  const [patientDownloadActivities, setpatientDownloadActivities] = useState(
    []
  );

  const [loading, setloading] = useState(false);

  const GetPatientById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getSpecificUser?userId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singlePatient = res.data?.specificUser;
        setpatientData((prevData) => ({
          ...prevData,
          fullName: singlePatient?.name,
          email: singlePatient?.email,
          mobile: singlePatient?.mobile,
          gender: singlePatient?.gender,
          avatar: singlePatient?.avatar,
          id: singlePatient?.id,
        }));
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetPatientById();
  }, []);

  const [patientDocuments, setpatientDocuments] = useState([]);
  const [searchForm, setSearchForm] = useState("");

  const [assignedActivity, setassignedActivity] = useState([]);
  const [searchActivity, setSearchActivity] = useState("");

  const [assignedActivityName, setassignedActivityName] = useState("");
  const [assignedActivityDetails, setassignedActivityDetails] = useState([]);

  const [assignedFormName, setassignedFormName] = useState("");
  const [assignedFormDetails, setassignedFormDetails] = useState([]);
  const [assignedFormQuestions, setassignedFormQuestions] = useState([]);

  const GetActivityAssigned = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/user/getAllAssignmentsByPatientId?patientId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setassignedActivity(res.data?.allFormsAndActivities);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetActivtityDetails = async (activityId, startDate, endDate) => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/patient_activity/getPatientActivitiesByActivityId?activityId=${activityId}&startDate=${startDate}&endDate=${endDate}&patientId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setnavigationId(2);
        setassignedActivityDetails(res.data?.assignedActivity);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetFormDetails = async (formId, startDate, endDate) => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/patient_form/getPatientFormsByFormId?formId=${formId}&startDate=${startDate}&endDate=${endDate}&patientId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setnavigationId(3);
        setassignedFormDetails(res.data?.assignedForm);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetPatientDocuments = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/patient_document/getDocumentByPatientId?patientId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setpatientDocuments(res.data?.allDocuments);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetAllPatientForms = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/patient_form/getAllPatientForms?patientId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setpatientDownloadForm(res.data?.assignedForm);
        setpatientDownloadActivities(res.data?.assignedActivities);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetActivityAssigned();
    GetPatientDocuments();
    GetAllPatientForms();
  }, []);

  const [activeSwitch, setactiveSwitch] = useState(true);

  const handleactiveSwitch = () => {
    setactiveSwitch(!activeSwitch);
  };

  const current = new Date();

  const filteredActivities = assignedActivity?.filter((val) => {
    const endDate = new Date(val?.endDate);
    return activeSwitch ? endDate > current : endDate <= current;
  });

  const handleExportData = () => {
    const filteredFormsToDownload = patientDownloadForms?.filter((val) => {
      const endDate = new Date(val?.endDate);
      return activeSwitch ? endDate > current : endDate <= current;
    });

    const filteredActivitiesToDownload = patientDownloadActivities?.filter(
      (val) => {
        const endDate = new Date(val?.endDate);
        return activeSwitch ? endDate > current : endDate <= current;
      }
    );

    const wb = XLSX.utils.book_new();

    const applyHeaderStyle = (sheet, headerRange) => {
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + "2";
        if (!sheet[address]) continue;
        sheet[address].s = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "#000" },
          },
          font: {
            bold: true,
          },
        };
      }
    };
    let totalActivities = filteredActivitiesToDownload.length,
      totalForms = filteredFormsToDownload.length,
      answeredForms = 0,
      answeredActivities = 0;
    for (var i = 0; i < filteredFormsToDownload.length; i++) {
      if (filteredFormsToDownload[i].questionAndAnswers[0].answer != null) {
        answeredForms++;
      }
    }
    for (var z = 0; z < filteredActivitiesToDownload.length; z++) {
      if (filteredActivitiesToDownload[z].answer != null) {
        answeredActivities++;
      }
    }

    const assignedFormSheetData = [
      [`Assigned Forms for patient ${patientData.fullName}`],
      [`Total Forms ${totalForms}`],
      [`Answered Forms ${answeredForms}`],
      ["Date", "Form Name", "Form Type", "Question", "Answers", "Answer"],
      ...filteredFormsToDownload.map((form) => [
        form.date.split("T")[0],
        // form.formId,
        form.formName,
        form.formType,
        // form.id,
        form.questionAndAnswers[0].question,
        form.questionAndAnswers[0].answers.join(", "),
        form.questionAndAnswers[0].answer,
      ]),
    ];
    const assignedFormSheet = XLSX.utils.aoa_to_sheet(assignedFormSheetData);
    applyHeaderStyle(
      assignedFormSheet,
      XLSX.utils.decode_range(assignedFormSheet["!ref"])
    );
    XLSX.utils.book_append_sheet(wb, assignedFormSheet, "Assigned Forms");

    const assignedActivitiesSheetData = [
      [`Assigned Activities for patient ${patientData.fullName}`],
      [`Total Activities ${totalActivities}`],
      [`Answered Activities ${answeredActivities}`],
      [
        "Activity Name",
        "Activity Type",
        "Answer",
        "Options",
        "Status",
        "Date",
        "Frequency",
      ],
      ...filteredActivitiesToDownload.map((activity) => [
        activity.activity.activityName,
        activity.activity.activityType,
        activity.answer,
        activity.activity.options,
        activity.activity.status,
        activity.date.split("T")[0],
        activity.frequency,
      ]),
    ];
    const assignedActivitiesSheet = XLSX.utils.aoa_to_sheet(
      assignedActivitiesSheetData
    );
    applyHeaderStyle(
      assignedActivitiesSheet,
      XLSX.utils.decode_range(assignedActivitiesSheet["!ref"])
    );
    XLSX.utils.book_append_sheet(
      wb,
      assignedActivitiesSheet,
      "Assigned Activities"
    );

    const adjustColumnWidths = (sheet) => {
      const cols = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let maxLength = 10;
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const cell = sheet[XLSX.utils.encode_cell({ r: R, c: C })];
          if (cell && cell.v) {
            const length = cell.v.toString().length;
            if (length > maxLength) {
              maxLength = length;
            }
          }
        }
        cols.push({ wch: maxLength });
      }
      sheet["!cols"] = cols;
    };

    adjustColumnWidths(assignedFormSheet);
    adjustColumnWidths(assignedActivitiesSheet);

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== wbout.length; ++i)
      view[i] = wbout.charCodeAt(i) & 0xff;
    const blob = new Blob([buf], { type: "application/octet-stream" });
    saveAs(blob, "data.xlsx");
  };

  return (
    <Box style={{ backgroundColor: "#fff", display: "flex", flex: 1, }} sx={{
      mt:2
    }}>
      {isForm && <MenuDrawer supervisor />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        {isForm &&( 
          <>
          <AppBar title="Patient Profile" />

        <CustomerProfileBoxComponent patientData={patientData} /> 
        </>)}

        <Stack direction="row" alignItems="center">
          <Grid container spacing={2} p={2}>
            {isForm && ( <Grid item xs={3}>
              <Box
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  //   alignItems: "center",
                  justifyContent: "center",
                  py: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#9EA5AD",
                    fontFamily: "FiraSans",
                    px: 2,
                    textTransform: "uppercase",
                    mb: 1,
                  }}
                >
                  Quick Navigation
                </Typography>
                {subNav.map((item, index) => (
                  <ListItem
                    key={item.title}
                    disablePadding
                    sx={{
                      backgroundColor:
                        item.id === navigationId ? "#f7f8fa" : "transparent",
                    }}
                  >
                    <ListItemButton onClick={() => setnavigationId(item.id)}>
                      <ListItemIcon
                        sx={{
                          color:
                            item.id === navigationId
                              ? colors.text
                              : colors.lightText,
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color:
                            item.id === navigationId
                              ? colors.text
                              : colors.lightText,
                        }}
                        primary={item.title}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </Grid> )}

            {/* Activites */}
            {navigationId === 0 && (
              <Fade in={navigationId === 0}>
                <Grid item xs={isForm?9:12}>
                  <Grid container spacing={2} px={2}>
                    {isForm && ( <Grid item xs={12} mb={2}>
                      <InactiveSwitch
                        activeSwitch={activeSwitch}
                        handleactiveSwitch={handleactiveSwitch}
                        title={"Activities"}
                        past={true}
                      />
                    </Grid> )}

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "#f7f8fa",
                          borderRadius: "12px",
                        }}
                      >
                        <TitleWithSearchBar
                          title="Activities"
                          noBtn
                          search={searchActivity}
                          showbutton={true}
                          excelButtonOnClick={handleExportData}
                        />

                        <StyledDataGrid
                          rows={
                            filteredActivities?.filter((val) =>
                              val?.name
                                ?.toLocaleLowerCase()
                                ?.includes(searchActivity?.toLocaleLowerCase())
                            ) || []
                          }
                          getRowId={(row) => row?.patientActivityId}
                          columns={activitiesColumn}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}

            {/* Patient Documents */}
            {navigationId === 1 && (
              <Fade in={navigationId === 1}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                        }}
                      >
                        <TitleWithSearchBar
                          title="Patient Documents"
                          noBtn
                          search={searchForm}
                          setSearch={setSearchForm}
                        />

                        <StyledDataGrid
                          rows={
                            patientDocuments?.filter((val) =>
                              val?.documentName
                                ?.toLocaleLowerCase()
                                ?.includes(searchForm?.toLocaleLowerCase())
                            ) || []
                          }
                          columns={patientDocumentColumn}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}

            {/* Activity Details */}
            {navigationId === 2 && (
              <Fade in={navigationId === 2}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                        }}
                      >
                        <TitleWithSearchBar
                          title={assignedActivityName}
                          noBtn
                          noSearch
                          backBtn
                          backBtnClick={() => setnavigationId(0)}
                        />

                        <StyledDataGrid
                          rows={assignedActivityDetails || []}
                          columns={ActivityDetailsColumn}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowClassName={(params) =>
                            params.row.id % 2 === 0 ? "even" : "odd"
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}

            {/* Forms */}
            {navigationId === 3 && (
              <Fade in={navigationId === 3}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                        }}
                      >
                        <TitleWithSearchBar
                          title="Form Details"
                          noBtn
                          noSearch
                          backBtn
                          backBtnClick={() => setnavigationId(0)}
                        />

                        <StyledDataGrid
                          rows={assignedFormDetails || []}
                          columns={formsColumn}
                          getRowId={(row) => row?.id}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}

            {/* Form Questions */}
            {navigationId === 4 && (
              <Fade in={navigationId === 4}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        mb={2}
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                          p: 2,
                        }}
                      >
                        <TitleWithSearchBar
                          title={assignedFormName}
                          noBtn
                          noSearch
                          backBtn
                          backBtnClick={() => setnavigationId(3)}
                        />
                        <StyledDataGrid
                          rows={assignedFormQuestions || []}
                          columns={questionsColumn}
                          getRowId={(row) => row?.id}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

export default PatientInsights;

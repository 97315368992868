import React from "react";
import { Route } from "react-router-dom";
import SupervisorDashboard from "../Pages/Supervisor/Dashboard/SupervisorDashboard";
import PatientsSupervisor from "../Pages/Supervisor/Patients/PatientsSupervisor";
import CreatePatient from "../Pages/Supervisor/Patients/CreatePatient";
import DoctorsSupervisor from "../Pages/Supervisor/Doctors/DoctorsSupervisor";
import CreateDoctor from "../Pages/Supervisor/Doctors/CreateDoctor";
import ReassignDrForm from "../Pages/Supervisor/Reassign/ReassignDrForm";
import ReassignDr from "../Pages/Supervisor/Reassign/ReassignDr";
import ReassignPatient from "../Pages/Supervisor/Reassign/ReassignPatient";
import ReassignPatientForm from "../Pages/Supervisor/Reassign/ReassignPatientForm";
import AssignActivityPatient from "../Pages/Supervisor/Patients/AssignActivityPatient";
import ChangeActivitySchedule from "../Pages/Supervisor/Patients/ChangeActivitySchedule";
import FormsSupervisor from "../Pages/Supervisor/Forms/FormsSupervisor";
import CreateForm from "../Pages/Vendor/Forms/CreateForm";
import ActivitiesSupervisor from "../Pages/Supervisor/Activities/ActivitiesSupervisor";
import CreateActivitySupervisor from "../Pages/Supervisor/Activities/CreateActivitySupervisor";
import QuestionsSupervisor from "../Pages/Supervisor/Questions/QuestionsSupervisor";
import CreateQuestionSupervisor from "../Pages/Supervisor/Questions/CreateQuestionSupervisor";
import Assignments from "../Pages/Supervisor/Assignments/Assignments";
import DoctorProfile from "../Pages/Supervisor/Doctors/DoctorProfile";
import PatientInsights from "../Pages/Supervisor/Patients/PatientInsights";
import EditProfile from "../Pages/Auth/EditProfile";
import CreateDailyGoal from "../Pages/Supervisor/Patients/CreateDailyGoal";
import SingleForm from "../Pages/Vendor/Forms/SingleForm";
import SupervisorUsers from "../Pages/Supervisor/SupervisorUsers/SupervisorUsers";
import CreateUserDrPatient from "../Pages/Supervisor/SupervisorUsers/CreateUserDrPatient";

const SupervisorRoutes = () => {
  return (
    <React.Fragment>
      <Route exact path="/supervisor/edit-profile" element={<EditProfile />} />

      {/* Dashboard */}
      <Route
        exact
        path="/supervisor/dashboard"
        element={<SupervisorDashboard />}
      />

      {/* Users */}
      <Route exact path="/supervisor/users" element={<SupervisorUsers />} />
      <Route
        exact
        path="/supervisor/users/:type"
        element={<CreateUserDrPatient />}
      />

      {/* Patients */}
      <Route path="/supervisor/patients" element={<PatientsSupervisor />} />
      <Route path="/supervisor/patients/:type" element={<CreatePatient />} />
      <Route
        exact
        path="/supervisor/patients/create-daily-goal"
        element={<CreateDailyGoal />}
      />
      <Route
        exact
        path="/supervisor/patients/assign/:type"
        element={<AssignActivityPatient />}
      />
      <Route
        exact
        path="/supervisor/patient/change-activity-schedule"
        element={<ChangeActivitySchedule />}
      />
      <Route
        exact
        path="/supervisor/patients/patient-insights"
        element={<PatientInsights />}
      />

      {/* Doctors */}
      <Route path="/supervisor/doctors" element={<DoctorsSupervisor />} />
      <Route path="/supervisor/doctors/profile" element={<DoctorProfile />} />
      <Route path="/supervisor/doctors/:type" element={<CreateDoctor />} />
      <Route
        exact
        path="/supervisor/doctors/reassign-doctor-to-supervisor"
        element={<ReassignDr />}
      />
      <Route
        exact
        path="/supervisor/doctors/reassign-doctor-to-supervisor/form"
        element={<ReassignDrForm />}
      />
      <Route
        exact
        path="/supervisor/doctors/reassign-patient-to-doctor"
        element={<ReassignPatient />}
      />
      <Route
        exact
        path="/supervisor/doctors/reassign-patient-to-doctor/form"
        element={<ReassignPatientForm />}
      />

      {/* Forms  */}
      <Route exact path="/supervisor/forms" element={<FormsSupervisor />} />
      <Route exact path="/supervisor/forms/create" element={<CreateForm />} />
      <Route
        exact
        path="/supervisor/forms/form-details"
        element={<SingleForm />}
      />

      {/* Activities */}
      <Route path="/supervisor/activities" element={<ActivitiesSupervisor />} />
      <Route
        path="/supervisor/activities/:type"
        element={<CreateActivitySupervisor />}
      />

      {/* Questions */}
      <Route path="/supervisor/questions" element={<QuestionsSupervisor />} />
      <Route
        path="/supervisor/questions/:type"
        element={<CreateQuestionSupervisor />}
      />

      {/* Assignments */}
      <Route path="/supervisor/assignments" element={<Assignments />} />
    </React.Fragment>
  );
};

export default SupervisorRoutes;

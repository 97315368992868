import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CustomerProfile from "../Pages/Vendor/Profile/CustomerProfile";
import Customers from "../Pages/Vendor/Customers/Customers";
import CustomerSites from "../Pages/Vendor/CustomerSitesVendor/CustomerSites";
import Forms from "../Pages/Vendor/Forms/Forms";
import RolesPermissions from "../Pages/Vendor/Profile/RolesPermissions";
import CreateCustomer from "./../Pages/Vendor/Customers/CreateCustomer";
import CreateForm from "../Pages/Vendor/Forms/CreateForm";
import PatientsProvider from "../Pages/Provider/Patients/PatientsProvider";
import CreatePatient from "../Pages/Provider/Patients/CreatePatient";
import ScheduledAppointments from "../Pages/Provider/Scheduled Appointments/ScheduledAppointments";
import PatientNotes from "../Pages/Provider/Patient Notes/PatientNotes";
import FormsProvider from "../Pages/Provider/Forms/FormsProvider";
import ActivityProvider from "../Pages/Provider/Activity/ActivityProvider";
import QuestionsProvider from "../Pages/Provider/Questions/QuestionsProvider";
import PatientInsights from "../Pages/Provider/Patient Insights/PatientInsights";
import EditProfile from "../Pages/Auth/EditProfile";
import CreateActivitySupervisor from "../Pages/Supervisor/Activities/CreateActivitySupervisor";
import CreateQuestionSupervisor from "../Pages/Supervisor/Questions/CreateQuestionSupervisor";
import AssignActivityPatient from "../Pages/Supervisor/Patients/AssignActivityPatient";
import ScheduleAppointmentWithPatient from "../Pages/Provider/Scheduled Appointments/ScheduleAppointmentWithPatient";
import CreateDailyGoal from "../Pages/Supervisor/Patients/CreateDailyGoal";
import SingleForm from "../Pages/Vendor/Forms/SingleForm";
import ProviderDashboard from "../Pages/Provider/Dashboard/ProviderDashboard";

export const ProviderRoutes = () => {
  return (
    <React.Fragment>
      <Route exact path="/provider/edit-profile" element={<EditProfile />} />

      <Route exact path="/provider/dashboard" element={<ProviderDashboard />} />

      {/* Patient */}
      <Route exact path="/provider/patients" element={<PatientsProvider />} />
      <Route path="/provider/patients/:type" element={<CreatePatient />} />
      <Route
        path="/provider/patients/assign/:type"
        element={<AssignActivityPatient />}
      />

      {/* Scheduled */}
      <Route
        exact
        path="/provider/scheduled-appointments"
        element={<ScheduledAppointments />}
      />
      <Route
        exact
        path="/provider/scheduled-appointments/:type"
        element={<ScheduleAppointmentWithPatient />}
      />

      {/* Note */}
      <Route exact path="/provider/patient-notes" element={<PatientNotes />} />

      {/* Forms */}
      <Route exact path="/provider/forms" element={<FormsProvider />} />

      {/* Activity */}
      <Route exact path="/provider/activity" element={<ActivityProvider />} />
      <Route
        exact
        path="/provider/activity/:type"
        element={<CreateActivitySupervisor />}
      />
      <Route
        exact
        path="/provider/forms/form-details"
        element={<SingleForm />}
      />

      {/* Questions */}
      <Route exact path="/provider/questions" element={<QuestionsProvider />} />
      <Route
        exact
        path="/provider/questions/:type"
        element={<CreateQuestionSupervisor />}
      />

      {/* Insights */}
      <Route
        exact
        path="/provider/patients/patient-insights"
        element={<PatientInsights />}
      />
      <Route
        exact
        path="/provider/patients/create-daily-goal"
        element={<CreateDailyGoal />}
      />
    </React.Fragment>
  );
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Snackbar,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import AppBar from "../../../components/AppBar";
import {
  colors,
  getCustomerStatusBackgroundColor,
  getStatusBackgroundColor,
  getStatusTextColor,
} from "../../../theme/theme";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { RedModal } from "../../../components/ConfirmationModal";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import moment from "moment";
import { AdminPanelSettings } from "@mui/icons-material";

const CustomerAdmins = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const [adminId, setadminId] = useState({});

  const [deleteModalState, setdeleteModalState] = useState(false);
  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setdeleteConfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const smallScreen = useMediaQuery("(max-width:650px)");

  const adminColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params?.row?.avatar !== "" && (
            <img
              src={params.row?.avatar}
              alt=""
              style={{ height: 32, width: 32, objectFit: "contain" }}
            />
          )}
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params?.value}
            </Typography>
          </Box>
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Phone",
      flex: 1.25,
      minWidth: smallScreen ? 150 : 0,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PhoneIphoneOutlinedIcon
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 1,
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.25,
      minWidth: smallScreen ? 150 : 0,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlinedIcon
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 1,
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1.25,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {moment(params?.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1.25,
      minWidth: smallScreen ? 200 : 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() =>
              navigate("/vendor/customers/admins/update", {
                state: {
                  id: params.row?.id,
                  customerId: location?.state?.customerId,
                },
              })
            }
          >
            <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              handleClick(e);
              setadminId(params.row?.id);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const [loading, setloading] = useState(false);

  const DeleteAdmin = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUserStatus`,
        {
          userId: adminId,
          verified: false,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdeleteConfirmation(true);
        GetAllAdmins();
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const [admins, setadmins] = useState([]);
  const location = useLocation();

  const GetAllAdmins = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/user/getAllAdmins?customerId=${location?.state?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setadmins(res.data?.allAdmins);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetAllAdmins();
  }, []);

  const [search, setsearch] = useState("");

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={DeleteAdmin}
        title="Delete Admin"
        mainText="Are you sure you want to delete this admin?"
        subText="This action cannot be undone."
      />

      <Snackbar
        open={deleteConfirmation}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="Customer admin successfully!"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <AppBar title="Admins" />

        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                width: "100%",
                overflowX: "auto",
              }}
            >
              <TitleWithSearchBar
                title="Admins"
                onClick={() => {
                  navigate("/vendor/customers/admins/create", {
                    state: { customerId: location?.state?.customerId },
                  });
                }}
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  admins?.filter((val) =>
                    val?.name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={adminColumns}
                getRowId={(row) => row?.id}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10, { value: admins?.length, label: 'All' }]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    color: "#F34141",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();
                    setdeleteModalState(true);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerAdmins;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "../../../components/AppBar";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  CreateDropDown,
  CreateHeader,
} from "../../../components/CreatePagesComponents";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { getAllSupervisorForAdmin } from "../../../redux/dispatchers/admin.dispatcher";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserById } from "../../../redux/dispatchers/login";

const AssignSupervisor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [confirmation, setconfirmation] = useState(false);
  const [supervisors, setsupervisors] = useState(
    location?.state?.assignedSupervisor || ""
  );
  const [supervisorId, setsupervisorId] = useState(
    location?.state?.assignedSupervisorId || ""
  );

  console.log(
    location?.state?.assignedSupervisorId,
    "supervisorId",
    location?.state?.assignedSupervisor
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [loading, setloading] = useState(false);

  const { allSupervisors } = useSelector((state) => state.admin);

  const supervisor = allSupervisors?.map((sv) => ({
    label: sv.name,
    value: sv.name,
    id: sv.id,
  }));

  const { userData } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getAllSupervisorForAdmin(userData?.customerId));
  }, []);

  const AssignSup = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/assignSiteToSupervisor`,
        {
          userId: supervisorId,
          siteId: location.state.id,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate("/admin/customer-sites");
        }, 2000);
        if (
          supervisor?.find((s) => s.value === supervisors)?.id === userData?.id
        ) {
          dispatch(getUserById(userData?.id));
        }
      })
      .catch((e) => {
        setloading(false);
      });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Supervisor Assigned Successfully"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title={`Assign supervisor`} />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={`Assign supervisor to ${location?.state?.name}`}
                  btnTitle="Assign"
                  onClick={AssignSup}
                  cancelFunc={() => navigate("/admin/customer-sites")}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={supervisors}
                        setValue={(value) => {
                          setsupervisors(value);
                          setsupervisorId(
                            supervisor?.find((s) => s.value === value)?.id
                          );
                        }}
                        title="Choose Supervisor"
                        data={supervisor || []}
                        Icon={PersonOutlineIcon}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AssignSupervisor;

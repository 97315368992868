import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import {
  Search,
  SearchIconWrapper,
  StyledDataGrid,
  StyledInputBase,
} from "../../../components/StyledSearchDataGrid";
import { useNavigate } from "react-router-dom";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import FilterBox from "../../../components/FilterBox";
import { dummyActivitiesData } from "../../../assets/data/DummyData";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useSelector } from "react-redux";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import moment from "moment";

const Assignments = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const assignmentColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: isSmallScreen ? 0.5 : 2.5,
      renderCell: (params) => (
        <Box
          sx={{
            displag: "flex",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: colors.text,
              fontFamily: "FiraSans",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      flex: 1.25,
      renderCell: (params) => {
        const assignedTo = params?.row?.assignedTo;
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {assignedTo?.avatar !== "" && (
              <img
                src={assignedTo?.avatar}
                alt=""
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                textDecoration: "underline",
                ml: 1,
              }}
            >
              {assignedTo?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              textTransform: "capitalize",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },

    {
      field: "scheduled",
      headerName: "Scheduled At",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {moment(params?.value).format("DD-MM-YYYY")}
          </Typography>
        </Box>
      ),
    },
  ];

  const { userData } = useSelector((state) => state.user);
  const [loading, setloading] = useState(false);

  const [search, setsearch] = useState("");

  const [allAssignments, setallAssignments] = useState([]);
  const GetAllAssignments = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getAllAssignments?siteId=${userData?.siteId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const { assignedActivities, assignedForms, assignedQuestions } =
          res.data;

        mergeAssignedArrays(
          assignedActivities,
          assignedForms,
          assignedQuestions
        );
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetAllAssignments();
  }, []);

  function mergeAssignedArrays(activities, forms, questions) {
    const transformedActivities = activities.map((act) => ({
      id: act?.activity?.id,
      name: act?.activity?.activityName,
      assignedTo: act?.user,
      createdAt: act?.createdAt,
      scheduled: act?.date,
      type: "activity",
    }));

    const transformedForms = forms.map((f) => ({
      id: f?.form?.id,
      name: f?.form?.form_name,
      assignedTo: f?.user,
      createdAt: f?.createdAt,
      scheduled: f?.date,
      type: "form",
    }));

    const transformedQuestions = questions.map((q) => ({
      id: q?.question?.id,
      name: q?.question?.questionName,
      assignedTo: q?.user,
      createdAt: q?.createdAt,
      scheduled: q?.date,
      type: "question",
    }));

    const all = [
      ...transformedActivities,
      ...transformedForms,
      ...transformedQuestions,
    ];
    setallAssignments(all);
  }

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Assignments" />

        {/* <FilterBox type="assignment" /> */}
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Assignments"
                noBtn
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  allAssignments?.filter((val) =>
                    val?.name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={assignmentColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "50vh",
                  },
                  mt: 1,
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    borderBottom: "1px solid #EBEFF5",
                    color: "#24292E",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();
                    navigate("/vendor/customers/create");
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  sx={{
                    color: "#F34141",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={handleClose}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Assignments;

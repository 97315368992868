import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Button,
  Typography,
  Avatar,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateCustomInput,
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import {
  AccountCircle,
  Close,
  EmailOutlined,
  Password,
  PeopleAltOutlined,
  PersonOutline,
  PhoneOutlined,
  Place,
  PlaceOutlined,
  TodayOutlined,
  Wc,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UploadBtn } from "../../../components/CustomBtn";
import axios from "axios";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { genders } from "../../../assets/data/DummyData";
import { getAllDoctorsForSupervisor } from "../../../redux/dispatchers/supervisor.dispatcher";
import { RedModal } from "../../../components/ConfirmationModal";
import PatientInsights from "./PatientInsights";

const CreatePatient = () => {
  const params = useParams();

  const [confirmation, setconfirmation] = useState(false);

  const [patientData, setpatientData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    password: "",
    gender: "",
    avatar: "",
    emr_patient_id: "",
  });

  const [allPatientDocuments, setallPatientDocuments] = useState([]);

  const handleUpload = (dataURI, name) => {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        setallPatientDocuments((prevData) => [
          ...prevData,
          { documentName: name, document_url: res.data?.url, status: "active" },
        ]);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const handleStatusChange = (index, newStatus) => {
    const updatedDocuments = [...allPatientDocuments];
    updatedDocuments[index].status = newStatus;
    setallPatientDocuments(updatedDocuments);
  };

  const [verified, setverified] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [avatarLink, setavatarLink] = useState("");
  const handleBrowseClick = () => {
    document.getElementById("avatarFileInput").click();
  };

  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        setloading(false);
        setpatientData((prevData) => ({
          ...prevData,
          avatar: res.data?.url,
        }));
        setavatarLink(res.data?.url);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useSelector((state) => state.user);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const CreatePat = async () => {
    if (
      patientData.fullName === "" ||
      patientData.email === "" ||
      patientData.password === "" ||
      patientData.emr_patient_id === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axiosInstance
        .post(
          `${BASE_URL}/user/createUserPatient`,
          {
            user: {
              name: patientData.fullName,
              password: patientData.password,
              mobile: patientData.mobile,
              email: patientData.email,
              verified: verified === "Active" ? true : false,
              dob: null,
              gender: patientData.gender,
              avatar: patientData.avatar,
              createdBy: userData?.id,
              siteId: userData?.siteId,
              customerId: userData?.customerId,
              documents: allPatientDocuments,
              emr_patient_id: patientData.emr_patient_id,
            },
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setTimeout(() => {
            navigate("/supervisor/patients");
          }, 2000);
          AssignPatient(res.data?.id);
          setpatientData({
            fullName: "",
            email: "",
            mobile: "",
            password: "",
            gender: "",
            avatar: "",
            emr_patient_id: "",
          });
        })
        .catch((e) => {
          seterror(true);
          seterrorMsg(
            `${e.response?.data?.err?.errors[0]?.message}`
          );

          setloading(false);
        });
    }
  };

  const UpdatePatient = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUser`,
        {
          userId: location?.state?.id,
          name: patientData.fullName,
          mobile: patientData.mobile,
          email: patientData.email,
          gender: patientData.gender,
          avatar: patientData.avatar,
          siteId: userData?.siteId,
          emr_patient_id: patientData?.emr_patient_id,
          verified: verified === "Active" ? true : false,
          newDocuments: allPatientDocuments,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate("/supervisor/patients");
        }, 2000);
        GetPatientById();
        AssignPatient(location?.state?.id);
      })
      .catch((e) => {
        setloading(false);
        seterror(true);
        seterrorMsg(`${e?.response?.data?.err?.errors[0]?.message}`);

      });
  };

  const GetPatientById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getSpecificUser?userId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singlePatient = res.data?.specificUser;
        setpatientData((prevData) => ({
          ...prevData,
          fullName: singlePatient?.name,
          email: singlePatient?.email,
          mobile: singlePatient?.mobile,
          gender: singlePatient?.gender,
          avatar: singlePatient?.avatar,
          emr_patient_id: singlePatient?.emr_patient_id,
        }));
        setverified(singlePatient?.verified ? "Active" : "Inactive");
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetPatientDocuments = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/patient_document/getDocumentByPatientId?patientId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallPatientDocuments(res.data?.allDocuments);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDoctorsForSupervisor(userData?.siteId));
  }, []);

  const { allDoctors } = useSelector((state) => state.supervisor);

  const [chooseDr, setchooseDr] = useState("");
  const [drId, setdrId] = useState("");
  const [chooseBackup, setchooseBackup] = useState("");
  const [backupDrId, setbackupDrId] = useState();

  const doctorData = allDoctors?.map((doctor) => ({
    value: doctor?.name,
    label: doctor?.name,
    doctorId: doctor?.id,
  }));

  const [backupDoctorData, setBackupDoctorData] = useState([]);

  const updateBackupDoctorOptions = (selectedDoctorId) => {
    const filteredDoctors = doctorData?.filter(
      (doctor) => doctor?.doctorId !== selectedDoctorId
    );

    const backupDoctorOptions = filteredDoctors?.map((doctor) => ({
      value: doctor?.value,
      label: doctor?.value,
      doctorId: doctor?.id,
    }));

    setBackupDoctorData(backupDoctorOptions);
  };

  const handleMainDoctorChange = (value) => {
    setchooseDr(value);
    const selectedDoctorId = doctorData?.find(
      (doc) => doc?.value === value
    )?.doctorId;
    setdrId(selectedDoctorId);

    updateBackupDoctorOptions(selectedDoctorId);
  };

  const handleBackupDoctorChange = (value) => {
    setchooseBackup(value);
    const selectedBackupDoctorId = doctorData?.find(
      (doc) => doc?.value === value
    )?.doctorId;
    setbackupDrId(selectedBackupDoctorId);
  };

  const AssignPatient = async (id) => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/doctor_patient/assignDoctorToPatient`,
        {
          doctorId: drId,
          backupDrId: backupDrId,
          siteId: userData?.siteId,
          patientId: id,
          customerId: userData?.customerId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setdrId("");
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const getAssignedDoctorWithPatientId = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/doctor_patient/getAssignedDoctor?patientId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setchooseDr(res?.data?.allPatientsDoctors?.assignedDoctor?.name);
        updateBackupDoctorOptions(res?.data?.allPatientsDoctors?.doctorId);
        setbackupDrId(res?.data?.allPatientsDoctors?.backupDrId);
        setdrId(res?.data?.allPatientsDoctors?.doctorId);
        setchooseBackup(
          doctorData?.find(
            (doc) => doc?.doctorId === res?.data?.allPatientsDoctors?.backupDrId
          )?.value
        );
        // const singlePatient = res.data?.specificUser;
        // setpatientData((prevData) => ({
        //   ...prevData,
        //   fullName: singlePatient?.name,
        //   email: singlePatient?.email,
        //   mobile: singlePatient?.mobile,
        //   gender: singlePatient?.gender,
        //   avatar: singlePatient?.avatar,
        // }));
        // setverified(singlePatient?.verified ? "Active" : "Inactive");
      })
      .catch((e) => {
        setloading(false);
      });
  };
  const [deleteModalState, setdeleteModalState] = React.useState(false);

  useEffect(() => {
    if (params?.type === "update" || params?.type === "view") {
      GetPatientById();
      GetPatientDocuments();
      getAssignedDoctorWithPatientId();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "update"
            ? "Patient updated successfully!"
            : "Patient created successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <RedModal
        open={deleteModalState}
        onClose={() => {setdeleteModalState(false);navigate(-1)}}
        onClick={() => {
          setdeleteModalState(false);
          if(params?.type === "update") {UpdatePatient()}else{CreatePat()};
        }}
        title="Confirmation"
        mainText={"Do you want to save it"}
        subText={""}
        
        actionText={"Yes"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "create"
              ? "Create New Patient"
              : params?.type === "view"
              ? "View Patient"
              : "Update Patient"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Patient"
                      : params?.type === "view"
                      ? "View Patient"
                      : "Update Patient"
                  }
                  btnTitle={params?.type === "create" ? "Save" : "Update"}
                  onClick={
                    params?.type === "create" ? CreatePat : UpdatePatient
                  }
                  noBtns={params?.type === "view" ? true : false}
                  cancelFunc={() => params?.type === "view"?navigate(-1):setdeleteModalState(true)}
                  assignActivity={params?.type !== "create"}
                  assignActivityOnPress={() =>
                    navigate("/supervisor/patients/assign/activity", {
                      state: {
                        id: location?.state?.id,
                        name: patientData?.fullName,
                        supervisor: true,
                      },
                    })
                  }
                  // viewActivityOnPress={() =>
                  //   navigate("/supervisor/patients/patient-insights", {
                  //     state: { id: location?.state?.id,
                  //       name: patientData?.fullName,
                  //       supervisor: true, },
                  //   })
                  // }
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={2.5}>
                      <img
                        src={"https://randomuser.me/api/portraits/men/9.jpg"}
                        style={{
                          height: "147.5px",
                          // width: "135px",
                          borderRadius: "8px",
                        }}
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={9.5}>
                      <UploadBtn />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          mb: 2,
                        }}
                      >
                        {patientData?.avatar ? (
                          <Avatar
                            alt="Remy Sharp"
                            sx={{
                              width: 125,
                              height: 125,
                              borderRadius: 360,
                              backgroundColor: "transparent",
                              border: "2px solid #EBEFF5",
                              p: 0.5,
                              cursor: "pointer",
                            }}
                            onClick={handleBrowseClick}
                          >
                            <img
                              src={patientData?.avatar}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt="Avatar"
                            />
                          </Avatar>
                        ) : (
                          <>
                            {avatarLink ? (
                              <Avatar
                                alt="Remy Sharp"
                                src={avatarLink}
                                sx={{
                                  width: 125,
                                  height: 125,
                                  border: "2px solid #EBEFF5",
                                  p: 2,
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                onClick={handleBrowseClick}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                                onClick={handleBrowseClick}
                              >
                                <AccountCircle sx={{ fontSize: 100 }} />
                              </Avatar>
                            )}
                          </>
                        )}
                        <input
                          type="file"
                          id="avatarFileInput"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => {
                            dataURItoBlob(e.target.files[0]);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={patientData.fullName}
                        setValue={(value) =>
                          setpatientData((prevData) => ({
                            ...prevData,
                            fullName: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Patient Full Name"
                        Icon={PersonOutline}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormInput
                        value={patientData.email}
                        setValue={(value) =>
                          setpatientData((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Patient Email"
                        Icon={EmailOutlined}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFormInput
                        value={patientData.mobile}
                        setValue={(value) =>
                          setpatientData((prevData) => ({
                            ...prevData,
                            mobile: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Patient Phone"
                        Icon={PhoneOutlined}
                      />
                    </Grid>

                    {params?.type === "create" && (
                      <>
                        <Grid item xs={12}>
                          <CustomFormInput
                            password
                            value={patientData.password}
                            setValue={(value) =>
                              setpatientData((prevData) => ({
                                ...prevData,
                                password: value,
                              }))
                            }
                            placeholder={"Password*"}
                            Icon={Password}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6}>
                      <CustomFormInput
                        value={patientData.emr_patient_id}
                        setValue={(value) =>
                          setpatientData((prevData) => ({
                            ...prevData,
                            emr_patient_id: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Patient EMR ID"
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CreateDropDown
                        value={patientData.gender}
                        setValue={(value) =>
                          setpatientData((prevData) => ({
                            ...prevData,
                            gender: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        title={"Gender"}
                        data={genders}
                        Icon={Wc}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CreateDropDown
                        value={verified}
                        setValue={setverified}
                        title={"Status"}
                        data={[
                          { value: "Active", label: "Active" },
                          { value: "Inactive", label: "Inactive" },
                        ]}
                        noIcon
                        readOnly={params?.type === "view" ? true : false}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <CreateDropDown
                        value={chooseDr}
                        setValue={(value) => {
                          setchooseDr(value);
                          setdrId(
                            doctorData?.find((doc) => doc?.value === value)
                              ?.doctorId
                          );
                        }}
                        title="Choose Doctor"
                        Icon={PeopleAltOutlined}
                        data={doctorData || []}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={chooseDr}
                        setValue={(value) => handleMainDoctorChange(value)}
                        title="Choose Doctor"
                        Icon={PeopleAltOutlined}
                        data={doctorData || []}
                        readOnly={params?.type === "view" ? true : false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={chooseBackup}
                        setValue={(value) => handleBackupDoctorChange(value)}
                        readOnly={params?.type === "view" ? true : false}
                        title="Choose Backup Doctor"
                        Icon={PeopleAltOutlined}
                        data={backupDoctorData || []}
                      />
                    </Grid>
                    {params?.type !== "view" && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 600, mb: 2 }}
                          >
                            Documents
                          </Typography>
                          <UploadBtn
                            formDoc={allPatientDocuments}
                            onClick={handleUpload}
                            setLoading={setloading}
                            documentUpload
                          />
                        </Grid>
                        {allPatientDocuments?.length > 0 && (
                          <Grid container spacing={2} mt={2} mx={1}>
                            {/* <Grid item xs={4}>
                              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                Document Name
                              </Typography>
                            </Grid> */}
                            <Grid item xs={6}>
                              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                View Document
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                Status
                              </Typography>
                            </Grid>
                            {allPatientDocuments?.map((doc, index) => (
                              <React.Fragment key={index}>
                                {/* <Grid item xs={4}>
                                  <Box
                                    sx={{
                                      backgroundColor: "#f7f8fa",
                                      borderRadius: "8px",
                                      p: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      height: "50px",
                                    }}
                                  >
                                    <Typography>{doc?.documentName}</Typography>
                                  </Box>
                                </Grid> */}
                                <Grid item xs={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      backgroundColor: "#f7f8fa",
                                      borderRadius: "8px",
                                      p: 1,
                                      height: "50px",
                                    }}
                                  >
                                    <a
                                      href={doc.document_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Typography>
                                        {doc?.documentName}
                                      </Typography>
                                    </a>
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <CreateDropDown
                                    value={doc.status}
                                    setValue={(newValue) =>
                                      handleStatusChange(index, newValue)
                                    }
                                    title={"Status"}
                                    data={[
                                      { value: "Active", label: "Active" },
                                      { value: "Inactive", label: "Inactive" },
                                    ]}
                                    noIcon
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                          </Grid>
                        )}
                        <PatientInsights isForm={false} />
                      </>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreatePatient;

import React from "react";
import { colors } from "../theme/theme";
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  Button,
  Switch,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  Add,
  Check,
  EditOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: colors.green,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: colors.green,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const CreateCustomInput = ({
  value,
  setValue,
  placeholder,
  mT,
  mB,
  Icon,
  readOnly,
}) => {
  return (
    <FormControl fullWidth sx={{ mt: mT ? 2 : 0, mb: mB ? 2 : 0 }}>
      <TextField
        sx={{
          backgroundColor: "#fff",
          fontFamily: "FiraSans",
          fontSize: "14px",
          fontWeight: "400",
          borderRadius: "8px",
        }}
        label={placeholder}
        placeholder={placeholder}
        value={value}
        disabled={readOnly}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          sx: {
            height: "50px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E0E2E7", // Customize border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#A0A2A7", // Customize border color on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#707173", // Customize border color when focused
            },
            "& .MuiInputAdornment-root": {
              color: value === "" ? "#707173" : colors.text,
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <Icon sx={{ color: colors.text }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          sx: {
            "&.Mui-focused": {
              color: "#138418", // Change label color when focused
            },
          },
        }}
      />
    </FormControl>
  );
};

export const CreateDropDown = ({
  value,
  setValue,
  title,
  data,
  Icon,
  noIcon,
  readOnly,
}) => {
  const [open, setopen] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(value);

    setopen(false);
  };

  return (
    <FormControl fullWidth disabled={readOnly}>
      <InputLabel id="customer-select-label">
        {title === "Choose Customer" ? "Choose Customer" : title}
      </InputLabel>
      <Select
        labelId="customer-select-label"
        displayEmpty
        disabled={data?.length === 0 || readOnly}
        value={value}
        onChange={handleChange}
        open={open}
        onClose={() => setopen(false)}
        onOpen={() => setopen(true)}
        MenuProps={{
          onClose: () => setopen(false),
        }}
        input={<OutlinedInput label="Choose Customer" />}
        InputProps={{
          disableUnderline: true,
          sx: {
            fontFamily: "FiraSans",
            fontSize: "14px",
            fontWeight: 400,
            color: "#000",
          },
        }}
        renderValue={(selected) => {
          if (selected === "" || selected === null) {
            return (
              <em
                style={{
                  fontStyle: "normal",
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                {title}
              </em>
            );
          }
          return selected;
        }}
        startAdornment={
          <>
            {noIcon ? null : (
              <InputAdornment position="start">
                <Icon sx={{ color: colors.text }} />
              </InputAdornment>
            )}
          </>
        }
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "50px",
          borderRadius: "7px",
          backgroundColor: "#FAFAFA",
          elevation: 0,
          fontFamily: "FiraSans",
          fontSize: "14px",
          fontWeight: "400",
          color: colors.text,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#EBEFF5",
          },
          "& input::placeholder": {
            color: colors.text,
            opacity: 1,
            fontFamily: "FiraSans",
          },
          ".MuiSvgIcon-root ": {
            fill: "black !important",
          },
          "& input.Mui-disabled": {
            color: colors.text,
            "-webkit-text-fill-color": colors.text,
          },
        }}
      >
        {title === "Choose Customer" && (
          <MenuItem
            style={{
              textTransform: "capitalize",
            }}
            value=""
          >
            None
          </MenuItem>
        )}

        {data?.map((option) => (
          <MenuItem
            style={{
              textTransform: "capitalize",
            }}
            key={option.value}
            value={option.value}
            onClick={option.action}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const CreateDropDownMultiple = ({
  value,
  setValue,
  title,
  data,
  Icon,
  noIcon,
  multipleSelect,
  readOnly,
}) => {
  const [open, setopen] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(value);

    //setopen(false);
  };

  return (
    <FormControl fullWidth disabled={readOnly}>
      <Select
        displayEmpty
        value={value}
        disabled={data?.length === 0 || readOnly}
        onChange={handleChange}
        open={open}
        multiple
        onClose={() => setopen(false)}
        onOpen={() => setopen(true)}
        MenuProps={{
          onClose: () => setopen(false),
        }}
        input={<OutlinedInput />}
        InputProps={{
          disableUnderline: true,
          sx: {
            fontFamily: "FiraSans",
            fontSize: "14px",
            fontWeight: 400,
            color: "#000",
          },
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <em
                style={{
                  fontStyle: "normal",
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                {title}
              </em>
            );
          }

          return selected.join(", ");
        }}
        startAdornment={
          <>
            {noIcon ? null : (
              <InputAdornment position="start">
                <Icon sx={{ color: colors.text }} />
              </InputAdornment>
            )}
          </>
        }
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "50px",
          borderRadius: "7px",
          backgroundColor: "#FAFAFA",
          elevation: 0,
          fontFamily: "FiraSans",
          fontSize: "14px",
          fontWeight: "400",
          color: colors.text,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#EBEFF5",
          },
          "& input::placeholder": {
            color: colors.text,
            opacity: 1,
            fontFamily: "FiraSans",
          },
          ".MuiSvgIcon-root ": {
            fill: "black !important",
          },
          "& input.Mui-disabled": {
            color: colors.text,
            "-webkit-text-fill-color": colors.text,
          },
        }}
      >
        {data.map((option) => (
          <MenuItem
            style={{
              textTransform: "capitalize",
            }}
            key={option.value}
            value={option.value}
            // onClick={(e) => multipleSelect(e, option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const CreateCustomTextArea = ({
  value,
  setValue,
  placeholder,
  mT,
  mB,
  Icon,
  noIcon,
  readOnly,
}) => {
  return (
    <FormControl fullWidth sx={{ mt: mT ? 2 : 0, mb: mB ? 2 : 0 }}>
      <OutlinedInput
        multiline
        rows={5}
        sx={{
          height: "150px",
          borderRadius: "7px",
          backgroundColor: "#FAFAFA",
          elevation: 0,
          fontFamily: "FiraSans",
          fontSize: "14px",
          fontWeight: "400",
          color: colors.text,
          "& .MuiOutlinedInput-multiline": {
            padding: "10px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(241, 242, 244, 1)",
          },
          "& textarea::placeholder": {
            color: colors.text,
            opacity: 1,
            fontFamily: "FiraSans",
          },
          "& input.Mui-disabled": {
            color: colors.text,
            "-webkit-text-fill-color": colors.text,
          },
        }}
        disabled={readOnly}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        startAdornment={
          <>
            {noIcon ? null : (
              <InputAdornment
                position="start"
                sx={{
                  alignSelf: "flex-start",
                  paddingTop: "17px",
                }}
              >
                <Icon sx={{ color: colors.text }} />
              </InputAdornment>
            )}
          </>
        }
      />
    </FormControl>
  );
};

export const CreateHeader = ({
  title,
  onClick,
  btnTitle,
  cancelFunc,
  noBtns,
  assignActivity = false,
  assignActivityOnPress,
  viewActivityOnPress,
}) => {
  return (
    <Box
      sx={{
        pb: 2,
        borderBottom: "1px solid #F1F2F4",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        px={2}
        pt={2}
      >
        {/* Title */}
        <Grid item>
          <Typography
            variant="h6"
            style={{
              color: colors.text,
              fontWeight: "500",
              fontSize: 18,
            }}
          >
            {title}
          </Typography>
        </Grid>

        {/* Buttons */}
        <Grid item>
          {!noBtns && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  height: "40px",
                  width: { xs: "100%", sm: "50%" },
                  border: "1px solid #EBEFF5",
                  backgroundColor: "#FAFAFA",
                  textTransform: "none",
                  borderRadius: "8px",
                  color: colors.text,
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "FiraSans",
                  boxShadow: "0px 1px 1px 0px #0000001F",
                  "&:hover": {
                    boxShadow: "0px 0px 0px 1px #676E7629",
                    backgroundColor: "#FAFAFA",
                  },
                  mr: 1,
                }}
                onClick={cancelFunc}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  height: "40px",
                  width: { xs: "100%", sm: "50%" },
                  backgroundColor: colors.green,
                  textTransform: "none",
                  borderRadius: "8px",
                  px: 3,
                  boxShadow: "0px 1px 1px 0px #0000001F",
                  "&:hover": {
                    boxShadow: "0px 0px 0px 1px #676E7629",
                    backgroundColor: colors.green,
                  },
                }}
                onClick={onClick}
              >
                {btnTitle === "Update" ? (
                  <Check sx={{ mr: 0.5, fontSize: "22px" }} />
                ) : btnTitle === "Create" ? (
                  <Add sx={{ mr: 0.5, fontSize: "22px" }} />
                ) : btnTitle === "Edit" ? (
                  <EditOutlined sx={{ mr: 0.5, fontSize: "22px" }} />
                ) : (
                  <Add sx={{ mr: 0.5, fontSize: "22px" }} />
                )}
                {btnTitle ? btnTitle : "Create"}
              </Button>
              {assignActivity && (
                <>
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    width: "100%",
                    backgroundColor: colors.primary,
                    textTransform: "none",
                    borderRadius: "8px",
                    px: 3,
                    boxShadow: "0px 1px 1px 0px #0000001F",
                    "&:hover": {
                      boxShadow: "0px 0px 0px 1px #676E7629",
                      backgroundColor: colors.green,
                    },
                    ml: 1,
                  }}
                  onClick={assignActivityOnPress}
                >
                  Assign Activity
                </Button>
                                {/* <Button
                                variant="contained"
                                sx={{
                                  height: "40px",
                                  width: "100%",
                                  backgroundColor: colors.primary,
                                  textTransform: "none",
                                  borderRadius: "8px",
                                  px: 3,
                                  boxShadow: "0px 1px 1px 0px #0000001F",
                                  "&:hover": {
                                    boxShadow: "0px 0px 0px 1px #676E7629",
                                    backgroundColor: colors.green,
                                  },
                                  ml: 1,
                                }}
                                onClick={viewActivityOnPress}
                              >
                                View Activity
                              </Button> */}
                              </>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export const CustomFormInput = ({
  value,
  setValue,
  placeholder,
  mT,
  mB,
  Icon,
  password,
  type,
  readOnly,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl fullWidth sx={{ mt: mT ? 2 : 0, mb: mB ? 2 : 0 }}>
      <TextField
        sx={{
          backgroundColor: "#fff",
          fontFamily: "FiraSans",
          fontSize: "14px",
          fontWeight: "400",
          borderRadius: "8px",
        }}
        placeholder={placeholder}
        label={placeholder}
        value={value}
        disabled={readOnly}
        onChange={(e) => setValue(e.target.value)}
        type={password && !showPassword ? "password" : type ? type : "text"}
        InputProps={{
          sx: {
            height: "50px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E0E2E7", // Customize border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#A0A2A7", // Customize border color on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#707173", // Customize border color when focused
            },
            "& .MuiInputAdornment-root": {
              color: value === "" ? "#707173" : colors.text,
            },
          },
          startAdornment: (
            <>
              {Icon && (
                <InputAdornment position="start">
                  <Icon sx={{ color: colors.text }} />
                </InputAdornment>
              )}
            </>
          ),
          endAdornment: (
            <>
              {password && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
        InputLabelProps={{
          sx: {
            "&.Mui-focused": {
              color: "#138418", // Change label color when focused
            },
          },
        }}
      />
    </FormControl>
  );
};

import { Stack, Typography } from "@mui/material";
import React from "react";
import { IOSSwitch } from "./CreatePagesComponents";

const InactiveSwitch = ({ handleactiveSwitch, activeSwitch, title, past }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      bgcolor="#fff"
      sx={{
        borderRadius: "9px",
        p: 2,
      }}
    >
      <Typography>
        {past
          ? `You are currently viewing ${
              activeSwitch ? "Current" : "Past"
            } ${title}
        . Toggle off to view the ${activeSwitch ? "Current" : "Past"} ${title}.`
          : `You are currently viewing ${
              activeSwitch ? "Active" : "Inactive"
            } ${title}
        . Toggle off to view the ${
          activeSwitch ? "Active" : "Inactive"
        } ${title}.`}
      </Typography>

      <IOSSwitch
        sx={{ m: 1 }}
        onChange={handleactiveSwitch}
        checked={activeSwitch}
      />
    </Stack>
  );
};

export default InactiveSwitch;

import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { colors } from "./../theme/theme";
import { roles } from "../assets/data/DummyData";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { CreateDropDown } from "./CreatePagesComponents";

const DropDown = ({ value, setValue, title, data, allCustomers,  }) => {
  const [open, setopen] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(value);

    setopen(false);
  };

  return (
    <FormControl fullWidth sx={{}}>
      <Select
        multiple
        displayEmpty
        value={value}
        onChange={handleChange}
        open={open}
        onClose={() => setopen(false)}
        onOpen={() => setopen(true)}
        MenuProps={{
          onClose: () => setopen(false),
        }}
        input={<OutlinedInput />}
        InputProps={{
          disableUnderline: true,
          sx: {
            fontFamily: "FiraSans",
            fontSize: "14px",
            fontWeight: 400,
            color: "#000",
          },
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <em
                style={{
                  fontStyle: "normal",
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                {title}
              </em>
            );
          }
          return selected.join(", ");
        }}
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "50px",
          borderRadius: "7px",
          backgroundColor: "transparent",
          elevation: 0,
          fontFamily: "FiraSans",
          fontSize: "14px",
          fontWeight: "400",
          color: colors.text,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#EBEFF5",
          },
          "& input::placeholder": {
            color: colors.text,
            opacity: 1,
            fontFamily: "FiraSans",
          },
          ".MuiSvgIcon-root ": {
            fill: "black !important",
          },
        }}
      >
        {roles.map((option) => (
          <MenuItem
            style={{
              textTransform: "capitalize",
            }}
            key={option.value}
            value={option.value}
            onClick={option.action}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const FilterBox = ({ type, allCustomers, value, setValue }) => {
  
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "8px",
        p: 2,
        m: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: "500",
          color: "#030315",
          mb: 2,
        }}
      >
        FILTERS
      </Typography>
      {
        type==='customerSites'&&(
          <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={6}>
          <CreateDropDown
              title="Choose Customer"
              value={value}
              setValue={(e) => setValue(e)}
              data={allCustomers}
              noIcon
            />
          </Grid>
          </Grid>
        )
      }
      {type === "form" ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Customer"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Customer Site"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Form Type"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                height: "47.5px",
                width: { xs: "100%", sm: "100%" },
                backgroundColor: "#FAFAFA",
                textTransform: "none",
                borderRadius: "8px",
                border: "1px solid #EBEFF5",
                boxShadow: "0px 2px 5px 0px #676E7614",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                color: "inherit",
              }}
            >
              Apply Filters
              <SendOutlinedIcon sx={{ ml: 1, fontSize: "20px" }} />
            </Button>
          </Grid>
        </Grid>
      ) : type === "activity" ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Activity Type"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Provider/Patient"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Date (From, To)"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                height: "47.5px",
                width: { xs: "100%", sm: "100%" },
                backgroundColor: "#FAFAFA",
                textTransform: "none",
                borderRadius: "8px",
                border: "1px solid #EBEFF5",
                boxShadow: "0px 2px 5px 0px #676E7614",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                color: "inherit",
              }}
            >
              Apply Filters
              <SendOutlinedIcon sx={{ ml: 1, fontSize: "20px" }} />
            </Button>
          </Grid>
        </Grid>
      ) : type === "questions" ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={5}>
            <DropDown
              title="Choose Customer"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <DropDown
              title="Choose Customer Site"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                height: "47.5px",
                width: { xs: "100%", sm: "100%" },
                backgroundColor: "#FAFAFA",
                textTransform: "none",
                borderRadius: "8px",
                border: "1px solid #EBEFF5",
                boxShadow: "0px 2px 5px 0px #676E7614",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                color: "inherit",
              }}
            >
              Apply Filters
              <SendOutlinedIcon sx={{ ml: 1, fontSize: "20px" }} />
            </Button>
          </Grid>
        </Grid>
      ) : type === "patients" ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3} md={2.5}>
            <DropDown
              title="Choose Patients"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={3} md={2.5}>
            <DropDown
              title="Choose Gender"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <DropDown
              title="Choose Role"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2.5}>
            <DropDown
              title="Choose Date (From, To)"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={3} md={2}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                height: "47.5px",
                width: { xs: "100%", sm: "100%" },
                backgroundColor: "#FAFAFA",
                textTransform: "none",
                borderRadius: "8px",
                border: "1px solid #EBEFF5",
                boxShadow: "0px 2px 5px 0px #676E7614",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                color: "inherit",
              }}
            >
              Apply Filters
              <SendOutlinedIcon sx={{ ml: 1, fontSize: "20px" }} />
            </Button>
          </Grid>
        </Grid>
      ) : type === "doctors" ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Provider"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Role"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Date (From, To)"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                height: "47.5px",
                width: { xs: "100%", sm: "100%" },
                backgroundColor: "#FAFAFA",
                textTransform: "none",
                borderRadius: "8px",
                border: "1px solid #EBEFF5",
                boxShadow: "0px 2px 5px 0px #676E7614",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                color: "inherit",
              }}
            >
              Apply Filters
              <SendOutlinedIcon sx={{ ml: 1, fontSize: "20px" }} />
            </Button>
          </Grid>
        </Grid>
      ) : type === "assignment" ? (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Assignment"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Assignment Type"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3.33}>
            <DropDown
              title="Choose Date (From, To)"
              value={[]}
              setValue={() => {}}
              data={roles}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                height: "47.5px",
                width: { xs: "100%", sm: "100%" },
                backgroundColor: "#FAFAFA",
                textTransform: "none",
                borderRadius: "8px",
                border: "1px solid #EBEFF5",
                boxShadow: "0px 2px 5px 0px #676E7614",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                color: "inherit",
              }}
            >
              Apply Filters
              <SendOutlinedIcon sx={{ ml: 1, fontSize: "20px" }} />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default FilterBox;

export const roles = [
  // {
  //   value: "Vendor",
  //   label: "Vendor",
  // },
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Supervisor",
    label: "Supervisor",
  },
  {
    value: "Provider",
    label: "Provider",
  },
];

export const createCustomerStatus = [
  {
    id: 1,
    label: "Active",
    value: "Active",
  },
  {
    id: 2,
    label: "Inactive",
    value: "Inactive",
  },
  {
    id: 3,
    label: "Set To Go Live",
    value: "Set To Go Live",
  },
  // {
  //   id: 4,
  //   label: "Initial Setup",
  //   value: "Initial Setup",
  // },
];
export const customerStatus = [
  {
    id: 1,
    label: "Active",
    value: "Active",
  },
  {
    id: 2,
    label: "Inactive",
    value: "Inactive",
  },
  // {
  //   id: 3,
  //   label: "Set To Go Live",
  //   value: "Set To Go Live",
  // },
  // {
  //   id: 4,
  //   label: "Initial Setup",
  //   value: "Initial Setup",
  // },
];

export const formTypes = [
  {
    id: 1,
    label: "General",
    value: "General",
  },
  {
    id: 2,
    label: "Clinical Symptoms",
    value: "Clinical Symptoms",
  },
  {
    id: 3,
    label: "Functioning",
    value: "Functioning",
  },
  {
    id: 4,
    label: "Addictions",
    value: "Addictions",
  },
  {
    id: 5,
    label: "Therapeutic Alliance",
    value: "Therapeutic Alliance",
  },
  {
    id: 6,
    label: "Child Assessments",
    value: "Child Assessments",
  },
];

export const siteStatus = [
  {
    id: 1,
    label: "New",
    value: "New",
  },
  {
    id: 2,
    label: "Closed",
    value: "Closed",
  },
];

export const customerData = [
  {
    id: 1,
    name: "Flores, Juanita",
    email: "Flores.J@gmail.com",
    phone: "+1 (307) 555-0133",
    sites: 5,
    gender: "(Male)",
    age: "04.09.1953 (67)",
    created_at: "Dec 10, 2022, 03:52 PM",
    avatar: require("../images/1.png"),
    role: "Psychiatric Nurse Practitioner",
  },
  {
    id: 2,
    name: "Cooper, Kristin",
    email: "Cooper.K@gmail.com",
    phone: "+1 (219) 555-0114",
    sites: 5,
    gender: "(Female)",
    age: "25.10.1962 (57)",
    created_at: "Nov 28, 2022, 08:27 PM",
    avatar: require("../images/1.png"),
    role: "Psychologist",
  },
  {
    id: 3,
    name: "Smith, John",
    email: "Smith.J@gmail.com",
    phone: "+1 (123) 555-0145",
    sites: 5,
    gender: "(Male)",
    age: "12.05.1979 (43)",
    created_at: "Oct 15, 2022, 11:14 AM",
    avatar: require("../images/1.png"),
    role: "Counselor",
  },
  {
    id: 4,
    name: "Brown, Emily",
    email: "Brown.E@gmail.com",
    phone: "+1 (456) 555-0189",
    sites: 5,
    gender: "(Female)",
    age: "18.08.1988 (33)",
    created_at: "Sep 02, 2022, 09:45 AM",
    avatar: require("../images/1.png"),
    role: "Therapist",
  },
  {
    id: 5,
    name: "Johnson, Michael",
    email: "Johnson.M@gmail.com",
    phone: "+1 (789) 555-0123",
    sites: 5,
    gender: "(Male)",
    age: "29.12.1975 (46)",
    created_at: "Aug 19, 2022, 05:36 PM",
    avatar: require("../images/1.png"),
    role: "Psychiatrist",
  },
  {
    id: 6,
    name: "Williams, Sarah",
    email: "Williams.S@gmail.com",
    phone: "+1 (012) 555-0198",
    sites: 5,
    gender: "(Female)",
    age: "09.03.1967 (55)",
    created_at: "Jul 07, 2022, 02:19 PM",
    avatar: require("../images/1.png"),
    role: "Occupational Therapist",
  },
  {
    id: 7,
    name: "Jones, David",
    email: "Jones.D@gmail.com",
    phone: "+1 (345) 555-0167",
    sites: 5,
    gender: "(Male)",
    age: "15.06.1992 (29)",
    created_at: "Jun 12, 2022, 10:28 AM",
    avatar: require("../images/1.png"),
    role: "Psychiatric Nurse Practitioner",
  },
  {
    id: 8,
    name: "Miller, Jessica",
    email: "Miller.J@gmail.com",
    phone: "+1 (678) 555-0147",
    sites: 5,
    gender: "(Female)",
    age: "08.01.1986 (36)",
    created_at: "May 20, 2022, 04:57 PM",
    avatar: require("../images/1.png"),
    role: "Psychologist",
  },
  {
    id: 9,
    name: "Davis, Matthew",
    email: "Davis.M@gmail.com",
    phone: "+1 (901) 555-0176",
    sites: 5,
    gender: "(Male)",
    age: "30.04.1970 (51)",
    created_at: "Apr 04, 2022, 01:08 PM",
    avatar: require("../images/1.png"),
    role: "Counselor",
  },
  {
    id: 10,
    name: "Taylor, Ashley",
    email: "Taylor.A@gmail.com",
    phone: "+1 (234) 555-0158",
    sites: 5,
    gender: "(Female)",
    age: "21.07.1982 (39)",
    created_at: "Mar 11, 2022, 07:23 AM",
    avatar: require("../images/1.png"),
    role: "Therapist",
  },
];

export const customerSitesData = [
  {
    id: 1,
    assignedTo: "Flores, Juanita",
    avatar: require("../images/1.png"),
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Dec 10, 2022, 03:52 PM",
    status: "New",
  },
  {
    id: 2,
    assignedTo: "Cooper, Kristin",
    flag: require("../images/table/flag (2).png"),
    avatar: require("../images/1.png"),
    address: "2464 Royal Ln. Mesa, New Jersey 45463",
    created_at: "Dec 10, 2022, 03:52 PM",
    status: "Closed",
  },
  {
    id: 3,
    assignedTo: "Smith, John",
    flag: require("../images/table/flag (3).png"),
    avatar: require("../images/1.png"),
    address: "2715 Ash Dr. San Jose, South Dakota 83475",
    created_at: "Dec 10, 2022, 03:52 PM",
    status: "Opened",
  },
  {
    id: 4,
    assignedTo: "Brown, Emily",
    flag: require("../images/table/flag (4).png"),
    avatar: require("../images/1.png"),
    address: "3891 Ranchview Dr. Richardson, California 62639",
    created_at: "Dec 10, 2022, 03:52 PM",
    status: "Active",
  },
  {
    id: 5,
    assignedTo: "Johnson, Michael",
    flag: require("../images/table/flag (5).png"),
    avatar: require("../images/1.png"),
    address: "8502 Preston Rd. Inglewood, Maine 98380",
    created_at: "Dec 10, 2022, 03:52 PM",
    status: "Suspended",
  },
  {
    id: 6,
    assignedTo: "Miller, Jessica",
    flag: require("../images/table/flag (2).png"),
    avatar: require("../images/1.png"),
    address: "3517 W. Gray St. Utica, Pennsylvania 57867",
    created_at: "Dec 10, 2022, 03:52 PM",
    status: "Active",
  },
];

export const formsData = [
  {
    id: 1,
    name: "Attendance Form",
    image: require("../images/table/forms (1).png"),
  },
  {
    id: 2,
    name: "Incident Report Form",
    image: require("../images/table/forms (2).png"),
  },
  {
    id: 3,
    name: "Safety Inspection Form",
    image: require("../images/table/forms (3).png"),
  },
  {
    id: 4,
    name: "Safety Meeting Form",
    image: require("../images/table/forms (4).png"),
  },
  {
    id: 5,
    name: "Training Form",
    image: require("../images/table/forms (2).png"),
  },
];

export const customerDummyData = [
  {
    id: 1,
    name: "Sprint Packers n Movers",
    email: "Flores.J@gmail.com",
    phone: "+1 (307) 555-0133",
    status: "Initial Setup",
    sitesNumber: 12,
    countryFlag: "au", // Flag of Australia
    city: "Canberra",
    createdOn: "Feb 2, 2019 19:28",
    createdBy: "by Mr Robert Fox",
    image: require("../images/table/companies (1).png"),
  },
  {
    id: 2,
    name: "ABC Movers",
    email: "abc@example.com",
    phone: "+1 (123) 456-7890",
    status: "Active",
    sitesNumber: 8,
    countryFlag: "us", // Flag of United States
    city: "New York",
    createdOn: "Mar 15, 2020 10:45",
    createdBy: "by Ms Emily Johnson",
    image: require("../images/table/companies (2).png"),
  },
  {
    id: 3,
    name: "XYZ Removals",
    email: "xyz@example.com",
    phone: "+44 20 1234 5678",
    status: "Active",
    sitesNumber: 20,
    countryFlag: "gb", // Flag of United Kingdom
    city: "London",
    createdOn: "Jan 5, 2021 08:30",
    createdBy: "by Dr. David Smith",
    image: require("../images/table/companies (3).png"),
  },
  {
    id: 4,
    name: "Swift Movers",
    email: "swift@example.com",
    phone: "+61 2 1234 5678",
    status: "Q/A",
    sitesNumber: 15,
    countryFlag: "ca", // Flag of Canada
    city: "Toronto",
    createdOn: "Aug 10, 2022 14:15",
    createdBy: "by Ms Sarah Lee",
    image: require("../images/table/companies (4).png"),
  },
  {
    id: 5,
    name: "Fast Transport Co.",
    email: "fast@example.com",
    phone: "+49 30 1234567",
    status: "Active",
    sitesNumber: 18,
    countryFlag: "de", // Flag of Germany
    city: "Berlin",
    createdOn: "May 20, 2020 09:30",
    createdBy: "by Mr. Michael Mueller",
    image: require("../images/table/companies (5).png"),
  },
  {
    id: 6,
    name: "Express Logistics",
    email: "express@example.com",
    phone: "+33 1 23 45 67 89",
    status: "Closed",
    sitesNumber: 10,
    countryFlag: "fr", // Flag of France
    city: "Paris",
    createdOn: "Oct 12, 2021 11:20",
    createdBy: "by Ms Sophie Dubois",
    image: require("../images/table/companies (6).png"),
  },
  {
    id: 7,
    name: "Rapid Shipping Ltd.",
    email: "rapid@example.com",
    phone: "+81 3-1234-5678",
    status: "Active",
    sitesNumber: 25,
    countryFlag: "jp", // Flag of Japan
    city: "Tokyo",
    createdOn: "Jun 8, 2019 16:40",
    createdBy: "by Mr. Satoshi Yamada",
    image: require("../images/table/companies (7).png"),
  },
  {
    id: 8,
    name: "Swift Cargo Services",
    email: "cargo@example.com",
    phone: "+61 2 9876 5432",
    status: "Initial Setup",
    sitesNumber: 30,
    countryFlag: "sg", // Flag of Singapore
    city: "Singapore",
    createdOn: "Apr 3, 2023 13:55",
    createdBy: "by Ms Jessica Tan",
    image: require("../images/table/companies (8).png"),
  },
  {
    id: 9,
    name: "Reliable Couriers",
    email: "reliable@example.com",
    phone: "+61 2 3333 4444",
    status: "Active",
    sitesNumber: 22,
    countryFlag: "nz", // Flag of New Zealand
    city: "Auckland",
    createdOn: "Jul 18, 2022 08:00",
    createdBy: "by Mr. Andrew Wilson",
    image: require("../images/table/companies (9).png"),
  },
  {
    id: 10,
    name: "Speedy Moving Services",
    email: "speedy@example.com",
    phone: "+44 20 8765 4321",
    status: "Inactive",
    sitesNumber: 12,
    countryFlag: "es", // Flag of Spain
    city: "Madrid",
    createdOn: "Sep 25, 2021 15:10",
    createdBy: "by Ms Maria Rodriguez",
    image: require("../images/table/companies (1).png"),
  },
];

export const dummyQuestionData = [
  {
    id: 1,
    question: "How can I reset my password?",
    customer: "ABC Inc.",
    createdAt: "2024-03-25",
    avatar: require("../images/1.png"),
  },
  {
    id: 2,
    question: "What are the system requirements?",
    customer: "XYZ Corporation",
    createdAt: "2024-03-24",
    avatar: require("../images/1.png"),
  },
  {
    id: 3,
    question: "How do I update my profile?",
    customer: "LMN Industries",
    createdAt: "2024-03-23",
    avatar: require("../images/1.png"),
  },
  {
    id: 4,
    question: "Where can I find the user manual?",
    customer: "PQR Enterprises",
    createdAt: "2024-03-22",
    avatar: require("../images/1.png"),
  },
  {
    id: 5,
    question: "How to contact customer support?",
    customer: "GHI Group",
    createdAt: "2024-03-21",
    avatar: require("../images/1.png"),
  },
];

export const supervisorRows = [
  {
    id: 1,
    name: "John Doe",
    avatar: require("../images/1.png"),
    sitesNumber: 5,
    gender: "Male",
    created_at: "2022-03-28",
  },
  {
    id: 2,
    name: "Jane Smith",
    avatar: require("../images/1.png"),
    sitesNumber: 3,
    gender: "Female",
    created_at: "2022-03-27",
  },
  {
    id: 3,
    name: "Alex Johnson",
    avatar: require("../images/1.png"),
    sitesNumber: 7,
    gender: "Male",
    created_at: "2022-03-26",
  },
  {
    id: 4,
    name: "Emily Brown",
    avatar: require("../images/1.png"),
    sitesNumber: 4,
    gender: "Female",
    created_at: "2022-03-25",
  },
  {
    id: 5,
    name: "Michael Wilson",
    avatar: require("../images/1.png"),
    sitesNumber: 6,
    gender: "Male",
    created_at: "2022-03-24",
  },
];

export const dummyRequestData = [
  {
    id: 1,
    name: "John Doe",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    address: "123 Main St",
    img: require("../images/table/flag (1).png"),
    createdOn: "2022-01-01",
    status: "",
    avatar: require("../images/1.png"),
  },
  {
    id: 2,
    name: "Jane Smith",
    note: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    address: "456 Elm St",
    img: require("../images/table/flag (2).png"),
    createdOn: "2022-01-02",
    status: "Pending",
    avatar: require("../images/1.png"),
  },
  {
    id: 3,
    name: "Michael Johnson",
    note: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    address: "789 Oak St",
    img: require("../images/table/flag (3).png"),
    createdOn: "2022-01-03",
    status: "Pending",
    avatar: require("../images/1.png"),
  },
  {
    id: 4,
    name: "Emily Brown",
    note: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    address: "101 Pine St",
    img: require("../images/table/flag (4).png"),
    createdOn: "2022-01-04",
    status: "Pending",
    avatar: require("../images/1.png"),
  },
  {
    id: 5,
    name: "William Taylor",
    note: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    address: "202 Cedar St",
    img: require("../images/table/flag (5).png"),
    createdOn: "2022-01-05",
    status: "Pending",
    avatar: require("../images/1.png"),
  },
];

export const dummyActivitiesData = [
  {
    id: 1,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-03-29",
    createdBy: "Admin",
    img: require("../../assets/images/table/companies (1).png"),
    type: "Activity",
  },
  {
    id: 2,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-03-30",
    createdBy: "User",
    img: require("../../assets/images/table/companies (2).png"),
    type: "Questionnaire",
  },
  {
    id: 3,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-04-01",
    createdBy: "Admin",
    img: require("../../assets/images/table/companies (3).png"),
    type: "Assigment",
  },
  {
    id: 4,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-04-02",
    createdBy: "User",
    img: require("../../assets/images/table/companies (4).png"),
    type: "Activity",
  },
  {
    id: 5,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-04-03",
    createdBy: "Admin",
    img: require("../../assets/images/table/companies (5).png"),
    type: "Form",
  },
  {
    id: 6,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-04-04",
    createdBy: "User",
    img: require("../../assets/images/table/companies (6).png"),
    type: "Questionnaire",
  },
  {
    id: 7,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-04-05",
    createdBy: "Admin",
    img: require("../../assets/images/table/companies (7).png"),
    type: "Activity",
  },
  {
    id: 8,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-04-06",
    createdBy: "User",
    img: require("../../assets/images/table/companies (8).png"),
    type: "Assigment",
  },
  {
    id: 9,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-04-07",
    createdBy: "Admin",
    img: require("../../assets/images/table/companies (9).png"),
    type: "Form",
  },
  {
    id: 10,
    names:
      "How often have you been bothered by the following problems? [Feeling nervous, anxious or on edge.]",
    scheduled: "2024-04-08",
    createdBy: "User",
    img: require("../../assets/images/table/companies (1).png"),
    type: "Activity",
  },
];

export const supervisorData = [
  {
    id: 1,
    name: "Flores, Juanita",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Dec 10, 2022, 03:52 PM",
    avatar: require("../images/1.png"),
  },
  {
    id: 2,
    name: "Cooper, Kristin",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Nov 28, 2022, 08:27 PM",
    avatar: require("../images/1.png"),
  },
  {
    id: 3,
    name: "Smith, John",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Oct 15, 2022, 11:14 AM",
    avatar: require("../images/1.png"),
  },
  {
    id: 4,
    name: "Brown, Emily",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Sep 02, 2022, 09:45 AM",
    avatar: require("../images/1.png"),
  },
  {
    id: 5,
    name: "Johnson, Michael",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Aug 19, 2022, 05:36 PM",
    avatar: require("../images/1.png"),
  },
  {
    id: 6,
    name: "Williams, Sarah",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Jul 07, 2022, 02:19 PM",
    avatar: require("../images/1.png"),
  },
  {
    id: 7,
    name: "Jones, David",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Jun 12, 2022, 10:28 AM",
    avatar: require("../images/1.png"),
  },
  {
    id: 8,
    name: "Miller, Jessica",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "May 20, 2022, 04:57 PM",
    avatar: require("../images/1.png"),
  },
  {
    id: 9,
    name: "Davis, Matthew",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Apr 04, 2022, 01:08 PM",
    avatar: require("../images/1.png"),
  },
  {
    id: 10,
    name: "Taylor, Ashley",
    flag: require("../images/table/flag (1).png"),
    address: "4140 Parker Rd. Allentown, New Mexico 31134",
    created_at: "Mar 11, 2022, 07:23 AM",
    avatar: require("../images/1.png"),
  },
];

export const genders = [
  { id: 1, label: "Male", value: "Male" },
  {
    id: 2,
    label: "Female",
    value: "Female",
  },
  { id: 3, label: "Other", value: "Other" },
];

export const activityTypes = [
  {
    id: 1,
    label: "Daily Check-In Questions",
    value: "Daily Check-In Questions",
  },
  {
    id: 2,
    label: "Daily Activity",
    value: "Daily Activity",
  },
];

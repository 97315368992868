import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllSupervisorForAdmin } from "../../../redux/dispatchers/admin.dispatcher";
import { Close, EmailOutlined } from "@mui/icons-material";
import { RedModal } from "../../../components/ConfirmationModal";

const SupervisorsAdmin = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const supervisorColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params?.row?.avatar !== "" && (
            <img
              src={params.row.avatar}
              alt=""
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
          )}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params?.value}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.25,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlined
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 1,
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "address",
      headerName: "Customer Site",
      flex: 2.5,
      renderCell: (params) => (
        <>
          {params?.row?.site === null ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  colors: colors.text,
                  fontFamily: "FiraSans",
                }}
              >
                Not Assigned
              </Typography>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src={`https://flagcdn.com/w40/${params?.row?.site?.countryCode}.png`}
                alt=""
                style={{ borderRadius: "5px" }}
              />

              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: colors.text,
                  fontFamily: "FiraSans",
                  ml: 1,
                }}
              >
                {params?.row?.site?.address}
              </Typography>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {moment(params.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() =>
              navigate("/admin/supervisors/update", {
                state: { id: params?.row?.id },
              })
            }
          >
            <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              setsupervisorId(params.row.id);
              handleClick(e);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const dispatch = useDispatch();
  const { allSupervisors } = useSelector((state) => state.admin);
  const { userData } = useSelector((state) => state.user);

  const [search, setsearch] = useState("");

  useEffect(() => {
    dispatch(getAllSupervisorForAdmin(userData?.customerId));
  }, []);

  const [deleteModalState, setdeleteModalState] = React.useState(false);
  const [supervisorId, setsupervisorId] = useState("");
  const [loading, setloading] = useState(false);
  const [snackbarState, setsnackbarState] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const UpdateStatus = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUserStatus`,
        {
          status: "inactive",
          userId: supervisorId,
          verified: false,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsnackbarState(true);
        dispatch(getAllSupervisorForAdmin(userData?.customerId));
      })
      .catch((err) => {
        setloading(false);
      });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarState}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={"Supervisor has been deleted successfully."}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={() => {
          setdeleteModalState(false);
          UpdateStatus();
        }}
        title="Confirmation"
        mainText="Are you sure want to delete this supervisor?"
        subText="Please ensure that once the supervisor is deleted, it cannot be recovered."
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Supervisors" />

        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                minHeight: "200px",
              }}
            >
              <TitleWithSearchBar
                title="Supervisors"
                onClick={() => navigate("/admin/supervisors/create")}
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  allSupervisors?.filter((val) =>
                    val?.name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={supervisorColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
                autoHeight
                componentsProps={{
                  noRowsOverlay: {
                    style: { minHeight: "200px" },
                  },
                }}
              />
            </Box>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                sx={{
                  borderBottom: "1px solid #EBEFF5",
                  color: "#24292E",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                }}
                onClick={() =>
                  navigate("/admin/supervisors/update", {
                    state: { id: supervisorId },
                  })
                }
              >
                Edit Doctor
              </MenuItem>
              <MenuItem
                sx={{
                  color: "#F34141",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                }}
                onClick={() => {
                  handleClose();
                  setdeleteModalState(true);
                }}
              >
                Delete Supervisor{" "}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SupervisorsAdmin;

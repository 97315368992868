import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import Grid from "@mui/material/Grid";
import { colors } from "../../../theme/theme";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  OutlinedInput,
  CircularProgress,
  Backdrop,
  Alert,
  Select,
  MenuItem,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CreateCustomInput,
  CreateCustomTextArea,
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import {
  Add,
  AddCircleOutline,
  Close,
  Delete,
  Edit,
  EditNote,
  FeedOutlined,
  RemoveCircleOutline,
} from "@mui/icons-material";
import CustomBtn, { UploadBtn } from "../../../components/CustomBtn";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { formTypes } from "../../../assets/data/DummyData";
import { getAllCustomerSites } from "../../../redux/dispatchers/vendor.dispatcher";

const CreateForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const currentRolePath = location?.pathname?.split("/")[1];

  const [confirmation, setconfirmation] = useState(false);

  const [formDoc, setformDoc] = useState("");
  const [customerSite, setCustomerSite] = useState([]);
  const [editCustomerSite, seteditCustomerSite] = useState([]);
  const [deletedCustomerSites, setdeletedCustomerSites] = useState([]);

  const [formData, setFormData] = useState({
    formName: "",
    formDesc: "",
    formType: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCustomerSites());
  }, []);

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const { allSites } = useSelector((state) => state.vendor);
  const { userData } = useSelector((state) => state.user);

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const customerDropdownData = allSites
    ?.filter((val) => val?.status?.toLowerCase() === "active")
    ?.map((customer) => ({
      label: customer?.site_name,
      value: customer?.site_name,
      siteId: customer?.id,
      customerId: customer?.id,
    }));

  const GetFormById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/form/getOneForm?formId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleForm = res.data?.allForm[0];
        seteditCustomerSite(
          singleForm?.form_sites?.map((item) => ({
            label: item?.site?.site_name,
            value: item?.site?.site_name,
            siteId: item?.siteId,
            customerId: item?.customerId,
          }))
        );
        setFormData({
          formName: singleForm?.form_name,
          formDesc: singleForm?.formDescription,
          formType: singleForm?.formType,
        });
        setQuestions(singleForm?.questions);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const CreateForm = async () => {
    if (
      formData.formName === "" ||
      customerSite === "" ||
      questions.length === 0
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
      return;
    } else {
      seterror(false);
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/form/createForm`,
          {
            allSites: [],
            form_name: formData.formName,
            formType: formData.formType,
            formDescription: formData.formDesc,
            status: "active",
            created_by: userData?.id,
            questions: questions,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setFormData({
            formName: "",
            formDesc: "",
            formType: "",
          });
          setformDoc("");
          setCustomerSite("");
          navigate("/vendor/forms");
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const EditForm = async () => {
    seterror(false);
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/form/editForm`,
        {
          formId: location?.state?.id,
          allSites: customerSite?.map((item) => ({
            siteId: item?.id,
            customerId: item?.customer?.id,
          })),
          deleteFormSite: deletedCustomerSites?.map((item) => ({
            siteId: item?.siteId,
          })),
          form_name: formData.formName,
          formType: formData.formType,
          formDescription: formData.formDesc,
          status: "active",
          questions: editQuestions,
          deletequestions: editedQuestionIds,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setFormData({
          formName: "",
          formDesc: "",
          formType: "",
        });
        setformDoc("");
        setCustomerSite("");
        setTimeout(() => {
          navigate("/vendor/forms");
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetFormById();
    }
  }, []);

  const [open, setOpen] = React.useState(false);

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const filteredCustomerSites = allSites
    ?.filter((val) => {
      return !editCustomerSite?.some((id) => id.siteId === val.id);
    })
    ?.map((site) => ({
      label: site.site_name,
      value: site.site_name,
      siteId: site.id,
      customerId: site.customerId,
    }));

  const handleDeleteSite = (siteId) => {
    const index = editCustomerSite.findIndex((item) => item.siteId === siteId);

    if (index !== -1) {
      const deletedSite = editCustomerSite.splice(index, 1)[0];

      const exists = deletedCustomerSites.some(
        (item) => item.siteId === deletedSite.siteId
      );

      if (!exists) {
        seteditCustomerSite([...editCustomerSite]);
        setdeletedCustomerSites([...deletedCustomerSites, deletedSite]);
      }
    }
  };

  const CustomQuestion = ({ onAddQuestion, initialData }) => {
    const [question, setQuestion] = useState(
      initialData ? initialData.question : ""
    );
    const [answers, setAnswers] = useState(
      initialData ? initialData.answers : [""]
    );

    useEffect(() => {
      if (initialData) {
        setQuestion(initialData.question);
        setAnswers(initialData.answers);
      }
    }, [initialData]);

    const handleAddAnswer = () => {
      setAnswers([...answers, ""]);
    };

    const handleRemoveAnswer = (index) => {
      const newAnswers = [...answers];
      newAnswers.splice(index, 1);
      setAnswers(newAnswers);
    };

    const handleAnswerChange = (index, value) => {
      const newAnswers = [...answers];
      newAnswers[index] = value;
      setAnswers(newAnswers);
    };

    const handleAddQuestionClick = () => {
      if (question.trim() !== "") {
        if (initialData !== null) {
          onAddQuestion({
            ...initialData,
            question,
            answers: answers.filter((answer) => answer.trim() !== ""),
          });
          setQuestion("");
          setAnswers([""]);
        } else {
          onAddQuestion({
            question,
            answers: answers.filter((answer) => answer.trim() !== ""),
          });
        }
      }
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomFormInput
            placeholder="Question"
            value={question}
            setValue={setQuestion}
          />
        </Grid>
        {answers.map((answer, index) => (
          <Grid key={index} item container alignItems="center" spacing={1}>
            <Grid item xs={11}>
              <TextField
                label={`Answer ${index + 1}`}
                value={answer}
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                fullWidth
                sx={{
                  borderRadius: "7px",
                  backgroundColor: "#FAFAFA",
                  elevation: 0,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(241, 242, 244, 1)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontFamily: "FiraSans",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: colors.text,
                  },
                }}
                inputProps={{
                  sx: {
                    fontFamily: "FiraSans",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: colors.text,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="remove-answer"
                onClick={() => handleRemoveAnswer(index)}
              >
                <RemoveCircleOutline />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <IconButton aria-label="add-answer" onClick={handleAddAnswer}>
            <AddCircleOutline />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{
              height: "40px",
              width: "20%",
              backgroundColor: "#138418",
              textTransform: "none",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#138418",
              },
            }}
            onClick={handleAddQuestionClick}
          >
            <Add sx={{ mr: 0.5, fontSize: "22px" }} />
            {initialData ? "Save Changes" : "Add Question"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const [questions, setQuestions] = useState([]);
  const [editQuestions, seteditQuestions] = useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [editedQuestionIds, setEditedQuestionIds] = useState([]);
  const [newlyAddedQuestions, setNewlyAddedQuestions] = useState([]);

  const handleQuestionEditClick = (index) => {
    setSelectedQuestionIndex(index);
  };

  const handleDeleteQuestion = (index, id) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    const updatedEditQuestions = [...editQuestions];
    updatedEditQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
    seteditQuestions(updatedEditQuestions);
    if (!editedQuestionIds.includes(id)) {
      setEditedQuestionIds([...editedQuestionIds, id]);
    }
  };

  const handleAddQuestion = (newQuestion) => {
    if (selectedQuestionIndex !== null) {
      const updatedQuestions = [...questions];
      updatedQuestions[selectedQuestionIndex] = newQuestion;
      setQuestions(updatedQuestions);
      seteditQuestions([...editQuestions, newQuestion]);
      if (!editedQuestionIds.includes(newQuestion.id)) {
        setEditedQuestionIds([...editedQuestionIds, newQuestion?.id]);
      }
      setSelectedQuestionIndex(null);
    } else {
      setQuestions([...questions, newQuestion]);
      seteditQuestions([...editQuestions, newQuestion]);
    }
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer
        admin={currentRolePath === "admin" ? true : false}
        vendor={currentRolePath === "vendor" ? true : false}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? `Form has been created successfully!`
            : `Form has been updated successfully!`
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={params?.type === "create" ? "Create Form" : "Update Form"}
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Form"
                      : "Update Form"
                  }
                  btnTitle={params?.type === "create" ? "Create" : "Update"}
                  onClick={params?.type === "create" ? CreateForm : EditForm}
                  cancelFunc={() => navigate("/vendor/forms")}
                />
                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    {params?.type === "update" && !location?.state?.assign && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          {editCustomerSite?.map((item) => (
                            <Stack
                              direction="row"
                              sx={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderRadius: "12px",
                                backgroundColor: "#FAFAFA",
                                padding: "0px",
                                border: "1px solid #EBEFF5",
                                mr: 2,
                                px: 2,
                                py: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                }}
                              >
                                {item.label}
                              </Typography>
                              <IconButton
                                sx={{ height: "15px", width: "15px", ml: 1 }}
                                onClick={() => handleDeleteSite(item.siteId)}
                              >
                                <Close sx={{ fontSize: "15px" }} />
                              </IconButton>
                            </Stack>
                          ))}
                        </Box>
                        <FormControl fullWidth>
                          <Select
                            multiple
                            displayEmpty
                            value={personName}
                            onChange={handleChange}
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            MenuProps={{
                              onClose: () => setOpen(false),
                            }}
                            input={<OutlinedInput />}
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                fontFamily: "FiraSans",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#000",
                              },
                            }}
                            renderValue={(selected) => {
                              if (selected?.length === 0) {
                                return (
                                  <em
                                    style={{
                                      fontStyle: "normal",
                                      fontSize: "14px",
                                      color: "#000",
                                    }}
                                  >
                                    Select Customer Site
                                  </em>
                                );
                              }

                              return selected?.join(", ");
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              height: "50px",
                              borderRadius: "7px",
                              backgroundColor: "#FAFAFA",
                              elevation: 0,
                              fontFamily: "FiraSans",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: colors.text,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EBEFF5",
                              },
                              "& input::placeholder": {
                                color: colors.text,
                                opacity: 1,
                                fontFamily: "FiraSans",
                              },
                              ".MuiSvgIcon-root ": {
                                fill: "black !important",
                              },
                            }}
                          >
                            {filteredCustomerSites?.map((option) => (
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                }}
                                key={option.value}
                                value={option.value}
                                onClick={() => {
                                  setCustomerSite((prevSelectedIds) => {
                                    const selectedId = allSites.find(
                                      (website) => website.id === option.siteId
                                    );

                                    if (prevSelectedIds.includes(selectedId)) {
                                      return prevSelectedIds.filter(
                                        (id) => id !== selectedId
                                      );
                                    } else {
                                      return [...prevSelectedIds, selectedId];
                                    }
                                  });
                                  setOpen(false);
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {params?.type === "update" && location?.state?.assign && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Select
                            multiple
                            displayEmpty
                            value={personName}
                            onChange={handleChange}
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            MenuProps={{
                              onClose: () => setOpen(false),
                            }}
                            input={<OutlinedInput />}
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                fontFamily: "FiraSans",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#000",
                              },
                            }}
                            renderValue={(selected) => {
                              if (selected?.length === 0) {
                                return (
                                  <em
                                    style={{
                                      fontStyle: "normal",
                                      fontSize: "14px",
                                      color: "#000",
                                    }}
                                  >
                                    Select Customer Site
                                  </em>
                                );
                              }

                              return selected?.join(", ");
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              height: "50px",
                              borderRadius: "7px",
                              backgroundColor: "#FAFAFA",
                              elevation: 0,
                              fontFamily: "FiraSans",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: colors.text,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EBEFF5",
                              },
                              "& input::placeholder": {
                                color: colors.text,
                                opacity: 1,
                                fontFamily: "FiraSans",
                              },
                              ".MuiSvgIcon-root ": {
                                fill: "black !important",
                              },
                            }}
                          >
                            {customerDropdownData?.map((option) => (
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                }}
                                key={option.value}
                                value={option.value}
                                onClick={() => {
                                  setCustomerSite((prevSelectedIds) => {
                                    const selectedId = allSites.find(
                                      (website) => website.id === option.siteId
                                    );

                                    if (prevSelectedIds.includes(selectedId)) {
                                      return prevSelectedIds.filter(
                                        (id) => id !== selectedId
                                      );
                                    } else {
                                      return [...prevSelectedIds, selectedId];
                                    }
                                  });
                                  setOpen(false);
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {location?.state?.assign ? null : (
                      <>
                        <Grid item xs={6}>
                          <CreateCustomInput
                            value={formData.formName}
                            setValue={(value) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                formName: value,
                              }))
                            }
                            placeholder="Form Name"
                            Icon={FeedOutlined}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CreateDropDown
                            value={formData.formType}
                            setValue={(value) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                formType: value,
                              }))
                            }
                            title="Form Type"
                            data={formTypes}
                            Icon={FeedOutlined}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CreateCustomTextArea
                            value={formData.formDesc}
                            setValue={(value) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                formDesc: value,
                              }))
                            }
                            placeholder="Form Description"
                            Icon={EditNote}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Add Form Questions
                          </Typography>
                        </Grid>
                        {questions?.map((question, index) => (
                          <Grid key={index} item xs={12}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Box>
                                <Typography variant="body1">
                                  Question: {question.question}
                                </Typography>
                                <Typography variant="body2">
                                  Answers: {question.answers.join(", ")}
                                </Typography>
                              </Box>
                              <Box>
                                <IconButton
                                  sx={{ height: "30px", width: "30px" }}
                                  onClick={() => handleQuestionEditClick(index)}
                                >
                                  <Edit sx={{ fontSize: "20px" }} />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteQuestion(index, question?.id)
                                  }
                                  sx={{ height: "30px", width: "30px", ml: 1 }}
                                >
                                  <Delete
                                    sx={{ color: "red", fontSize: "20px" }}
                                  />
                                </IconButton>
                              </Box>
                            </Stack>
                          </Grid>
                        ))}

                        <Grid item xs={12}>
                          <CustomQuestion
                            onAddQuestion={handleAddQuestion}
                            initialData={
                              selectedQuestionIndex !== null
                                ? questions[selectedQuestionIndex]
                                : null
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateForm;

import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  useMediaQuery,
  Snackbar,
  Menu,
  MenuItem,
} from "@mui/material";
import DataGridTable from "../../../components/DataGridTable";
import AppBar from "../../../components/AppBar";
import {
  customerData,
  customerSitesData,
  formsData,
} from "../../../assets/data/DummyData";
import { GreenModal, RedModal } from "../../../components/ConfirmationModal";
import CustomerProfileBoxComponent from "../../../components/CustomerProfileBoxComponent";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  colors,
  getCustomerStatusBackgroundColor,
  getStatusTextColor,
} from "../../../theme/theme";
import {
  Close,
  EditOutlined,
  MoreVert,
  VisibilityOutlined,
} from "@mui/icons-material";
import moment from "moment";
import { BASE_URL } from "../../../constants/config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers, getAllCustomerSites } from "../../../redux/dispatchers/vendor.dispatcher";
import FilterBox from "../../../components/FilterBox";

const CustomerSites = () => {
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width:650px)");

  const [confirmation, setconfirmation] = useState(false);

  const customerSitesColumns = [
    {
      field: "site_name",
      headerName: "Site Name",
      flex: 3,
      minWidth: smallScreen ? 500 : 0,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/vendor/customer-sites/profile", {
                state: { id: params.row.id },
              })
            }
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        </div>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      flex: 3,
      minWidth: smallScreen ? 500 : 0,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={`https://flagcdn.com/w40/${params?.row?.countryCode}.png`}
            alt=""
            style={{ borderRadius: "5px" }}
          />
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/vendor/customer-sites/profile", {
                state: { id: params.row.id },
              })
            }
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: smallScreen ? 200 : 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getCustomerStatusBackgroundColor(
                params?.value?.toLocaleLowerCase()
              ),
              color: getStatusTextColor(params?.value?.toLocaleLowerCase()),
              borderRadius: "8px",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1.25,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {moment(params.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1.5,
      align: "center",
      minWidth: smallScreen ? 200 : 100,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() =>
              navigate("/vendor/customer-sites/profile", {
                state: { id: params.row.id },
              })
            }
          >
            <VisibilityOutlined sx={{ fontSize: "20px" }} />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate("/vendor/customer-sites/update", {
                state: { id: params.row.id },
              })
            }
          >
            <EditOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              handleClick(e);
              setsiteId(params.row.id);
              setsiteStatus(params.row.status);
            }}
          >
            <MoreVert sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteModalState, setdeleteModalState] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const { allSites } = useSelector((state) => state.vendor);
  const { allCustomers } = useSelector((state) => state.vendor);

  // const [selectedCustomers, setselectedCustomers] = useState("")
  const modCustomers = allCustomers?.map((cus) => ({
    label: cus?.organization_name,
    value: cus?.organization_name,
    id: cus.id,
  }));

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getAllCustomerSites());
    dispatch(getAllCustomers());

  }, []);

  const [siteId, setsiteId] = useState("");
  const [loading, setloading] = useState(false);
  const [snackbarState, setsnackbarState] = useState(false);
  const [siteStatus, setsiteStatus] = useState("");
  const [updatedSiteStatus, setupdatedSiteStatus] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const UpdateStatus = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/site/updateSiteStatus`,
        {
          status: updatedSiteStatus,
          siteId: siteId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsnackbarState(true);
        dispatch(getAllCustomerSites());
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const filteredSites = allSites?.filter((val) =>
    val?.address
      ?.toLocaleLowerCase()
      ?.includes(search?.toLocaleLowerCase())
  )


  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarState}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={
          siteStatus?.toLocaleLowerCase() === "active"
            ? "Site has been blocked successfully."
            : "Site has been unblocked successfully."
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={() => {
          setdeleteModalState(false);
          UpdateStatus();
        }}
        title="Confirmation"
        mainText={
          siteStatus?.toLocaleLowerCase() === "active"
            ? "Are you sure want to close this site?"
            : "Are you sure want to open this site?"
        }
        actionText={
          siteStatus?.toLocaleLowerCase() === "active"
            ? "Close Site"
            : "Open Site"
        }
      />

      <GreenModal
        open={confirmation}
        onClose={() => setconfirmation(false)}
        onClick={() => console.log("Deleted")}
        title="Need request access"
        mainText="Requesting access for 60 minutes to troubleshoot the customer's site."
        subText="Please ensure that you’ll have only 60 mins only if the customer grants you access."
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            fontFamily: "FiraSans",
            color:
              siteStatus?.toLocaleLowerCase() === "active" ? "#F37373" : "#000",
          }}
          onClick={() => {
            handleClose();
            setdeleteModalState(true);
            if (siteStatus?.toLocaleLowerCase() === "active") {
              setupdatedSiteStatus("inactive");
            } else {
              setupdatedSiteStatus("active");
            }
          }}
        >
          {siteStatus?.toLocaleLowerCase() === "active"
            ? "Delete Site"
            : "Open Site"}
        </MenuItem>
      </Menu>

      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <AppBar title="Customer Sites" />
        <FilterBox 
        type='customerSites' allCustomers={modCustomers} value={selectedCustomer} setValue={setSelectedCustomer}/>
        {/* Data Grids */}
        <Grid container spacing={2} p={2}>
          {/* First Box */}
          <Grid item xs={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                width: "100%",
                overflowX: "auto",
              }}
            >
              <TitleWithSearchBar
                title="Customer Sites"
                onClick={() => {
                  navigate("/vendor/customer-sites/create");
                }}
                search={search}
                setSearch={setSearch}
              />

              <StyledDataGrid
                rows={
                 filteredSites?.filter((val) => val?.customer?.organization_name?.includes(selectedCustomer)) || []
                }
                columns={customerSitesColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                getRowId={(row) => row?.id}
                pageSizeOptions={[5, 10, { value: filteredSites?.length, label: 'All' }]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
                rowSelectionModel={[]}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerSites;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import Grid from "@mui/material/Grid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { colors } from "../../../theme/theme";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AccountCircle, Password, PersonOutlined } from "@mui/icons-material";
import {
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { ErrorSnackbar } from "../../../components/CustomSnackbar";
import { useSelector } from "react-redux";

const CreateAdmin = () => {
  const navigation = useNavigate();
  const params = useParams();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const location = useLocation();

  const [confirmation, setconfirmation] = useState(false);

  const [adminDetails, setAdminDetails] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    avatar: "",
  });

  const [isAdmin, setisAdmin] = useState(true);
  const [isSupervisor, setisSupervisor] = useState(false);
  const [isDoctor, setisDoctor] = useState(false);

  const handleChangeAdmin = (event) => {
    setisAdmin(event.target.checked);
  };

  const handleChangeSupervisor = (event) => {
    setisSupervisor(event.target.checked);
  };

  const handleChangeDoctor = (event) => {
    setisDoctor(event.target.checked);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const [loading, setloading] = useState(false);

  const { userData } = useSelector((state) => state.user);

  const [avatarLink, setavatarLink] = useState("");
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        setloading(false);
        setAdminDetails((prevData) => ({
          ...prevData,
          avatar: res.data?.url,
        }));
        setavatarLink(res.data?.url);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  const handleCreateCustomer = async () => {
    seterror(false);
    seterrorMsg("");
    if (
      adminDetails.fullName === "" ||
      adminDetails.email === "" ||
      adminDetails.phone === "" ||
      adminDetails.password === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/user/createUserAdmin`,
          {
            user: {
              avatar: "",
              name: adminDetails.fullName,
              email: adminDetails.email,
              mobile: adminDetails.phone,
              password: adminDetails.password,
              customerId: location.state?.customerId,
              createdBy: userData?.id,
              isAdmin: true,
              isSupervisor: false,
              isDoctor: false,
            },
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          seterror(false);
          setTimeout(() => {
            navigation("/vendor/customers/admins", {
              state: {
                customerId: location.state?.customerId,
              },
            });
          }, 2000);

          setAdminDetails({
            fullName: "",
            lastName: "",
            email: "",
            phone: "",
            password: "",
          });
        })
        .catch((e) => {
          setloading(false);
          seterror(true);
          seterrorMsg(e?.response?.data.err?.errors[0]?.message)
        });
    }
  };

  const UpdateUser = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUser`,
        {
          userId: location?.state?.id,
          name: adminDetails.fullName,
          email: adminDetails.email,
          mobile: adminDetails.phone,
          gender: adminDetails.gender,
          avatar: adminDetails.avatar,
          customerId: location.state?.customerId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        GetAdminById();
        setTimeout(() => {
          navigation("/vendor/customers/admins", {
            state: {
              customerId: location.state?.customerId,
            },
          });
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetAdminById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getSpecificUser?userId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const admin = res?.data?.specificUser;

        setAdminDetails({
          fullName: admin?.name,
          email: admin?.email,
          phone: admin?.mobile,
          avatar: admin?.avatar,
        });
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetAdminById();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? "Admin Created Successfully!"
            : "Admin Updated Successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <ErrorSnackbar
        error={error}
        handleCloseError={seterror}
        errorMsg={errorMsg}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={params?.type === "create" ? "Create Admin" : "Update Admin"}
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Admin"
                      : "Update Admin"
                  }
                  btnTitle={params?.type === "create" ? "Create" : "Update"}
                  onClick={(e) => {
                    e.preventDefault();
                    params?.type === "update"
                      ? UpdateUser()
                      : handleCreateCustomer();
                  }}
                  cancelFunc={() =>
                    navigation("/vendor/customers/admins", {
                      state: {
                        customerId: location.state?.customerId,
                      },
                    })
                  }
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mt: 4 }}>
                      <Typography
                        sx={{ color: colors.text, fontWeight: "500" }}
                      >
                        Admin Details (Head / IT){" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          mb: 2,
                        }}
                      >
                        {adminDetails?.avatar ? (
                          <Avatar
                            alt="Remy Sharp"
                            sx={{
                              width: 125,
                              height: 125,
                              borderRadius: 360,
                              backgroundColor: "transparent",
                              border: "2px solid #EBEFF5",
                              p: 0.5,
                              cursor: "pointer",
                            }}
                            onClick={handleBrowseClick}
                          >
                            <img
                              src={adminDetails?.avatar}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt="Avatar"
                            />
                          </Avatar>
                        ) : (
                          <>
                            {avatarLink ? (
                              <Avatar
                                alt="Remy Sharp"
                                src={avatarLink}
                                sx={{
                                  width: 125,
                                  height: 125,
                                  border: "2px solid #EBEFF5",
                                  p: 2,
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                onClick={handleBrowseClick}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                                onClick={handleBrowseClick}
                              >
                                <AccountCircle sx={{ fontSize: 100 }} />
                              </Avatar>
                            )}
                          </>
                        )}
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => {
                            dataURItoBlob(e.target.files[0]);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={adminDetails.fullName}
                        setValue={(value) =>
                          setAdminDetails((prevData) => ({
                            ...prevData,
                            fullName: value,
                          }))
                        }
                        placeholder="Admin Full Name*"
                        Icon={PersonOutlined}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={adminDetails.email}
                        setValue={(value) =>
                          setAdminDetails((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        placeholder="Admin Email*"
                        Icon={MailOutlineIcon}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={adminDetails.phone}
                        setValue={(value) =>
                          setAdminDetails((prevData) => ({
                            ...prevData,
                            phone: value,
                          }))
                        }
                        placeholder="Admin Phone*"
                        Icon={PhoneIphoneOutlinedIcon}
                      />
                    </Grid>
                    {params?.type !== "update" && (
                      <Grid item xs={12}>
                        <CustomFormInput
                          password
                          value={adminDetails.password}
                          setValue={(value) =>
                            setAdminDetails((prevData) => ({
                              ...prevData,
                              password: value,
                            }))
                          }
                          placeholder={"Admin Password*"}
                          Icon={Password}
                        />
                      </Grid>
                    )}
                    {/*<Grid item xs={12}>
                      <Typography
                        sx={{
                          color: colors.text,
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Roles:
                      </Typography>
                      <Stack
                        direction={isSmallScreen ? "column" : "row"}
                        spacing={1}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAdmin}
                              onChange={handleChangeAdmin}
                              inputProps={{ "aria-label": "controlled" }}
                              required
                            />
                          }
                          label="Admin"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isSupervisor}
                              onChange={handleChangeSupervisor}
                              inputProps={{ "aria-label": "controlled" }}
                              required
                            />
                          }
                          label="Supervisor"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isDoctor}
                              onChange={handleChangeDoctor}
                              inputProps={{ "aria-label": "controlled" }}
                              required
                            />
                          }
                          label="Doctor"
                        />
                      </Stack>
                    </Grid>*/}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateAdmin;

import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { DocProfileComp } from "../../../components/DocProfileComp";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { colors } from "../../../theme/theme";
import { MoreVert } from "@mui/icons-material";

const PatientNotes = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const notesColumns = [
    {
      field: "names",
      headerName: "Name",
      flex: isSmallScreen ? 0.5 : 2.5,
      renderCell: (params) => (
        <Box
          sx={{
            displag: "flex",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: colors.text,
              fontFamily: "FiraSans",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },

    {
      field: "scheduled",
      headerName: "Created On/By",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.25,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton>
            <MoreVert sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const notesRows = [
    {
      id: 1,
      names:
        "I had a meeting today with James and we spoke about his relationship issues. ",
      scheduled: "2024-04-15 10:30 AM",
    },
    {
      id: 2,
      names: "I had a meeting with James and we spoke about his diet. ",
      scheduled: "2024-04-15 11:00 AM",
    },
    {
      id: 3,
      names: "Have you experienced any recent injuries or trauma?",
      scheduled: "2024-04-15 11:30 AM",
    },
    {
      id: 4,
      names:
        "How would you describe the intensity of your pain/discomfort on a scale of 1 to 10?",
      scheduled: "2024-04-15 11:30 AM",
    },
    {
      id: 5,
      names: "Have you experienced any trauma, recently?",
      scheduled: "2024-04-15 11:30 AM",
    },
  ];

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer provider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Patient Notes" />

        {/* Customer Profile */}
        <DocProfileComp />

        {/* Data Grid */}
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar title="Patient Notes" />

              <StyledDataGrid
                rows={notesRows}
                columns={notesColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
              {/* <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                sx={{
                  borderBottom: "1px solid #EBEFF5",
                  color: "#24292E",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                }}
                onClick={() => {
                  handleClose();
                  navigate("/vendor/customers/create");
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                sx={{
                  color: "#F34141",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                }}
                onClick={handleClose}
              >
                Delete
              </MenuItem>
            </Menu> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PatientNotes;

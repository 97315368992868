import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  CssBaseline,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  SwipeableDrawer,
  ListItemButton,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import FeedIcon from "@mui/icons-material/Feed";
import { colors } from "../theme/theme";
import { useMediaQuery } from "@mui/material";
import { useUser } from "../constants/context";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PeopleIcon from "@mui/icons-material/People";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpIcon from "@mui/icons-material/Help";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import MuiDrawer from "@mui/material/Drawer";
import {
  ChecklistOutlined,
  InsertInvitation,
  InsertInvitationOutlined,
  Notes,
  NotesOutlined,
  PieChart,
  PieChartOutline,
  RequestPage,
  RequestPageOutlined,
  Splitscreen,
  SplitscreenOutlined,
} from "@mui/icons-material";

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MenuDrawer({ vendor, admin, supervisor, provider }) {
  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  let drawerMenu = [];

  const { setnavigationId, navigationId } = useUser();

  if (vendor) {
    drawerMenu = [
      {
        id: 0,
        text: "Dashboard",
        icon:
          location.pathname === "/vendor/dashboard" ? (
            <DashboardIcon />
          ) : (
            <DashboardOutlinedIcon />
          ),
        route: "/vendor/dashboard",
      },
      {
        id: 1,
        text: "Customers",
        icon:
          location.pathname === "/vendor/customers" ? (
            <GroupsIcon />
          ) : (
            <GroupsOutlinedIcon />
          ),
        route: "/vendor/customers",
      },
      {
        id: 2,
        text: "Sites",
        icon:
          location.pathname === "/vendor/customer-sites" ? (
            <BusinessCenterIcon />
          ) : (
            <BusinessCenterOutlinedIcon />
          ),
        route: "/vendor/customer-sites",
      },
      {
        id: 5,
        text: "Request",
        icon:
          location.pathname === "/vendor/request" ? (
            <RequestPage />
          ) : (
            <RequestPageOutlined />
          ),
        route: "/vendor/request",
      },
      {
        id: 3,
        text: "Forms",
        icon:
          location.pathname === "/vendor/forms" ? (
            <FeedIcon />
          ) : (
            <FeedOutlinedIcon />
          ),
        route: "/vendor/forms",
      },
      {
        id: 4,
        text: "Activities",
        icon:
          location.pathname === "/vendor/activity" ? (
            <ChecklistIcon />
          ) : (
            <ChecklistOutlinedIcon />
          ),
        route: "/vendor/activity",
      },

    ];
  } else if (admin) {
    drawerMenu = [
      {
        id: 0,
        text: "Dashboard",
        icon:
          location.pathname === "/admin/dashboard" ? (
            <DashboardIcon />
          ) : (
            <DashboardOutlinedIcon />
          ),
        route: "/admin/dashboard",
      },
      {
        id: 1,
        text: "Sites",
        icon:
          location.pathname === "/admin/customer-sites" ? (
            <BusinessCenterIcon />
          ) : (
            <BusinessCenterOutlinedIcon />
          ),
        route: "/admin/customer-sites",
      },
      {
        id: 2,
        text: "Requests",
        icon:
          location.pathname === "/admin/requests" ? (
            <RocketLaunchIcon />
          ) : (
            <RocketLaunchOutlinedIcon />
          ),
        route: "/admin/requests",
      },
      // {
      //   id: 2,
      //   text: "Doctors",
      //   icon:
      //     location.pathname === "/supervisor/doctors" ? (
      //       <img
      //         src={require("../assets/icons/doctor-active.png")}
      //         style={{ height: "24px", width: "24px" }}
      //         alt=""
      //       />
      //     ) : (
      //       <img
      //         src={require("../assets/icons/doctor.png")}
      //         style={{ height: "24px", width: "24px" }}
      //         alt=""
      //       />
      //     ),
      //   route: "/admin/doctors",
      // },
      {
        id: 3,
        text: "Users",
        icon:
          location.pathname === "/admin/users" ? (
            <PeopleIcon />
          ) : (
            <PeopleOutlinedIcon />
          ),
        route: "/admin/users",
      },
      // {
      //   id: 4,
      //   text: "Forms",
      //   icon:
      //     location.pathname === "/admin/forms" ? (
      //       <FeedIcon />
      //     ) : (
      //       <FeedOutlinedIcon />
      //     ),
      //   route: "/admin/forms",
      // },
      // {
      //   id: 5,
      //   text: "Questions",
      //   icon:
      //     location.pathname === "/admin/questions" ? (
      //       <HelpIcon />
      //     ) : (
      //       <HelpOutlineIcon />
      //     ),
      //   route: "/admin/questions",
      // },
      {
        id: 6,
        text: "Activities",
        icon:
          location.pathname === "/admin/activities" ? (
            <ChecklistIcon />
          ) : (
            <ChecklistOutlinedIcon />
          ),
        route: "/admin/activities",
      },
    ];
  } else if (supervisor) {
    drawerMenu = [
      {
        id: 0,
        text: "Dashboard",
        icon:
          location.pathname === "/supervisor/dashboard" ? (
            <DashboardIcon />
          ) : (
            <DashboardOutlinedIcon />
          ),
        route: "/supervisor/dashboard",
      },

      
      // {
      //   id: 0,
      //   text: "Patients",
      //   icon:
      //     location.pathname === "/supervisor/patients" ? (
      //       <PeopleIcon />
      //     ) : (
      //       <PeopleOutlinedIcon />
      //     ),
      //   route: "/supervisor/patients",
      // },
      // {
      //   id: 1,
      //   text: "Doctors",
      //   icon:
      //     location.pathname === "/supervisor/doctors" ? (
      //       <img
      //         src={require("../assets/icons/doctor-active.png")}
      //         style={{ height: "24px", width: "24px" }}
      //         alt=""
      //       />
      //     ) : (
      //       <img
      //         src={require("../assets/icons/doctor.png")}
      //         style={{ height: "24px", width: "24px" }}
      //         alt=""
      //       />
      //     ),
      //   route: "/supervisor/doctors",
      // },
      {
        id: 1,
        text: "Users",
        icon:
          location.pathname === "/supervisor/users" ? (
            <PeopleIcon />
          ) : (
            <PeopleOutlinedIcon />
          ),
        route: "/supervisor/users",
      },
      {
        id: 2,
        text: "Patients",
        icon:
          location.pathname === "/supervisor/patients" ? (
            <PeopleIcon />
          ) : (
            <PeopleOutlinedIcon />
          ),
        route: "/supervisor/patients",
      },

      {
        id: 3,
        text: "Activities",
        icon:
          location.pathname === "/supervisor/activities" ? (
            <ChecklistIcon />
          ) : (
            <ChecklistOutlinedIcon />
          ),
        route: "/supervisor/activities",
      },
      // {
      //   id: 2,
      //   text: "Forms",
      //   icon:
      //     location.pathname === "/supervisor/forms" ? (
      //       <FeedIcon />
      //     ) : (
      //       <FeedOutlinedIcon />
      //     ),
      //   route: "/supervisor/forms",
      // },

      // {
      //   id: 4,
      //   text: "Questions",
      //   icon:
      //     location.pathname === "/supervisor/questions" ? (
      //       <HelpIcon />
      //     ) : (
      //       <HelpOutlineIcon />
      //     ),
      //   route: "/supervisor/questions",
      // },
      // {
      //   id: 5,
      //   text: "Assingment",
      //   icon:
      //     location.pathname === "/supervisor/assignments" ? (
      //       <Splitscreen />
      //     ) : (
      //       <SplitscreenOutlined />
      //     ),
      //   route: "/supervisor/assignments",
      // },
    ];
  } else if (provider) {
    drawerMenu = [
      {
        id: 0,
        text: "Dashboard",
        icon:
          location.pathname === "/provider/dashboard" ? (
            <DashboardIcon />
          ) : (
            <DashboardOutlinedIcon />
          ),
        route: "/provider/dashboard",
      },

      {
        id: 0,
        text: "Patients",
        icon:
          location.pathname === "/provider/patients" ? (
            <PeopleIcon />
          ) : (
            <PeopleOutlinedIcon />
          ),
        route: "/provider/patients",
      },
      {
        id: 6,
        text: "Activities",
        icon:
          location.pathname === "/provider/activity" ? (
            <ChecklistIcon />
          ) : (
            <ChecklistOutlinedIcon />
          ),
        route: "/provider/activity",
      },

      // {
      //   id: 1,
      //   text: "Scheduled Appointments",
      //   icon:
      //     location.pathname === "/provider/scheduled-appointments" ? (
      //       <InsertInvitation />
      //     ) : (
      //       <InsertInvitationOutlined />
      //     ),
      //   route: "/provider/scheduled-appointments",
      // },
      // {
      //   id: 2,
      //   text: "Patient Notes",
      //   icon:
      //     location.pathname === "/provider/patient-notes" ? (
      //       <Notes />
      //     ) : (
      //       <NotesOutlined />
      //     ),
      //   route: "/provider/patient-notes",
      // },
      // {
      //   id: 3,
      //   text: "Forms",
      //   icon:
      //     location.pathname === "/provider/forms" ? (
      //       <FeedIcon />
      //     ) : (
      //       <FeedOutlinedIcon />
      //     ),
      //   route: "/provider/forms",
      // },

      // {
      //   id: 7,
      //   text: "Questions",
      //   icon:
      //     location.pathname === "/provider/questions" ? (
      //       <HelpIcon />
      //     ) : (
      //       <HelpOutlineIcon />
      //     ),
      //   route: "/provider/questions",
      // },
    ];
  }

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer("left", false)}
      onKeyDown={toggleDrawer("left", false)}
    >
      <List>
        <img
          src={require("../assets/images/logo_sm.png")}
          alt=""
          style={{ margin: "15px 20px" }}
        />
        {drawerMenu.map((item) => (
          <ListItem key={item.text}>
            <ListItemButton sx={{ padding: 0 }}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  sx: {
                    whiteSpace: "pre-line",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const smallScreen = useMediaQuery("(max-width:700px)");
  const { toggleDrawer, state } = useUser();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {!smallScreen ? (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            sx={{
              p: open ? 0 : 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {open && (
              <img
                src={require("../assets/images/logo_sm.png")}
                alt=""
                style={{ marginLeft: "20px" }}
              />
            )}
            <IconButton onClick={() => setOpen(!open)}>
              <MenuOutlinedIcon style={{ color: colors.text }} />
            </IconButton>
          </DrawerHeader>

          <List sx={{ mt: 0.2 }}>
            {drawerMenu?.map((item, index) => (
              <ListItem
                key={item.text}
                onClick={() => {
                  navigate(item.route);
                  setnavigationId(item.id);
                }}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    location.pathname === item.route ? "#FAFAFA" : "#fff",
                }}
              >
                {item.icon && (
                  <ListItemIcon
                    style={{
                      color:
                        location.pathname === item.route
                          ? colors.text
                          : "#454C52",
                    }}
                    sx={{
                      minWidth: "40px",
                      mb: open ? 0 : 1,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                )}
                {open && (
                  <ListItemText
                    style={{
                      color:
                        location.pathname === item.route
                          ? colors.text
                          : "#454C52",
                      fontWeight:
                        location.pathname === item.route ? "900" : "400",
                      fontFamily: "FiraSans-bold",
                      whiteSpace: "pre-line",
                    }}
                    primary={item.text}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Drawer>
      ) : (
        <div>
          <SwipeableDrawer
            anchor="left"
            open={state.left}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list()}
          </SwipeableDrawer>
        </div>
      )}
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "../../../components/AppBar";
import {
  CreateCustomInput,
  CreateCustomTextArea,
  CreateDropDown,
  CreateHeader,
} from "../../../components/CreatePagesComponents";
import { UploadBtn } from "../../../components/CustomBtn";
import { EditNote, HelpOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance, { BASE_URL } from "../../../constants/config";

const CreateQuestionSupervisor = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [confirmation, setconfirmation] = useState(false);

  const [questionsData, setquestionsData] = useState({
    questionName: "",
    questionDescription: "",
    questionType: "",
    doc: "",
  });
  const [formDoc, setformDoc] = useState("");
  const [questionType, setquestionType] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const { userData } = useSelector((state) => state.user);

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const CreateQues = async () => {
    if (questionsData.questionName === "") {
      seterror(true);
      seterrorMsg("Please fill all the fields");
      return;
    } else {
      seterror(false);
      setloading(true);
      await axiosInstance
        .post(
          `${BASE_URL}/question/createQuestion`,
          {
            selectedSites: [
              {
                siteId: userData?.siteId,
                customerId: userData?.customerId,
              },
            ],
            questionName: questionsData.questionName,
            questionType: questionsData.questionType,
            questiondescription: questionsData.questionDescription,
            document_url: formDoc,
            createdBy: userData?.id,
            status: "active",
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setquestionsData({
            questionName: "",
            questionDescription: "",
            questionType: "",
            doc: "",
          });
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const UpdateQues = async () => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/question/editQuestion`,
        {
          questionId: location?.state?.id,
          selectedSites: [],
          deleteQuestionSite: [],
          questionName: questionsData.questionName,
          questionType: questionsData.questionType,
          questiondescription: questionsData.questionDescription,
          document_url: formDoc,
          createdBy: userData?.id,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setquestionsData({
          questionName: "",
          questionDescription: "",
          questionType: "",
          doc: "",
        });
        GetQuesById();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetQuesById = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/question/getQuestionDetails?questionId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        const singleQuestion = res.data?.questionSite[0];
        setquestionsData({
          questionName: singleQuestion?.questionName,
          questionDescription: singleQuestion?.questiondescription,
          questionType: singleQuestion?.questionType,
          doc: singleQuestion?.document_url,
        });
        setformDoc(singleQuestion?.document_url);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetQuesById();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer
        supervisor={location?.state?.provider === true ? false : true}
        provider={!location?.state?.provider === true ? false : true}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? `Form has been created successfully!`
            : `Form has been updated successfully!`
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? `Question has been created successfully!`
            : `Question has been updated successfully!`
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Create New Questions" />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Question"
                      : "Update Question"
                  }
                  btnTitle={params?.type === "create" ? "Create" : "Update"}
                  onClick={params?.type === "create" ? CreateQues : UpdateQues}
                  cancelFunc={() => navigate(-1)}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CreateCustomInput
                        value={questionsData.questionName}
                        setValue={(value) =>
                          setquestionsData((prevData) => ({
                            ...prevData,
                            questionName: value,
                          }))
                        }
                        placeholder="Write a Question"
                        Icon={HelpOutline}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={questionType}
                        setValue={setquestionType}
                        title="Question Type"
                        Icon={HelpOutline}
                        data={[
                          {
                            label: "Questionnaire",
                            value: "Questionnaire",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CreateCustomTextArea
                        value={questionsData.questionDescription}
                        setValue={(value) =>
                          setquestionsData((prevData) => ({
                            ...prevData,
                            questionDescription: value,
                          }))
                        }
                        placeholder="Question Description"
                        Icon={EditNote}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <UploadBtn
                        onClick={setformDoc}
                        formDoc={formDoc}
                        setloading={setloading}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateQuestionSupervisor;

import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Drawer,
  Stack,
  FormControl,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FilterBox from "./../../../components/FilterBox";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import {
  customerData,
  dummyActivitiesData,
} from "../../../assets/data/DummyData";
import { useNavigate } from "react-router-dom";
import {
  Close,
  Download,
  DownloadOutlined,
  EditOutlined,
  EmailOutlined,
  MoreVert,
  PhoneIphoneOutlined,
  Search,
  SendOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { colors } from "../../../theme/theme";
import { RedModal } from "../../../components/ConfirmationModal";
import { CreateDropDown } from "../../../components/CreatePagesComponents";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import axios from "axios";
import { getAllPatientsForDoctor } from "../../../redux/dispatchers/provider.dispatcher";
import { saveAs } from "file-saver";
import * as XLSX from 'xlsx';

const PatientsProvider = () => {
  const navigate = useNavigate();

  const [deleteModalState, setdeleteModalState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmation, setConfirmation] = useState(false);

  const [snackbarState, setsnackbarState] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const patientsColumns = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params?.row?.patients?.avatar !== "" ? (
            <img
              src={params?.row?.patients?.avatar}
              alt=""
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
          ) : null}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClose();
                navigate("/provider/patients/patient-insights", {
                  state: { id: params.row?.patients?.id },
                });
              }}
            >
              {params?.row?.patients?.name}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "activityType",
      headerName: "Activity Type",
      flex: 2,
      renderCell: (params) => {
        const activityTypes = params?.row?.patients?.patient_activities
          ?.filter((act) => act?.activity?.activityType)
          ?.map((act) => act?.activity?.activityType);

        let displayText = "No Activity";

        if (
          activityTypes?.length > 0 &&
          params?.row?.patients?.patient_forms.length === 0
        ) {
          displayText = activityTypes?.join(", ");
        } else if (
          params?.row?.patients?.patient_forms.length > 0 &&
          activityTypes?.length === 0
        ) {
          displayText = "Form";
        } else if (
          params?.row?.patients?.patient_forms.length > 0 ||
          activityTypes?.length > 0
        ) {
          displayText = "Form, " + activityTypes?.join(", ");
        }
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 1,
                // whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {displayText}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "verified",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params?.row?.patients?.verified === true ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              // handleClick(e);
              setpatientId(params.row?.patients?.id);
              setpatientName(params.row?.patients?.name);
              setpatientStatus(params.row?.patients?.verified);
              navigate("/provider/patients/update", {
                state: { id: params.row?.patients?.id },
              });
            }}
          >
            <EditOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const [pastActivity, setpastActivity] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setpastActivity(newOpen);
    setAnchorEl(null);
  };

  const [downloadForms, setdownloadForms] = useState(false);
  const toggleDownloadFormsDrawer = (newOpen) => () => {
    setdownloadForms(newOpen);
    setAnchorEl(null);
  };

  const [value, setValue] = useState("");
  const [activityType, setactivityType] = useState([]);

  const smallScreen = useMediaQuery("(max-width: 750px)");

  const activityColumns = [
    {
      field: "names",
      headerName: "Name",
      flex: smallScreen ? 0.5 : 2.5,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <img
            src={params.row.img}
            alt=""
            style={{ height: "30px", width: "30px", borderRadius: "50%" }}
          />
          <Typography
            sx={{
              ml: 1,
              fontSize: "14px",
              fontWeight: "500",
              color: colors.text,
              fontFamily: "FiraSans",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "scheduled",
      headerName: "Created On",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.25,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreVert sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const DrawerList = (
    <Box sx={{}} role="presentation" onClick={toggleDrawer(false)}>
      <Stack
        direction={smallScreen ? "column" : "row"}
        alignItems={!smallScreen ? "center" : "flex-start"}
        justifyContent={smallScreen ? "center" : "space-between"}
        width={"100%"}
        p={3}
      >
        <Stack direction={"row"} alignItems="center" justifyContent="center">
          <Close sx={{ mr: 1, cursor: "pointer" }} />
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: colors.text,
              fontFamily: "FiraSans",
            }}
          >
            All Activities - Nguyen Shane
          </Typography>
        </Stack>

        <Box>
          <Grid container spacing={2} sx={{ flex: 1 }}>
            <Grid item lg={3} sm={3} xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{
                    mt: smallScreen ? 2 : 0,
                    height: "50px",
                    borderRadius: "9px",
                    backgroundColor: "#fff",
                    elevation: 0,
                    fontFamily: "FiraSans",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: colors.text,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(241, 242, 244, 1)",
                    },
                    "& input::placeholder": {
                      color: colors.text,
                      opacity: 1,
                      fontFamily: "FiraSans",
                    },
                  }}
                  placeholder={"Search"}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search sx={{ color: colors.text }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
              <CreateDropDown
                value={activityType}
                setValue={setactivityType}
                title="Choose Question"
                noIcon
                data={[]}
              />
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
              <CreateDropDown
                value={activityType}
                setValue={setactivityType}
                title="Choose Question"
                noIcon
                data={[]}
              />
            </Grid>
            <Grid item lg={3} sm={3} xs={12}>
              <Button
                disableElevation
                variant="contained"
                sx={{
                  height: "47.5px",
                  width: { xs: "100%", sm: "100%" },
                  backgroundColor: "#FAFAFA",
                  textTransform: "none",
                  borderRadius: "8px",
                  border: "1px solid #EBEFF5",
                  boxShadow: "0px 2px 5px 0px #676E7614",
                  "&:hover": {
                    backgroundColor: "#FAFAFA",
                  },
                  color: "inherit",
                }}
              >
                Apply Filters
                <SendOutlined sx={{ ml: 1, fontSize: "20px" }} />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Grid container>
        <Grid item xs={12}>
          <StyledDataGrid
            rows={dummyActivitiesData}
            columns={activityColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            sx={{
              borderRadius: "12px",
              border: "none",
              px: 2,
            }}
            pageSizeOptions={[5, 10]}
            getRowClassName={(params) =>
              params.row.id % 2 === 0 ? "even" : "odd"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );

  const [formDownloaded, setformDownloaded] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setformDownloaded(false);
  };

  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();

  const [patientSearch, setpatientSearch] = useState("");

  const { userData } = useSelector((state) => state.user);
  const [allPatients, setallPatients] = useState([]);
  const [patientDownloadForms, setpatientDownloadForm] = useState([]);
  const [patientDownloadActivities, setpatientDownloadActivities] = useState([]);

  const GetPatients = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/doctor_patient/getAllPatientsWithDoctorsByDrId?doctorId=${userData?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallPatients(res.data?.allPatientsDoctors);
      })
      .catch((e) => {
        setloading(false);
      });
  };
  const GetAllSiteForms = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/patient_form/getAllSiteForms?siteId=${userData?.siteId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setpatientDownloadForm(res.data?.assignedForm);
        setpatientDownloadActivities(res.data?.assignedActivities);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const current = new Date();

    const handleExportData = () => {
    const filteredFormsToDownload = patientDownloadForms?.filter((val) => {
      const endDate = new Date(val?.endDate);
      // return activeSwitch ? endDate > current : endDate <= current;
      return  endDate <= current;
    });
    const filteredActivitiesToDownload = patientDownloadActivities?.filter((val) => {
      const endDate = new Date(val?.endDate);
  //    return activeSwitch ? endDate > current : endDate <= current;
      return  endDate <= current;
    });
    const wb = XLSX.utils.book_new();

    // Function to apply header styling
    const applyHeaderStyle = (sheet, headerRange) => {
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + "2"; // Assuming headers are in the second row
        if (!sheet[address]) continue;
        sheet[address].s = {
          fill: {
            patternType: 'solid',
            fgColor: { rgb: "#000" }
          },
          font: {
            bold: true
          }
        };
      }
    };
    let totalActivities=filteredActivitiesToDownload.length,totalForms=filteredFormsToDownload.length,answeredForms=0,answeredActivities=0;
    for(var i=0;i<filteredFormsToDownload.length;i++)
    {
      if(filteredFormsToDownload[i].questionAndAnswers[0].answer!=null)
      {
        answeredForms++;
      }
    }
    for(var z=0;z<filteredActivitiesToDownload.length;z++)
      {
        if(filteredActivitiesToDownload[z].answer!=null)
        {
          answeredActivities++;
        }
      }
    // Process assignedForm
    const assignedFormSheetData = [
      [`Forms History`], // Header
      // [`Total Forms ${totalForms}`], // Header
      // [`Answered Forms ${answeredForms}`], // Header
      ["Date", "Form Name", "Form Type", "Question", "Answers", "Answer","Assigned To Name","Assigned To Email"], // Column Headers
      ...filteredFormsToDownload?.map(form => [
        form.date.split('T')[0],
        // form.formId,
        form.formName,
        form.formType,
        // form.id,
        form.questionAndAnswers[0].question,
        form.questionAndAnswers[0].answers.join(", "),
        form.questionAndAnswers[0].answer,
        form.user.name,
        form.user.email
         ])
    ];
    const assignedFormSheet = XLSX.utils.aoa_to_sheet(assignedFormSheetData);
    applyHeaderStyle(assignedFormSheet, XLSX.utils.decode_range(assignedFormSheet['!ref']));
    XLSX.utils.book_append_sheet(wb, assignedFormSheet, 'Assigned Forms');
  
    // Process assignedActivities
    const assignedActivitiesSheetData = [
      [`Activities History`], // Header
      // [`Total Activities ${totalActivities}`], // Header
      // [`Answered Activities ${answeredActivities}`], // Header
      [
        "Activity Name", "Activity Type", "Answer",
         "Options", 
        "Status", "Date", "Frequency","Assigned To Name","Assigned To Email"
        
      ], // Column Headers
      ...filteredActivitiesToDownload?.map(activity => [
        activity.activity.activityName,
        activity.activity.activityType,
        activity.answer,
        activity.activity.options,
        activity.activity.status,
        activity.date.split('T')[0],
        activity.frequency,
        activity.user.name,
        activity.user.email,

      ])
    ];
    const assignedActivitiesSheet = XLSX.utils.aoa_to_sheet(assignedActivitiesSheetData);
    applyHeaderStyle(assignedActivitiesSheet, XLSX.utils.decode_range(assignedActivitiesSheet['!ref']));
    XLSX.utils.book_append_sheet(wb, assignedActivitiesSheet, 'Assigned Activities');
  
    // Adjust column widths
    const adjustColumnWidths = (sheet) => {
      const cols = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let maxLength = 10; // Minimum column width
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const cell = sheet[XLSX.utils.encode_cell({r: R, c: C})];
          if (cell && cell.v) {
            const length = cell.v.toString().length;
            if (length > maxLength) {
              maxLength = length;
            }
          }
        }
        cols.push({wch: maxLength});
      }
      sheet['!cols'] = cols;
    };
  
    adjustColumnWidths(assignedFormSheet);
    adjustColumnWidths(assignedActivitiesSheet);
  
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== wbout.length; ++i) view[i] = wbout.charCodeAt(i) & 0xFF;
    const blob = new Blob([buf], { type: 'application/octet-stream' });
    saveAs(blob, 'data.xlsx');
   };

  useEffect(() => {
    GetPatients();
    GetAllSiteForms();

  }, []);

  const [patientId, setpatientId] = useState("");
  const [patientName, setpatientName] = useState("");
  const [patientStatus, setpatientStatus] = useState();
  const [changedPatientStatus, setchangedPatientStatus] = useState();

  const DeletePatient = async () => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/user/updateUserStatus`,
        {
          status: changedPatientStatus ? "active" : "inactive",
          userId: patientId,
          verified: changedPatientStatus,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdeleteModalState(false);
        setConfirmation(true);
        GetPatients();
      })
      .catch((e) => {
        setloading(false);
      });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer provider />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={formDownloaded}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Form Downloaded"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Snackbar
        open={snackbarState}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={
          "Patient has been marked as " + changedPatientStatus
            ? "active successfully!"
            : "inactive successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={() => {
          setdeleteModalState(false);
          DeletePatient();
        }}
        title="Confirmation"
        mainText={
          patientStatus
            ? "Are you sure you want to mark this patient as inactive?"
            : "Are you sure you want to mark this patient as active?"
        }
        actionText={patientStatus ? "Mark as Inactive" : "Mark as Active"}
      />

      <Drawer
        anchor="right"
        open={pastActivity}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80%",
            backgroundColor: "#fff",
            border: "none",
          },
        }}
      >
        {DrawerList}
      </Drawer>

      <Drawer
        anchor="right"
        open={downloadForms}
        onClose={toggleDownloadFormsDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80%",
            backgroundColor: "#fff",
            border: "none",
          },
        }}
      >
        <Box p={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent="center"
            >
              <Close
                sx={{ mr: 1, cursor: "pointer" }}
                onClick={() => setdownloadForms(false)}
              />
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: colors.text,
                  fontFamily: "FiraSans",
                }}
              >
                View Activities & Forms
              </Typography>
            </Stack>
            <Box>
              <Grid container spacing={2} sx={{ flex: 1 }}>
                <Grid item lg={8} sm={8} xs={12}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      sx={{
                        mt: smallScreen ? 2 : 0,
                        height: "50px",
                        borderRadius: "9px",
                        backgroundColor: "#fff",
                        elevation: 0,
                        fontFamily: "FiraSans",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: colors.text,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(241, 242, 244, 1)",
                        },
                        "& input::placeholder": {
                          color: colors.text,
                          opacity: 1,
                          fontFamily: "FiraSans",
                        },
                      }}
                      placeholder={"Search"}
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start">
                          <Search sx={{ color: colors.text }} />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={4} sm={4} xs={12}>
                  <Button
                    disableElevation
                    variant="contained"
                    sx={{
                      height: "47.5px",
                      width: { xs: "100%", sm: "100%" },
                      backgroundColor: colors.green,
                      textTransform: "none",
                      borderRadius: "8px",
                      border: "1px solid #EBEFF5",
                      boxShadow: "0px 2px 5px 0px #676E7614",
                      "&:hover": {
                        backgroundColor: "#FAFAFA",
                      },
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    <DownloadOutlined sx={{ mr: 1, fontSize: "18px" }} />
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Drawer>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Patients" />

        {/* Filter */}
        {/* <FilterBox type="patients" /> */}

        {/* Data Grid */}
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Patients"
                onClick={() => navigate("/provider/patients/create")}
                search={patientSearch}
                setSearch={setpatientSearch}
                showbutton={true}
                excelButtonOnClick={handleExportData}

              />

              <StyledDataGrid
                rows={
                  allPatients?.filter((val) =>(
                    val?.patients?.name
                      ?.toLocaleLowerCase()
                      ?.includes(patientSearch?.toLocaleLowerCase())
                      || val?.patients?.emr_patient_id
                      ?.toLocaleLowerCase()
                      ?.includes(patientSearch?.toLocaleLowerCase())
                      || val?.patients?.email
                      ?.toLocaleLowerCase()
                      ?.includes(patientSearch?.toLocaleLowerCase())
                      || val?.patients?.mobile
                      ?.toLocaleLowerCase()
                      ?.includes(patientSearch?.toLocaleLowerCase()))
                      
                  ) || []
                }
                columns={patientsColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5,10, { value: allPatients?.length, label: 'All' }]}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => {
              handleClose();
              navigate("/provider/patients/view", {
                state: { id: patientId },
              });
            }}
          >
            View Patient Record
          </MenuItem> */}
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() =>
              navigate("/provider/patients/patient-insights", {
                state: { id: patientId },
              })
            }
          >
            View Patient Activities
          </MenuItem>
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => {
              handleClose();
              navigate("/provider/patients/update", {
                state: { id: patientId },
              });
            }}
          >
            Edit Patient Record
          </MenuItem>
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => {
              handleClose();
              navigate("/provider/patients/assign/activity", {
                state: { id: patientId, name: patientName, provider: true },
              });
            }}
          >
            Assign Activity
          </MenuItem>
          <MenuItem
            sx={{
              color: "#F34141",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => {
              setdeleteModalState(true);
              setchangedPatientStatus(!patientStatus);
              handleClose();
            }}
          >
            {patientStatus ? "Mark as Inactive" : "Mark as Active"}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default PatientsProvider;

import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  loading: false,
  role: "",
};

const userReq = createAction("userByIdRequest");
const userSuccess = createAction("userByIdSuccess");
const userFail = createAction("userByIdFailure");

const userRoleSuccess = createAction("userRoleSuccess");

const clear = createAction("clearUserData");

export const authReducer = createReducer(initialState, (builder) => {
  builder
    // User by Id
    .addCase(userReq, (state, action) => {
      state.loading = true;
    })
    .addCase(userSuccess, (state, action) => {
      state.loading = false;
      state.userData = action.payload.specificUser;
      if (action.payload.specificUser.role === "vendor") {
        state.role = "vendor";
      }
    })
    .addCase(userFail, (state, action) => {
      state.loading = false;
    })

    .addCase(userRoleSuccess, (state, action) => {
      state.userRole = action.payload;
    })

    // Clear User Data
    .addCase(clear, (state, action) => {
      state.userData = null;
    });
});

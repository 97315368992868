import React, { useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Backdrop,
  IconButton,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { colors } from "../theme/theme";
import axios from "axios";
import { BASE_URL } from "../constants/config";
import { Close } from "@mui/icons-material";

const CustomBtn = ({ text, onClick }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      sx={{
        mt: 3,
        mb: 2,
        width: "45%",
        height: "50px",
        backgroundColor: colors.primaryBtn,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: "#1aaa1a",
        },
      }}
      onClick={onClick}
    >
      {text}
      <EastIcon sx={{ ml: 1 }} />
    </Button>
  );
};

export default CustomBtn;

export const UploadBtn = ({
  text,
  onClick,
  type,
  formDoc,
  setloading,
  documentUpload,
  setDocumentName,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setselectedFile] = useState({});

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const fls = Array.from(e.dataTransfer.files);

    const pdfFiles = fls.filter((file) => file.type === "application/pdf");

    if (pdfFiles.length > 0) {
      setselectedFile(pdfFiles[0]);
    } else {
      alert("Please drop a PDF file.");
    }

    const files = Array.from(e.dataTransfer.files);
    setselectedFile(files[0]);
  };

  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        onClick(res.data?.url);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  return (
    <Grid item xs={12}>
      <Box
        // onDragEnter={handleDragEnter}
        // onDragOver={(e) => e.preventDefault()}
        // onDragLeave={handleDragLeave}
        // onDrop={handleDrop}
        sx={{
          height: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #EBEFF5",
          borderRadius: "8px",
          cursor: "pointer",
          backgroundColor: isDragging ? "#000" : "#F5F5F5",
        }}
      >
        {/* {isDragging && (
          <>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isDragging}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "FiraSans",
                  color: colors.text,
                }}
              >
                Drop Files
              </Typography>
            </Backdrop>
          </>
        )} */}
        <Box
          sx={{
            width: "70%",
            height: "100%",
            backgroundColor: "#fafafa",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          {/* {formDoc ? (
            <>
              <img
                src={require("../assets/icons/pdf.png")}
                style={{
                  height: "50px",
                  width: "50px",
                  marginRight: "10px",
                }}
                alt=""
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "FiraSans",
                  color: "blue",
                  textAlign: "center",
                  maxWidth: "70%",
                  textDecorationLine: "underline",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                }}
                onClick={() => window.open(formDoc, "_blank")}
              >
                {formDoc}
              </Typography>
            </>
          ) : (
            <>
              {selectedFile?.name === undefined ? (
                <>
                  <img
                    src={require("../assets/images/table/upload.png")}
                    style={{
                      height: "50px",
                      width: "50px",
                      marginRight: "10px",
                    }}
                    alt=""
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "FiraSans",
                      color: colors.text,
                    }}
                  >
                    Upload Documents
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "400",
                        fontFamily: "FiraSans",
                        color: "#596066",
                      }}
                    >
                      or use the “Browse” button
                    </Typography>
                  </Typography>
                </>
              ) : (
                <>
                  <img
                    src={require("../assets/icons/pdf.png")}
                    style={{
                      height: "50px",
                      width: "50px",
                      marginRight: "10px",
                    }}
                    alt=""
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "FiraSans",
                      color: colors.text,
                    }}
                  >
                    {selectedFile?.name}
                  </Typography>
                </>
              )}
            </>
          )} */}
          <>
            <img
              src={require("../assets/images/table/upload.png")}
              style={{
                height: "50px",
                width: "50px",
                marginRight: "10px",
              }}
              alt=""
            />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "FiraSans",
                color: colors.text,
              }}
            >
              Upload Documents
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                  color: "#596066",
                }}
              >
                or use the “Browse” button
              </Typography>
            </Typography>
          </>
        </Box>
        <Box
          sx={{
            width: "30%",
            height: "100%",
            backgroundColor: "#F5F5F5",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleBrowseClick}
            sx={{
              boxShadow: "box-shadow: 0px 2px 5px 0px #676E7614",
              borderRadius: "8px",
              backgroundColor: "#fff",
              padding: "14px 22px",
              border: "1px solid #EBEFF5",
              color: colors.text,
              fontWeight: "500",
              fontSize: "16px",
              fontFamily: "FiraSans",
              textTransform: "none",
            }}
          >
            Browse Files
          </Button>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept=".pdf"
            onChange={(e) => {
              const file = e.target.files[0];

              if (file.type === "application/pdf") {
                setselectedFile(file);
                if (documentUpload) {
                  onClick(file, file.name);
                } else {
                  dataURItoBlob(file);
                }
              } else {
                alert("Please select a PDF file.");
              }
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export const MultipleUploadBtn = ({
  text,
  onClick,
  type,
  formDoc,
  setLoading,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedUrls, setUploadedUrls] = useState([]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);

    const pdfFiles = files.filter((file) => file.type === "application/pdf");

    if (pdfFiles.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...pdfFiles]);
      uploadFiles(pdfFiles);
    } else {
      alert("Please drop PDF files.");
    }
  };

  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  const uploadFiles = async (files) => {
    setLoading(true);
    const promises = files.map(async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      return await axios
        .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
        .then((res) => res.data?.url)
        .catch((err) => {
          console.error("File upload error:", err);
          return null;
        });
    });

    console.log("Promises:", promises);

    await Promise.all(promises)
      .then((urls) => {
        const validUrls = urls.filter((url) => url !== null);
        setUploadedUrls((prevUrls) => [...prevUrls, ...validUrls]);
        onClick(validUrls);
        console.log("Uploaded URLs:", validUrls);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error uploading files:", err);
        setLoading(false);
      });
  };

  return (
    <Grid item xs={12}>
      <Box
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          height: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #EBEFF5",
          borderRadius: "8px",
          cursor: "pointer",
          backgroundColor: isDragging ? "#000" : "#F5F5F5",
        }}
      >
        {isDragging && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isDragging}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                fontFamily: "FiraSans",
                color: colors.text,
              }}
            >
              Drop Files
            </Typography>
          </Backdrop>
        )}
        <Box
          sx={{
            width: "70%",
            height: "100%",
            backgroundColor: "#fafafa",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          {selectedFiles.length === 0 ? (
            <>
              <img
                src={require("../assets/images/table/upload.png")}
                style={{
                  height: "50px",
                  width: "50px",
                  marginRight: "10px",
                }}
                alt=""
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "FiraSans",
                  color: colors.text,
                }}
              >
                Upload Documents
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                    color: "#596066",
                  }}
                >
                  or use the “Browse” button
                </Typography>
              </Typography>
            </>
          ) : (
            selectedFiles.map((file, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <img
                  src={require("../assets/icons/pdf.png")}
                  style={{
                    height: "50px",
                    width: "50px",
                    marginRight: "10px",
                  }}
                  alt=""
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "FiraSans",
                    color: colors.text,
                  }}
                >
                  {file.name}
                </Typography>
              </Box>
            ))
          )}
        </Box>
        <Box
          sx={{
            width: "30%",
            height: "100%",
            backgroundColor: "#F5F5F5",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleBrowseClick}
            sx={{
              boxShadow: "box-shadow: 0px 2px 5px 0px #676E7614",
              borderRadius: "8px",
              backgroundColor: "#fff",
              padding: "14px 22px",
              border: "1px solid #EBEFF5",
              color: colors.text,
              fontWeight: "500",
              fontSize: "16px",
              fontFamily: "FiraSans",
              textTransform: "none",
            }}
          >
            Browse Files
          </Button>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept=".pdf"
            multiple
            onChange={(e) => {
              const files = Array.from(e.target.files).filter(
                (file) => file.type === "application/pdf"
              );
              if (files.length > 0) {
                setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
                uploadFiles(files);
              } else {
                alert("Please select PDF files.");
              }
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
};

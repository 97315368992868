import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  FormControl,
  Select,
  OutlinedInput,
  CircularProgress,
  Backdrop,
  Stack,
  DialogContent,
  DialogTitle,
  DialogActions,
  Dialog,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import {
  Search,
  SearchIconWrapper,
  StyledDataGrid,
  StyledInputBase,
} from "../../../components/StyledSearchDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { dummyRequestData } from "../../../assets/data/DummyData";
import DoneIcon from "@mui/icons-material/Done";
import {
  ConfirmationModal,
  RedModal,
} from "../../../components/ConfirmationModal";
import { BASE_URL } from "../../../constants/config";
import axios from "axios";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Check } from "@mui/icons-material";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { ErrorSnackbar } from "../../../components/CustomSnackbar";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  // backdropFilter: "blur(.5px) sepia(.5%)",
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    height: "100%",
    width: "100%",
  },
}));

const Requests = () => {
  const navigate = useNavigate();

  const [denyRequest, setdenyRequest] = useState(false);
  const [approveRequest, setapproveRequest] = useState(false);
  const [snackbarState, setsnackbarState] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [requestId, setrequestId] = useState("");
  const [denyReason, setdenyReason] = useState("");
  const [search, setSearch] = useState("");

  const requestColumn = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params?.row?.user?.avatar !== null && (
            <img
              src={params?.row?.user?.avatar}
              alt=""
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                objectFit: "contain",
              }}
            />
          )}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params?.row?.user?.name}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "note",
      headerName: "Note",
      flex: 2,
      renderCell: (params) => (
        <Box
          style={{
            display: "flex",
            flex: 1,
            cursor: "pointer",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <img
              src={`https://flagcdn.com/w40/${params?.row?.site?.countryCode}.png`}
              alt=""
              style={{
                width: "30px",
                borderRadius: "5px",
              }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
                ml: 1,
              }}
            >
              {params.row?.site?.address}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: colors.subText,
              fontSize: "12px",
              fontWeight: "400",
              mt: 0.5,
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "95%",
            }}
          >
            Vendor has sent a request for the above site to access and repair.
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.5,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
            fontFamily: "FiraSans",
          }}
        >
          {moment(params.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <>
          {params.value === "pending" ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setapproveRequest(true);
                  setrequestId(params.row.id);
                }}
                variant="contained"
                sx={{
                  height: "35px",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                  color: "#fff",
                  backgroundColor: "#138418",
                  padding: "9px 14px",
                  textTransform: "none",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#138418",
                  },
                }}
              >
                <DoneIcon sx={{ mr: 1, height: "20px", width: "20px" }} />
                Approve
              </Button>
              <Button
                onClick={() => {
                  setdenyRequest(true);
                  setrequestId(params.row.id);
                }}
                variant="contained"
                sx={{
                  ml: 1,
                  height: "35px",
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "FiraSans",
                  color: "#fff",
                  padding: "9px 14px",
                  backgroundColor: "#F34141",
                  textTransform: "none",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#F34141",
                  },
                }}
              >
                <CloseIcon sx={{ mr: 1, height: "20px", width: "20px" }} />
                Deny
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor:
                  params?.value === "expired"
                    ? "#F0F0F0"
                    : params?.value === "rejected"
                    ? "#F37373"
                    : "#6AD09D",
                padding: "9px 14px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: params?.value === "approved" ? "pointer" : "default",
              }}
              onClick={() => {
                if (params?.value === "approved") {
                  setdenyRequest(true);
                  setrequestId(params.row.id);
                }
              }}
            >
              <Typography
                sx={{
                  color: params?.value === "expired" ? colors.text : "#fff",
                  fontSize: "13px",
                  fontWeight: "500",
                  fontFamily: "FiraSans",
                }}
              >
                {params?.value === "expired"
                  ? "Request Expired"
                  : params?.value === "rejected"
                  ? "Request Rejected"
                  : "End Access for Vendor"}
              </Typography>
            </Box>
          )}
        </>
      ),
    },
  ];

  const [loading, setloading] = useState(false);
  const [allRequests, setallRequests] = useState([]);

  const { userData } = useSelector((state) => state.user);

  const GetAllRequests = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/request/getAllRequest?customerId=${userData?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallRequests(res.data?.requestResult);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetAllRequests();
  }, []);

  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  const handleStartDateChange = (newValue) => {
    setstartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setendDate(newValue);
  };

  const ApproveRequest = async () => {
    if (startDate === null || endDate === null) {
      seterror(true);
      seterrorMsg("Please select the start and end date");
      return;
    } else if (startDate > endDate) {
      seterror(true);
      seterrorMsg("End date should be greater than start date");
      return;
    } else {
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/request/updateRequestStatus`,
          {
            status: "approved",
            startTime: startDate,
            endTime: endDate,
            requestId: requestId,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setsnackbarState(true);
          setapproveRequest(false);
          GetAllRequests();
        })
        .catch((err) => {
          setloading(false);
        });
    }
  };

  const DenyRequest = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/request/updateRequestStatus`,
        {
          status: "rejected",
          requestId: requestId,
          message: denyReason,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdenyRequest(false);
        setsnackbarState(true);
        GetAllRequests();
      })
      .catch((err) => {
        setloading(false);
      });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ErrorSnackbar
        error={error}
        handleCloseError={seterror}
        errorMsg={errorMsg}
      />

      <RedModal
        open={denyRequest}
        onClose={() => setdenyRequest(false)}
        title="Confirmation"
        mainText="Are you sure want to Deny all the requests?"
        subText="Please ensure that once the deny, all should be raise again."
        onClick={() => {
          DenyRequest();
        }}
        actionText="Deny"
        textBox
        text={denyReason}
        setText={setdenyReason}
      />

      <BootstrapDialog
        onClose={() => setapproveRequest(false)}
        aria-labelledby="customized-dialog-title"
        open={approveRequest}
        sx={{
          height: "100%",
          width: "100%",
          maxHeight: "80vh",
          overflowY: "auto",
        }} // Set maxHeight to limit dialog height
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "FiraSans",
            color: colors.text,
          }}
          id="customized-dialog-title"
        >
          Confirmation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setapproveRequest(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          p={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
                textAlign: "center",
              }}
            >
              Please choose a duration time for accessing the site
            </Typography>
            <Typography
              gutterBottom
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: colors.lightText,
                fontFamily: "FiraSans",
                px: 2.5,
                maxWidth: "70%",
                pb: 4,
                pt: 2,
                textAlign: "center",
              }}
            >
              Please ensure that once approved, the requesting person will be
              able to view the records until the specified time.
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column" sx={{ mr: 2 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: colors.text,
                    fontFamily: "FiraSans",
                  }}
                >
                  Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={startDate}
                    onChange={handleStartDateChange}
                    PopperProps={{
                      placement: "top-end",
                    }}
                    renderInput={(props) => (
                      <DemoContainer {...props} sx={{ width: "47.5%" }} />
                    )}
                  />
                </LocalizationProvider>
              </Stack>

              <Stack direction="column">
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: colors.text,
                    fontFamily: "FiraSans",
                  }}
                >
                  End Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(props) => (
                      <DemoContainer {...props} sx={{ width: "47.5%" }} />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
          }}
        >
          <Button
            variant="contained"
            disableElevation
            sx={{
              height: "40px",
              border: "1px solid #c8c8c8",
              backgroundColor: "transparent",
              textTransform: "none",
              borderRadius: "8px",
              color: colors.text,
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "FiraSans",
              "&:hover": {
                backgroundColor: "#FAFAFA",
              },
              px: 2.5,
            }}
            autoFocus
            onClick={() => setapproveRequest(false)}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            disableElevation
            sx={{
              height: "40px",
              border: "1px solid #c8c8c8",
              backgroundColor: colors.green,
              textTransform: "none",
              borderRadius: "8px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "FiraSans",
              "&:hover": {
                backgroundColor: colors.green,
              },
              px: 2.5,
            }}
            autoFocus
            onClick={(e) => {
              ApproveRequest();
            }}
          >
            <Check sx={{ mr: 1 }} />
            Approve
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/* <ConfirmationModal
        open={approveRequest}
        onClose={() => setapproveRequest(false)}
        title="Confirmation"
        mainText="You have approved all the requests"
      /> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Requests" />

        {/* Data Grids */}
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              {/* <Box>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  px={2}
                  pt={2}
                >
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{
                        color: colors.text,
                        fontWeight: "500",
                        fontSize: 18,
                      }}
                    >
                      Requests
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{
                            "aria-label": "search",
                            color: colors.text,
                          }}
                        />
                      </Search>
                      <FormControl fullWidth>
                        <Select
                          multiple
                          displayEmpty
                          value={actionValue}
                          onChange={handleChange}
                          open={open}
                          onClose={() => setopen(false)}
                          onOpen={() => setopen(true)}
                          MenuProps={{
                            onClose: () => setopen(false),
                          }}
                          input={<OutlinedInput />}
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              fontFamily: "FiraSans",
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#000",
                            },
                            placeholder: { color: "red" },
                          }}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em
                                  style={{
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    color: "#000",
                                  }}
                                >
                                  Actions
                                </em>
                              );
                            }
                            return selected.join(", ");
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            backgroundColor: "#fff",
                            height: "40px",
                            borderRadius: "8px",
                            "& .MuiSelect-root": {
                              paddingLeft: "8px", 
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#CCCFD0",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#CCCFD0",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#CCCFD0",
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "black !important",
                            },
                          }}
                        >
                          {actionDropdown.map((option) => (
                            <MenuItem
                              style={{
                                textTransform: "capitalize",
                              }}
                              key={option.value}
                              value={option.value}
                              onClick={option.action}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Box> */}
              <TitleWithSearchBar
                title="Requests"
                noBtn
                search={search}
                setSearch={setSearch}
              />

              <StyledDataGrid
                rows={allRequests?.filter((val) =>
                  val?.site?.address
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )}
                columns={requestColumn}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5,10, { value: allRequests?.length, label: 'All' }]}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                rowHeight={75}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Requests;

import React, { useState } from "react";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateCustomInput,
  CreateDropDown,
  CreateHeader,
} from "../../../components/CreatePagesComponents";
import {
  Close,
  EmailOutlined,
  HelpOutlineOutlined,
  PersonOutline,
  PersonOutlineOutlined,
  PhoneOutlined,
  Place,
  PlaceOutlined,
  TodayOutlined,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { colors } from "../../../theme/theme";

const ChangeActivitySchedule = () => {
  const params = useParams();

  const [confirmation, setconfirmation] = useState(false);

  const [prevEmail, setprevEmail] = useState("");
  const [newEmail, setnewEmail] = useState("");

  const [patientGender, setpatientGender] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [activityType, setactivityType] = useState([]);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Patient has been created successfully!"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title={"Change Activity Schedule"} />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={"Change Activity Schedule"}
                  btnTitle={"Change"}
                  onClick={() => setconfirmation(true)}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={activityType}
                        setValue={setactivityType}
                        title="Choose Patient"
                        Icon={PersonOutlineOutlined}
                        data={[]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={activityType}
                        setValue={setactivityType}
                        title="Choose Question"
                        Icon={HelpOutlineOutlined}
                        data={[]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{
                            width: "100%",
                            borderRadius: "7px",
                            backgroundColor: "#FAFAFA",
                            fontFamily: "FiraSans",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: colors.text,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(241, 242, 244, 1)",
                            },
                            "& input::placeholder": {
                              color: colors.text,
                              opacity: 1,
                              fontFamily: "FiraSans",
                            },
                            "& .MuiSvgIcon-root": {
                              fill: "black !important",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChangeActivitySchedule;

import axios from "axios";

const production = process.env.REACT_APP_BASE_URL;
//const local = "https://192.168.18.14:3001/api";

//export const BASE_URL = "https://localhost:3001/api";
export const BASE_URL = "https://backend.axon.nucleussolution.com/api";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

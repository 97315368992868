import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { colors } from "../../../theme/theme";
import { useNavigate } from "react-router-dom";
import { EmailOutlined, PhoneIphoneOutlined } from "@mui/icons-material";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { useSelector } from "react-redux";
import moment from "moment";

const ReassignDr = () => {
  const navigate = useNavigate();
  const reassignColumns = [
    {
      field: "supervisor",
      headerName: "Supervisor",
      flex: 2,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params?.row?.supervisor?.avatar !== "" &&
            params?.row?.supervisor?.avatar !== null && (
              <img
                src={params.row?.supervisor?.avatar}
                alt=""
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
            )}
          <Box ml={1} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                colors: colors.text,
              }}
            >
              {params?.row?.supervisor?.name}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center", mr: 1.5 }}>
                <EmailOutlined
                  style={{ color: "#9EA5AD", height: "12px", width: "12px" }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#596066",
                    fontFamily: "FiraSans",
                    ml: 0.25,
                  }}
                >
                  {params.row?.supervisor?.email}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PhoneIphoneOutlined
                  style={{ color: "#9EA5AD", height: "12px", width: "12px" }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#596066",
                    fontFamily: "FiraSans",
                    ml: 0.25,
                  }}
                >
                  {params?.row?.supervisor?.mobile}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      field: "doctor",
      headerName: "Provider",
      flex: 1.5,
      renderCell: (params) => {
        const doc = params?.row?.doctor;
        const backupDoc = params?.row?.backupDoc;
        return (
          <Box>
            {doc === null ? (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#596066",
                  fontFamily: "FiraSans",
                }}
              >
                No Provider Assigned
              </Typography>
            ) : (
              <>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
                  {doc?.avatar !== "" && doc?.avatar !== null && (
                    <img
                      src={doc?.avatar}
                      alt=""
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <Box ml={1}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        colors: colors.text,
                        fontFamily: "FiraSans",
                      }}
                    >
                      {doc?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#596066",
                        fontFamily: "FiraSans",
                      }}
                    >
                      {doc?.doctorType}
                    </Typography>
                  </Box>
                </Box>
                {backupDoc && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {backupDoc?.avatar !== "" && backupDoc?.avatar !== null && (
                      <img
                        src={backupDoc?.avatar}
                        alt=""
                        style={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    <Box ml={1}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            colors: colors.text,
                            fontFamily: "FiraSans",
                          }}
                        >
                          {backupDoc?.name}
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: "#F1F2F4",
                            height: "16px",
                            padding: "5px 7px",
                            borderRadius: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            ml: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "9px",
                              fontWeight: "400",
                              color: "#453C52",
                              fontFamily: "FiraSans",
                            }}
                          >
                            Backup
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#596066",
                          fontFamily: "FiraSans",
                        }}
                      >
                        {backupDoc?.doctorType}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.75,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {moment(params?.value).format("MMM DD, YYYY, hh:mm A")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      align: "center",
      renderCell: (params) => (
        <>
          {/* <IconButton
            onClick={() =>
              navigate(
                "/supervisor/doctors/reassign-doctor-to-supervisor/form",
                {
                  state: {
                    supervisor: params?.row?.supervisor?.id,
                    doctor: params?.row?.doctor?.id,
                    backupDoc: params?.row?.backupDoc?.id,

                  }
                }
              )
            }
          >
            <EditOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton> */}
        </>
      ),
    },
  ];

  const [loading, setloading] = useState(false);
  const [supervisorWithDrList, setsupervisorWithDrList] = useState([]);
  const [search, setsearch] = useState("");

  const { userData } = useSelector((state) => state.user);

  const GetSupWithDr = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/doctor_supervisor/getAllSupervisorsWithDoctors?customerId=${userData?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        splitSupervisors(res.data?.allSupervisorsDoctors);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetSupWithDr();
  }, []);

  const splitSupervisors = (supervisorsArray) => {
    let supervisorWithAssignedDoc = [];

    supervisorsArray?.forEach((supervisor, index) => {
      const { assignedDoctors, ...supervisorWithoutDoctors } = supervisor;
      if (assignedDoctors?.length === 0) {
        const newEntry = {
          id: `${supervisor?.id}_0`,
          supervisor: supervisorWithoutDoctors,
          doctor: null,
          backupDoc: null,
        };
        supervisorWithAssignedDoc.push(newEntry);
      } else {
        assignedDoctors?.forEach((doctor, ind) => {
          const newEntry = {
            id: `${supervisor?.id}_${ind}`,
            supervisor: supervisorWithoutDoctors,
            doctor: doctor?.doctors,
            backupDoc: doctor?.backupDoctors,
          };

          supervisorWithAssignedDoc.push(newEntry);
        });
      }
    });
    setsupervisorWithDrList(supervisorWithAssignedDoc);
    return supervisorWithAssignedDoc;
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Reassign Provider to Supervisor" />

        {/* Data Grid */}
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Reassign Provider to Supervisor"
                search={search}
                setSearch={setsearch}
                onClick={() =>
                  navigate(
                    "/supervisor/doctors/reassign-doctor-to-supervisor/form"
                  )
                }
              />

              <StyledDataGrid
                rows={
                  supervisorWithDrList?.filter((val) =>
                    val?.supervisor?.name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                getRowId={(row) => row?.id}
                columns={reassignColumns}
                rowHeight={150}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                rowSelection={false}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
            </Box>
          </Grid>
        </Grid>

        {/* Menu */}
        {/* <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => {
              handleClose();
              navigate("/supervisor/doctors/update");
            }}
          >
            Edit Doctor
          </MenuItem>
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
          >
            Reassign Doctor to Supervisor
          </MenuItem>
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
          >
            Reassign Patient to Doctor
          </MenuItem>
          <MenuItem
            sx={{
              color: "#F34141",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => setdeleteModalState(true)}
          >
            Delete
          </MenuItem>
        </Menu> */}

        {/* Delete Modal */}
        {/* <RedModal
          open={deleteModalState}
          onClose={() => setdeleteModalState(false)}
          onClick={() => setdeleteModalState(false)}
          title="Confirmation"
          mainText="Are you sure want to delete this Doctor?"
          subText="Please ensure that once this Doctor is deleted, it cannot be recovered."
        /> */}
      </Box>
    </Box>
  );
};

export default ReassignDr;

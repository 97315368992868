import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CustomerProfile from "../Pages/Vendor/Profile/CustomerProfile";
import Customers from "../Pages/Vendor/Customers/Customers";
import CustomerSites from "../Pages/Vendor/CustomerSitesVendor/CustomerSites";
import Forms from "../Pages/Vendor/Forms/Forms";
import RolesPermissions from "../Pages/Vendor/Profile/RolesPermissions";
import CreateCustomer from "./../Pages/Vendor/Customers/CreateCustomer";
import CreateForm from "../Pages/Vendor/Forms/CreateForm";
import CreateSitesVendor from "../Pages/Vendor/CustomerSitesVendor/CreateSitesVendor";
import SingleForm from "../Pages/Vendor/Forms/SingleForm";
import SitesOfSingleCustomer from "../Pages/Vendor/Customers/SitesOfSingleCustomer";
import CustomerAdmins from "../Pages/Vendor/CustomerAdmin/CustomerAdmins";
import CreateAdmin from "../Pages/Vendor/CustomerAdmin/CreateAdmin";
import EditProfile from "../Pages/Auth/EditProfile";
import Request from "../Pages/Vendor/Request/Request";
import VendorDashboard from "../Pages/Vendor/Dashboard/VendorDashboard";
import Activities from "../Pages/Vendor/Activities/Activities";

export const VendorRoutes = () => {
  return (
    <React.Fragment>
      <Route exact path="/vendor/edit-profile" element={<EditProfile />} />

      <Route exact path="/vendor/dashboard" element={<VendorDashboard />} />

      <Route exact path="/vendor/request" element={<Request />} />

      {/* Customers */}
      <Route exact path="/vendor/customers" element={<Customers />} />
      <Route
        exact
        path="/vendor/customers/roles-permissions"
        element={<RolesPermissions />}
      />
      <Route
        exact
        path="/vendor/customers/:type"
        element={<CreateCustomer />}
      />
      <Route
        exact
        path="/vendor/customers/customer-site"
        element={<SitesOfSingleCustomer />}
      />
      <Route
        exact
        path="/vendor/customers/admins"
        element={<CustomerAdmins />}
      />
      <Route
        exact
        path="/vendor/customers/admins/:type"
        element={<CreateAdmin />}
      />

      {/* Customer Sites */}
      <Route exact path="/vendor/customer-sites" element={<CustomerSites />} />
      <Route
        exact
        path="/vendor/customer-sites/:type"
        element={<CreateSitesVendor />}
      />
      <Route
        exact
        path="/vendor/customer-sites/profile"
        element={<CustomerProfile />}
      />

      <Route exact path="/vendor/activity" element={<Activities />} />

      {/* Forms */}

      <Route exact path="/vendor/forms" element={<Forms />} />
      <Route exact path="/vendor/forms/form-details" element={<SingleForm />} />
      <Route exact path="/vendor/forms/:type" element={<CreateForm />} />
    </React.Fragment>
  );
};

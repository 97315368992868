import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import {
  EditOutlined,
  EmailOutlined,
  Feed,
  FeedOutlined,
  Help,
  HelpOutline,
  LocalHospitalOutlined,
  MoreVert,
  MoreVertOutlined,
  People,
  PeopleOutlined,
  PeopleOutlineOutlined,
  Person2Outlined,
  PhoneIphoneOutlined,
  Search,
  Splitscreen,
  SplitscreenOutlined,
} from "@mui/icons-material";
import { IOSSwitch } from "../../../components/CreatePagesComponents";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  customerData,
  dummyQuestionData,
} from "../../../assets/data/DummyData";
import { DocProfileComp } from "../../../components/DocProfileComp";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useSelector } from "react-redux";
import InactiveSwitch from "../../../components/InactiveSwitch";
import moment from "moment";

const DoctorProfile = () => {
  const [navigationId, setnavigationId] = useState(0);
  const subNav = [
    {
      id: 0,
      title: "Forms",
      icon: navigationId === 0 ? <Feed /> : <FeedOutlined />,
    },
    {
      id: 1,
      title: "Patients",
      icon: navigationId === 1 ? <People /> : <PeopleOutlined />,
    },
    {
      id: 2,
      title: "Questions",
      icon: navigationId === 2 ? <Help /> : <HelpOutline />,
    },
    {
      id: 3,
      title: "Assignment",
      icon: navigationId === 3 ? <Splitscreen /> : <SplitscreenOutlined />,
    },
  ];

  const assignmentColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2.5,
      renderCell: (params) => (
        <Box
          sx={{
            displag: "flex",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: colors.text,
              fontFamily: "FiraSans",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      flex: 1.25,
      renderCell: (params) => {
        const assignedTo = params?.row?.assignedTo;
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {assignedTo?.avatar !== "" && (
              <img
                src={assignedTo?.avatar}
                alt=""
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                textDecoration: "underline",
                ml: 1,
              }}
            >
              {assignedTo?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              textTransform: "capitalize",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },

    {
      field: "scheduled",
      headerName: "Scheduled At",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {moment(params?.value).format("DD-MM-YYYY")}
          </Typography>
        </Box>
      ),
    },
  ];

  const assignFormsColumn = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={require("../../../assets/icons/pdf.png")}
            alt=""
            style={{ height: 32, width: 32 }}
          />
          <div
            style={{ marginLeft: 10, fontWeight: "400", color: colors.text }}
          >
            {params?.row?.form?.form_name}
          </div>
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.75,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {moment(params?.row?.form?.updatedAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      ),
    },
  ];

  const patientColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => {
        const patient = params.row?.patients;
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {patient?.avatar !== "" && (
              <img
                src={patient?.avatar}
                alt=""
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                }}
              />
            )}
            <Box ml={1}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  colors: colors.text,
                  fontFamily: "FiraSans",
                }}
              >
                {patient?.name}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.25,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlined
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 0.5,
            }}
          >
            {params?.row?.patients?.email}
          </Typography>
        </Box>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PhoneIphoneOutlined
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.row?.patients?.mobile}
          </Typography>
        </Box>
      ),
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 0.5,
              textDecoration: "underline",
            }}
          >
            {params?.row?.patients?.gender}
          </Typography>
        </Box>
      ),
    },
  ];

  const questionsColumn = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={require("../../../assets/icons/question.png")}
            alt=""
            style={{ height: 32, width: 32 }}
          />
          <div
            style={{ marginLeft: 10, fontWeight: "400", color: colors.text }}
          >
            {params?.row?.question?.questionName}
          </div>
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.75,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {moment(params?.row?.question?.updatedAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      ),
    },
  ];

  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [doctorData, setdoctorData] = useState({});
  const [allPatients, setallPatients] = useState([]);
  const [allAssignments, setallAssignments] = useState([]);

  const [patientSearch, setpatientSearch] = useState("");
  const [questionSearch, setquestionSearch] = useState("");
  const [assignmentSearch, setassignmentSearch] = useState("");

  const { formsData, allQuestions } = useSelector((state) => state.supervisor);

  const [activeSwitch, setactiveSwitch] = useState(true);

  const handleactiveSwitch = () => {
    setactiveSwitch(!activeSwitch);
  };

  const activeOrInactiveForms = formsData?.filter((val) => {
    return val?.form?.status === (activeSwitch ? "active" : "inactive");
  });

  const activeOrInactiveQuestions = allQuestions?.filter((val) => {
    return val?.question?.status === (activeSwitch ? "active" : "inactive");
  });

  const filteredForms = activeOrInactiveForms?.filter((val) =>
    val?.form?.form_name
      ?.toLocaleLowerCase()
      ?.includes(value?.toLocaleLowerCase())
  );

  const filteredQuestions = activeOrInactiveQuestions?.filter((val) =>
    val?.question?.questionName
      ?.toLocaleLowerCase()
      ?.includes(questionSearch?.toLocaleLowerCase())
  );

  const filteredAssignments = allAssignments?.filter((val) =>
    val?.name
      ?.toLocaleLowerCase()
      ?.includes(assignmentSearch?.toLocaleLowerCase())
  );

  const filteredPatients = allPatients?.filter((val) =>
    val?.patients?.name
      ?.toLocaleLowerCase()
      ?.includes(patientSearch?.toLocaleLowerCase())
  );

  const [loading, setloading] = useState(false);

  const GetDoctorById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getSpecificUser?userId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleDoctor = res.data?.specificUser;
        setdoctorData((prevData) => ({
          ...prevData,
          fullName: singleDoctor?.name,
          email: singleDoctor?.email,
          mobile: singleDoctor?.mobile,
          gender: singleDoctor?.gender,
          avatar: singleDoctor?.avatar,
          id: singleDoctor?.id,
          doctorType: singleDoctor?.doctorType,
        }));
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetPatientsByDoc = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/doctor_patient/getAllPatientsWithDoctorsByDrId?doctorId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallPatients(res.data?.allPatientsDoctors);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetAssignmentsById = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/user/getAllAssignmentsByAssignedBy?assignedBy=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        const { assignedActivities, assignedForms, assignedQuestions } =
          res.data;

        mergeAssignedArrays(
          assignedActivities,
          assignedForms,
          assignedQuestions
        );
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetDoctorById();
    GetPatientsByDoc();
    GetAssignmentsById();
  }, []);

  function mergeAssignedArrays(activities, forms, questions) {
    const transformedActivities = activities.map((act) => ({
      id: act?.activity?.id,
      name: act?.activity?.activityName,
      assignedTo: act?.user,
      createdAt: act?.createdAt,
      scheduled: act?.date,
      type: "activity",
    }));

    const transformedForms = forms.map((f) => ({
      id: f?.form?.id,
      name: f?.form?.form_name,
      assignedTo: f?.user,
      createdAt: f?.createdAt,
      scheduled: f?.date,
      type: "form",
    }));

    const transformedQuestions = questions.map((q) => ({
      id: q?.question?.id,
      name: q?.question?.questionName,
      assignedTo: q?.user,
      createdAt: q?.createdAt,
      scheduled: q?.date,
      type: "question",
    }));

    const all = [
      ...transformedActivities,
      ...transformedForms,
      ...transformedQuestions,
    ];
    setallAssignments(all);
  }

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Provider Profile" />

        {!loading && (
          <DocProfileComp
            data={doctorData}
            patientCount={allPatients?.length}
          />
        )}
        <Stack direction="row" alignItems="center">
          <Grid container spacing={2} p={2}>
            <Grid item xs={3}>
              <Box
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  //   alignItems: "center",
                  justifyContent: "center",
                  py: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#9EA5AD",
                    fontFamily: "FiraSans",
                    px: 2,
                    textTransform: "uppercase",
                    mb: 1,
                  }}
                >
                  Quick Navigation
                </Typography>
                {subNav.map((item, index) => (
                  <ListItem
                    key={item.title}
                    disablePadding
                    sx={{
                      backgroundColor:
                        item.id === navigationId ? "#f7f8fa" : "transparent",
                    }}
                  >
                    <ListItemButton onClick={() => setnavigationId(item.id)}>
                      <ListItemIcon
                        sx={{
                          color:
                            item.id === navigationId
                              ? colors.text
                              : colors.lightText,
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color:
                            item.id === navigationId
                              ? colors.text
                              : colors.lightText,
                        }}
                        primary={item.title}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </Grid>
            {navigationId === 0 && (
              <Fade in={navigationId === 0}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <InactiveSwitch
                        activeSwitch={activeSwitch}
                        handleactiveSwitch={handleactiveSwitch}
                        title={"Forms"}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        mb={2}
                        sx={{
                          backgroundColor: "#FAFAFA",
                          borderRadius: "12px",
                          p: 2,
                        }}
                      >
                        <FormControl fullWidth>
                          <OutlinedInput
                            sx={{
                              height: "40px",
                              borderRadius: "9px",
                              backgroundColor: "#fff",
                              elevation: 0,
                              fontFamily: "FiraSans",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: colors.text,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(241, 242, 244, 1)",
                              },
                              "& input::placeholder": {
                                color: colors.text,
                                opacity: 1,
                                fontFamily: "FiraSans",
                              },
                            }}
                            placeholder={"Search"}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            startAdornment={
                              <InputAdornment position="start">
                                <Search sx={{ color: colors.text }} />
                              </InputAdornment>
                            }
                          />
                        </FormControl>

                        <StyledDataGrid
                          rows={filteredForms || []}
                          columns={assignFormsColumn}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowClassName={(params) =>
                            params.row.id % 2 === 0 ? "even" : "odd"
                          }
                          getRowId={(row) => row?.form?.id}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
            {navigationId === 1 && (
              <Fade in={navigationId === 1}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                        }}
                      >
                        <TitleWithSearchBar
                          title="Patients"
                          onClick={() => navigate("/supervisor/users/create")}
                          search={patientSearch}
                          setSearch={setpatientSearch}
                        />

                        <StyledDataGrid
                          rows={filteredPatients || []}
                          columns={patientColumns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowClassName={(params) =>
                            params.row.id % 2 === 0 ? "even" : "odd"
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
            {navigationId === 2 && (
              <Fade in={navigationId === 2}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <InactiveSwitch
                        activeSwitch={activeSwitch}
                        handleactiveSwitch={handleactiveSwitch}
                        title={"Questions"}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                        }}
                      >
                        <TitleWithSearchBar
                          title="Questions"
                          onClick={() =>
                            navigate("/supervisor/questions/create")
                          }
                          search={questionSearch}
                          setSearch={setquestionSearch}
                        />

                        <StyledDataGrid
                          rows={filteredQuestions || []}
                          columns={questionsColumn}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowClassName={(params) =>
                            params.row.id % 2 === 0 ? "even" : "odd"
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
            {navigationId === 3 && (
              <Fade in={navigationId === 3}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "12px",
                        }}
                      >
                        <TitleWithSearchBar
                          title="Assignments"
                          noBtn
                          search={assignmentSearch}
                          setSearch={setassignmentSearch}
                        />

                        <StyledDataGrid
                          rows={filteredAssignments || []}
                          columns={assignmentColumns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 5 },
                            },
                          }}
                          sx={{
                            borderRadius: "12px",
                            border: "none",
                            "& .MuiDataGrid-virtualScroller": {
                              minHeight: "50vh",
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowClassName={(params) =>
                            params.row.id % 2 === 0 ? "even" : "odd"
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

export default DoctorProfile;

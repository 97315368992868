import React from "react";
import { Box } from "@mui/system";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";

const RolesPermissions = () => {
  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Customer Profile" />
      </Box>
    </Box>
  );
};

export default RolesPermissions;

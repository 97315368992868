import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { colors } from "../theme/theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation, Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useUser } from "../constants/context";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearVendor } from "../redux/dispatchers/vendor.dispatcher";
import { clearAdmin } from "../redux/dispatchers/admin.dispatcher";
import {
  clearUserData,
  getUserById,
  logoutUser,
  setUserRole,
} from "../redux/dispatchers/login";
import { clearSupervisor } from "../redux/dispatchers/supervisor.dispatcher";
import { clearProvider } from "../redux/dispatchers/provider.dispatcher";
import axios from "axios";
import { BASE_URL } from "../constants/config";

const AppBar = ({ title, breadcrumbs }) => {
  const { setnavigationId } = useUser();
  const { userData, userRole } = useSelector((state) => state.user);

  const { setuserRoleLoading } = useUser();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  let currentLink = location.pathname.split("/").pop();

  const wordsArray = currentLink.split("-");

  const capitalizedWords = wordsArray.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  const capitalizedHeading = capitalizedWords.join(" ");

  const [loading, setloading] = useState(false);
  const ChangeUserRole = (role) => {
    setloading(true);
    setuserRoleLoading(true);
    axios
      .get(
        `${BASE_URL}/user/switchLoggedInUser?userId=${userData?.id}&role=${role}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setuserRoleLoading(false);
        if (role === "admin") {
          navigate("/admin/dashboard");
        } else if (role === "supervisor") {
          // dispatch(getUserById(userData?.id))
          navigate("/supervisor/dashboard");
        } else if (role === "doctor") {
          navigate("/provider/dashboard");
        }
      })
      .catch((err) => {
        setloading(false);
        setuserRoleLoading(false);
      });
  };

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index, array) => ({
      path: `/${array.slice(0, index + 1).join("/")}`,
      name: crumb.charAt(0).toUpperCase() + crumb.slice(1),
    }));

  if (crumbs?.length > 0 && crumbs[0]?.name === "Vendor") {
    crumbs[0].name = "Home";
    crumbs[0].path = "/vendor/dashboard";
  }

  if (crumbs?.length > 0 && crumbs[0]?.name === "Admin") {
    crumbs[0].name = "Home";
    crumbs[0].path = "/admin/dashboard";
  }

  if (crumbs?.length > 0 && crumbs[0]?.name === "Supervisor" ) {
    crumbs[0].name = "Home";
    crumbs[0].path = "/supervisor/dashboard";

  }

  if (crumbs?.length > 0 && crumbs[0]?.name === "Provider") {
    crumbs[0].name = "Home";
    crumbs[0].path = "/provider/dashboard";

  }

  const smallScreen = useMediaQuery("(max-width:650px)");
  const { toggleDrawer } = useUser();

  const dispatch = useDispatch();

  const handleLogout = () => {
    handleClose();
    dispatch(logoutUser());
    navigate("/login");
    setnavigationId(0);
    dispatch(clearUserData());
    dispatch(setUserRole(""));

    if (userRole === "vendor") {
      dispatch(clearVendor());
    } else if (userRole === "admin") {
      dispatch(clearAdmin());
    } else if (userRole === "supervisor") {
      dispatch(clearSupervisor());
    } else if (userRole === "doctor") {
      dispatch(clearProvider());
      dispatch(clearSupervisor());
    }
  };

  const handleSwitchUserRole = (role) => {
    dispatch(setUserRole(role));
    dispatch(clearVendor());
    dispatch(clearAdmin());
    dispatch(clearSupervisor());
    dispatch(clearProvider());
    dispatch(clearSupervisor());

    handleClose();
    if (role === "admin") {
      ChangeUserRole("admin");
    } else if (role === "supervisor") {
      ChangeUserRole("supervisor");
    } else if (role === "doctor") {
      ChangeUserRole("doctor");
    }
  };

  return (
    <Box
      sx={{
        height: "75px",
        p: 2,
        borderBottom: "1px solid #EBEBEB",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {smallScreen && (
          <IconButton onClick={toggleDrawer("left", true)} sx={{ mr: 2 }}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              color: colors.text,
              fontWeight: "500",
              fontSize: smallScreen ? "14px" : 20,
              fontFamily: "FiraSans-bold",
            }}
          >
            {title}
          </Typography>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {crumbs?.map((crumb, index) => {
              const crumbName = crumb?.name
                .replace(/-/g, " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());

                
              return (
                <Typography
                  onClick={() => navigate(crumb.path)}
                  key={index}
                  color="inherit"
                  underline="hover"
                  sx={{
                    cursor: "pointer",
                    fontSize: smallScreen ? "10px" : "14px",
                    fontWeight: "500",
                    fontFamily: "FiraSans",
                    ":hover": {
                      textDecoration: "underline",
                    },
                    color:
                      currentLink === crumb?.name?.toLocaleLowerCase()
                        ? colors.text
                        : "#676E76",
                  }}
                >
                  {crumbName === "Customer Sites" ? "Sites" : crumbName}
                </Typography> 
              );
            })}
          </Breadcrumbs>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderLeft: "1px solid #E6E6E6",
          paddingLeft: "20px",
        }}
      >
        <Typography
          sx={{
            color: "#080D18",
            fontWeight: "500",
            fontSize: smallScreen ? "14px" : 16,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {userData?.name}
          <Typography
            sx={{
              color: "#777981",
              fontWeight: "500",
              fontSize: smallScreen ? "12px" : 12,
              textTransform: "capitalize",
            }}
          >
            {userRole==='doctor'?'Provider':userRole}
          </Typography>
        </Typography>
        {userData?.avatar !== "" && (
          <img
            src={userData?.avatar}
            alt="Logo"
            style={{
              marginLeft: "20px",
              borderRadius: "360px",
              height: 40,
              width: 40,
              objectFit: "contain",
            }}
          />
        )}

        <ExpandMoreIcon
          style={{ color: colors.text, cursor: "pointer" }}
          onClick={handleClick}
        />
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          sx={{ borderBottom: "1px solid #EBEFF5" }}
          onClick={(e) => {
            handleClose(e);
            if (userRole === "doctor") {
              navigate(`/provider/edit-profile`);
            } else {
              navigate(`/${userRole}/edit-profile`);
            }
          }}
        >
          Profile
        </MenuItem>
        {userData?.isAdmin && userRole !== "admin" && (
          <MenuItem
            onClick={() => handleSwitchUserRole("admin")}
            sx={{ borderBottom: "1px solid #EBEFF5" }}
          >
            Login as Admin
          </MenuItem>
        )}
        {userData?.isSupervisor && userRole !== "supervisor" && (
          <MenuItem
            onClick={() => handleSwitchUserRole("supervisor")}
            sx={{ borderBottom: "1px solid #EBEFF5" }}
          >
            Login as Supervisor
          </MenuItem>
        )}
        {userData?.isDoctor && userRole !== "doctor" && (
          <MenuItem
            onClick={() => handleSwitchUserRole("doctor")}
            sx={{ borderBottom: "1px solid #EBEFF5" }}
          >
            Login as Provider
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default AppBar;

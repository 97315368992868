import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  Stack,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import moment from "moment";
import { RedModal } from "../../../components/ConfirmationModal";
import { Close, MoreVert, VisibilityOutlined } from "@mui/icons-material";
import InactiveSwitch from "../../../components/InactiveSwitch";

const Forms = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formColumns = [
    {
      field: "form_name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <img
            src={require("../../../assets/icons/pdf.png")}
            alt=""
            style={{ height: 32, width: 32 }}
          />
          <Typography
            style={{
              marginLeft: 10,
              fontWeight: "400",
              color: colors.text,
              cursor: "pointer",
              whiteSpace: "pre-line",
            }}
            onClick={() =>
              navigate(`/vendor/forms/form-details`, {
                state: { id: params?.row?.id },
              })
            }
          >
            {params?.value}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "formType",
      headerName: "Type",
      flex: 1.25,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.textColor,
              fontFamily: "FiraSans-bold",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "questions",
      headerName: "Questions",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.textColor,
              fontFamily: "FiraSans-bold",
            }}
          >
            {params?.value?.length}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 0.5,
      renderCell: (params) => (
        <div
          style={{
            marginLeft: 10,
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {moment(params.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() =>
              navigate(`/vendor/forms/form-details`, {
                state: { id: params?.row?.id,type:'Form' },
              })
            }
          >
            <VisibilityOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate("/vendor/forms/update", {
                state: { id: params?.row?.id },
              })
            }
          >
            <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setformId(params?.row?.id);
              setstatus(params?.row?.status);
            }}
          >
            <MoreVert sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const [loading, setloading] = useState(false);
  const [allForms, setallForms] = useState([]);

  const GetAllForms = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/form/getAllForms`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        setallForms(res.data?.allForm);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetAllForms();
  }, []);

  const [search, setsearch] = useState("");
  const [deleteModalState, setdeleteModalState] = useState(false);
  const [status, setstatus] = useState("");
  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setdeleteConfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [formId, setformId] = useState("");

  const DeleteForms = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/form/updateFormStatus`,
        {
          formId: formId,
          status: status,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdeleteModalState(false);
        GetAllForms();
        setdeleteConfirmation(true);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const [activeSwitch, setactiveSwitch] = useState(true);

  const handleactiveSwitch = () => {
    setactiveSwitch(!activeSwitch);
  };

  const filteredForms = allForms?.filter((val) => {
    return val?.status === (activeSwitch ? "active" : "inactive");
  });

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={deleteConfirmation}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={`Form ${
          status === "deleted" ? "delete" : "marked as " + status
        } successfully!`}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={DeleteForms}
        title="Delete Form"
        mainText={`Are you sure you want to ${
          status === "deleted" ? status : "mark this form as " + status
        }`}
        subText={status === "deleted" ? "This action cannot be undone." : ""}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Forms" />
        <Grid container p={2}>
          <Grid item xs={12} mb={2}>
            <InactiveSwitch
              handleactiveSwitch={handleactiveSwitch}
              activeSwitch={activeSwitch}
              title="Forms"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              {/* Title */}
              <TitleWithSearchBar
                title="Forms"
                onClick={() => {
                  navigate("/vendor/forms/create");
                }}
                search={search}
                setSearch={setsearch}
              />

              {/* Data Grid */}
              <StyledDataGrid
                rows={
                  filteredForms?.filter((val) =>
                    val?.form_name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={formColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10, { value: filteredForms?.length, label: 'All' }]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
                rowSelectionModel={[]}
              />

              {/* Menu */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    borderBottom: "1px solid #EBEFF5",
                    color: "#24292E",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();
                    setdeleteModalState(true);
                    if (status === "active") {
                      setstatus("inactive");
                    } else {
                      setstatus("active");
                    }
                  }}
                >
                  Mark as {status === "active" ? "Inactive" : "Active"}
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                    borderBottom: "1px solid #EBEFF5",
                  }}
                  onClick={() => {
                    navigate("/vendor/forms/update", {
                      state: { id: formId, assign: true },
                    });
                  }}
                >
                  Assign to customer site
                </MenuItem>
                <MenuItem
                  sx={{
                    color: "#F34141",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();
                    setdeleteModalState(true);
                    setstatus("deleted");
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Forms;

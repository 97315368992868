import React from "react";
import { Route } from "react-router-dom";
import AdminDashboard from "../Pages/Admin/Dashboard/AdminDashboard";
import Requests from "../Pages/Admin/Requests/Requests";
import CustomerSitesAdmin from "../Pages/Admin/CustomerSitesAdmin/CustomerSitesAdmin";
import Activities from "../Pages/Admin/Activities/Activities";
import Doctors from "../Pages/Admin/Doctors/Doctors";
import FormsAdmin from "../Pages/Admin/Forms/FormsAdmin";
import CreateCustomerSites from "../Pages/Admin/CustomerSitesAdmin/CreateCustomerSites";
import CreateActivity from "../Pages/Admin/Activities/CreateActivity";
import SupervisorsAdmin from "../Pages/Admin/Supervisor/SupervisorsAdmin";
import Questions from "./../Pages/Admin/Questions/Questions";
import CreateQuestions from "./../Pages/Admin/Questions/CreateQuestions";
import AssignSupervisor from "../Pages/Admin/Supervisor/AssignSupervisor";
import CreateSupervisor from "../Pages/Admin/Supervisor/CreateSupervisor";
import CreateFormsInAdmin from "../Pages/Admin/Forms/CreateFormsInAdmin";
import CreateDoctor from "../Pages/Admin/Doctors/CreateDoctor";
import EditProfile from "../Pages/Auth/EditProfile";
import Users from "../Pages/Admin/Users/Users";
import CreateUsers from "../Pages/Admin/Users/CreateUser";
import SingleForm from "../Pages/Vendor/Forms/SingleForm";
import CreateActivitySupervisor from "../Pages/Supervisor/Activities/CreateActivitySupervisor";

export const AdminRoutes = () => {
  return (
    <React.Fragment>
      <Route exact path="/admin/edit-profile" element={<EditProfile />} />
      <Route exact path="/admin/dashboard" element={<AdminDashboard />} />

      {/* Customer Sites */}
      <Route
        exact
        path="/admin/customer-sites"
        element={<CustomerSitesAdmin />}
      />
      <Route
        exact
        path="/admin/customer-sites/:type"
        element={<CreateCustomerSites />}
      />

      {/* Users */}
      <Route exact path="/admin/users" element={<Users />} />
      <Route exact path="/admin/users/:type" element={<CreateUsers />} />

      {/* Supervisor */}
      <Route exact path="/admin/supervisors" element={<SupervisorsAdmin />} />
      <Route
        exact
        path="/admin/supervisors/:type"
        element={<CreateSupervisor />}
      />
      <Route
        exact
        path="/admin/supervisor/assign"
        element={<AssignSupervisor />}
      />

      {/* Forms */}
      <Route exact path="/admin/forms" element={<FormsAdmin />} />
      <Route exact path="/admin/forms/:type" element={<CreateFormsInAdmin />} />
      <Route exact path="/admin/forms/form-details" element={<SingleForm />} />

      {/* Questions */}
      <Route exact path="/admin/questions" element={<Questions />} />
      <Route
        exact
        path="/admin/questions/:type"
        element={<CreateQuestions />}
      />

      {/* Activities */}
      <Route exact path="/admin/activities" element={<Activities />} />
      <Route
        exact
        path="/admin/activities/:type"
        element={<CreateActivitySupervisor />}
      />

      {/* Doctors */}
      <Route exact path="/admin/doctors" element={<Doctors />} />
      <Route exact path="/admin/doctor/:type" element={<CreateDoctor />} />

      <Route exact path="/admin/requests" element={<Requests />} />
    </React.Fragment>
  );
};

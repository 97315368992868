import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Close, PeopleAltOutlined } from "@mui/icons-material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateDropDown,
  CreateHeader,
} from "../../../components/CreatePagesComponents";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { useNavigate } from "react-router-dom";
import { ErrorSnackbar } from "../../../components/CustomSnackbar";
import { getAllDoctorsForSupervisor } from "../../../redux/dispatchers/supervisor.dispatcher";

const ReassignPatientForm = () => {
  const [confirmation, setconfirmation] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const { userData } = useSelector((state) => state.user);

  const [unassignedPatients, setunassignedPatients] = useState([]);

  const GetUnassignedPatients = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/doctor_patient/getAllUnAssignedPatients?siteId=${userData?.siteId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setunassignedPatients(res.data?.allNotAssignedPatients);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [chooseDr, setchooseDr] = useState("");
  const [choosePatient, setchoosePatient] = useState("");

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const [drId, setdrId] = useState("");
  const [patientId, setpatientId] = useState("");

  useEffect(() => {
    dispatch(getAllDoctorsForSupervisor(userData?.siteId));
  }, []);

  const { allDoctors } = useSelector((state) => state.supervisor);

  const doctorData = allDoctors?.map((doctor) => ({
    value: doctor?.name,
    label: doctor?.name,
    doctorId: doctor?.id,
  }));

  const patients = unassignedPatients?.map((patient) => ({
    value: patient?.name,
    label: patient?.name,
    patId: patient?.id,
  }));

  const AssignPatient = async () => {
    if (drId === "" || patientId === "") {
      seterror(true);
      seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axiosInstance
        .post(
          `${BASE_URL}/doctor_patient/assignDoctorToPatient`,
          {
            doctorId: drId,
            siteId: userData?.siteId,
            patientId: patientId,
            customerId: userData?.customerId,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setTimeout(() => {
            navigate("/supervisor/doctors/reassign-patient-to-doctor");
          }, 2000);
          setdrId("");
          setchooseDr("");
          setchoosePatient("");
          setpatientId("");
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  useEffect(() => {
    GetUnassignedPatients();
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ErrorSnackbar
        error={error}
        handleCloseError={seterror}
        errorMsg={errorMsg}
      />
      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message="New Patient has been reassigned successfully!"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title={"Reassign Patient to Doctor"} />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={"Reassign Patient to Doctor"}
                  btnTitle={"Reassign"}
                  onClick={AssignPatient}
                  cancelFunc={() =>
                    navigate("/supervisor/doctors/reassign-patient-to-doctor")
                  }
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={choosePatient}
                        setValue={(e) => {
                          setchoosePatient(e);
                          setpatientId(
                            patients?.find((pat) => pat?.value === e)?.patId
                          );
                        }}
                        title="Choose Patient"
                        Icon={PeopleAltOutlined}
                        data={patients || []}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={chooseDr}
                        setValue={(value) => {
                          setchooseDr(value);
                          setdrId(
                            doctorData?.find((doc) => doc?.value === value)
                              ?.doctorId
                          );
                        }}
                        title="Choose Provider"
                        Icon={PeopleAltOutlined}
                        data={doctorData || []}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReassignPatientForm;

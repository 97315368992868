import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../theme/theme";
import {
  EditOutlined,
  EmailOutlined,
  MoreVert,
  PhoneIphoneOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import moment from "moment";

const ReassignPatient = () => {
  const navigate = useNavigate();

  const reassignColumns = [
    {
      field: "doctor",
      headerName: "Provider",
      flex: 1.5,
      renderCell: (params) => {
        const doctor = params?.row?.doctor;
        return (
          <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
            {doctor?.avatar !== "" && (
              <img
                src={doctor?.avatar}
                alt=""
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
            )}
            <Box ml={1}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  colors: colors.text,
                  fontFamily: "FiraSans",
                }}
              >
                {doctor?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#596066",
                  fontFamily: "FiraSans",
                }}
              >
                Primary Care Physician
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "patient",
      headerName: "Patient",
      flex: 2,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => {
        const patient = params?.row?.patients;
        return (
          <Box>
            {patient === null ? (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#596066",
                  fontFamily: "FiraSans",
                }}
              >
                No Patient Assigned
              </Typography>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {patient?.avatar !== "" && (
                  <img
                    src={patient?.avatar}
                    alt=""
                    style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                )}
                <Box ml={1} sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      colors: colors.text,
                    }}
                  >
                    {patient?.name}
                  </Typography>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mr: 1.5 }}
                    >
                      <EmailOutlined
                        style={{
                          color: "#9EA5AD",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#596066",
                          fontFamily: "FiraSans",
                          ml: 0.25,
                        }}
                      >
                        {patient?.email}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PhoneIphoneOutlined
                        style={{
                          color: "#9EA5AD",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#596066",
                          fontFamily: "FiraSans",
                          ml: 0.25,
                        }}
                      >
                        {patient?.mobile}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.75,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {moment(params?.value).format("MMM DD, YYYY")}
          </Typography>
        </Box>
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "",
    //   flex: 0.5,
    //   align: "center",
    //   renderCell: (params) => (
    //     <IconButton
    //       onClick={() =>
    //         navigate("/supervisor/doctors/reassign-patient-to-doctor/form")
    //       }
    //     >
    //       <EditOutlined sx={{ height: "20px", width: "20px" }} />
    //     </IconButton>
    //   ),
    // },
  ];

  const [loading, setloading] = useState(false);
  const [doctorsWithPatientList, setdoctorsWithPatientList] = useState([]);
  const [search, setsearch] = useState("");

  const { userData } = useSelector((state) => state.user);

  const GetDrWithPatients = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/doctor_patient/getAllPatientsWithDoctors?siteId=${userData?.siteId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        splitDoctor(res.data?.allPatientsDoctors);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetDrWithPatients();
  }, []);

  const splitDoctor = (doctorsArray) => {
    let doctorsWithAssignedPatients = [];

    doctorsArray?.forEach((doctor, index) => {
      const { assignedDoctor, ...doctorWithoutPatients } = doctor;
      if (assignedDoctor?.length === 0) {
        const newEntry = {
          id: `${doctor?.id}_0`,
          doctor: doctorWithoutPatients,
          patients: null,
        };
        doctorsWithAssignedPatients.push(newEntry);
      } else {
        assignedDoctor?.forEach((assignedPat, ind) => {
          const newEntry = {
            id: assignedPat?.id,
            doctor: doctorWithoutPatients,
            patients: assignedPat?.patients,
          };

          doctorsWithAssignedPatients.push(newEntry);
        });
      }
    });
    setdoctorsWithPatientList(doctorsWithAssignedPatients);
    return doctorsWithAssignedPatients;
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Reassign Patient to Provider" />

        {/* Data Grid */}
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Reassign Patient to Provider"
                onClick={() =>
                  navigate(
                    "/supervisor/doctors/reassign-patient-to-doctor/form"
                  )
                }
              />
              <StyledDataGrid
                rows={
                  doctorsWithPatientList?.filter((val) =>
                    val?.doctor?.name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={reassignColumns}
                rowHeight={75}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                rowSelection={false}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
            </Box>
          </Grid>
        </Grid>

        {/* Menu */}
        {/* <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => {
              handleClose();
              navigate("/supervisor/doctors/update");
            }}
          >
            Edit Doctor
          </MenuItem>
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
          >
            Reassign Doctor to Supervisor
          </MenuItem>
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
          >
            Reassign Patient to Doctor
          </MenuItem>
          <MenuItem
            sx={{
              color: "#F34141",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => setdeleteModalState(true)}
          >
            Delete
          </MenuItem>
        </Menu> */}

        {/* Delete Modal */}
        {/* <RedModal
          open={deleteModalState}
          onClose={() => setdeleteModalState(false)}
          onClick={() => setdeleteModalState(false)}
          title="Confirmation"
          mainText="Are you sure want to delete this Doctor?"
          subText="Please ensure that once this Doctor is deleted, it cannot be recovered."
        /> */}
      </Box>
    </Box>
  );
};

export default ReassignPatient;

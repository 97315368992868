import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import { colors } from "../../theme/theme";
import { useNavigate } from "react-router-dom";

const Splash = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh", // Set the height to fill the screen
        overflow: "hidden", // Hide any overflow content
      }}
    >
      {/* Background Image */}
      <img
        src={require("../../assets/images/login/splash.png")}
        alt="Background"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "100%",
          minHeight: "100%",
          width: "auto",
          height: "auto",
          objectFit: "contain",
        }}
      />

      {/* Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "40%",
          backgroundImage:
            "linear-gradient(180deg, rgba(8, 13, 27, 0) 0%, rgba(8, 13, 27, 0.9) 100%)", // Adjusted alpha value to make it darker
        }}
      />

      <img
        src={require("../../assets/images/logo_large.png")}
        alt="Logo"
        style={{
          position: "absolute",
          bottom: "17.5%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Center the logo horizontally and vertically
          width: "400px", // Adjust the width of the logo as needed
          height: "auto",
        }}
      />

      {/* Sign In Button */}
      <Box
        sx={{
          position: "absolute",
          bottom: "40px", // Adjust the distance from the bottom as needed
          left: "50%",

          transform: "translateX(-20%)", // Center the button horizontally
          width: "30%",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          sx={{
            mb: 2,
            width: "45%",
            height: "50px",
            backgroundColor: colors.primaryBtn,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#1aaa1a",
            },
          }}
          onClick={() => navigate("/login")}
        >
          Sign In
          <EastIcon sx={{ ml: 1 }} />
        </Button>
      </Box>
    </Box>
  );
};

export default Splash;

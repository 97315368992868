import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { Box, Grid } from "@mui/material";
import DataGridTable from "../../../components/DataGridTable";
import {
  customerData,
  customerSitesData,
  dummyActivitiesData,
  dummyQuestionData,
  formsData,
  supervisorRows,
} from "../../../assets/data/DummyData";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { getAllDoctorsForSupervisor, getAllFormsForSupervisor } from "../../../redux/dispatchers/supervisor.dispatcher";

const ProviderDashboard = () => {
  const { userData } = useSelector((state) => state.user);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [patientSearch, setpatientSearch] = useState("");

  const questionsRows = [
    {
      id: 1,
      names: "What symptoms are you experiencing?",
      scheduled: "2021-09-10",
    },
    {
      id: 2,
      names: "When did your symptoms first begin?",
      scheduled: "2021-09-10",
    },
    {
      id: 3,
      names: "Have you experienced any recent injuries or trauma?",
      scheduled: "2021-09-10",
    },
    {
      id: 4,
      names: "When did your symptoms first begin?",
      scheduled: "2021-09-10",
    },
    {
      id: 5,
      names: "What symptoms are you experiencing?",
      scheduled: "2021-09-10",
    },
  ];
  const [allPatients, setallPatients] = useState([]);
  const [allActivities, setallActivities] = useState([]);

  const GetAllActivities = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/activity/getAllActivityByCustomerId?customerId=${userData?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallActivities(res.data?.allFormsAndActivities);
      })
      .catch((e) => {
        setloading(false);
      });
  };


  const GetPatients = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/doctor_patient/getAllPatientsWithDoctorsByDrId?doctorId=${userData?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallPatients(res.data?.allPatientsDoctors);
      })
      .catch((e) => {
        setloading(false);
      });
  };
  const [activeSwitch, setactiveSwitch] = useState(true);

  const handleactiveSwitch = () => {
    setactiveSwitch(!activeSwitch);
  };
  // const { formsData } = useSelector((state) => state.supervisor);

  // const filteredForms = formsData?.filter((val) => {
  //   return val?.form?.status === (activeSwitch ? "active" : "inactive");
  // });
  // const { userData, userRole } = useSelector((state) => state.user);
  //const { allDoctors } = useSelector((state) => state.provider);


  useEffect(() => {
    GetPatients();
    // dispatch(getAllFormsForSupervisor(userData?.siteId));
    GetAllActivities();
    // dispatch(getAllSupervisorForAdmin(userData?.customerId));
//    dispatch(getAllDoctorsForSupervisor(userData?.siteId));

   // GetAllSiteForms();
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer provider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Dashboard" />
        {/* Data Grids */}
        <Grid container spacing={2} p={2}>
          {/* Patients Box */}
          <Grid item xs={12} md={6}>
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <DataGridTable
                title="Patients"
                type="providerpatients"
                rowData={                  allPatients?.filter((val) =>
                    val?.patients?.name
                      ?.toLocaleLowerCase()
                      ?.includes(patientSearch?.toLocaleLowerCase())
                  ) || []
}
                pageSize={5}
                access={true}
              />
            </Box>
          </Grid>

          {/* Forms Box */}
          <Grid item xs={12} md={6}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Activities"
                type="forms"
                rowData={allActivities || []}
                pageSize={5}
                access={true}
              />
            </Box>
          </Grid>

          {/* Doctors Box */}
          {/* <Grid item xs={12} md={6}>
            <Box
              bgcolor="#fff"
              sx={{ borderRadius: "20px" }}
              width="100%"
              mr={2}
            >
              <DataGridTable
                title="Doctors"
                type="doctors"
                rowData={allDoctors || []}
                pageSize={5}
                access={true}
              />
            </Box>
          </Grid> */}

          {/* Fourth Box */}
          {/* <Grid item xs={12} md={6}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Activity"
                type="activity"
                rowData={dummyActivitiesData}
                pageSize={5}
                access={true}
              />
            </Box>
          </Grid> */}

          {/* Fifth Box */}
          {/* <Grid item xs={12} md={6}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Questions"
                type="activity"
                rowData={questionsRows}
                pageSize={5}
                access={true}
              />
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProviderDashboard;

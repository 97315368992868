import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  specificCustomer: null,
};

const clearVendor = createAction("clearVendor");

const request = createAction("customerByIdRequest");
const success = createAction("customerByIdSuccess");
const fail = createAction("customerByIdFailure");

const allrequest = createAction("allCustomerRequest");
const allsuccess = createAction("allCustomerSuccess");
const allfail = createAction("allCustomerFailure");

const sitesReq = createAction("allCustomerSitesRequest");
const sitesSuccess = createAction("allCustomerSitesSuccess");
const sitesFail = createAction("allCustomerSitesFailure");

export const vendorReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(request, (state, action) => {
      state.loading = true;
    })
    .addCase(success, (state, action) => {
      state.loading = false;
      state.specificCustomer = action.payload.customer;
    })
    .addCase(fail, (state, action) => {
      state.loading = false;
    })

    // All customers
    .addCase(allrequest, (state, action) => {
      state.loading = true;
    })
    .addCase(allsuccess, (state, action) => {
      state.loading = false;
      state.allCustomers = action.payload.customers;
    })
    .addCase(allfail, (state, action) => {
      state.loading = false;
    })

    // All customer sites
    .addCase(sitesReq, (state, action) => {
      state.loading = true;
    })
    .addCase(sitesSuccess, (state, action) => {
      state.loading = false;
      state.allSites = action.payload.allSites;
    })
    .addCase(sitesFail, (state, action) => {
      state.loading = false;
    })

    .addCase(clearVendor, (state, action) => {
      state.specificCustomer = null;
      state.allCustomers = null;
      state.allSites = null;
    });
});

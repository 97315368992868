import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  Stack,
  useMediaQuery,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import moment, { min } from "moment";
import { useSelector } from "react-redux";
import { CheckBox } from "@mui/icons-material";

const SingleForm = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { userRole } = useSelector((state) => state.user);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formColumns = [
    {
      field: "question",
      headerName: "Name",
      flex: 1.5,
      minWidth: smallScreen ? 700 : 0,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              marginLeft: 10,
              fontWeight: "400",
              color: colors.text,
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {location?.state?.type=='Form' ? params?.value : params?.row?.activityName}
          </div>
        </div>
      ),
    },
    {
      field: "answers",
      headerName: "Options",
      flex: 1.5,
      minWidth: smallScreen ? 600 : 0,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => {
        const options =location?.state?.type==='Form' ?  params?.row?.answers : location?.state?.type==='Daily Activity'?['Yes','No']:params?.row?.options
        return(
        <Box sx={{ display: "flex", alignItems: "center" }}>
        {options?.map((i) => {
            return (
              <Stack direction="row" alignItems="center" key={i}>
                <CheckBox color="primary" />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#596066",
                    fontFamily: "FiraSans",
                    ml: 1,
                    mr: 1.5,
                  }}
                >
                  {i}
                </Typography>
              </Stack>
            );
          })}
        </Box>
      )},
    },
  ];

  const [loading, setloading] = useState(false);
  const [singleFormData, setsingleFormData] = useState([]);
  const [formName, setformName] = useState("");
  const [options, setOptions] = useState([]);

  const [search, setsearch] = useState("");

  const GetSingleForm = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/form/getFormDetails?formId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then(async (res) => {
        setloading(false);
        const data = res.data?.allForm?.map((i) => i.questions);
         setsingleFormData(data[0]);
        setformName(res.data?.allForm[0]?.form_name);
        setOptions(res.data?.allForm[0]?.answers);
      })
      .catch((e) => {
        setloading(false);
      });
  };



  const GetSingleActivity = async () => {
    console.log("single")
    setloading(true);
    await axios
      .get(`${BASE_URL}/activity/getActivityDetails?activityId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        // console.log("res", res.data?.activitySite?.activityName)
        setformName(res?.data?.activitySite?.activityName);
        setsingleFormData([res.data?.activitySite]);
        if(location?.state?.type==='Daily Activity')
        {
          setOptions(['Yes','No']);
        }
        else
        {
          setOptions(res?.data?.activitySite?.options)
        }
      })
      .catch((e) => {
        setloading(false);
      });
  };
  useEffect(() => {
    if(location?.state?.type==='Form')
    {
      GetSingleForm();
    }
    else{
      GetSingleActivity();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer
        vendor={userRole === "vendor" ? true : false}
        admin={userRole === "admin" ? true : false}
        supervisor={userRole === "supervisor" ? true : false}
        provider={userRole === "doctor" ? true : false}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title={location?.state?.type+' Details'}/>
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              p={4}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              {/* Title */}
              <TitleWithSearchBar
                title={formName}
                onClick={() => {
                  navigate("/vendor/forms/create", {
                    state: { role: "vendor" },
                  });
                }}
                noBtn={userRole === "vendor" ? true : true}
                search={search}
                noSearch={true}
                setSearch={setsearch}
              />

<Box
      sx={{
        // border: '1px solid #ccc',
        // borderRadius: '8px',
        padding: '16px',
        maxWidth: '600px',
          
        paddingBottom: '12px',
      }}
    >
      {/* <Typography variant="h6" component="h2" gutterBottom>
        {formName}
      </Typography> */}
      <Box sx={{ marginBottom: '12px' }}>
        {/* <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
          {formName}
        </Typography> */}
        <Box>
        {location?.state?.type==='Form' && (singleFormData?.map((option, index) => (
          <>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
               {index+1+' :'} &nbsp;&nbsp;   {option?.question}
        </Typography>

           {option?.answers?.map((answer,index)=>(
            <Stack direction="row" alignItems="center" key={index} sx={{ marginBottom: '12px' }}>
                <CheckBox color="primary" />
                <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#596066',
                  fontFamily: 'FiraSans',
                  ml: 1,
                }}
              >
                {answer}
              </Typography>
            </Stack>
           )) }
            </>
          )))}
          {location?.state?.type!='Form' && (options?.map((option, index) => (
            <Stack direction="row" alignItems="center" key={index} sx={{ marginBottom: '8px' }}>
                <CheckBox color="primary" />
                <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#596066',
                  fontFamily: 'FiraSans',
                  ml: 1,
                }}
              >
                {option}
              </Typography>
            </Stack>
          )))}
        </Box>
      </Box>
    </Box>              {/* Data Grid */}
              {/* <StyledDataGrid
                rows={
                  location?.state?.type==='Form' ?
                  singleFormData?.filter((val) =>
                    val?.question
                      ?.toLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) :singleFormData || []
                }
                columns={formColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
                rowSelectionModel={[]}
                rowSelection="none"
              />
 */}
              {/* Menu */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    borderBottom: "1px solid #EBEFF5",
                    color: "#24292E",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();
                    navigate("/vendor/customers/create");
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  sx={{
                    color: "#F34141",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={handleClose}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SingleForm;

import {
  AccountCircle,
  Close,
  MailOutline,
  Password,
  PersonOutline,
  PersonOutlined,
  PhoneIphoneOutlined,
  Wc,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import { genders } from "../../../assets/data/DummyData";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const CreateSupervisor = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [confirmation, setconfirmation] = useState(false);

  const { siteData } = useSelector((state) => state.admin);
  const { userData } = useSelector((state) => state.user);

  console.log(siteData)
  const filteredCustomerSites = siteData
    ?.filter((val) => val?.user === null)
    ?.map((site) => ({
      label: site.site_name,
      value: site.site_name,
      siteId: site.id,
      customerId: site.customerId,
    }));

  const [supervisorData, setSupervisorData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    password: "",
    gender: "",
    avatar: "",
    customerSite: "",
  });

  const [avatarLink, setavatarLink] = useState("");
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        setloading(false);
        setSupervisorData((prevData) => ({
          ...prevData,
          avatar: res.data?.url,
        }));
        setavatarLink(res.data?.url);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const CreateSupervisor = async () => {
    if (
      supervisorData.fullName === "" ||
      supervisorData.email === "" ||
      supervisorData.password === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/user/createUserSupervisor`,
          {
            user: {
              name: supervisorData.fullName,
              password: supervisorData.password,
              mobile: supervisorData.mobile,
              email: supervisorData.email,
              verified: true,
              dob: null,
              gender: supervisorData.gender,
              avatar: supervisorData.avatar,
              createdBy: userData?.id,
              siteId: filteredCustomerSites?.find(
                (site) => site.value === supervisorData?.customerSite
              )?.siteId,
              customerId: userData?.customerId,
            },
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setTimeout(() => {
            navigate("/admin/supervisors");
          }, 2000);
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const UpdateSupervisor = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUser`,
        {
          userId: location?.state?.id,
          name: supervisorData.fullName,
          mobile: supervisorData.mobile,
          email: supervisorData.email,
          gender: supervisorData.gender,
          avatar: supervisorData.avatar,
          siteId: filteredCustomerSites?.find(
            (site) => site.value === supervisorData?.customerSite
          )?.siteId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate("/admin/supervisors");
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetSupervisorById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getOneSupervisor?userId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleSupervisor = res.data?.allSupervisors[0];
        setSupervisorData((prevData) => ({
          ...prevData,
          fullName: singleSupervisor?.name,
          email: singleSupervisor?.email,
          mobile: singleSupervisor?.mobile,
          gender: singleSupervisor?.gender,
          avatar: singleSupervisor?.avatar,
          customerSite: filteredCustomerSites?.find(
            (site) => site.siteId === singleSupervisor?.siteId
          )?.value,
        }));
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetSupervisorById();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "update"
            ? "Supervisor updated successfully!"
            : "Supervisor created successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "update"
              ? "Update Supervisor"
              : "Create Supervisor"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "update"
                      ? "Update Supervisor"
                      : "Create Supervisor"
                  }
                  btnTitle={params?.type === "update" ? "Update" : "Create"}
                  onClick={
                    params?.type === "update"
                      ? UpdateSupervisor
                      : CreateSupervisor
                  }
                  cancelFunc={() => navigate("/admin/supervisors")}
                />
                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          mb: 2,
                        }}
                      >
                        {supervisorData?.avatar ? (
                          <Avatar
                            alt="Remy Sharp"
                            sx={{
                              width: 125,
                              height: 125,
                              borderRadius: 360,
                              backgroundColor: "transparent",
                              border: "2px solid #EBEFF5",
                              p: 0.5,
                              cursor: "pointer",
                            }}
                            onClick={handleBrowseClick}
                          >
                            <img
                              src={supervisorData?.avatar}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt="Avatar"
                            />
                          </Avatar>
                        ) : (
                          <>
                            {avatarLink ? (
                              <Avatar
                                alt="Remy Sharp"
                                src={avatarLink}
                                sx={{
                                  width: 125,
                                  height: 125,
                                  border: "2px solid #EBEFF5",
                                  p: 2,
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                onClick={handleBrowseClick}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                                onClick={handleBrowseClick}
                              >
                                <AccountCircle sx={{ fontSize: 100 }} />
                              </Avatar>
                            )}
                          </>
                        )}
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => {
                            dataURItoBlob(e.target.files[0]);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={params?.type === "update" ? 6 : 12}>
                      <CustomFormInput
                        value={supervisorData.fullName}
                        setValue={(value) =>
                          setSupervisorData((prevData) => ({
                            ...prevData,
                            fullName: value,
                          }))
                        }
                        placeholder="Full Name"
                        Icon={PersonOutlined}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={supervisorData.email}
                        setValue={(value) =>
                          setSupervisorData((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        placeholder="Email"
                        Icon={MailOutline}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={supervisorData.mobile}
                        setValue={(value) =>
                          setSupervisorData((prevData) => ({
                            ...prevData,
                            mobile: value,
                          }))
                        }
                        placeholder="Mobile Number"
                        Icon={PhoneIphoneOutlined}
                      />
                    </Grid>
                    {params?.type !== "update" && (
                      <Grid item xs={6}>
                        <CustomFormInput
                          password
                          value={supervisorData.password}
                          setValue={(value) =>
                            setSupervisorData((prevData) => ({
                              ...prevData,
                              password: value,
                            }))
                          }
                          placeholder={"Password"}
                          Icon={Password}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <CreateDropDown
                        value={supervisorData.gender}
                        setValue={(value) =>
                          setSupervisorData((prevData) => ({
                            ...prevData,
                            gender: value,
                          }))
                        }
                        title={"Gender"}
                        data={genders}
                        Icon={Wc}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={supervisorData.customerSite}
                        setValue={(value) =>
                          setSupervisorData((prevData) => ({
                            ...prevData,
                            customerSite: value,
                          }))
                        }
                        title={"Customer Sites"}
                        data={filteredCustomerSites}
                        noIcon
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateSupervisor;

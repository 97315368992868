import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import {
  Box,
  Grid,
  Typography,
  Button,
  Snackbar,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Backdrop,
  CircularProgress,
  Alert,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import {
  CreateCustomInput,
  CreateCustomTextArea,
  CreateDropDown,
  CreateHeader,
} from "../../../components/CreatePagesComponents";
import { UploadBtn } from "../../../components/CustomBtn";
import { Close, EditNote, HelpOutline } from "@mui/icons-material";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllQuestionsForAdmin } from "../../../redux/dispatchers/admin.dispatcher";

const CreateQuestions = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [confirmation, setconfirmation] = useState(false);

  const [questionsData, setquestionsData] = useState({
    questionName: "",
    questionDescription: "",
    questionType: "",
    doc: "",
  });
  const [formDoc, setformDoc] = useState("");
  const [questionType, setquestionType] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const { siteData } = useSelector((state) => state.admin);
  const { userData } = useSelector((state) => state.user);

  const [customerSite, setCustomerSite] = useState([]);
  const [editCustomerSite, seteditCustomerSite] = useState([]);
  const [deletedCustomerSites, setdeletedCustomerSites] = useState([]);

  const customerDropdownData = siteData?.map((customer) => ({
    label: customer?.site_name,
    value: customer?.site_name,
    siteId: customer?.id,
    customerId: customer?.customerId,
  }));

  const filteredCustomerSites = siteData
    ?.filter((val) => {
      return !editCustomerSite?.some((id) => id.siteId === val.id);
    })
    ?.map((site) => ({
      label: site.site_name,
      value: site.site_name,
      siteId: site.id,
      customerId: site.customerId,
    }));

  const handleDeleteSite = (siteId) => {
    const index = editCustomerSite.findIndex((item) => item.siteId === siteId);

    if (index !== -1) {
      const deletedSite = editCustomerSite.splice(index, 1)[0];

      const exists = deletedCustomerSites.some(
        (item) => item.siteId === deletedSite.siteId
      );

      if (!exists) {
        seteditCustomerSite([...editCustomerSite]);
        setdeletedCustomerSites([...deletedCustomerSites, deletedSite]);
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const CreateQues = async () => {
    if (questionsData.questionName === "" || customerSite.length === 0) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
      return;
    } else {
      seterror(false);
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/question/createQuestion`,
          {
            selectedSites: customerSite?.map((item) => ({
              siteId: item.id,
              customerId: userData?.customerId,
            })),
            questionName: questionsData.questionName,
            questionType: questionsData.questionType,
            questiondescription: questionsData.questionDescription,
            document_url: formDoc,
            createdBy: userData?.id,
            status: "active",
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setquestionsData({
            questionName: "",
            questionDescription: "",
            questionType: "",
            doc: "",
          });
          dispatch(getAllQuestionsForAdmin(userData?.customerId));
          setTimeout(() => {
            navigate("/admin/questions");
          }, 2000);
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const UpdateQues = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/question/editQuestion`,
        {
          questionId: location?.state?.id,
          selectedSites: customerSite?.map((item) => ({
            siteId: item.id,
            customerId: userData?.customerId,
          })),
          questionName: questionsData.questionName,
          questionType: questionsData.questionType,
          questiondescription: questionsData.questionDescription,
          document_url: formDoc,
          createdBy: userData?.id,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setquestionsData({
          questionName: "",
          questionDescription: "",
          questionType: "",
          doc: "",
        });
        dispatch(getAllQuestionsForAdmin(userData?.customerId));
        GetQuesById();
        setTimeout(() => {
          navigate("/admin/questions");
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetQuesById = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/question/getQuestionDetails?questionId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        const singleQuestion = res.data?.questionSite[0];
        setquestionsData({
          questionName: singleQuestion?.questionName,
          questionDescription: singleQuestion?.questiondescription,
          questionType: singleQuestion?.questionType,
          doc: singleQuestion?.document_url,
        });
        setformDoc(singleQuestion?.document_url);
        seteditCustomerSite(
          res.data?.questionSite?.site?.map((item) => ({
            label: item?.site?.site_name,
            value: item?.site?.site_name,
            siteId: item?.id,
            customerId: item?.customerId,
          }))
        );
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetQuesById();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? `Form has been created successfully!`
            : `Form has been updated successfully!`
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? `Question has been created successfully!`
            : `Question has been updated successfully!`
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Create New Questions" />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Question"
                      : "Update Question"
                  }
                  btnTitle={params?.type === "create" ? "Create" : "Update"}
                  onClick={params?.type === "create" ? CreateQues : UpdateQues}
                  cancelFunc={() => navigate("/admin/questions")}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CreateCustomInput
                        value={questionsData.questionName}
                        setValue={(value) =>
                          setquestionsData((prevData) => ({
                            ...prevData,
                            questionName: value,
                          }))
                        }
                        placeholder="Write a Question"
                        Icon={HelpOutline}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={questionType}
                        setValue={setquestionType}
                        title="Question Type"
                        Icon={HelpOutline}
                        data={[
                          {
                            label: "Questionnaire",
                            value: "Questionnaire",
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {params?.type === "update" ? (
                        // <>
                        //   <Box
                        //     sx={{
                        //       display: "flex",
                        //       alignItems: "center",
                        //       mb: 2,
                        //     }}
                        //   >
                        //     {editCustomerSite?.map((item) => (
                        //       <Stack
                        //         direction="row"
                        //         sx={{
                        //           alignItems: "center",
                        //           justifyContent: "space-between",
                        //           borderRadius: "12px",
                        //           backgroundColor: "#FAFAFA",
                        //           padding: "0px",
                        //           border: "1px solid #EBEFF5",
                        //           mr: 2,
                        //           px: 2,
                        //           py: 1,
                        //         }}
                        //       >
                        //         <Typography
                        //           sx={{
                        //             fontSize: "12px",
                        //           }}
                        //         >
                        //           {item.label}
                        //         </Typography>
                        //         <IconButton
                        //           sx={{ height: "15px", width: "15px", ml: 1 }}
                        //           onClick={() => handleDeleteSite(item.siteId)}
                        //         >
                        //           <Close sx={{ fontSize: "15px" }} />
                        //         </IconButton>
                        //       </Stack>
                        //     ))}
                        //   </Box>
                        //   <FormControl fullWidth>
                        //     <Select
                        //       multiple
                        //       displayEmpty
                        //       value={personName}
                        //       onChange={handleChange}
                        //       open={open}
                        //       onClose={() => setOpen(false)}
                        //       onOpen={() => setOpen(true)}
                        //       MenuProps={{
                        //         onClose: () => setOpen(false),
                        //       }}
                        //       input={<OutlinedInput />}
                        //       InputProps={{
                        //         disableUnderline: true,
                        //         sx: {
                        //           fontFamily: "FiraSans",
                        //           fontSize: "14px",
                        //           fontWeight: 400,
                        //           color: "#000",
                        //         },
                        //       }}
                        //       renderValue={(selected) => {
                        //         if (selected?.length === 0) {
                        //           return (
                        //             <em
                        //               style={{
                        //                 fontStyle: "normal",
                        //                 fontSize: "14px",
                        //                 color: "#000",
                        //               }}
                        //             >
                        //               Select Customer Site
                        //             </em>
                        //           );
                        //         }

                        //         return selected?.join(", ");
                        //       }}
                        //       inputProps={{ "aria-label": "Without label" }}
                        //       sx={{
                        //         height: "50px",
                        //         borderRadius: "7px",
                        //         backgroundColor: "#FAFAFA",
                        //         elevation: 0,
                        //         fontFamily: "FiraSans",
                        //         fontSize: "14px",
                        //         fontWeight: "400",
                        //         color: colors.text,
                        //         "& .MuiOutlinedInput-notchedOutline": {
                        //           borderColor: "#EBEFF5",
                        //         },
                        //         "& input::placeholder": {
                        //           color: colors.text,
                        //           opacity: 1,
                        //           fontFamily: "FiraSans",
                        //         },
                        //         ".MuiSvgIcon-root ": {
                        //           fill: "black !important",
                        //         },
                        //       }}
                        //     >
                        //       {filteredCustomerSites?.map((option) => (
                        //         <MenuItem
                        //           style={{
                        //             textTransform: "capitalize",
                        //           }}
                        //           key={option.value}
                        //           value={option.value}
                        //           onClick={() => {
                        //             setCustomerSite((prevSelectedIds) => {
                        //               const selectedId = siteData.find(
                        //                 (website) =>
                        //                   website.id === option.siteId
                        //               );

                        //               if (
                        //                 prevSelectedIds.includes(selectedId)
                        //               ) {
                        //                 return prevSelectedIds.filter(
                        //                   (id) => id !== selectedId
                        //                 );
                        //               } else {
                        //                 return [...prevSelectedIds, selectedId];
                        //               }
                        //             });
                        //             setOpen(false);
                        //           }}
                        //         >
                        //           {option.label}
                        //         </MenuItem>
                        //       ))}
                        //     </Select>
                        //   </FormControl>
                        // </>
                        <></>
                      ) : (
                        <FormControl fullWidth>
                          <Select
                            multiple
                            displayEmpty
                            value={personName}
                            onChange={handleChange}
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            MenuProps={{
                              onClose: () => setOpen(false),
                            }}
                            input={<OutlinedInput />}
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                fontFamily: "FiraSans",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#000",
                              },
                            }}
                            renderValue={(selected) => {
                              if (selected?.length === 0) {
                                return (
                                  <em
                                    style={{
                                      fontStyle: "normal",
                                      fontSize: "14px",
                                      color: "#000",
                                    }}
                                  >
                                    Select Customer Site
                                  </em>
                                );
                              }

                              return selected?.join(", ");
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              height: "50px",
                              borderRadius: "7px",
                              backgroundColor: "#FAFAFA",
                              elevation: 0,
                              fontFamily: "FiraSans",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: colors.text,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EBEFF5",
                              },
                              "& input::placeholder": {
                                color: colors.text,
                                opacity: 1,
                                fontFamily: "FiraSans",
                              },
                              ".MuiSvgIcon-root ": {
                                fill: "black !important",
                              },
                            }}
                          >
                            {customerDropdownData?.map((option) => (
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                }}
                                key={option.value}
                                value={option.value}
                                onClick={() => {
                                  setCustomerSite((prevSelectedIds) => {
                                    const selectedId = siteData?.find(
                                      (website) => website.id === option.siteId
                                    );

                                    if (prevSelectedIds.includes(selectedId)) {
                                      return prevSelectedIds.filter(
                                        (id) => id !== selectedId
                                      );
                                    } else {
                                      return [...prevSelectedIds, selectedId];
                                    }
                                  });
                                  setOpen(false);
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <CreateCustomTextArea
                        value={questionsData.questionDescription}
                        setValue={(value) =>
                          setquestionsData((prevData) => ({
                            ...prevData,
                            questionDescription: value,
                          }))
                        }
                        placeholder="Question Description"
                        Icon={EditNote}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <UploadBtn
                        onClick={setformDoc}
                        formDoc={formDoc}
                        setloading={setloading}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateQuestions;

import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import DataGridTable from "../../../components/DataGridTable";
import {
  customerData,
  customerSitesData,
  dummyQuestionData,
  formsData,
  supervisorRows,
} from "../../../assets/data/DummyData";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllActivitiesForAdmin,
  getAllAdminCustomerSites,
  getAllFormsForAdmin,
  getAllQuestionsForAdmin,
  getAllSupervisorForAdmin,
} from "../../../redux/dispatchers/admin.dispatcher";
import { useNavigate } from "react-router-dom";
import axiosInstance, { BASE_URL } from "../../../constants/config";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredForms, setfilteredForms] = useState([]);
  const { userData } = useSelector((state) => state.user);
  const { allSupervisors, siteData, allQuestions, allForms } = useSelector(
    (state) => state.admin
  );
  //var filteredForms;
  const GetAllActivities = async () => {
    //setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/activity/getAllActivityByCustomerId?customerId=${userData?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
       // setloading(false);
       setfilteredForms(res.data?.allFormsAndActivities?.slice(0, 5));
        
        // setallActivities(res.data?.allFormsAndActivities);
      })
      .catch((e) => {
        //setloading(false);
      });
  };

  
  var [filteredSupervisors, setfilteredSupervisors] = useState([]);

  const GetUsers = async () => {
    //setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/user/getAllSupervisorAndDoctor?customerId=${userData?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
      //  setloading(false);
        setfilteredSupervisors(res.data?.allUsers?.slice(0, 5));
      })
      .catch((e) => {
      //  setloading(false);
      });
  };


  useEffect(() => {
    dispatch(getAllSupervisorForAdmin(userData?.customerId));
   // dispatch(getAllFormsForAdmin(userData?.customerId));
    dispatch(getAllQuestionsForAdmin(userData?.customerId));
    dispatch(getAllAdminCustomerSites(userData?.customerId));
    dispatch(getAllActivitiesForAdmin(userData?.customerId));
    GetAllActivities();
    GetUsers();

  }, []);

  const [doctorSearch, setdoctorSearch] = useState("");
  const [siteSearch, setsiteSearch] = useState("");
  const [formSearch, setformSearch] = useState("");
  const [questionSearch, setquestionSearch] = useState("");
  const [supervisorSearch, setsupervisorSearch] = useState("");

  const filteredSites = siteData
    ?.filter((val) =>
      val?.address?.toLowerCase()?.includes(siteSearch?.toLowerCase())
    )
    ?.slice(0, 5);

  // const filteredForms = allForms
  //   ?.filter((val) =>
  //     val?.form?.form_name
  //       ?.toLocaleLowerCase()
  //       ?.includes(formSearch?.toLocaleLowerCase())
  //   )
  //   ?.slice(0, 5);

  const filteredQuestions = allQuestions
    ?.filter((val) =>
      val?.question?.questionName
        ?.toLocaleLowerCase()
        ?.includes(questionSearch?.toLocaleLowerCase())
    )
    ?.slice(0, 5);

  // const filteredSupervisors = allSupervisors
  //   ?.filter((val) =>
  //     val?.name
  //       ?.toLocaleLowerCase()
  //       ?.includes(supervisorSearch?.toLocaleLowerCase())
  //   )
  //   ?.slice(0, 5);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Dashboard" />

        {/* Data Grids */}
        <Grid container spacing={2} p={2}>
          {/* Doctors Table */}
          {/* <Grid item xs={12}>
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <DataGridTable
                title="Doctors"
                type="employees"
                rowData={customerData}
                pageSize={5}
                search={doctorSearch}
                setSearch={setdoctorSearch}
              />
            </Box>
          </Grid> */}

          {/* Customer Sites Table */}
          <Grid item xs={12} md={6}>
            <Box
              bgcolor="#fff"
              sx={{ borderRadius: "20px" }}
              width="100%"
              mr={2}
            >
              <DataGridTable
                title="Sites"
                type="customer_sites"
                rowData={filteredSites || []}
                pageSize={5}
                search={siteSearch}
                setSearch={setsiteSearch}
                onCreate={() => navigate("/admin/customer-sites/create")}
              />
            </Box>
          </Grid>
          {/* Supervisors Table */}
          <Grid item xs={12} md={6}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Users"
                type="supervisors"
                rowData={filteredSupervisors || []}
                pageSize={5}
                search={supervisorSearch}
                setSearch={setsupervisorSearch}
                onCreate={() => navigate("/admin/users/create")}
              />
            </Box>
          </Grid>
          {/* Forms Table */}
          <Grid item xs={12} md={12}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Activities"
                type="forms"
                rowData={filteredForms || []}
                pageSize={5}
                search={formSearch}
                setSearch={setformSearch}
                fullWidth
                onCreate={() => navigate("/admin/forms/create")}
              />
            </Box>
          </Grid>

          {/* Questions Table */}
          {/* <Grid item xs={12} md={4.5}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Requests"
                type="questions"
                rowData={filteredQuestions || []}
                pageSize={5}
                search={questionSearch}
                setSearch={setquestionSearch}
                fullWidth
                onCreate={() => {
                  navigate("/admin/questions/create");
                }}
              />
            </Box>
          </Grid> */}


        </Grid>
      </Box>
    </Box>
  );
};

export default AdminDashboard;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Stack,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { useNavigate } from "react-router-dom";
import { colors } from "./../../../theme/theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TitleWithSearchBar from "./../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { Close } from "@mui/icons-material";
import { RedModal } from "../../../components/ConfirmationModal";
import InactiveSwitch from "../../../components/InactiveSwitch";

const Activities = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const activitiesColumn = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "activityName",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.text,
              fontFamily: "FiraSans",
              ml: 1,
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    // {
    //   field: "scheduled",
    //   headerName: "Scheduled On",
    //   flex: 0.5,
    //   renderCell: (params) => (
    //     <Typography
    //       sx={{
    //         fontSize: "12px",
    //         fontWeight: "400",
    //         color: "#596066",
    //         fontFamily: "FiraSans",
    //         textDecoration: "underline",
    //       }}
    //     >
    //       {params.row?.activity?.scheduledOn === "" ||
    //       params.row?.activity?.scheduledOn === null
    //         ? "N/A"
    //         : moment(params.row?.activity?.scheduledOn).format("DD-MM-YYYY")}
    //     </Typography>
    //   ),
    // },
    {
      field: "activityType",
      headerName: "Activity Type",
      flex: 1.5,
      renderCell: (params) => (
        <div
          style={{
            marginLeft: 10,
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params?.value?.charAt(0).toUpperCase() + params?.value?.slice(1)}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(e) => {
              handleClick(e);
              setactivityId(params.row?.id);
              setactivityStatus(params.row?.status);
              setactivityType(params.row?.activityType);
             // setactivityIsAssigned(params?.row?.isAssigned);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [search, setsearch] = useState("");

  const [activityId, setactivityId] = useState("");

  const { userData } = useSelector((state) => state.user);
  const [allActivities, setallActivities] = useState([]);

  const GetAllActivities = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/activity/getAllActivities`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallActivities(res.data?.allActivities);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetAllActivities();
  }, []);

  const [snackbarState, setsnackbarState] = useState(false);

  const [status, setstatus] = useState("");
  const [activityType, setactivityType] = useState("");
  const [activityStatus, setactivityStatus] = useState("");
  const [activityIsAssigned, setactivityIsAssigned] = useState();

  const DeleteActivity = async () => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/activity/updateActivityStatus`,
        {
          status: status,
          activityId: activityId,
          type:activityType
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsnackbarState(true);
        GetAllActivities();
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };
  const [deleteModalState, setdeleteModalState] = useState(false);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [activeSwitch, setactiveSwitch] = useState(true);

  const handleactiveSwitch = () => {
    setactiveSwitch(!activeSwitch);
  };

  const filteredActivities = allActivities?.filter((val) => {
    return val?.status === (activeSwitch ? "active" : "inactive");
  });

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarState}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={
          status === "deleted"
            ? "Activity Deleted Successfully"
            : "Activity Status Changed Successfully"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={DeleteActivity}
        title={status === "deleted" ? "Delete Form" : "Change Activity Status"}
        mainText={
          status === "deleted"
            ? "Are you sure you want to delete this forms?"
            : activityStatus === "active"
            ? "Are you sure you want to deactivate this activity?"
            : "Are you sure you want to activate this activity?"
        }
        subText="This action cannot be undone."
        actionText={status === "deleted" ? "Delete" : "Change Status"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Activities" />

        {/* <FilterBox type="activity" /> */}
        <Grid container p={2}>
          <Grid item xs={12} mb={2}>
            <InactiveSwitch
              activeSwitch={activeSwitch}
              handleactiveSwitch={handleactiveSwitch}
              title={"Activities"}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Activities"
                onClick={() =>
                  navigate("/admin/activities/create", {
                    state: { admin: true },
                  })
                }
                noBtn={true}
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  filteredActivities || []
                }
                getRowId={(row) => row?.id}
                columns={activitiesColumn}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5,10, { value: filteredActivities?.length, label: 'All' }]}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                  {/* <MenuItem
                    sx={{
                      borderBottom: "1px solid #EBEFF5",
                      color: "#24292E",
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "FiraSans",
                    }}
                    disabled={activityIsAssigned?true:false}
                    onClick={() =>
                      navigate("/admin/activities/update", {
                        state: { id: activityId },
                      })
                    }
                  >
                    Edit Activity
                  </MenuItem> */}
                 
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid #EBEFF5",
                      color: "#24292E",
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "FiraSans",
                    }}
                    onClick={() =>
                      navigate(`/vendor/forms/form-details`, {
                        state: { id: activityId,type:activityType },
                      })
                    }
                  >
                    View {activityType}
                  </MenuItem>
                
                {/* {activityType !== "Form" && (
                  <MenuItem
                    sx={{
                      color: "#24292E",
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "FiraSans",
                    }}
                    onClick={(e) => {
                      handleClose(e);
                      setdeleteModalState(true);
                      if (activityStatus === "active") {
                        setstatus("inactive");
                      } else {
                        setstatus("active");
                      }
                    }}
                  >
                    Change Status as{" "}
                    {activityStatus === "active" ? "Inactive" : "Active"}
                  </MenuItem>
                )}
                <MenuItem
                    sx={{
                      borderBottom: "1px solid #EBEFF5",
                      color: "#24292E",
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "FiraSans",
                    }}
                    disabled={activityIsAssigned?true:false}
                    onClick={(e) => {
                      handleClose(e);
                      setdeleteModalState(true);
                        setstatus("deleted");
                        setactivityType(activityType)
                    }}
                  >
                    Delete {activityType}
                  </MenuItem> */}
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Activities;

import "./App.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import Router from "./routes/Router";
import { UserProvider } from "./constants/context";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";

const defaultTheme = createTheme({
  typography: {
    fontFamily: "FiraSans, Arial, sans-serif",
  },
});

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <UserProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router />
          </PersistGate>
        </Provider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./reducers/login.reducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { vendorReducer } from "./reducers/vendor.reducer";
import { adminReducer } from "./reducers/admin.reducer";
import { supervisorReducer } from "./reducers/supervisor.reducer";
import { providerReducer } from "./reducers/provider.reducer";

const rootPersistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: authReducer,
  vendor: vendorReducer,
  admin: adminReducer,
  supervisor: supervisorReducer,
  provider: providerReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;

export const persistor = persistStore(store);

import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Fade,
  Backdrop,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Menu,
  Snackbar,
} from "@mui/material";
import AppBar from "../../../components/AppBar";
import { colors } from "../../../theme/theme";
import IconButton from "@mui/material/IconButton";
import CustomerProfileBoxComponent from "../../../components/CustomerProfileBoxComponent";
import {
  Close,
  EditOutlined,
  Feed,
  FeedOutlined,
  Groups,
  GroupsOutlined,
  LocalHospital,
  LocalHospitalOutlined,
  SendOutlined,
  SupervisorAccount,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { useSelector } from "react-redux";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { GreenModal } from "../../../components/ConfirmationModal";

const DataGridTable = ({
  rowData,
  type,
  pageSize,
  title,
  createBtn = true,
  setopenModal,
  onRequest,
  hasAccess = false,
  requestSent = false,
  requestPopup,
  setrequestPopup,
  search,
  setSearch,
  expireAccess,
}) => {
  const formColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2.5,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={require("../../../assets/icons/pdf.png")}
            alt=""
            style={{ borderRadius: 360, height: 32, width: 32 }}
          />
          <Typography
            style={{
              marginLeft: 10,
              fontWeight: "400",
              color: colors.text,
              cursor: "pointer",
            }}
            onClick={() => window.open(params.row?.form?.documnetOne, "_blank")}
          >
            {params.row?.form?.form_name}
          </Typography>
        </div>
      ),
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 2.5,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{ marginLeft: 10, fontWeight: "400", color: colors.text }}
          >
            {params.row?.customer?.organization_name}
          </Typography>
        </div>
      ),
    },
  ];

  const supColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2.5,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params?.row?.avatar && (
            <img
              src={params?.row?.avatar}
              alt=""
              style={{
                borderRadius: 360,
                height: 32,
                width: 32,
                objectFit: "contain",
              }}
            />
          )}
          <Typography
            style={{
              marginLeft: 10,
              fontWeight: "400",
              color: colors.text,
            }}
          >
            {params.value}
          </Typography>
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2.5,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            style={{ marginLeft: 10, fontWeight: "400", color: colors.text }}
          >
            {params.value}
          </Typography>
        </div>
      ),
    },
  ];

  return (
    <Box
      mb={2}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
      }}
    >
      <TitleWithSearchBar
        title={title}
        noBtn={true}
        search={search}
        setSearch={setSearch}
      />

      {!hasAccess ? (
        <Box position="relative" sx={{ height: "400px" }}>
          <Box
            style={{ filter: "blur(5px)" }}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                height: "50vh",
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            ></Box>
          </Box>

          <Box
            p={2}
            textAlign="center"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              width: "75%",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: "22px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {requestSent
                ? "Your request is waiting for customer's approval"
                : `${
                    expireAccess
                      ? "Your access is expired to view the Customer's"
                      : "No access to view the Customer's"
                  } ${title} list. However, if you require access, please request it by clicking “Send Request” button.`}
            </Typography>
            {!requestSent && (
              <Button
                variant="contained"
                sx={{
                  height: "40px",
                  width: "175px",
                  backgroundColor: "#138418",
                  textTransform: "none",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#138418",
                  },
                  mt: 2,
                }}
                onClick={() => {
                  setrequestPopup(true);
                }}
              >
                Send Request
                <SendOutlined sx={{ ml: 1, fontSize: "20px" }} />
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <StyledDataGrid
          rows={rowData}
          columns={type === "forms" ? formColumns : supColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: pageSize },
            },
          }}
          sx={{
            borderRadius: "12px",
            border: "none",
          }}
          pageSizeOptions={[5, 10]}
          getRowClassName={(params) =>
            params.row.id % 2 === 0 ? "even" : "odd"
          }
          autoHeight
          autoLayout
        />
      )}
    </Box>
  );
};

const CustomerProfile = () => {
  const navigate = useNavigate();
  const [navigationId, setnavigationId] = useState(0);

  const subNav = [
    {
      id: 0,
      title: "Forms",
      icon: navigationId === 0 ? <Feed /> : <FeedOutlined />,
    },
    {
      id: 1,
      title: "Patients",
      icon: navigationId === 1 ? <Groups /> : <GroupsOutlined />,
    },
    {
      id: 2,
      title: "Doctors",
      icon: navigationId === 2 ? <LocalHospital /> : <LocalHospitalOutlined />,
    },
    {
      id: 3,
      title: "Supervisors",
      icon:
        navigationId === 3 ? (
          <SupervisorAccount />
        ) : (
          <SupervisorAccountOutlined />
        ),
    },
  ];

  const location = useLocation();
  const { userData } = useSelector((state) => state.user);

  const [hasAccess, setHasAccess] = useState(false);
  const [requestPopup, setrequestPopup] = useState(false);
  const [requestSent, setrequestSent] = useState(false);

  const [loading, setloading] = useState(false);

  const [customerSitesData, setcustomerSitesData] = useState([]);

  const [expireAccess, setexpireAccess] = useState(false);

  const [customerId, setcustomerId] = useState();

  const GetCustomerSiteData = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/site/getOneSite?siteId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        setcustomerSitesData(res.data?.oneSite);
        setcustomerId(res.data?.oneSite?.customerId);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const CheckRequestAccess = async () => {
    setexpireAccess(false);
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/request/getRequestByUserId?userId=${userData?.id}&siteId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        if (res.data?.message === "Not requested.") {
          setHasAccess(false);
        } else if (res.data?.requestResult?.status === "pending") {
          setHasAccess(false);
          setrequestSent(true);
        } else if (res.data?.requestResult?.status === "approved") {
          setHasAccess(true);
          GetForms();
          GetSupervisors();
          GetDoctors();
        } else if (res.data?.requestResult?.status === "expired") {
          setHasAccess(false);
          setexpireAccess(true);
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const RequestAccess = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/request/createRequest`,
        {
          siteId: location?.state?.id,
          status: "pending",
          customerId: customerId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsnackbarState(true);
        setrequestSent(true);
        setrequestPopup(false);
        CheckRequestAccess();
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetCustomerSiteData();
    CheckRequestAccess();
  }, []);

  const [formsData, setformsData] = useState([]);
  const [supervisroData, setsupervisroData] = useState([]);
  const [doctorsData, setdoctorsData] = useState([]);

  const GetForms = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/form/getFormDetailsBySiteId?siteId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setformsData(res.data?.allForm);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const GetSupervisors = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/user/getSupervisorBySiteId?siteId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsupervisroData(res.data?.allSupervisors);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const GetDoctors = async () => {
    setloading(true);
    await axios
      .get(
        `${BASE_URL}/user/getDoctorsBySiteId?siteId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdoctorsData(res.data?.allDoctors);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const [snackbarState, setsnackbarState] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [search, setSearch] = useState("");
  const [supervisorSearch, setsupervisorSearch] = useState("");
  const [doctorSearch, setdoctorSearch] = useState("");

  const filteredFormData =
    formsData?.length > 0 &&
    formsData?.filter((item) => {
      return item?.form?.form_name
        ?.toLowerCase()
        ?.includes(search?.toLowerCase());
    });

  const filteredSupData =
    supervisroData?.length > 0 &&
    supervisroData?.filter((item) => {
      return item?.name
        ?.toLowerCase()
        ?.includes(supervisorSearch?.toLowerCase());
    });

  const filteredDocData =
    doctorsData?.length > 0 &&
    doctorsData?.filter((item) => {
      return item?.name?.toLowerCase()?.includes(doctorSearch?.toLowerCase());
    });

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <GreenModal
        open={requestPopup}
        onClose={() => setrequestPopup(false)}
        onClick={() => {
          // setHasAccess(true);
          // setsnackbarState(true);
          // setconfirmation(false);
          RequestAccess();
        }}
        title="Need request access"
        mainText="Requesting access to troubleshoot the customer's site."
        subText="Please ensure that you’ll have access only if the customer grants you access."
      />

      <Snackbar
        open={snackbarState}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Request sent successfully!"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Customer Sites" />

        <Stack direction={"row"} alignItems={"center"}>
          <Grid container spacing={2} p={2}>
            <CustomerProfileBoxComponent
              data={customerSitesData}
              editOnClick={() => {
                navigate("/vendor/customer-sites/update", {
                  state: { id: location?.state?.id },
                });
              }}
            />

            <Grid item xs={3}>
              <Box
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  py: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#9EA5AD",
                    fontFamily: "FiraSans",
                    px: 2,
                    textTransform: "uppercase",
                    mb: 1,
                  }}
                >
                  Quick Navigation
                </Typography>
                {subNav.map((item, index) => (
                  <ListItem
                    key={item.title}
                    disablePadding
                    sx={{
                      backgroundColor:
                        item.id === navigationId ? "#f7f8fa" : "transparent",
                    }}
                  >
                    <ListItemButton onClick={() => setnavigationId(item.id)}>
                      <ListItemIcon
                        sx={{
                          color:
                            item.id === navigationId
                              ? colors.text
                              : colors.lightText,
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color:
                            item.id === navigationId
                              ? colors.text
                              : colors.lightText,
                        }}
                        primary={item.title}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </Grid>

            {navigationId === 0 && (
              <Fade in={navigationId === 0}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        bgcolor="#fff"
                        width="100%"
                        sx={{ borderRadius: "20px" }}
                      >
                        <DataGridTable
                          title="Forms"
                          type="forms"
                          rowData={filteredFormData || []}
                          pageSize={5}
                          onRequest={RequestAccess}
                          hasAccess={hasAccess}
                          requestPopup={requestPopup}
                          setrequestPopup={setrequestPopup}
                          requestSent={requestSent}
                          search={search}
                          setSearch={setSearch}
                          expireAccess={expireAccess}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}

            {navigationId === 1 && (
              <Fade in={navigationId === 1}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        bgcolor="#fff"
                        width="100%"
                        sx={{ borderRadius: "20px" }}
                      >
                        <DataGridTable
                          title="Patients"
                          type="employees"
                          rowData={[]}
                          pageSize={5}
                          onRequest={RequestAccess}
                          hasAccess={hasAccess}
                          requestPopup={requestPopup}
                          setrequestPopup={setrequestPopup}
                          requestSent={requestSent}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}

            {navigationId === 2 && (
              <Fade in={navigationId === 2}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        bgcolor="#fff"
                        width="100%"
                        sx={{ borderRadius: "20px" }}
                      >
                        <DataGridTable
                          title="Providers"
                          type="employees"
                          rowData={filteredDocData || []}
                          pageSize={5}
                          onRequest={RequestAccess}
                          hasAccess={hasAccess}
                          requestPopup={requestPopup}
                          setrequestPopup={setrequestPopup}
                          requestSent={requestSent}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}

            {navigationId === 3 && (
              <Fade in={navigationId === 3}>
                <Grid item xs={9}>
                  <Grid container spacing={2} px={2}>
                    <Grid item xs={12}>
                      <Box
                        bgcolor="#fff"
                        width="100%"
                        sx={{ borderRadius: "20px" }}
                      >
                        <DataGridTable
                          title="Supervisors"
                          type="employees"
                          rowData={filteredSupData || []}
                          pageSize={5}
                          onRequest={RequestAccess}
                          hasAccess={hasAccess}
                          requestPopup={requestPopup}
                          setrequestPopup={setrequestPopup}
                          requestSent={requestSent}
                          search={supervisorSearch}
                          setSearch={setsupervisorSearch}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

export default CustomerProfile;

import React from "react";
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { colors } from "../theme/theme";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PasswordIcon from "@mui/icons-material/Password";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const CustomInput = ({ value, setValue, placeholder, mT, mB, Icon }) => {
  return (
<FormControl fullWidth sx={{ mt: mT ? 2 : 0, mb: mB ? 2 : 0 }}>
  <TextField
    sx={{
      backgroundColor: "#fff",
      fontFamily: "FiraSans",
      fontSize: "14px",
      fontWeight: "400",
      borderRadius: "8px",
    }}
    label={placeholder}
    placeholder={placeholder}
    value={value}
    onChange={(e) => setValue(e.target.value)}
    InputProps={{
      sx: {
        height: "50px",
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#E0E2E7', // Customize border color
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#A0A2A7', // Customize border color on hover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#707173', // Customize border color when focused
        },
        '& .MuiInputAdornment-root': {
          color: value === "" ? "#707173" : colors.text,
        },
      },
      startAdornment: (
        <InputAdornment position="start">
          <Icon sx={{ color: value === "" ? "#707173" : colors.text }} />
        </InputAdornment>
      ),
    }}
    InputLabelProps={{
      sx: {
        '&.Mui-focused': {
          color: '#138418', // Change label color when focused
        },
      },
    }}
  />
</FormControl>

  );
};

export const CustomPasswordInput = ({ value, setValue, mT, mB,title }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      fullWidth
      sx={{ mt: mT ? 2 : 0, mb: mB ? 2 : 0 }}
      variant="outlined"
    >
      <TextField
        type={showPassword ? "text" : "password"}
        sx={{
          backgroundColor: "#fff",
          fontFamily: "FiraSans",
          fontSize: "14px",
          fontWeight: "400",
          borderRadius: "8px",
        }}
            InputProps={{
          sx: {
            height: "50px",
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#E0E2E7', // Customize border color
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#A0A2A7', // Customize border color on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#707173', // Customize border color when focused
            },
            '& .MuiInputAdornment-root': {
              color: value === "" ? "#707173" : colors.text,
            },
          },
          startAdornment: (
            <InputAdornment position="start">
            <PasswordIcon sx={{ color: "rgba(3,3,21,0.75)" }} />
          </InputAdornment>
          ),
          endAdornment:(
            <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>

          )
        }}
        InputLabelProps={{
          sx: {
            '&.Mui-focused': {
              color: '#138418', // Change label color when focused
            },
          },
        }}
    
        value={value}
        label={title?title:"Password"}
        placeholder={title?title:"Password"}
        onChange={(e) => setValue(e.target.value)}
      />
    </FormControl>
  );
};

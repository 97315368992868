import axiosInstance, { BASE_URL } from "../../constants/config";

export const getAllAdminCustomerSites =
  (customerId = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "allCustomerSitesRequest",
      });
      const { data } = await axiosInstance.get(
        `${BASE_URL}/site/getAllSitesWithSupervisor?customerId=${customerId}`,
        {
          withCredentials: true,
        }
      );
      dispatch({
        type: "allCustomerSitesSuccess",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "allCustomerSitesFailure",
        payload: error,
      });
    }
  };

export const getAllSupervisorForAdmin = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: "allSupervisorRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/user/getAllSupervisors?customerId=${customerId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allSupervisorSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allSupervisorFailure",
      payload: error,
    });
  }
};

export const getAllFormsForAdmin = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: "allFormsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/form/getFormDetailsByCustomerId?customerId=${customerId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allFormsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allFormsFailure",
      payload: error,
    });
  }
};

export const getAllQuestionsForAdmin = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: "allQuestionsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/question/getAllQuestionByCustomerId?customerId=${customerId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allQuestionsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allQuestionsFailure",
      payload: error,
    });
  }
};

export const getAllActivitiesForAdmin = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: "allActivitiesRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/activity/getAllActivityByCustomerId?customerId=${customerId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allActivitiesSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allActivitiesFailure",
      payload: error,
    });
  }
};

export const getAllDoctorsForAdmin = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: "allDoctorsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/user/getAllDoctors?customerId=${customerId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allDoctorsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allDoctorsFailure",
      payload: error,
    });
  }
};

export const clearAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "clearAdmin",
    });
  } catch (error) {}
};

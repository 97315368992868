import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Close,
  EditOutlined,
  EmailOutlined,
  MoreVert,
  PhoneIphoneOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { colors } from "../../../theme/theme";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import FilterBox from "../../../components/FilterBox";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { RedModal } from "../../../components/ConfirmationModal";
import { customerData } from "../../../assets/data/DummyData";
import { useDispatch, useSelector } from "react-redux";
import { getAllDoctorsForSupervisor } from "../../../redux/dispatchers/supervisor.dispatcher";
import moment from "moment";
import { getAllSupervisorForAdmin } from "../../../redux/dispatchers/admin.dispatcher";

const DoctorsSupervisor = () => {
  const navigate = useNavigate();

  const [deleteModalState, setdeleteModalState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmation, setConfirmation] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const doctorColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {params?.row?.avatar !== "" && (
            <img
              src={params.row.avatar}
              alt=""
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                cursor: "pointer",
                objectFit: "contain",
              }}
              onClick={() =>
                navigate("/supervisor/doctors/profile", {
                  state: { id: params.row.id },
                })
              }
            />
          )}
          <Box ml={1}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                colors: colors.text,
                fontFamily: "FiraSans",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate("/supervisor/doctors/profile", {
                  state: { id: params.row.id },
                })
              }
            >
              {params?.value}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.25,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailOutlined
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 0.5,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "mobile",
      headerName: "Phone",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PhoneIphoneOutlined
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "doctorType",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              whiteSpace: "pre-line",
            }}
          >
            {params?.value === null ? "N/A" : params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created On/By",
      flex: 0.75,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {moment(params?.value).format("DD MMM YYYY")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.75,
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() =>
              navigate("/supervisor/doctors/profile", {
                state: { id: params.row.id },
              })
            }
          >
            <VisibilityOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate("/supervisor/doctors/update", {
                state: { id: params.row.id },
              });
            }}
          >
            <EditOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              handleClick(e);
              setdoctorId(params.row.id);
            }}
          >
            <MoreVert sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const [doctorId, setdoctorId] = useState("");

  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { allDoctors } = useSelector((state) => state.supervisor);

  useEffect(() => {
    dispatch(getAllDoctorsForSupervisor(userData?.siteId));
    dispatch(getAllSupervisorForAdmin(userData?.customerId));
  }, []);

  const [search, setsearch] = useState("");

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Provider" />

        {/* Filter */}
        {/* <FilterBox type="doctors" /> */}

        {/* Data Grid */}
        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Providers"
                onClick={() => navigate("/supervisor/doctors/create")}
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  allDoctors?.filter((val) =>
                    val?.name
                      ?.toLocaleLowerCase()
                      .includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={doctorColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
            </Box>
          </Grid>
        </Grid>

        {/* Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => {
              handleClose();
              navigate("/supervisor/doctors/update", {
                state: { id: doctorId },
              });
            }}
          >
            Edit Provider
          </MenuItem>
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() =>
              navigate("/supervisor/doctors/reassign-doctor-to-supervisor")
            }
          >
            Reassign Provider to Supervisor
          </MenuItem>
          <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() =>
              navigate("/supervisor/doctors/reassign-patient-to-doctor")
            }
          >
            Reassign Patient to Provider
          </MenuItem>
          <MenuItem
            sx={{
              color: "#F34141",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => setdeleteModalState(true)}
          >
            Delete
          </MenuItem>
        </Menu>

        {/* Delete Modal */}
        <RedModal
          open={deleteModalState}
          onClose={() => setdeleteModalState(false)}
          onClick={() => setdeleteModalState(false)}
          title="Confirmation"
          mainText="Are you sure want to delete this Provider?"
          subText="Please ensure that once this Provider is deleted, it cannot be recovered."
        />
      </Box>
    </Box>
  );
};

export default DoctorsSupervisor;

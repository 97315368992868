import {
  AccountCircle,
  Close,
  HelpOutline,
  MailOutline,
  Password,
  PersonOutline,
  PersonOutlined,
  PhoneIphoneOutlined,
  Wc,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import { genders } from "../../../assets/data/DummyData";
import axios from "axios";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { colors } from "../../../theme/theme";
import { RedModal } from "../../../components/ConfirmationModal";

const CreateUserDrPatient = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [confirmation, setconfirmation] = useState(false);

  const { userData } = useSelector((state) => state.user);

  const [usersData, setusersData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    password: "",
    gender: "",
    avatar: "",
    doctorType: "",
  });

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [isAdmin, setisAdmin] = useState(true);
  const [isSupervisor, setisSupervisor] = useState(false);
  const [isDoctor, setisDoctor] = useState(false);
  const [isPatient, setisPatient] = useState(false);

  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "doctor") {
      setisDoctor(true);
      setisPatient(false);
    } else {
      setisDoctor(false);
      setisPatient(true);
    }
  };

  const [avatarLink, setavatarLink] = useState("");
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        setloading(false);
        setusersData((prevData) => ({
          ...prevData,
          avatar: res.data?.url,
        }));
        setavatarLink(res.data?.url);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const CreateUsers = async () => {
    if (
      usersData.fullName === "" ||
      usersData.email === "" ||
      usersData.password === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/user/createUserAdmin`,
          {
            user: {
              name: usersData.fullName,
              password: usersData.password,
              mobile: usersData.mobile,
              email: usersData.email,
              verified: true,
              dob: null,
              gender: usersData.gender,
              avatar: usersData.avatar,
              createdBy: userData?.id,
              customerId: userData?.customerId,
              isAdmin: false,
              isSupervisor: false,
              isDoctor: true,
              doctorType: usersData.type,
              siteId: userData?.siteId,
            },
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
          AssignDr(res.data?.id);
        })
        .catch((e) => {
          setloading(false);
          seterror(true);
          seterrorMsg(`${e?.response?.data?.err?.errors[0]?.message}`);

        });
    }
  };

  const UpdateUser = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUser`,
        {
          userId: location?.state?.id,
          name: usersData.fullName,
          mobile: usersData.mobile,
          email: usersData.email,
          gender: usersData.gender,
          avatar: usersData.avatar,
          isAdmin: isAdmin,
          isSupervisor: isSupervisor,
          isDoctor: isDoctor,
          doctorType: usersData.doctorType,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        AssignDr(location?.state?.id);
      })
      .catch((e) => {
        setloading(false);
        seterror(true);
        seterrorMsg(`${e?.response?.data?.err?.errors[0]?.message}`);

      });
  };

  const GetUserById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getSpecificUser?userId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleUser = res.data?.specificUser;
        setusersData((prevData) => ({
          ...prevData,
          fullName: singleUser?.name,
          email: singleUser?.email,
          mobile: singleUser?.mobile,
          gender: singleUser?.gender,
          avatar: singleUser?.avatar,
          doctorType: singleUser?.doctorType,
        }));
        setisAdmin(singleUser?.isAdmin);
        setisSupervisor(singleUser?.isSupervisor);
        setisDoctor(singleUser?.isDoctor);
        setisPatient(singleUser?.role === "patient" ? true : false);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const getSpecificDoctorWithSupervisor = async () => {
    setloading(true);
    if (location?.state?.id === undefined) {
      return;
    }
    await axiosInstance
      .get(
        `${BASE_URL}/doctor_supervisor/getSpecificDoctorWithSupervisor?doctorId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);

        setsupervisorId(res.data.allSupervisorsDoctors.supervisorId);
        setchooseSupervisor(res.data.allSupervisorsDoctors.supervisors.name);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update" || params?.type === "view") {
      GetUserById();
      getSpecificDoctorWithSupervisor();
    }
  }, []);

  const { allSupervisors } = useSelector((state) => state.admin);

  const supervisorData = allSupervisors?.map((supervisor) => ({
    value: supervisor?.name,
    label: supervisor?.name,
    supervisorId: supervisor?.id,
    siteId: supervisor?.siteId,
  }));

  const [chooseSupervisor, setchooseSupervisor] = useState("");
  const [supervisorId, setsupervisorId] = useState("");
  const [supervisorSiteId, setsupervisorSiteId] = useState("");
  const [deleteModalState, setdeleteModalState] = React.useState(false);

  const AssignDr = async (id) => {
    if (supervisorId === "") {
      return;
      // seterror(true);
      // seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axiosInstance
        .post(
          `${BASE_URL}/doctor_supervisor/assignDoctorToSupervisor`,
          {
            doctorId: id,
            supervisorId: supervisorId,
            siteId: supervisorSiteId,
            customerId: userData?.customerId,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setchooseSupervisor("");
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "update"
            ? "User updated successfully!"
            : "User created successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <RedModal
        open={deleteModalState}
        onClose={() => {setdeleteModalState(false);navigate(-1)}}
        onClick={() => {
          setdeleteModalState(false);
          if(params?.type === "update") {UpdateUser()}else{CreateUsers()};
        }}
        title="Confirmation"
        mainText={"Do you want to save it"}
        subText={""}
        
        actionText={"Yes"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "update"
              ? "Update User"
              : params?.type === "view"
              ? "View User"
              : "Create User"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "update"
                      ? "Update User"
                      : params?.type === "view"
                      ? "View User"
                      : "Create User"
                  }
                  btnTitle={params?.type === "update" ? "Update" : "Create"}
                  onClick={params?.type === "update" ? UpdateUser : CreateUsers}
                  noBtns={params?.type === "view" ? true : false}
                  cancelFunc={() => params?.type === "view"?navigate(-1):setdeleteModalState(true)}
                />
                {/* Form */}
                <Box px={isSmallScreen ? 5 : 15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          mb: 2,
                        }}
                      >
                        {usersData?.avatar ? (
                          <Avatar
                            alt="Remy Sharp"
                            sx={{
                              width: 125,
                              height: 125,
                              borderRadius: 360,
                              backgroundColor: "transparent",
                              border: "2px solid #EBEFF5",
                              p: 0.5,
                              cursor: "pointer",
                            }}
                            onClick={handleBrowseClick}
                          >
                            <img
                              src={usersData?.avatar}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt="Avatar"
                            />
                          </Avatar>
                        ) : (
                          <>
                            {avatarLink ? (
                              <Avatar
                                alt="Remy Sharp"
                                src={avatarLink}
                                sx={{
                                  width: 125,
                                  height: 125,
                                  border: "2px solid #EBEFF5",
                                  p: 2,
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                onClick={handleBrowseClick}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                                onClick={handleBrowseClick}
                              >
                                <AccountCircle sx={{ fontSize: 100 }} />
                              </Avatar>
                            )}
                          </>
                        )}
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => {
                            dataURItoBlob(e.target.files[0]);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={params?.type === "update" ? 6 : 12}>
                      <CustomFormInput
                        value={usersData.fullName}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            fullName: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Full Name"
                        Icon={PersonOutlined}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={usersData.email}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Email"
                        Icon={MailOutline}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={usersData.mobile}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            mobile: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Mobile Number"
                        Icon={PhoneIphoneOutlined}
                      />
                    </Grid>
                    {params?.type === "create" && (
                      <Grid item xs={6}>
                        <CustomFormInput
                          password
                          value={usersData.password}
                          setValue={(value) =>
                            setusersData((prevData) => ({
                              ...prevData,
                              password: value,
                            }))
                          }
                          placeholder={"Password"}
                          Icon={Password}
                        />
                      </Grid>
                    )}
                    {/* <Grid item xs={6}>
                      <CreateDropDown
                        value={usersData.gender}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            gender: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        title={"Gender"}
                        data={genders}
                        Icon={Wc}
                      />
                    </Grid> */}
                    {/* {isDoctor && (
                      <Grid item xs={12}>
                        <CreateDropDown
                          value={usersData.doctorType}
                          setValue={(value) =>
                            setusersData((prevData) => ({
                              ...prevData,
                              doctorType: value,
                            }))
                          }
                          title="Doctor Type"
                          Icon={HelpOutline}
                          data={[
                            {
                              label: "Therapist",
                              value: "Therapist",
                            },
                            {
                              label: "Counselor",
                              value: "Counselor",
                            },
                            {
                              label: "Psychologist",
                              value: "Psychologist",
                            },
                            {
                              label: "Psychiatric Nurse Practitioner",
                              value: "Psychiatric Nurse Practitioner",
                            },
                            {
                              label: "Occupational Therapist",
                              value: "Occupational Therapist",
                            },
                            {
                              label: "Art Therapist",
                              value: "Art Therapist",
                            },
                            {
                              label: "Mental Health Counselor",
                              value: "Mental Health Counselor",
                            },
                            {
                              label: "Psychiatric",
                              value: "Psychiatric",
                            },
                            {
                              label: "Psychiatrist Assistant",
                              value: "Psychiatrist Assistant",
                            },
                          ]}
                        />
                      </Grid>
                    )} */}
                    {/* {params?.type !== "update" && (
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: colors.text,
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          Roles:
                        </Typography>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="doctor"
                              control={<Radio />}
                              label="Doctor"
                            />
                            <FormControlLabel
                              value="patient"
                              control={<Radio />}
                              label="Patient"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )} */}
                    <Grid item xs={12}>
                      <CreateDropDown
                        value={chooseSupervisor}
                        setValue={(value) => {
                          setchooseSupervisor(value);
                          setsupervisorSiteId(
                            supervisorData?.find((sup) => sup?.value === value)
                              ?.siteId
                          );
                          setsupervisorId(
                            supervisorData?.find((sup) => sup?.value === value)
                              ?.supervisorId
                          );
                        }}
                        title="Choose Supervisor"
                        noIcon
                        readOnly={params?.type === "view" ? true : false}
                        data={supervisorData || []}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateUserDrPatient;

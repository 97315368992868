import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Button,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Stack,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Fade,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import { CreateDropDown } from "../../../components/CreatePagesComponents";
import {
  Check,
  Checklist,
  Close,
  East,
  EventRepeat,
  MoreVert,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { colors } from "../../../theme/theme";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import InactiveSwitch from "../../../components/InactiveSwitch";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import moment from "moment";
import {
  getAllFormsForSupervisor,
  getAllQuestionsForSupervisor,
} from "../../../redux/dispatchers/supervisor.dispatcher";
import dayjs from "dayjs";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {},
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {},
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#fff",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #F1F2F4",
  boxShadow: "0px 2px 5px 0px #53B48314;",
  ...(ownerState.active && {
    border: "2px solid #B9E0CD",
    boxShadow: "0px 2px 5px 0px #53B48314;",
  }),
  ...(ownerState.completed && {
    border: "2px solid #B9E0CD",
    boxShadow: "0px 2px 5px 0px #53B48314;",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, inActive, className } = props;

  const icons = {
    1: "1",
    2: "2",
    3: "3",
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, inActive }}
      className={className}
    >
      {!completed ? (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          color={"#000"}
          fontSize={20}
          fontWeight={"bold"}
        >
          {icons[String(props.icon)]}
          <Typography sx={{ fontSize: "10px", color: "#000", mt: -0.5 }}>
            Step
          </Typography>
        </Stack>
      ) : (
        <Check sx={{ color: colors.green }} />
      )}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const CreateHeader = ({
  title,
  onClick,
  btnTitle,
  currentStep,
  activeStep,
  setActiveStep,
  error,
  setError,
  setErrorMsg,
  errorMsg,
  patientId,
  selectedForms,
  selectedDate,
  Assign,
  type,
  activityType,
  selectedEndDate,
}) => {
  const navigate = useNavigate();
  const steps = ["1 Step", "2 Step", "3 Step"];
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    isLastStep() && allStepsCompleted()
      ? Assign()
      : setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    if (activeStep === 0 && (patientId === "" || activityType === "")) {
      console.log(activityType, "activityType");
      setError(true);
      setErrorMsg(
        `Please select ${
          patientId === "" ? "a patient" : "an activity type"
        } to assign the activity to`
      );
      return;
    } else if (activeStep === 1 && selectedForms.length === 0) {
      setError(true);
      setErrorMsg(`Please select a activity to assign to the patient`);
      return;
    } else if (
      activeStep === 2 &&
      (selectedDate === null || selectedEndDate === null)
    ) {
      setError(true);
      setErrorMsg(
        `Please select a start date and end date to assign the activity to the patient`
      );
      return;
    } else {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    }
  };

  return (
    <Box
      sx={{
        pb: 2,
        borderBottom: "1px solid #F1F2F4",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        px={2}
        pt={2}
      >
        {/* Title */}
        <Grid item>
          <Typography
            variant="h6"
            style={{
              color: colors.text,
              fontWeight: "500",
              fontSize: 18,
            }}
          >
            {title}
          </Typography>
        </Grid>

        {/* Buttons */}
        <Grid
          item
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Stepper
                nonLinear
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Button
              variant="contained"
              sx={{
                height: "40px",
                width: { xs: "50%", sm: "25%" },
                border: "1px solid #EBEFF5",
                backgroundColor: "#FAFAFA",
                textTransform: "none",
                borderRadius: "8px",
                color: colors.text,
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "FiraSans",
                boxShadow: "0px 1px 1px 0px #0000001F",
                "&:hover": {
                  boxShadow: "0px 0px 0px 1px #676E7629",
                  backgroundColor: "#FAFAFA",
                },
                mr: 1,
              }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                height: "40px",
                width: { xs: "50%", sm: "25%" },
                backgroundColor: colors.green,
                textTransform: "none",
                borderRadius: "8px",
                px: 3,
                boxShadow: "0px 1px 1px 0px #0000001F",
                "&:hover": {
                  boxShadow: "0px 0px 0px 1px #676E7629",
                  backgroundColor: colors.green,
                },
              }}
              onClick={handleComplete}
            >
              Next
              <East sx={{ ml: 1 }} />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const AssignActivityPatient = () => {
  const [value, setValue] = useState("");

  const [activeStep, setActiveStep] = React.useState(0);

  const [confirmation, setconfirmation] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [currentStep, setCurrentStep] = useState(1);
  const [prevStep, setPrevStep] = useState(1);

  const steps = ["Change Email", "Enter OTP", "Final Step"];

  const handleNextStep = () => {
    setPrevStep(currentStep);
    setCurrentStep((prevStep) =>
      prevStep < steps.length ? prevStep + 1 : prevStep
    );
  };

  const assignActColumn = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              color: colors.text,
              fontFamily: "FiraSans",
              ml: 1,
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },

    {
      field: "type",
      headerName: "Activity Type",
      flex: 1.5,
      renderCell: (params) => (
        <div
          style={{
            marginLeft: 10,
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params?.value?.charAt(0).toUpperCase() + params?.value?.slice(1)}
        </div>
      ),
    },
  ];

  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const location = useLocation();
  const params = useParams();
  const [selectedPatient, setselectedPatient] = useState(
    location?.state?.name || ""
  );
  const [activityType, setactivityType] = useState("");
  const [activityFrequency, setactivityFrequency] = useState("");
  const [patientId, setpatientId] = useState(location?.state?.id || "");
  const { allPatients } = useSelector((state) => state.supervisor);
  const { allAssignedPatients } = useSelector((state) => state.provider);
  const { userData } = useSelector((state) => state.user);

  const patientData = location?.state?.provider
    ? allAssignedPatients?.map((patient) => ({
        label: patient?.patients?.name,
        value: patient?.patients?.name,
        id: patient?.patients?.id,
      }))
    : allPatients?.map((patient) => ({
        label: patient.name,
        value: patient.name,
        id: patient.id,
      }));

  const [loading, setloading] = useState(false);
  const [allActivities, setallActivities] = useState([]);

  const GetAllActivities = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/activity/getAllActivityByCustomerId?customerId=${userData?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setallActivities(res.data?.allFormsAndActivities);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    GetAllActivities();
  }, []);

  const [activeSwitch, setactiveSwitch] = useState(true);

  const activeOrInactiveActivities = allActivities?.filter((val) => {
    return val?.status === (activeSwitch ? "active" : "inactive");
  });

  const filteredActivities = activeOrInactiveActivities?.filter((val) =>
    val?.name?.toLocaleLowerCase()?.includes(value?.toLocaleLowerCase())
  );

  const navigate = useNavigate();

  const [selectedForms, setselectedForms] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const AssignForm = async () => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/patient_form/assignFormToPatient`,
        {
          patientId: patientId,
          formId: selectedForms[0]?.id,
          siteId: userData?.siteId,
          customerId: userData?.customerId,
          assignedBy: userData?.id,
          start_date: dayjs(selectedDate).format("YYYY-MM-DD"),
          end_date: dayjs(selectedEndDate).format("YYYY-MM-DD"),
          status: "pending",
          frequency: activityFrequency?.toLowerCase(),
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        setpatientId("");
        setselectedPatient("");
        setSelectedDate(null);
        setSelectedEndDate(null);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const AssignActivity = async () => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/patient_activity/assignActivityToPatient`,
        {
          patientId: patientId,
          activityId: selectedForms[0]?.id,
          siteId: userData?.siteId,
          customerId: userData?.customerId,
          assignedBy: userData?.id,
          start_date: dayjs(selectedDate).format("YYYY-MM-DD"),
          end_date: dayjs(selectedEndDate).format("YYYY-MM-DD"),
          status: "pending",
          frequency: activityFrequency?.toLowerCase(),
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        setpatientId("");
        setselectedPatient("");
        setSelectedDate(null);
        setSelectedEndDate(null);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const activityTypes = [
    {
      id: 1,
      label: "Daily Check-In Questions",
      value: "Daily Check-In Questions",
    },
    {
      id: 2,
      label: "Daily Activity",
      value: "Daily Activity",
    },
    {
      id: 3,
      label: "Form",
      value: "Form",
    },
  ];

  const activityFrequencyData = [
    {
      id: 1,
      label: "Daily",
      value: "Daily",
    },
    {
      id: 2,
      label: "Weekly",
      value: "Weekly",
    },
    {
      id: 3,
      label: "Bi-Weekly",
      value: "Bi-Weekly",
    },
    {
      id: 4,
      label: "Monthly",
      value: "Monthly",
    },
  ];

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <MenuDrawer
        supervisor={location?.state?.supervisor ? true : false}
        provider={location?.state?.provider ? true : false}
      />

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={`Activity has been assigned to the patient successfully!`}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
          opacity: currentStep !== prevStep ? 0 : 1,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        <AppBar title={"Assign Activity"} />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                  opacity: currentStep !== prevStep ? 0 : 1,
                  transition: "opacity 0.5s ease-in-out",
                }}
              >
                <CreateHeader
                  title={"Assign Activity"}
                  btnTitle={"Next"}
                  onClick={handleNextStep}
                  currentStep={currentStep}
                  steps={steps}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  error={error}
                  setError={seterror}
                  setErrorMsg={seterrorMsg}
                  errorMsg={errorMsg}
                  patientId={patientId}
                  selectedForms={selectedForms}
                  Assign={activityType === "Form" ? AssignForm : AssignActivity}
                  selectedDate={selectedDate}
                  selectedEndDate={selectedEndDate}
                  type={params?.type}
                  activityType={activityType}
                />

                {/* Form based on step */}
                <Box px={15} py={5}>
                  {activeStep === 0 && (
                    <Fade in={activeStep === 0}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CreateDropDown
                            value={selectedPatient}
                            setValue={(e) => {
                              setselectedPatient(e);
                              const patient = location?.state?.provider
                                ? allAssignedPatients.find(
                                    (patient) => patient?.patients?.name === e
                                  )?.patients?.id
                                : allPatients.find(
                                    (patient) => patient?.name === e
                                  )?.id;
                              setpatientId(patient);
                            }}
                            title="Choose Patient"
                            Icon={PersonOutlineOutlined}
                            data={patientData || []}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CreateDropDown
                            value={activityType}
                            setValue={setactivityType}
                            title="Activity Type"
                            Icon={Checklist}
                            data={activityTypes}
                          />
                        </Grid>
                      </Grid>
                    </Fade>
                  )}
                  {activeStep === 1 && (
                    <Fade in={activeStep === 1}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CreateDropDown
                            value={selectedPatient}
                            setValue={(e) => {
                              setselectedPatient(e);
                              const patient = location?.state?.provider
                                ? allAssignedPatients.find(
                                    (patient) => patient?.patients?.name === e
                                  )?.patients?.id
                                : allPatients.find(
                                    (patient) => patient?.name === e
                                  )?.id;
                              setpatientId(patient);
                            }}
                            title="Choose Patient"
                            Icon={PersonOutlineOutlined}
                            data={patientData || []}
                          />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <InactiveSwitch
                            activeSwitch={activeSwitch}
                            handleactiveSwitch={handleactiveSwitch}
                            title={"Forms"}
                          />
                        </Grid> */}

                        <Grid item xs={12}>
                          <Box
                            mb={2}
                            sx={{
                              backgroundColor: "#FAFAFA",
                              borderRadius: "12px",
                              p: 2,
                            }}
                          >
                            <FormControl fullWidth>
                              <OutlinedInput
                                sx={{
                                  height: "40px",
                                  borderRadius: "9px",
                                  backgroundColor: "#fff",
                                  elevation: 0,
                                  fontFamily: "FiraSans",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: colors.text,
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(241, 242, 244, 1)",
                                  },
                                  "& input::placeholder": {
                                    color: colors.text,
                                    opacity: 1,
                                    fontFamily: "FiraSans",
                                  },
                                }}
                                placeholder={"Search"}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <SearchIcon sx={{ color: colors.text }} />
                                  </InputAdornment>
                                }
                              />
                            </FormControl>

                            <StyledDataGrid
                              rows={
                                filteredActivities?.filter(
                                  (val) => val?.type === activityType
                                ) || []
                              }
                              columns={assignActColumn}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 10 },
                                },
                              }}
                              sx={{
                                borderRadius: "12px",
                                border: "none",
                                "& .MuiDataGrid-virtualScroller": {
                                  minHeight: "50vh",
                                },
                                cursor: "pointer",
                              }}
                              pageSizeOptions={[5, 10]}
                              getRowId={(row) => row?.uniqId}
                              onRowSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRows = filteredActivities?.filter(
                                  (row) => selectedIDs.has(row?.uniqId)
                                );
                                setselectedForms(selectedRows);
                                // if (params?.type === "activity") {
                                // } else if (params?.type === "form") {
                                //   const selectedRows = filteredForms?.filter(
                                //     (row) => selectedIDs.has(row?.form?.id)
                                //   );
                                //   setselectedForms(selectedRows);
                                // } else if (params?.type === "question") {
                                //   const selectedRows =
                                //     filteredQuestions?.filter((row) =>
                                //       selectedIDs.has(row?.question?.id)
                                //     );
                                //   setselectedForms(selectedRows);
                                // }
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Fade>
                  )}
                  {activeStep === 2 && (
                    <Fade in={activeStep === 2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CreateDropDown
                            value={selectedPatient}
                            setValue={(e) => {
                              setselectedPatient(e);
                              const patient = location?.state?.provider
                                ? allAssignedPatients.find(
                                    (patient) => patient?.patients?.name === e
                                  )?.patients?.id
                                : allPatients.find(
                                    (patient) => patient?.name === e
                                  )?.id;
                              setpatientId(patient);
                            }}
                            title="Choose Patient"
                            Icon={PersonOutlineOutlined}
                            data={patientData || []}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ p: 2 }}>
                            <Typography sx={{ pb: 2 }}>
                              {params?.type === "form"
                                ? "Selected Forms:"
                                : params?.type === "activity"
                                ? "Selected Activities:"
                                : "Selected Questions:"}
                            </Typography>

                            {selectedForms?.map((form) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ mb: 1 }}
                              >
                                {activityType === "Form" ? (
                                  <img
                                    src={require("../../../assets/icons/pdf.png")}
                                    alt=""
                                    style={{ height: 32, width: 32 }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../assets/icons/activity.png")}
                                    alt=""
                                    style={{ height: 32, width: 32 }}
                                  />
                                )}
                                <Typography sx={{ ml: 2 }}>
                                  {form?.name}
                                </Typography>
                              </Stack>
                            ))}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Start Date"
                              sx={{
                                width: "100%",
                                borderRadius: "7px",
                                backgroundColor: "#FAFAFA",
                                fontFamily: "FiraSans",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: colors.text,
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(241, 242, 244, 1)",
                                },
                                "& input::placeholder": {
                                  color: colors.text,
                                  opacity: 1,
                                  fontFamily: "FiraSans",
                                },
                                "& .MuiSvgIcon-root": {
                                  fill: "black !important",
                                },
                              }}
                              placeholder="Select Start Date"
                              value={selectedDate}
                              onChange={(newValue) => {
                                setSelectedDate(newValue);
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="End Date"
                              sx={{
                                width: "100%",
                                borderRadius: "7px",
                                backgroundColor: "#FAFAFA",
                                fontFamily: "FiraSans",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: colors.text,
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(241, 242, 244, 1)",
                                },
                                "& input::placeholder": {
                                  color: colors.text,
                                  opacity: 1,
                                  fontFamily: "FiraSans",
                                },
                                "& .MuiSvgIcon-root": {
                                  fill: "black !important",
                                },
                              }}
                              value={selectedEndDate}
                              onChange={(newValue) =>
                                setSelectedEndDate(newValue)
                              }
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                          <CreateDropDown
                            value={activityFrequency}
                            setValue={setactivityFrequency}
                            title="Activity Frequency"
                            Icon={EventRepeat}
                            data={activityFrequencyData}
                          />
                        </Grid>
                      </Grid>
                    </Fade>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AssignActivityPatient;

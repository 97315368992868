import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Snackbar,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import AppBar from "../../../components/AppBar";
import {
  colors,
  getCustomerStatusBackgroundColor,
  getStatusBackgroundColor,
  getStatusTextColor,
} from "../../../theme/theme";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { RedModal } from "../../../components/ConfirmationModal";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TitleWithSearchBar from "./../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import moment from "moment";
import {
  getAllCustomers,
  getCustomerById,
} from "../../../redux/dispatchers/vendor.dispatcher";
import { useDispatch, useSelector } from "react-redux";
import { AdminPanelSettings } from "@mui/icons-material";

const Customers = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const [customerId, setcustomerId] = useState("");
  const [customerName, setcustomerName] = useState("");

  const [deleteModalState, setdeleteModalState] = useState(false);
  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setdeleteConfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const smallScreen = useMediaQuery("(max-width:650px)");

  const customerColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={params.row.image}
            alt=""
            style={{ height: 32, width: 32 }}
          /> */}
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
              {params.row?.organization_name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EmailOutlinedIcon
                style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#596066",
                  fontFamily: "FiraSans",
                  ml: 0.5,
                }}
              >
                {params.row?.email}
              </Typography>
            </Box>
          </Box>
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Phone",
      flex: 1.25,
      minWidth: smallScreen ? 150 : 0,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PhoneIphoneOutlinedIcon
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: smallScreen ? 200 : 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",

              py: 1,
            }}
            style={{
              textAlign: "center",
              backgroundColor: getCustomerStatusBackgroundColor(
                params?.value?.toLocaleLowerCase()
              ),
              color: getStatusTextColor(params?.value?.toLocaleLowerCase()),

              borderRadius: "8px",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "sitesNumber",
      headerName: "Sites",
      flex: 1,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <WorkOutlineOutlinedIcon
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              textDecoration: "underline",
              ml: 1,
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/vendor/customers/customer-site", {
                state: {
                  customerId: params?.row?.id,
                  customerName: params?.row?.organization_name,
                },
              })
            }
          >
            {params?.row?.sites?.length} Sites{" "}
            {/* <KeyboardArrowDownOutlinedIcon
              sx={{ height: "16px", width: "16px", color: "#9EA5AD" }}
            /> */}
          </Typography>
        </Box>
      ),
    },
    {
      field: "users",
      headerName: "Admins",
      flex: 1,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AdminPanelSettings
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              textDecoration: "underline",
              ml: 1,
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/vendor/customers/admins", {
                state: { customerId: params?.row?.id },
              })
            }
          >
            {params?.value?.length} Admin
          </Typography>
        </Box>
      ),
    },
    {
      field: "countryFlag",
      headerName: "Country",
      flex: 1,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={`https://flagcdn.com/w80/${params?.row?.countryCode}.png`}
            alt=""
            style={{ borderRadius: "5px", width: "40px" }}
          />
        </Box>
      ),
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              textDecoration: "underline",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1.25,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {moment(params?.value).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1.25,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <>
          {/* <IconButton onClick={() => navigate("/vendor/customers/profile")}>
            <VisibilityOutlinedIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton> */}
          <IconButton
            onClick={() => {
              navigate("/vendor/customers/update", {
                state: { id: 1, edit: true, data: params.row?.id },
              });
            }}
          >
            <EditOutlinedIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              handleClick(e);
              setcustomerId(params.row?.id);
              setcustomerName(params.row?.organization_name);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const [loading, setloading] = useState(false);

  const DeleteCustomers = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/customer/updateCustomerStatus`,
        {
          customerId: customerId,
          status: "Closed",
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdeleteConfirmation(true);
        dispatch(getAllCustomers());
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCustomers());
  }, []);

  const { allCustomers } = useSelector((state) => state.vendor);
  const [search, setsearch] = useState("");

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={DeleteCustomers}
        title="Delete Customer"
        mainText="Are you sure you want to delete this customer?"
        subText="This action cannot be undone."
      />

      <Snackbar
        open={deleteConfirmation}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="Customer deleted successfully!"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <AppBar title="Customers" />

        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                width: "100%",
                overflowX: "auto",
              }}
            >
              <TitleWithSearchBar
                title="Customers"
                onClick={() => {
                  navigate("/vendor/customers/create");
                }}
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  allCustomers?.filter((val) =>
                    val?.organization_name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={customerColumns}
                getRowId={(row) => row?.id}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10, { value: allCustomers?.length, label: 'All' }]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
                rowSelection={false}
              />

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    borderBottom: "1px solid #EBEFF5",
                    color: "#24292E",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();
                    navigate("/vendor/customers/update", {
                      state: { id: 1, edit: true, data: customerId },
                    });
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  sx={{
                    borderBottom: "1px solid #EBEFF5",
                    color: "#24292E",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();

                    navigate("/vendor/customers/customer-site", {
                      state: {
                        customerId: customerId,
                        customerName: customerName,
                      },
                    });
                  }}
                >
                  Customer Sites
                </MenuItem>
                <MenuItem
                  sx={{
                    borderBottom: "1px solid #EBEFF5",
                    color: "#24292E",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() =>
                    navigate("/vendor/customers/admins", {
                      state: {
                        customerId: customerId,
                        customerName: customerName,
                      },
                    })
                  }
                >
                  Admins
                </MenuItem>
                <MenuItem
                  sx={{
                    color: "#F34141",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={() => {
                    handleClose();
                    setdeleteModalState(true);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Customers;

import {
  AssignmentTurnedInOutlined,
  Close,
  Groups3Outlined,
  LanguageOutlined,
  MailOutline,
  MapOutlined,
  PhoneOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../constants/config";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import { customerStatus } from "../../../assets/data/DummyData";
import { useSelector } from "react-redux";
import { ErrorSnackbar } from "../../../components/CustomSnackbar";
import { colors } from "../../../theme/theme";

const CreateSitesVendor = () => {
  const params = useParams();
  const navigation = useNavigate();

  const location = useLocation();

  const [confirmation, setconfirmation] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const { userData } = useSelector((state) => state.user);
  const [loading, setloading] = useState(false);
  const [formData, setFormData] = useState({
    site_name: "",
    email: "",
    phone: "",
    address: "",
    totalBeds: "",
    totalFacilities: "",
    status: "",
    country: "",
    countryCode: "",
    postalCode:"",
    city: "",
    state:""
  });

  const [customerId, setcustomerId] = useState(
    location?.state?.customerId || ""
  );

  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const CreateCustomerSite = async () => {
    if (
      formData.site_name === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.address === "" ||
      formData.status === "" ||
      formData.country === "" ||
      customerId === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/site/createSite`,
          {
            customerId: customerId,
            site_name: formData.site_name,
            email: formData.email,
            mobile: formData.phone,
            address: formData.address,
            beds: formData.totalBeds,
            facilities: formData.totalFacilities,
            status: formData.status,
            created_by: userData?.id,
            country: formData.country,
            postalCode:formData.postalCode,
            countryCode: formData.countryCode?.toLocaleLowerCase(),
            state: formData.state,
            city: formData.city,

          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setTimeout(() => {
            navigation(-1);
          }, 2000);
          setFormData({
            site_name: "",
            email: "",
            phone: "",
            address: "",
            totalBeds: "",
            totalFacilities: "",
            status: "",
            country: "",
            countryCode: "",
            postalCode:"",
            state: "",
            city: "",

          });
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const UpdateCustomerSite = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/site/editSite`,
        {
          siteId: location?.state?.id,
          site_name: formData.site_name,
          email: formData.email,
          mobile: formData.phone,
          address: formData.address,
          beds: formData.totalBeds,
          facilities: formData.totalFacilities,
          status: formData.status,
          countryCode: formData.countryCode?.toLocaleLowerCase(),
          country: formData.country,
          created_by: userData?.id,
          postalCode:formData.postalCode,
          state: formData.state,
          city: formData.city,

        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigation(-1);
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://api.countrystatecity.in/v1/countries",
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const transformedArray = response.data.map((country) => ({
        label: country.name,
        value: country.name,
        code: country.iso2,
      }));
      setCountries(transformedArray);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const GetCustomerSiteById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/site/getOneSite?siteId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleSite = res.data?.oneSite;
        setcustomerId(singleSite?.customerId);
        setFormData({
          site_name: singleSite?.site_name,
          email: singleSite?.email,
          phone: singleSite?.mobile,
          address: singleSite?.address,
          totalBeds: singleSite?.beds,
          totalFacilities: singleSite?.facilities,
          status: singleSite?.status,
          country: singleSite?.country,
          countryCode: singleSite?.countryCode,
          postalCode:singleSite?.postalCode,
          city: singleSite?.city,
          state: singleSite?.state,

        });
      })
      .catch((e) => {
        setloading(false);
      });
  };
  const [cities, setcities] = useState([]);
  const [states, setstates] = useState([]);
  const [countryCode, setcountryCode] = useState("");

  const fetchStatesByCountries = async (name) => {
    const country = countries?.find((country) => country.value === name);
    const code = params?.type === "update" ? name : country?.code;
    try {
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${code}/states`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const transformedArray = response.data.map((state) => ({
        label: state.name,
        value: state.name,
        code: state.iso2,
      }));
      setcountryCode(country?.code);

      setstates(transformedArray);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const fetchCitiesByStatesAndCountries = async (code) => {
    const statesCode = states?.find((state) => state.value === code);
    const counCode =
      params?.type === "update" ? formData?.countryCode : countryCode;

    try {
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${counCode}/states/${statesCode?.code}/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const transformedArray = response.data.map((country) => ({
        label: country.name,
        value: country.name,
      }));
      setcities(transformedArray);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  useEffect(() => {
    fetchCountries();

    if (params?.type === "update") {
      GetCustomerSiteById();
    }
  }, []);

  const { allCustomers } = useSelector((state) => state.vendor);

  const [open, setOpen] = React.useState(false);

  const [personName, setPersonName] = React.useState(
    location?.state?.customerName || ""
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(value);
  };

  const filteredCustomers = allCustomers?.map((cust) => ({
    label: cust?.organization_name,
    value: cust?.organization_name,
    customerId: cust?.id,
  }));

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ErrorSnackbar
        error={error}
        handleCloseError={seterror}
        errorMsg={errorMsg}
      />
      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          params?.type === "update"
            ? "Customer Site Updated Successfully!"
            : "Customer Site Created Successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "update"
              ? "Update Customer Site"
              : "Create Customer Site"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "update"
                      ? "Update Customer Site"
                      : "Create New Customer Site"
                  }
                  btnTitle={params?.type === "update" ? "Update" : "Create"}
                  onClick={() => {
                    if (params?.type === "update") {
                      UpdateCustomerSite();
                    } else {
                      CreateCustomerSite();
                    }
                  }}
                  cancelFunc={() => navigation(-1)}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    {params?.type !== "update" && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Select
                            displayEmpty
                            value={personName}
                            onChange={handleChange}
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            MenuProps={{
                              onClose: () => setOpen(false),
                            }}
                            input={<OutlinedInput />}
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                fontFamily: "FiraSans",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#000",
                              },
                            }}
                            renderValue={(selected) => {
                              if (selected === "") {
                                return (
                                  <em
                                    style={{
                                      fontStyle: "normal",
                                      fontSize: "14px",
                                      color: "#000",
                                    }}
                                  >
                                    Select Customer*
                                  </em>
                                );
                              }

                              return selected;
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                              height: "50px",
                              borderRadius: "7px",
                              backgroundColor: "#FAFAFA",
                              elevation: 0,
                              fontFamily: "FiraSans",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: colors.text,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EBEFF5",
                              },
                              "& input::placeholder": {
                                color: colors.text,
                                opacity: 1,
                                fontFamily: "FiraSans",
                              },
                              ".MuiSvgIcon-root ": {
                                fill: "black !important",
                              },
                            }}
                          >
                            {filteredCustomers?.map((option) => (
                              <MenuItem
                                style={{
                                  textTransform: "capitalize",
                                }}
                                key={option.value}
                                value={option.value}
                                onClick={() => {
                                  setcustomerId(option.customerId);
                                  setOpen(false);
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={formData.site_name}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            site_name: value,
                          }))
                        }
                        placeholder="Site Name*"
                        Icon={WorkOutlineOutlined}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.email}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        placeholder="Email*"
                        Icon={MailOutline}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.phone}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            phone: value,
                          }))
                        }
                        placeholder="Phone"
                        Icon={PhoneOutlined}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CreateDropDown
                        value={formData.status}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            status: value,
                          }))
                        }
                        title="Status*"
                        data={customerStatus}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.totalBeds}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            totalBeds: value,
                          }))
                        }
                        placeholder="No. Of Beds"
                        Icon={AssignmentTurnedInOutlined}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={formData.address}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            address: value,
                          }))
                        }
                        placeholder="Site Address*"
                        Icon={MapOutlined}
                      />
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.totalFacilities}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            totalFacilities: value,
                          }))
                        }
                        placeholder="Total Facilities"
                        Icon={Groups3Outlined}
                      />
                    </Grid> */}

                    <Grid item xs={12} sm={4}>
                      <CreateDropDown
                        value={formData.country}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            country: value,
                            countryCode: countries?.find(
                              (country) => country.value === value
                            )?.code,
                          }));
                          fetchStatesByCountries(value);

                        }}
                        title="Country*"
                        data={countries}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* <CustomFormInput
                        value={formData.state}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            state: value,
                          }));
                        }}
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Province/State*"
                        noIcon
                      /> */}

                      <CreateDropDown
                        value={formData.state}
                        setValue={(value) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            state: value,
                          }));
                          fetchCitiesByStatesAndCountries(value);
                        }}
                        title="Province/State"
                        data={states}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {/* <CustomFormInput
                        value={formData.city}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            city: value,
                          }));
                        }}
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="City*"
                        noIcon
                      /> */}

                      <CreateDropDown
                        value={formData.city}
                        setValue={(value, code) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            city: value,
                          }));
                        }}
                        title="City"
                        data={cities}
                        noIcon
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.postalCode}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            postalCode: value,
                          }))
                        }
                        placeholder="Postal Code"
                        Icon={AssignmentTurnedInOutlined}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateSitesVendor;

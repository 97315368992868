import {
  AccountCircle,
  Close,
  HelpOutline,
  MailOutline,
  Password,
  PersonOutline,
  PersonOutlined,
  PhoneIphoneOutlined,
  Wc,
  PeopleAltOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateDropDown,
  CreateDropDownMultiple,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import { customerStatus, genders, roles } from "../../../assets/data/DummyData";
import axios from "axios";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { colors } from "../../../theme/theme";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminCustomerSites } from "../../../redux/dispatchers/admin.dispatcher";
import { RedModal } from "../../../components/ConfirmationModal";
import { getUserById } from "../../../redux/dispatchers/login";

const CreateUsers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [backupDoctorData, setBackupDoctorData] = useState([]);

  const [rolesData, setrolesData] = useState([]);

  const [chooseSupervisor, setchooseSupervisor] = useState("");
  const [supervisorId, setsupervisorId] = useState("");

  const [chooseBackup, setchooseBackup] = useState("");

  const [supervisorSiteId, setsupervisorSiteId] = useState("");

  const [confirmation, setconfirmation] = useState(false);

  const { userData } = useSelector((state) => state.user);

  const [backupDrId, setbackupDrId] = useState();

  const [usersData, setusersData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    password: "",
    gender: "",
    avatar: "",
    doctorType: "",
    status: "",
    siteId: "",
  });

  const [userSite, setuserSite] = useState("")

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [isAdmin, setisAdmin] = useState(false);
  const [isSupervisor, setisSupervisor] = useState(false);
  const [isDoctor, setisDoctor] = useState(false);

  const handleChangeSupervisor = (event) => {
    setisSupervisor(event.target.checked);
  };

  const handleChangeAdmin = (event) => {
    setisAdmin(event.target.checked);
  };

  const handleChangeDoctor = (event) => {
    setisDoctor(event.target.checked);
  };

  const [avatarLink, setavatarLink] = useState("");
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        setloading(false);
        setusersData((prevData) => ({
          ...prevData,
          avatar: res.data?.url,
        }));
        setavatarLink(res.data?.url);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const CreateUsers = async () => {
    if(rolesData?.includes("Supervisor"))
      {
        if(!usersData?.siteId)
        {
          setloading(false);
          seterror(true);
          seterrorMsg("Site is a required field.");
          return;
        }
      }
    if (
      usersData.fullName === "" ||
      usersData.email === "" ||
      usersData.password === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
      return;
    } else {
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/user/createUserAdmin`,
          {
            user: {
              name: usersData.fullName,
              password: usersData.password,
              mobile: usersData.mobile,
              email: usersData.email,
              dob: null,
              gender: usersData.gender,
              avatar: usersData.avatar,
              createdBy: userData?.id,
              customerId: userData?.customerId,
              isAdmin: rolesData?.includes("Admin"),
              isSupervisor: rolesData?.includes("Supervisor"),
              isDoctor: rolesData?.includes("Provider"),
              doctorType: usersData.doctorType,
              verified: usersData.status === "Active" ? true : false,
              siteId: usersData?.siteId?usersData?.siteId:null,
            },
          },
          {
            withCredentials: true,
          }
        )
        .then(async (res) => {
          if(supervisorId)
          {
            await AssignDr(res.data.id);
          }
          setloading(false);
          setconfirmation(true);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        })
        .catch((e) => {
          seterror(true);
          seterrorMsg(`${e?.response?.data?.err?.errors[0]?.message}`);
              setloading(false);
        });
    }
  };

  const UpdateUser = async () => {
    setloading(true);
    if(rolesData?.includes("Supervisor"))
    {
      if(!usersData?.siteId)
      {
        setloading(false);
        seterror(true);
        seterrorMsg("Site is not assigned");
        return;
      }
    }
    await axios
      .post(
        `${BASE_URL}/user/updateUser`,
        {
          userId: location?.state?.id,
          name: usersData.fullName,
          mobile: usersData.mobile,
          email: usersData.email,
          gender: usersData.gender,
          avatar: usersData.avatar,
          isAdmin: rolesData?.includes("Admin"),
          isSupervisor: rolesData?.includes("Supervisor"),
          isDoctor: rolesData?.includes("Provider"),
          doctorType: usersData.doctorType,
          verified: usersData.status === "Active" ? true : false,
          siteId: usersData?.siteId?usersData?.siteId:null,
        },
        {
          withCredentials: true,
        }
      )
      .then(async (res) => {
        if(supervisorId)
        {
          await AssignDr(location?.state?.id);
        }
        if(userData?.siteId !== usersData?.siteId){

          dispatch(getUserById(userData?.id))
        }
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
        seterror(true);
        seterrorMsg(`${e?.response?.data?.err?.errors[0]?.message}`);

      });
  };


  const GetUserById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getSpecificUser?userId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleUser = res.data?.specificUser;
        setusersData((prevData) => ({
          ...prevData,
          fullName: singleUser?.name,
          email: singleUser?.email,
          mobile: singleUser?.mobile,
          gender: singleUser?.gender,
          avatar: singleUser?.avatar,
          doctorType: singleUser?.doctorType,
          siteId: singleUser.siteId,
          //status:singleUser?.verified
        }));
        setuserSite(siteData?.find(
          (s) => s?.id === singleUser?.siteId
        )?.site_name)
        var arr = [];
        if (singleUser.verified === true) {
          setusersData((prevData) => ({
            ...prevData,
            status: "Active",
          }));
        }
        if (singleUser.verified === false) {
          setusersData((prevData) => ({
            ...prevData,
            status: "Inactive",
          }));
        }
        if (singleUser?.isAdmin) {
          arr.push("Admin");
        }
        if (singleUser?.isSupervisor) {
          arr.push("Supervisor");
        }
        if (singleUser?.isDoctor) {
          arr.push("Provider");
        }
        setrolesData(arr);
        setisAdmin(singleUser?.isAdmin);
        setisSupervisor(singleUser?.isSupervisor);
        setisDoctor(singleUser?.isDoctor);
      })
      .catch((e) => {
        setloading(false);
      });
  };
  const handleBackupDoctorChange = (value) => {
    setchooseBackup(value);
    const selectedBackupDoctorId = doctorData?.find(
      (doc) => doc?.value === value
    )?.doctorId;
    setbackupDrId(selectedBackupDoctorId);
  };

  const [unassignedDoctors, setunassignedDoctors] = useState([]);

  const GetUnassignedDoctors = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/doctor_supervisor/getAllUnAssignedDoctors?customerId=${userData?.customerId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setunassignedDoctors(res.data?.allNotAssignedDoctors);
        updateBackupDoctorOptions(location?.state?.id);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const getSpecificDoctorWithSupervisor = async () => {
    setloading(true);
    if (location?.state?.id === undefined) {
      return;
    }
    await axiosInstance
      .get(
        `${BASE_URL}/doctor_supervisor/getSpecificDoctorWithSupervisor?doctorId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);

        setsupervisorId(res.data.allSupervisorsDoctors.supervisorId);
        setchooseSupervisor(res.data.allSupervisorsDoctors.supervisors.name);
        setbackupDrId(res.data?.allSupervisorsDoctors.backupDoctorId);
        // setunassignedDoctors(res.data?.allNotAssignedDoctors);
        updateBackupDoctorOptions(location?.state?.id);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const updateBackupDoctorOptions = (selectedDoctorId) => {
    const filteredDoctors = unassignedDoctors?.filter(
      (doctor) => doctor?.id !== selectedDoctorId
    );

    const backupDoctorOptions = filteredDoctors?.map((doctor) => ({
      value: doctor?.name,
      label: doctor?.name,
      doctorId: doctor?.id,
    }));

    setBackupDoctorData(backupDoctorOptions);
  };

  const doctorData = unassignedDoctors?.map((doctor) => ({
    value: doctor?.name,
    label: doctor?.name,
    doctorId: doctor?.id,
  }));

  const { allSupervisors } = useSelector((state) => state.admin);
  const filteredSupervisors = allSupervisors?.filter((val) => {
    return val?.verified === true
  });

  const supervisorData = filteredSupervisors?.map((supervisor) => ({
    value: supervisor?.name,
    label: supervisor?.name,
    supervisorId: supervisor?.id,
    siteId: supervisor?.siteId,
  }));

  const AssignDr = async (id) => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/doctor_supervisor/assignDoctorToSupervisor`,
        {
          doctorId: id,
          supervisorId: supervisorId,
          siteId: null,
          backupDoctorId: backupDrId || null,
          customerId: userData?.customerId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setbackupDrId("");
        setchooseBackup("");
        setchooseSupervisor("");
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const dispatch = useDispatch();

  var { siteData } = useSelector((state) => state.admin);
  const sd = siteData
  // ?.filter((val) => val?.user === null)
  .map((site) => ({
    value: site?.site_name,
    label: site?.site_name,
    siteId: site?.id,
  }));
  const [deleteModalState, setdeleteModalState] = React.useState(false);

  useEffect(() => {
    if (params?.type === "update" || params?.type === "view") {
      GetUserById();
      getSpecificDoctorWithSupervisor();
    }
    GetUnassignedDoctors();
    dispatch(getAllAdminCustomerSites(userData?.customerId));
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "update"
            ? "User updated successfully!"
            : "User created successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <RedModal
        open={deleteModalState}
        onClose={() => {setdeleteModalState(false);navigate(-1)}}
        onClick={() => {
          setdeleteModalState(false);
          if(params?.type === "update") {UpdateUser()}else{CreateUsers()};
        }}
        title="Confirmation"
        mainText={"Do you want to save it"}
        subText={""}
        
        actionText={"Yes"}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "update"
              ? "Update User"
              : params?.type === "view"
              ? "View User"
              : "Create User"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "update"
                      ? "Update User"
                      : params?.type === "view"
                      ? "View User"
                      : "Create User"
                  }
                  noBtns={params?.type === "view" ? true : false}
                  btnTitle={params?.type === "update" ? "Update" : "Create"}
                  onClick={params?.type === "update" ? UpdateUser : CreateUsers}
                  cancelFunc={() => {params?.type === "view"?navigate(-1):setdeleteModalState(true)}}
                />
                {/* Form */}
                <Box px={isSmallScreen ? 5 : 15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          mb: 2,
                        }}
                      >
                        {usersData?.avatar ? (
                          <Avatar
                            alt="Remy Sharp"
                            sx={{
                              width: 125,
                              height: 125,
                              borderRadius: 360,
                              backgroundColor: "transparent",
                              border: "2px solid #EBEFF5",
                              p: 0.5,
                              cursor: "pointer",
                            }}
                            onClick={handleBrowseClick}
                          >
                            <img
                              src={usersData?.avatar}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt="Avatar"
                            />
                          </Avatar>
                        ) : (
                          <>
                            {avatarLink ? (
                              <Avatar
                                alt="Remy Sharp"
                                src={avatarLink}
                                sx={{
                                  width: 125,
                                  height: 125,
                                  border: "2px solid #EBEFF5",
                                  p: 2,
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                onClick={handleBrowseClick}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                                onClick={handleBrowseClick}
                              >
                                <AccountCircle sx={{ fontSize: 100 }} />
                              </Avatar>
                            )}
                          </>
                        )}
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => {
                            dataURItoBlob(e.target.files[0]);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={params?.type === "update" ? 6 : 12}>
                      <CustomFormInput
                        value={usersData.fullName}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            fullName: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Full Name"
                        Icon={PersonOutlined}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        type="email"
                        value={usersData.email}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Email"
                        Icon={MailOutline}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        type="number"
                        value={usersData.mobile}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            mobile: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        placeholder="Mobile Number"
                        Icon={PhoneIphoneOutlined}
                      />
                    </Grid>
                    {params?.type === "create" && (
                      <Grid item xs={6}>
                        <CustomFormInput
                          password
                          value={usersData.password}
                          setValue={(value) =>
                            setusersData((prevData) => ({
                              ...prevData,
                              password: value,
                            }))
                          }
                          placeholder={"Password"}
                          Icon={Password}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <CreateDropDown
                        value={usersData.status}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            status: value,
                          }))
                        }
                        readOnly={params?.type === "view" ? true : false}
                        title={"Status"}
                        data={customerStatus}
                        Icon={Wc}
                      />
                    </Grid>

                    {/* <Grid item xs={6}>
                      <CreateDropDown
                        value={usersData.gender}
                        setValue={(value) =>
                          setusersData((prevData) => ({
                            ...prevData,
                            gender: value,
                          }))
                        }
                        title={"Gender"}
                        data={genders}
                        Icon={Wc}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <CreateDropDownMultiple
                        value={rolesData}
                        setValue={setrolesData}
                        title={"Roles"}
                        data={roles}
                        noIcon
                        readOnly={params?.type === "view" ? true : false}
                      />
                    </Grid>
                    {rolesData && rolesData?.includes("Supervisor") && (
                      <Grid item xs={12}>
                        <CreateDropDown
                          value={userSite}
                          setValue={(value) => {
                            setusersData((prevData) => ({
                              ...prevData,
                              siteId: sd?.find((sup) => sup?.value === value)
                                ?.siteId,
                            }));
                            setuserSite(sd?.find((sup) => sup?.value === value)?.value)
                          }}
                          readOnly={params?.type === "view" ? true : false}
                          title={"Assign Site"}
                          data={sd}
                          noIcon
                        />
                      </Grid>
                    )}
                    {/* <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: colors.text,
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Roles:
                      </Typography>
                      <Stack
                        direction={isSmallScreen ? "column" : "row"}
                        spacing={1}
                      >
                      <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAdmin}
                              onChange={handleChangeAdmin}
                              inputProps={{ "aria-label": "controlled" }}
                              required
                            />
                          }
                          label="IT Admin"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isSupervisor}
                              onChange={handleChangeSupervisor}
                              inputProps={{ "aria-label": "controlled" }}
                              required
                            />
                          }
                          label="Supervisor"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isDoctor}
                              onChange={handleChangeDoctor}
                              inputProps={{ "aria-label": "controlled" }}
                              required
                            />
                          }
                          label="Doctor"
                        />
                      </Stack>
                    </Grid> */}
                    {
                      rolesData &&
                      (rolesData?.includes("Provider") &&
                      !rolesData?.includes("Supervisor")) && 
                    
                        <Grid item xs={12}>
                                <CreateDropDown
                                  value={chooseSupervisor}
                                  setValue={(value) => {
                                    setchooseSupervisor(value);
                                    setusersData((prevData) => ({
                                      ...prevData,
                                      siteId: supervisorData?.find(
                                        (sup) => sup.value === value
                                      )?.siteId,
                                    }));
                                    setsupervisorId(
                                      supervisorData?.find(
                                        (sup) => sup.value === value
                                      )?.supervisorId
                                    );
                                  }}
                                  readOnly={
                                    params?.type === "view" ? true : false
                                  }
                                  title="Choose Supervisor"
                                  noIcon
                                  data={supervisorData || []}
                                />
                              </Grid>
}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateUsers;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Stack,
  FormControl,
  Select,
  Pagination,
  Tooltip,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  colors,
  getCustomerStatusBackgroundColor,
  getStatusTextColor,
} from "../../../theme/theme";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RedModal } from "../../../components/ConfirmationModal";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminCustomerSites } from "../../../redux/dispatchers/admin.dispatcher";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { Close, Warning } from "@mui/icons-material";

const CustomerSitesAdmin = () => {
    const navigate = useNavigate();

  const smallScreen = useMediaQuery("(max-width:650px)");

  const [deleteModalState, setdeleteModalState] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setsnackbarState(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sitesColumns = [
    {
      field: "S.No",
      headerName: "S.No",
      flex: 0.5,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "site_name",
      headerName: "Site Name",
      flex: 3,
      minWidth: smallScreen ? 500 : 0,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              displag: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: colors.text,
                fontFamily: "FiraSans",
              }}
            >
                 {params?.row?.user==null &&
              <Tooltip title="Supervisor not assigned to Site" placement="top-start">
              <Warning style={{fontSize:"14px",fontWeight:"500",color:"red"}}></Warning> &nbsp;
             </Tooltip>
    }
    {params?.row?.backupSupervisor==null &&
              <Tooltip title="Backup supervisor not assigned to Site" placement="top-start">
              <Warning style={{fontSize:"14px",fontWeight:"500",color:"yellow"}}></Warning> &nbsp;
             </Tooltip>
    }
              {params.value}
            </Typography>
          </Box>
        </div>
      ),
    },
    // {
    //   field: "countryFlag",
    //   headerName: "Country",
    //   flex: 1.25,
    //   minWidth: smallScreen ? 200 : 0,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", alignItems: "center" }}>
    //       <img
    //         src={`https://flagcdn.com/w80/${params?.row?.countryCode}.png`}
    //         alt=""
    //         style={{ borderRadius: "5px", width: "40px" }}
    //       />
    //     </Box>
    //   ),
    // },
    {
      field: "state",
      headerName: "Province",
      flex: 1.25,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    // {
    //   field: "address",
    //   headerName: "Address",
    //   flex: 3,
    //   minWidth: smallScreen ? 500 : 0,
    //   renderCell: (params) => (
    //     <Box sx={{}}>
    //       <Typography
    //         sx={{
    //           fontSize: "14px",
    //           fontWeight: "500",
    //           color: colors.text,
    //           fontFamily: "FiraSans",
    //         }}
    //       >
    //         {params.value}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   field: "assignedTo",
    //   headerName: "Assigned To",
    //   flex: 1.5,
    //   renderHeader: (params) => (
    //     <Typography
    //       sx={{
    //         fontSize: 14,
    //         fontWeight: "400",
    //         color: colors.text,
    //       }}
    //     >
    //       {params?.colDef?.headerName}
    //     </Typography>
    //   ),
    //   renderCell: (params) => (
    //     <>
    //       {params?.row?.user === null ? (
    //         <Box sx={{ display: "flex", alignItems: "center" }}>
    //           <Typography
    //             sx={{
    //               fontSize: "14px",
    //               fontWeight: "500",
    //               colors: colors.text,
    //               fontFamily: "FiraSans",
    //             }}
    //           >
    //             Not Assigned
    //           </Typography>
    //         </Box>
    //       ) : (
    //         <Box sx={{ display: "flex", alignItems: "center" }}>
    //           {params?.row?.user?.avatar !== "" && (
    //             <img
    //               src={params?.row?.user?.avatar}
    //               alt=""
    //               style={{
    //                 height: "30px",
    //                 width: "30px",
    //                 borderRadius: "50%",
    //                 objectFit: "contain",
    //               }}
    //             />
    //           )}
    //           <Box ml={1}>
    //             <Typography
    //               sx={{
    //                 fontSize: "14px",
    //                 fontWeight: "500",
    //                 colors: colors.text,
    //                 fontFamily: "FiraSans",
    //                 whiteSpace: "pre-wrap",
    //                 wordWrap: "break-word",
    //                 overflow: "hidden",
    //               }}
    //             >
    //               {params?.row?.user?.name}
    //             </Typography>
    //           </Box>
    //         </Box>
    //       )}
    //     </>
    //   ),
    // },

    {
      field: "beds",
      headerName: "Beds",
      flex: 1.25,
      minWidth: smallScreen ? 200 : 0,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {params?.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: smallScreen ? 200 : 0,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getCustomerStatusBackgroundColor(
                params?.value?.toLocaleLowerCase()
              ),
              color: getStatusTextColor(params?.value?.toLocaleLowerCase()),
              borderRadius: "8px",
            }}
          >
            {params?.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <>
            <Button
            onClick={() => {
              navigate("/admin/customer-sites/view", {
                state: { id: params.row.id },
              })
              // handleClick(e);
              setsiteId(params.row.id);
              setsiteName(params.row.address);
              setassignedSupervisor(params.row.user?.name);
              setassignedSupervisorId(params.row.user?.id);
              setsiteStatus(params.row.status);
            }}
            variant="contained"
            sx={{
              height: "40px",
              width: "100%",
              backgroundColor: "#138418",
              textTransform: "none",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#138418",
              },
            }}
        >
                View Site
              </Button>

          {/* <IconButton
            onClick={(e) => {
              handleClick(e);
              setsiteId(params.row.id);
              setsiteName(params.row.address);
              setassignedSupervisor(params.row.user?.name);
              setassignedSupervisorId(params.row.user?.id);
              setsiteStatus(params.row.status);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton> */}
        </>
      ),
    },
  ];

  const { siteData } =  useSelector((state) => state.admin);

  const [currentPage, setcurrentPage] = useState(1);
  const [totalCount, settotalCount] = useState(siteData?.length || 0);
  const [totalPages, settotalPages] = useState(1);
  const [siteId, setsiteId] = useState("");
  const [siteName, setsiteName] = useState("");
  const [numOfProductPerPage, setnumOfProductPerPage] = useState(10);
  const startIndex = (currentPage - 1) * numOfProductPerPage + 1;
  const endIndex = Math.min(startIndex + numOfProductPerPage - 1);
 


  const [assignedSupervisor, setassignedSupervisor] = useState("");
  const [assignedSupervisorId, setassignedSupervisorId] = useState("");

  const dispatch = useDispatch();
  
 // console.log(siteData.length);
 // settotalCount();
  const { userData } = useSelector((state) => state.user);

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getAllAdminCustomerSites(userData?.customerId));
  }, []);

  const [loading, setloading] = useState(false);
  const [snackbarState, setsnackbarState] = useState(false);

  const [siteStatus, setsiteStatus] = useState("");
  const [newSiteStatus, setnewSiteStatus] = useState("");

  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  
  const handleChangeProductPerPage = (event) => {
    setnumOfProductPerPage(event.target.value);
  };

  console.log(numOfProductPerPage, "num")

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const BlockSite = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/site/updateSiteStatus`,
        {
          status: newSiteStatus,
          siteId: siteId,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setsnackbarState(true);
        dispatch(getAllAdminCustomerSites(userData?.customerId));
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg(err?.response?.data?.message);
        console.log(err?.response?.data?.message);
      });
  };
  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={snackbarState}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={newSiteStatus === "active" ? "Site Opened" : "Site Closed"}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

<Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Sites" />

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() =>
              navigate("/admin/supervisor/assign", {
                state: {
                  id: siteId,
                  name: siteName,
                  assignedSupervisor: assignedSupervisor,
                  assignedSupervisorId: assignedSupervisorId,
                },
              })
            }
          >
            Assign Supervisor
          </MenuItem> */}
          {/* <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() =>
              navigate("/admin/customer-sites/view", {
                state: { id: siteId },
              })
            }
          >
            View Site Record
          </MenuItem> */}
          {/* <MenuItem
            sx={{
              borderBottom: "1px solid #EBEFF5",
              color: "#24292E",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() =>
              navigate("/admin/customer-sites/update", {
                state: { id: siteId },
              })
            }
          >
            Edit Site{" "}
          </MenuItem> */}
          {/* <MenuItem
            sx={{
              color: "#F34141",
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "FiraSans",
            }}
            onClick={() => {
              handleClose();
              setdeleteModalState(true);
              setnewSiteStatus(
                siteStatus?.toLocaleLowerCase() === "active"
                  ? "inactive"
                  : "active"
              );
            }}
          >
            {`Mark as ${
              siteStatus?.toLocaleLowerCase() === "active"
                ? "Inactive"
                : "Active"
            }`}
          </MenuItem> */}
        </Menu>

        <RedModal
          open={deleteModalState}
          onClose={() => setdeleteModalState(false)}
          onClick={() => {
            setdeleteModalState(false);
            BlockSite();
          }}
          title="Confirmation"
          mainText={newSiteStatus === "active" ? "Open Site" : "Close Site"}
          subText={"Are you sure you want to continue?"}
          actionText={newSiteStatus === "active" ? "Open" : "Close"}
        />

<Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Sites"
                onClick={() => navigate("/admin/customer-sites/create")}
                search={search}
                setSearch={setSearch}
                halfWidth
              />

              <StyledDataGrid
                rows={
                  siteData?.filter((val) =>
                    val?.address?.toLowerCase()?.includes(search?.toLowerCase())
                  ) || []
                }
                columns={sitesColumns}
                pagination
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: numOfProductPerPage },
                  },
                }}
                pageSizeOptions={[5,10, { value: siteData?.length, label: 'All' }]}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
              />
            </Box>

          </Grid>
        </Grid>
        </Box>
      </Box>
  )
}

export default CustomerSitesAdmin
import axiosInstance, { BASE_URL } from "../../constants/config";

export const getAllPatientsForDoctor = (doctorId) => async (dispatch) => {
  try {
    dispatch({
      type: "allAssignedPatientsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/doctor_patient/getAllPatientsWithDoctorsByDrId?doctorId=${doctorId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allAssignedPatientsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allAssignedPatientsFailure",
      payload: error,
    });
  }
};

export const getAllCheckIns = (patientId) => async (dispatch) => {
  try {
    dispatch({
      type: "allDailyCheckInsRequest",
    });
    const { data } = await axiosInstance.get(
      `${BASE_URL}/patient_checkin/getAllPatientCheckIn?patientId=${patientId}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "allDailyCheckInsSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "allDailyCheckInsFailure",
      payload: error,
    });
  }
};

export const clearProvider = () => async (dispatch) => {
  try {
    dispatch({
      type: "clearProvider",
    });
  } catch (error) {}
};

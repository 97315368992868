import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  colors,
  getCustomerStatusBackgroundColor,
  getStatusTextColor,
} from "../../../theme/theme";
import {
  Search,
  SearchIconWrapper,
  StyledDataGrid,
  StyledInputBase,
} from "../../../components/StyledSearchDataGrid";
import { useNavigate } from "react-router-dom";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import FilterBox from "../../../components/FilterBox";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { getAllFormsForAdmin } from "../../../redux/dispatchers/admin.dispatcher";
import { RedModal } from "../../../components/ConfirmationModal";
import { Close, VisibilityOutlined } from "@mui/icons-material";
import InactiveSwitch from "../../../components/InactiveSwitch";

const FormsAdmin = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [formId, setformId] = useState("");

  const formColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={require("../../../assets/icons/pdf.png")}
            alt=""
            style={{ height: 32, width: 32 }}
          />
          <Typography
            style={{
              marginLeft: 10,
              fontWeight: "400",
              color: colors.text,
              fontSize: 14,
            }}
          >
            {params.row?.form?.form_name}
          </Typography>
        </div>
      ),
    },
    {
      field: "customer",
      headerName: "Customer Name",
      flex: 1.25,
      renderHeader: (params) => (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            color: colors.text,
          }}
        >
          {params?.colDef?.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 0.5,
            }}
          >
            {params?.row?.customer?.organization_name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "createdSites",
      headerName: "Customer Site",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <WorkOutlineOutlinedIcon
            style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#596066",
              fontFamily: "FiraSans",
              ml: 1,
            }}
          >
            {params?.row?.site?.site_name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: "12px",
              minWidth: "85px",
              py: 1,
              textTransform: "capitalize",
            }}
            style={{
              textAlign: "center",
              backgroundColor: getCustomerStatusBackgroundColor(
                params?.row?.form?.status?.toLocaleLowerCase()
              ),
              color: getStatusTextColor(
                params?.row?.form?.status?.toLocaleLowerCase()
              ),
              borderRadius: "8px",
            }}
          >
            {params?.row?.form?.status}
          </Typography>
        </Box>
      ),
    },
    {
      field: "created_at",
      headerName: "Created On",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            marginLeft: 10,
            fontWeight: "400",
            fontSize: "14px",
            color: "#596066",
          }}
        >
          {moment(params.row.form?.updatedAt).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() =>
              navigate(`/admin/forms/form-details`, {
                state: { id: params?.row?.form?.id },
              })
            }
          >
            <VisibilityOutlined sx={{ height: "20px", width: "20px" }} />
          </IconButton>
          {/* <IconButton
            onClick={(e) => {
              handleClick(e);
              setformId(params.row?.form?.id);
              setformStatus(params.row?.form?.status);
            }}
          >
            <MoreVertIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton> */}
        </>
      ),
    },
  ];

  const dispatch = useDispatch();
  const { allForms } = useSelector((state) => state.admin);
  const { userData } = useSelector((state) => state.user);

  const [loading, setloading] = useState(false);
  const [search, setsearch] = useState("");
  const [deleteModalState, setdeleteModalState] = useState(false);
  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setdeleteConfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [status, setstatus] = useState("");
  const [formStatus, setformStatus] = useState("");
  const DeleteForms = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/form/updateFormStatus`,
        {
          formId: formId,
          status: status,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setdeleteModalState(false);
        dispatch(getAllFormsForAdmin(userData?.customerId));
        setdeleteConfirmation(true);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    dispatch(getAllFormsForAdmin(userData?.customerId));
  }, []);

  const [activeSwitch, setactiveSwitch] = useState(true);

  const handleactiveSwitch = () => {
    setactiveSwitch(!activeSwitch);
  };

  const filteredForms = allForms?.filter((val) => {
    return val?.form?.status === (activeSwitch ? "active" : "inactive");
  });

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={deleteConfirmation}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={`Form marked as ${status} successfully!`}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <RedModal
        open={deleteModalState}
        onClose={() => setdeleteModalState(false)}
        onClick={DeleteForms}
        title={`Mark as ${status} Form`}
        mainText={`Are you sure you want to mark this form as ${status}?`}
        actionText={`Mark as ${status}`}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Forms" />

        {/* <FilterBox type="form" /> */}
        <Grid container p={2}>
          <Grid item xs={12} mb={2}>
            <InactiveSwitch
              handleactiveSwitch={handleactiveSwitch}
              activeSwitch={activeSwitch}
              title="Forms"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <TitleWithSearchBar
                title="Forms"
                noBtn
                search={search}
                setSearch={setsearch}
              />

              <StyledDataGrid
                rows={
                  filteredForms?.filter((val) =>
                    val?.form?.form_name
                      ?.toLocaleLowerCase()
                      ?.includes(search?.toLocaleLowerCase())
                  ) || []
                }
                columns={formColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    color: status === "active" ? "#F34141" : "#000",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "FiraSans",
                  }}
                  onClick={(e) => {
                    setdeleteModalState(true);
                    if (formStatus === "active") {
                      setstatus("inactive");
                    } else {
                      setstatus("active");
                    }
                    handleClose(e);
                  }}
                >
                  {formStatus === "active"
                    ? "Mark as Inactive"
                    : "Mark as Active"}
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FormsAdmin;

import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {};

const clear = createAction("clearProvider");

const patientReq = createAction("allAssignedPatientsRequest");
const patientSucces = createAction("allAssignedPatientsSuccess");
const patientFail = createAction("allAssignedPatientsFail");

const checkInReq = createAction("allDailyCheckInsRequest");
const checkInSucces = createAction("allDailyCheckInsSuccess");
const checkInFail = createAction("allDailyCheckInsFail");

export const providerReducer = createReducer(initialState, (builder) => {
  builder

    // All assigned patients
    .addCase(patientReq, (state, action) => {
      state.loading = true;
    })
    .addCase(patientSucces, (state, action) => {
      state.loading = false;
      state.allAssignedPatients = action.payload?.allPatientsDoctors;
    })
    .addCase(patientFail, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // All daily check-ins
    .addCase(checkInReq, (state, action) => {
      state.loading = true;
    })
    .addCase(checkInSucces, (state, action) => {
      state.loading = false;
      state.allDailyCheckIns = action.payload?.allPatientCheckIn;
    })
    .addCase(checkInFail, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // Clear provider
    .addCase(clear, (state, action) => {
      state.allAssignedPatients = [];
    });
});

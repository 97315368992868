import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import Login from "../Pages/Auth/Login";
import Splash from "../Pages/Auth/Splash";
import { VendorRoutes } from "./VendorRoutes";
import { AdminRoutes } from "./AdminRoutes";
import SupervisorRoutes from "./SupervisorRoutes";
import { ProviderRoutes } from "./ProviderRoutes";
import EditProfile from "../Pages/Auth/EditProfile";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import ResetPassword from "../Pages/Auth/ResetPassword";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* auth router  */}
        <Route exact path="/" element={<Splash />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path={`/reset-password/:id/:token`}
          element={<ResetPassword />}
        />

        {/* Vendor */}
        <Route element={<PrivateRoute role="vendor" />}>{VendorRoutes()}</Route>

        {/* Admin */}
        <Route element={<PrivateRoute role="admin" />}>{AdminRoutes()}</Route>

        {/* Supervisor */}
        <Route element={<PrivateRoute role="supervisor" />}>
          {SupervisorRoutes()}
        </Route>

        {/* Provider */}
        <Route element={<PrivateRoute role="doctor" />}>
          {ProviderRoutes()}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

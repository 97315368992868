import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { colors } from "../theme/theme";
import {
  EditOutlined,
  EmailOutlined,
  LocalHospitalOutlined,
  MoreVertOutlined,
  PeopleOutlineOutlined,
  Person2Outlined,
  PhoneIphoneOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const DocProfileComp = ({ data, patientCount }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            height: "100px",
            borderRadius: "20px",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 4,
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            {data?.avatar !== "" && (
              <img
                alt=""
                src={data?.avatar}
                style={{
                  height: "75px",
                  width: "75px",
                  borderRadius: "360px",
                  objectFit: "contain",
                }}
              />
            )}

            {/* Name */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: colors.text,
                  fontFamily: "FiraSans",
                }}
              >
                {data?.fullName}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "12px",
                  color: colors.lightText,
                  fontFamily: "FiraSans",
                }}
              >
                #12456855{" "}
              </Typography> */}
            </Box>
          </Stack>

          {/* Email */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <EmailOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              {data?.email}
            </Typography>
          </Box>

          {/* Phone */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PhoneIphoneOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              {data?.mobile}
            </Typography>
          </Box>

          {/* Profession */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Person2Outlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              {data?.doctorType === null ? "N/A" : data?.doctorType}
            </Typography>
          </Box>

          {/* Hospital */}
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocalHospitalOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              St. Joseph Hospital{" "}
            </Typography>
          </Box> */}

          {/* Patients */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PeopleOutlineOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              {patientCount} Patients{" "}
            </Typography>
          </Box>

          {/* Btns */}
          <Stack direction={"row"}>
            <IconButton
              onClick={() => {
                navigate("/supervisor/doctors/update", {
                  state: { id: data?.id },
                });
              }}
            >
              <EditOutlined
                sx={{ color: colors.lightText, fontSize: "20px" }}
              />
            </IconButton>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

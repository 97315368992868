import React from "react";
import { Box, Grid, Typography, IconButton, Stack } from "@mui/material";
import { colors } from "../theme/theme";
import {
  EmailOutlined,
  PhoneIphoneOutlined,
  EditOutlined,
  MoreVertOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

const CustomerProfileBoxComponent = ({ editOnClick, vendor, data }) => {
  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            height: "100px",
            borderRadius: "20px",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 4,
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <img
              alt=""
              src={require("../assets/images/table/companies (1).png")}
              style={{ height: "50px", width: "50px", borderRadius: "25px" }}
            /> */}
            {/* Name */}
            <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: colors.text,
                  fontFamily: "FiraSans",
                }}
              >
                {data?.site_name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: colors.lightText,
                  fontFamily: "FiraSans",
                }}
              >
                {data?.address}
              </Typography>
            </Box>
          </Stack>

          {/* Email */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <EmailOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 0.5,
              }}
            >
              {data?.email}
            </Typography>
          </Box>

          {/* Phone */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PhoneIphoneOutlined
              style={{ color: "#9EA5AD", height: "16px", width: "16px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
              }}
            >
              {data?.mobile}
            </Typography>
          </Box>

          {/* Country */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={`https://flagcdn.com/w80/${data?.countryCode}.png`}
              srcset="https://flagcdn.com/w160/ua.png 2x"
              width="40"
              alt=""
              style={{ borderRadius: "5px" }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#596066",
                fontFamily: "FiraSans",
                ml: 1,
              }}
            >
              {data?.city ? data?.city : data?.country}
            </Typography>
          </Box>

          {/* Btns */}
          <Box
            sx={{
              height: "32px",
              px: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#6AD09D",
              borderRadius: "6px",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: "FiraSans",
                textTransform: "capitalize",
              }}
            >
              {data?.status}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={editOnClick}>
              <EditOutlined />
            </IconButton>
            {/* <IconButton>
              <MoreVertOutlined />
            </IconButton> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomerProfileBoxComponent;

import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {};

const clear = createAction("clearSupervisor");

const doctorReq = createAction("allDoctorsRequest");
const doctorSuccess = createAction("allDoctorsSuccess");
const doctorFail = createAction("allDoctorsFailure");

const patientReq = createAction("allPatientsRequest");
const patientSuccess = createAction("allPatientsSuccess");
const patientFail = createAction("allPatientsFailure");

const formReq = createAction("allFormsRequest");
const formSuccess = createAction("allFormsSuccess");
const formFail = createAction("allFormsFailure");

const questionReq = createAction("allQuestionsRequest");
const questionSuccess = createAction("allQuestionsSuccess");
const questionFail = createAction("allQuestionsFailure");

export const supervisorReducer = createReducer(initialState, (builder) => {
  builder
    // doctor
    .addCase(doctorReq, (state, action) => {
      state.loading = true;
    })
    .addCase(doctorSuccess, (state, action) => {
      state.loading = false;
      state.allDoctors = action.payload.allDoctors;
    })
    .addCase(doctorFail, (state, action) => {
      state.loading = false;
    })

    // patient
    .addCase(patientReq, (state, action) => {
      state.loading = true;
    })
    .addCase(patientSuccess, (state, action) => {
      state.loading = false;
      state.allPatients = action.payload.allPatients;
    })
    .addCase(patientFail, (state, action) => {
      state.loading = false;
    })

    // form
    .addCase(formReq, (state, action) => {
      state.loading = true;
    })
    .addCase(formSuccess, (state, action) => {
      state.loading = false;
      state.formsData = action.payload.allForm;
    })
    .addCase(formFail, (state, action) => {
      state.loading = false;
    })

    // question
    .addCase(questionReq, (state, action) => {
      state.loading = true;
    })
    .addCase(questionSuccess, (state, action) => {
      state.loading = false;
      state.allQuestions = action.payload.allQuetions;
    })
    .addCase(questionFail, (state, action) => {
      state.loading = false;
    })

    // clear
    .addCase(clear, (state, action) => {
      state.loading = false;
      state.allDoctors = [];
      state.allPatients = [];
      state.formsData = [];
      state.allQuestions = [];
    });
});

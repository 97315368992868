import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import Grid from "@mui/material/Grid";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { colors } from "../../../theme/theme";
import Typography from "@mui/material/Typography";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AssignmentTurnedInOutlined,
  BusinessCenterOutlined,
  Groups3Outlined,
  LanguageOutlined,
  MapOutlined,
  Password,
  PersonOutlined,
} from "@mui/icons-material";
import {
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { createCustomerStatus, customerStatus } from "../../../assets/data/DummyData";
import { ErrorSnackbar } from "../../../components/CustomSnackbar";
import { useSelector } from "react-redux";

const CreateCustomer = () => {
  const params = useParams();
  const navigation = useNavigate();

  const location = useLocation();
  const { data } = location.state || location;

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [confirmation, setconfirmation] = useState(false);

  const [formData, setFormData] = useState({
    organization_name: "",
    organization_type: "",
    email: "",
    phone: "",
    address: "",
    country: "",
    state: "",
    city: "",
    website: "",
    totalBeds: 0,
    totalFacilities: "",
    status: "",
    postalCode:"",

    billing_name:"",
    billing_address:"",
    billing_country: "",
    billing_state: "",
    billing_city: "",
    billing_postalCode:"",
  });

  const [adminDetails, setAdminDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const [loading, setloading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [states, setstates] = useState([]);

  const [billingcountries, setBillingCountries] = useState([]);
  const [billingcities, setBillingCities] = useState([]);
  const [billingstates, setBillingStates] = useState([]);

  const [countryCode, setcountryCode] = useState("");
  const [billingcountryCode, setbillingcountryCode] = useState("");
  const [sameAsAbove, setSameAsAbove] = useState(true);

  const [isAdmin, setisAdmin] = useState(true);

  const [isSupervisor, setisSupervisor] = useState(false);
  const [isDoctor, setisDoctor] = useState(false);

  const handleChangeAdmin = (event) => {
    setisAdmin(event.target.checked);
  };

  const handleChangeSameAsAbove = (event) => {
    setSameAsAbove(event.target.checked);
    setFormData(
      {
        ...formData,
        billing_country:formData.country,
        billing_address:formData.address,
        billing_city:formData.city,
        billing_postalCode:formData.postalCode
      }
    )
  };

  const handleChangeSupervisor = (event) => {
    setisSupervisor(event.target.checked);
  };

  const handleChangeDoctor = (event) => {
    setisDoctor(event.target.checked);
  };

  const { userData } = useSelector((state) => state.user);

  const handleCreateCustomer = async () => {
    seterror(false);
    seterrorMsg("");
    if (
      formData.organization_name === "" ||
      formData.email === "" ||
      formData.address === "" ||
      formData.status === "" ||
      formData.country === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields for customer details");
    } else if (
      adminDetails.firstName === "" ||
      adminDetails.email === "" ||
      adminDetails.phone === "" ||
      adminDetails.password === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields for admin details");
    } else {
      setloading(true);
      await axios
        .post(
          `${BASE_URL}/customer/createCustomer`,
          {
            customer: {
              organization_name: formData.organization_name,
              organization_type: formData.organization_type,
              email: formData.email,
              mobile: formData.phone,
              head_office_address: formData.address,
              city: formData.city,
              state: formData.state,
              country: formData.country,
              website: formData.website,
              beds: 0,
              facilities: formData.totalFacilities,
              status: formData.status,
              created_by: userData?.id,
              countryCode: countryCode?.toLocaleLowerCase(),
              billing_name:formData.billing_name,
              billing_address:sameAsAbove?formData.address:formData.billing_address,
              postalCode:formData.postalCode,
              billing_city: sameAsAbove?formData.city:formData.billing_city,
              billing_state: sameAsAbove?formData.state:formData.billing_state,
              billing_country: sameAsAbove?formData.country:formData.billing_country,
              billing_postalCode: sameAsAbove?formData.postalCode:formData.billing_postalCode,

            },
            user: {
              name: adminDetails.firstName + " " + adminDetails.lastName,
              email: adminDetails.email,
              mobile: adminDetails.phone,
              password: adminDetails.password,
              avatar: "",
              isSupervisor: isSupervisor,
              isAdmin: isAdmin,
              isDoctor: isDoctor,
            },
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          seterror(false);
          setTimeout(() => {
            navigation("/vendor/customers");
          }, 2000);
          setFormData({
            organization_name: "",
            organization_type: "",
            email: "",
            phone: "",
            address: "",
            country: "",
            state: "",
            city: "",
            website: "",
            totalBeds: "",
            totalFacilities: "",
            status: "",
            billing_name:"",
            billing_address:"",
            postalCode:"",
            billing_city: "",
            billing_state: "",
            billing_country: "",
            billing_postalCode: "",

          });
          setAdminDetails({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            password: "",
          });
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        "https://api.countrystatecity.in/v1/countries",
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const transformedArray = response.data.map((country) => ({
        label: country.name,
        value: country.name,
        code: country.iso2,
      }));
      setCountries(transformedArray);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const fetchStatesByCountries = async (name,type) => {
    const country = countries?.find((country) => country.value === name);

    try {
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${country?.code}/states`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      if(type=='org')
      {
        const transformedArray = response.data.map((state) => ({
          label: state.name,
          value: state.name,
          code: state.iso2,
        }));
        setcountryCode(country?.code);
  
        setstates(transformedArray);
      }
      else
      {        
        const transformedArray = response.data.map((state) => ({
        label: state.name,
        value: state.name,
        code: state.iso2,
      }));
      setbillingcountryCode(country?.code);

      setBillingStates(transformedArray);
        
      }
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const fetchCitiesByStatesAndCountries = async (code,type) => {

    try {

      if(type=='org')
      {
        const statesCode = states?.find((state) => state.value === code);
        const response = await axios.get(
          `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${statesCode?.code}/cities`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
            },
          }
        );
        const transformedArray = response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setcities(transformedArray);
      }
      else{
        const statesCode = billingstates?.find((state) => state.value === code);

        const response = await axios.get(
          `https://api.countrystatecity.in/v1/countries/${billingcountryCode}/states/${statesCode?.code}/cities`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
            },
          }
        );
        const transformedArray = response.data.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setBillingCities(transformedArray);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const UpdateCustomer = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/customer/editCustomer`,
        {
          customerId: data,
          organization_name: formData.organization_name,
          organization_type: formData.organization_type,
          email: formData.email,
          mobile: formData.phone,
          head_office_address: formData.address,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          website: formData.website,
          beds: 0,
          facilities: formData.totalFacilities,
          status: formData.status,
          created_by: "vendor",
          postalCode:formData.postalCode,
          countryCode: countryCode.toLocaleLowerCase(),
          billing_city: sameAsAbove?formData.city:formData.billing_city,
          billing_state: sameAsAbove?formData.state:formData.billing_state,
          billing_country: sameAsAbove?formData.country:formData.billing_country,
          billing_postalCode: sameAsAbove?formData.postalCode:formData.billing_postalCode,
          billing_address:sameAsAbove?formData.address:formData.billing_address,
          
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        navigation("/vendor/customers");
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetCustomerById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/customer/getSpecificCustomer?customerId=${data}`, {
        withCredentials: true,
      })
      .then(async (res) => {
        setloading(false);
        const customer = res.data?.customer;
        setFormData({
          organization_name: customer?.organization_name,
          organization_type: customer?.organization_type,
          email: customer?.email,
          phone: customer?.mobile,
          address: customer?.head_office_address,
          country: customer?.country,
          state: customer?.state,
          city: customer?.city,
          website: customer?.website,
          totalBeds: customer?.beds,
          totalFacilities: customer?.facilities,
          status: customer?.status,
          billing_address:customer?.billing_address,
          billing_name:customer?.billing_name,
          postalCode:customer?.postalCode,
          billing_city: customer?.billing_city,
          billing_state: customer?.billing_state,
          billing_country: customer?.billing_country,
          billing_postalCode: customer?.billing_postalCode,

        });
        await fetchStatesByCountries(res?.data?.customer?.country,'org');
        await fetchStatesByCountries(res?.data?.customer?.billing_country,'billing');
        await fetchCitiesByStatesAndCountries(res?.data?.customer?.state,'org');
        await fetchCitiesByStatesAndCountries(res?.data?.customer?.billing_state,'billing');
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetCustomerById();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={
          params?.type === "update"
            ? "Customer Updated Successfully!"
            : "Customer Created Successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <ErrorSnackbar
        error={error}
        handleCloseError={seterror}
        errorMsg={errorMsg}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "update" ? "Update Customer" : "Create Customer"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "update"
                      ? "Update Customer"
                      : "Create New Customer"
                  }
                  btnTitle={params?.type === "update" ? "Update" : "Create"}
                  onClick={(e) => {
                    e.preventDefault();
                    if (params?.type === "update") {
                      UpdateCustomer();
                    } else {
                      handleCreateCustomer();
                    }
                  }}
                  cancelFunc={() => navigation("/vendor/customers")}
                />

                {/* Form */}
                <Box px={isSmallScreen ? 5 : 15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomFormInput
                        value={formData.organization_name}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            organization_name: value,
                          }))
                        }
                        placeholder="Organization Name*"
                        Icon={PersonOutlinedIcon}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomFormInput
                        value={formData.organization_type}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            organization_type: value,
                          }))
                        }
                        placeholder="Organization Type"
                        Icon={BusinessCenterOutlined}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.email}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        placeholder="Email*"
                        Icon={MailOutlineIcon}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.phone}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            phone: value,
                          }))
                        }
                        placeholder="Phone"
                        Icon={PhoneIphoneOutlinedIcon}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={formData.address}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            address: value,
                          }))
                        }
                        placeholder="Organization Address*"
                        Icon={MapOutlined}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CreateDropDown
                        value={formData.country}
                        setValue={(value) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            country: value,
                          }));
                          fetchStatesByCountries(value,'org');
                        }}
                        title="Country*"
                        data={countries}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CreateDropDown
                        value={formData.state}
                        setValue={(value) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            state: value,
                          }));
                          fetchCitiesByStatesAndCountries(value,'org');
                        }}
                        title="Province/State"
                        data={states}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CreateDropDown
                        value={formData.city}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            city: value,
                          }))
                        }
                        title="City"
                        data={cities}
                        noIcon
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomFormInput
                        value={formData.postalCode}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            postalCode: value,
                          }))
                        }
                        placeholder="Postal Code*"
                        Icon={PersonOutlinedIcon}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.website}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            website: value,
                          }))
                        }
                        placeholder="Website"
                        Icon={LanguageOutlined}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CreateDropDown
                        value={formData.status}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            status: value,
                          }))
                        }
                        title="Status*"
                        data={createCustomerStatus}
                        noIcon
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.totalFacilities}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            totalFacilities: value,
                          }))
                        }
                        placeholder="Total Facilities"
                        Icon={Groups3Outlined}
                      />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={formData.totalBeds}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            totalBeds: value,
                          }))
                        }
                        placeholder="No. of Beds"
                        Icon={AssignmentTurnedInOutlined}
                      />
                    </Grid> */}
                        <Grid item xs={12} sx={{ mt: 4 }}>
                          <Typography
                            sx={{ color: colors.text, fontWeight: "500" }}
                          >
                            Billing Detials{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <CustomFormInput
                            value={formData.billing_name}
                            setValue={(value) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                billing_name: value,
                              }))
                            }
                            placeholder="Billing Person Name*"
                            Icon={PersonOutlined}
                          />
                        </Grid>
                        <Stack
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={1}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={sameAsAbove}
                                  onChange={handleChangeSameAsAbove}
                                  inputProps={{ "aria-label": "controlled" }}
                                  required
                                />
                              }
                              
                              label="Same As Mailing Address"
                            />
                        </Stack>
                        <Grid item xs={12} sm={12}>
                          <CustomFormInput
                            value={formData.billing_address}
                            setValue={(value) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                billing_address: value,
                              }))
                            }
                            placeholder="Billing Address*"
                            Icon={PersonOutlined}
                            readOnly={sameAsAbove}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                      <CreateDropDown
                        value={formData.billing_country}
                        setValue={(value) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            billing_country: value,
                          }));
                          fetchStatesByCountries(value,'billing');
                        }}
                        title="Billing Country*"
                        data={countries}
                        noIcon
                        readOnly={sameAsAbove}

                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CreateDropDown
                        value={formData.billing_state}
                        setValue={(value) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            billing_state: value,
                          }));
                          fetchCitiesByStatesAndCountries(value,'billing');
                        }}
                        title="Billing Province/State"
                        data={billingstates}
                        noIcon
                        readOnly={sameAsAbove}

                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CreateDropDown
                        value={formData.billing_city}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            billing_city: value,
                          }))
                        }
                        title="Billing City"
                        data={billingcities}
                        noIcon
                        readOnly={sameAsAbove}

                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomFormInput
                        value={formData.billing_postalCode}
                        setValue={(value) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            billing_postalCode: value,
                          }))
                        }
                        placeholder="Billing Postal Code*"
                        Icon={PersonOutlinedIcon}
                        readOnly={sameAsAbove}

                      />
                    </Grid>

                    {params?.type !== "update" && (
                      <>
                        <Grid item xs={12} sx={{ mt: 4 }}>
                          <Typography
                            sx={{ color: colors.text, fontWeight: "500" }}
                          >
                            Admin Details (Head / IT){" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CustomFormInput
                            value={adminDetails.firstName}
                            setValue={(value) =>
                              setAdminDetails((prevData) => ({
                                ...prevData,
                                firstName: value,
                              }))
                            }
                            placeholder="Admin First Name*"
                            Icon={PersonOutlined}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CustomFormInput
                            value={adminDetails.lastName}
                            setValue={(value) =>
                              setAdminDetails((prevData) => ({
                                ...prevData,
                                lastName: value,
                              }))
                            }
                            placeholder="Admin Last Name*"
                            Icon={PersonOutlined}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CustomFormInput
                            value={adminDetails.email}
                            setValue={(value) =>
                              setAdminDetails((prevData) => ({
                                ...prevData,
                                email: value,
                              }))
                            }
                            placeholder="Admin Email*"
                            Icon={MailOutlineIcon}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CustomFormInput
                            value={adminDetails.phone}
                            setValue={(value) =>
                              setAdminDetails((prevData) => ({
                                ...prevData,
                                phone: value,
                              }))
                            }
                            placeholder="Admin Phone*"
                            Icon={PhoneIphoneOutlinedIcon}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CustomFormInput
                            password
                            value={adminDetails.password}
                            setValue={(value) =>
                              setAdminDetails((prevData) => ({
                                ...prevData,
                                password: value,
                              }))
                            }
                            placeholder={"Admin Password*"}
                            Icon={Password}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              color: colors.text,
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Roles:
                          </Typography>
                          <Stack
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={1}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAdmin}
                                  onChange={handleChangeAdmin}
                                  inputProps={{ "aria-label": "controlled" }}
                                  required
                                />
                              }
                              disabled={true}
                              label="Admin"
                            />
                            {/* <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isSupervisor}
                                  onChange={handleChangeSupervisor}
                                  inputProps={{ "aria-label": "controlled" }}
                                  required
                                />
                              }
                              label="Supervisor"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isDoctor}
                                  onChange={handleChangeDoctor}
                                  inputProps={{ "aria-label": "controlled" }}
                                  required
                                />
                              }
                              label="Provider"
                            /> */}
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateCustomer;

import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance, { BASE_URL } from "../../constants/config";
import { getUserById } from "../../redux/dispatchers/login";
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
} from "@mui/material";
import {
  AccountCircle,
  Close,
  MailOutline,
  PersonOutlined,
  PhoneIphoneOutlined,
  Wc,
} from "@mui/icons-material";
import AppBar from "../../components/AppBar";
import {
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../components/CreatePagesComponents";
import { genders } from "../../assets/data/DummyData";
import MenuDrawer from "../../components/MenuDrawer";

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.user);
  const [userDetails, setuserDetails] = useState({
    fullName: userData?.name !== null ? userData?.name : "",
    mobile: userData?.mobile !== null ? userData?.mobile : "",
    email: userData?.email !== null ? userData?.email : "",
    gender: userData?.gender !== null ? userData?.gender : "",
    avatar: userData?.avatar !== null ? userData?.avatar : "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [avatarLink, setavatarLink] = useState(
    userData?.avatar !== null ? userData?.avatar : ""
  );
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        setloading(false);
        setuserDetails((prevData) => ({
          ...prevData,
          avatar: res.data?.url,
        }));
        setavatarLink(res.data?.url);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  const [confirmation, setconfirmation] = useState(false);

  const [loading, setloading] = useState(false);

  const UpdateUser = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUser`,
        {
          userId: userData?.id,
          name: userDetails?.fullName,
          mobile: userDetails?.mobile,
          email: userDetails?.email,
          gender: userDetails?.gender,
          avatar: userDetails?.avatar,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        dispatch(getUserById(userData?.id));
        navigate(-1);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer
        vendor={userData?.role === "vendor" ? true : false}
        admin={userData?.role === "admin" ? true : false}
        supervisor={userData?.role === "supervisor" ? true : false}
        provider={userData?.role === "provider" ? true : false}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={confirmation}
        autoHideDuration={2000}
        onClose={handleClose}
        message={"Profile Updated Successfully!"}
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title={"Edit Profile"} />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={"Update Profile"}
                  btnTitle={"Update"}
                  onClick={UpdateUser}
                  cancelFunc={() => navigate(-1)}
                />
                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          mb: 2,
                        }}
                      >
                        {userDetails?.avatar ? (
                          <Avatar
                            alt="Remy Sharp"
                            sx={{
                              width: 125,
                              height: 125,
                              borderRadius: 360,
                              backgroundColor: "transparent",
                              border: "2px solid #EBEFF5",
                              p: 0.5,
                              cursor: "pointer",
                            }}
                            onClick={handleBrowseClick}
                          >
                            <img
                              src={userDetails?.avatar}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt="Avatar"
                            />
                          </Avatar>
                        ) : (
                          <>
                            {avatarLink ? (
                              <Avatar
                                alt="Remy Sharp"
                                src={avatarLink}
                                sx={{
                                  width: 125,
                                  height: 125,
                                  border: "2px solid #EBEFF5",
                                  p: 2,
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                onClick={handleBrowseClick}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                                onClick={handleBrowseClick}
                              >
                                <AccountCircle sx={{ fontSize: 100 }} />
                              </Avatar>
                            )}
                          </>
                        )}
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => {
                            dataURItoBlob(e.target.files[0]);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={userDetails.fullName}
                        setValue={(value) =>
                          setuserDetails((prevData) => ({
                            ...prevData,
                            fullName: value,
                          }))
                        }
                        placeholder="Full Name"
                        Icon={PersonOutlined}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={userDetails.email}
                        setValue={(value) =>
                          setuserDetails((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        placeholder="Email"
                        Icon={MailOutline}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={userDetails.mobile}
                        setValue={(value) =>
                          setuserDetails((prevData) => ({
                            ...prevData,
                            mobile: value,
                          }))
                        }
                        placeholder="Mobile Number"
                        Icon={PhoneIphoneOutlined}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CreateDropDown
                        value={userDetails.gender}
                        setValue={(value) =>
                          setuserDetails((prevData) => ({
                            ...prevData,
                            gender: value,
                          }))
                        }
                        title={"Gender"}
                        data={genders}
                        Icon={Wc}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditProfile;

import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import DataGridTable from "../../../components/DataGridTable";
import {
  customerData,
  customerSitesData,
  dummyQuestionData,
  formsData,
  supervisorRows,
} from "../../../assets/data/DummyData";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllActivitiesForAdmin,
  getAllAdminCustomerSites,
  getAllFormsForAdmin,
  getAllQuestionsForAdmin,
  getAllSupervisorForAdmin,
} from "../../../redux/dispatchers/admin.dispatcher";
import { useNavigate } from "react-router-dom";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { getAllCustomers, getAllCustomerSites } from "../../../redux/dispatchers/vendor.dispatcher";

const VendorDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const [doctorSearch, setdoctorSearch] = useState("");
  const [siteSearch, setsiteSearch] = useState("");
  const [formSearch, setformSearch] = useState("");
  const [questionSearch, setquestionSearch] = useState("");
  const [supervisorSearch, setsupervisorSearch] = useState("");
  const { allCustomers } = useSelector((state) => state.vendor);
  const { allSites } = useSelector((state) => state.vendor);
  const [search, setsearch] = useState("");

  const filteredSites = allSites?.filter((val) =>
    val?.address
      ?.toLocaleLowerCase()
      ?.includes(search?.toLocaleLowerCase())
  )?.slice(0, 5);
  useEffect(() => {
    dispatch(getAllCustomers());
    dispatch(getAllCustomerSites());


  }, []);


  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Dashboard" />

        {/* Data Grids */}
        <Grid container spacing={2} p={2}>
          {/* Doctors Table */}
          <Grid item xs={12}>
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <DataGridTable
                title="Customers"
                type="customers"
                rowData={allCustomers?.slice(0, 5) || []}
                pageSize={5}
                // search={doctorSearch}
                // setSearch={setdoctorSearch}
              />
            </Box>
          </Grid>

          {/* Customer Sites Table */}
          <Grid item xs={12} md={12}>
            <Box
              bgcolor="#fff"
              sx={{ borderRadius: "20px" }}
              width="100%"
              mr={2}
            >
              <DataGridTable
                title="Sites"
                type="customer_sites"
                rowData={filteredSites || []}
                pageSize={5}
                // search={siteSearch}
                // setSearch={setsiteSearch}
                // onCreate={() => navigate("/admin/customer-sites/create")}
              />
            </Box>
          </Grid>

          {/* Forms Table */}
          {/* <Grid item xs={12} md={5.5}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Activities"
                type="forms"
                rowData={filteredForms || []}
                pageSize={5}
                search={formSearch}
                setSearch={setformSearch}
                fullWidth
                onCreate={() => navigate("/admin/forms/create")}
              />
            </Box>
          </Grid> */}

          {/* Questions Table */}
          {/* <Grid item xs={12} md={4.5}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Requests"
                type="questions"
                rowData={filteredQuestions || []}
                pageSize={5}
                search={questionSearch}
                setSearch={setquestionSearch}
                fullWidth
                onCreate={() => {
                  navigate("/admin/questions/create");
                }}
              />
            </Box>
          </Grid> */}

          {/* Supervisors Table */}
          {/* <Grid item xs={12} md={12}>
            <Box bgcolor="#fff" sx={{ borderRadius: "20px" }} width="100%">
              <DataGridTable
                title="Users"
                type="supervisors"
                rowData={filteredSupervisors || []}
                pageSize={5}
                search={supervisorSearch}
                setSearch={setsupervisorSearch}
                onCreate={() => navigate("/admin/users/create")}
              />
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default VendorDashboard;

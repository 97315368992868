import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useUser } from "../constants/context";

const PrivateRoute = ({ role }) => {
  const { userData, userRole } = useSelector((state) => state.user);

  const { userRoleLoading } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (userRole !== role && !userRoleLoading) {
      navigate("/login");
    }
  }, [userRole, role, userRoleLoading]);

  return userRole === role && !userRoleLoading ? <Outlet /> : null;
};

export default PrivateRoute;

import React, { useEffect, useState } from "react";
import MenuDrawer from "../../../components/MenuDrawer";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "../../../components/AppBar";
import ChecklistIcon from "@mui/icons-material/Checklist";
import {
  CreateCustomInput,
  CreateCustomTextArea,
  CreateDropDown,
  CreateHeader,
} from "../../../components/CreatePagesComponents";
import { EditNote, PersonOutlineOutlined } from "@mui/icons-material";
import { UploadBtn } from "../../../components/CustomBtn";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { colors } from "../../../theme/theme";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const ScheduleAppointmentWithPatient = () => {
  const params = useParams();

  const [confirmation, setconfirmation] = useState(false);

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const { allAssignedPatients } = useSelector((state) => state.provider);

  const patientData = allAssignedPatients?.map((patient) => ({
    label: patient?.patients?.name,
    value: patient?.patients?.name,
    id: patient?.patients?.id,
  }));

  const [selectedPatient, setselectedPatient] = useState("");
  const [patientId, setpatientId] = useState("");

  const [scheduledOn, setscheduledOn] = useState(null);

  const handleStartDateChange = (newValue) => {
    setscheduledOn(newValue);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.user);

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const CreateAppointment = async () => {
    if (scheduledOn === null || patientId === "") {
      seterror(true);
      seterrorMsg("Please fill all the fields");
      return;
    } else {
      seterror(false);
      setloading(true);
      await axiosInstance
        .post(
          `${BASE_URL}/appointment/createAppointment`,
          {
            patientId: patientId,
            doctorId: userData?.id,
            siteId: userData?.siteId,
            customerId: userData?.customerId,
            bookedBy: userData?.id,
            status: "active",
            date: scheduledOn,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setselectedPatient("");
          setscheduledOn(null);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const UpdateAppointment = async () => {
    setloading(true);
    await axiosInstance
      .post(
        `${BASE_URL}/appointment/editAppointment`,
        {
          appointmentId: location?.state?.id,
          date: scheduledOn,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        GetScheduleById();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetScheduleById = async () => {
    setloading(true);
    await axiosInstance
      .get(
        `${BASE_URL}/appointment/getOneAppointmentById?appointmentId=${location?.state?.id}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        const data = res?.data?.appointmentDetail;
        setscheduledOn(dayjs(data?.date));
        setselectedPatient(data?.patient?.name);
        setpatientId(data?.patient?.id);
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetScheduleById();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer provider />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "create"
            ? "Appointment Created Successfully!"
            : "Appointment Updated Successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "create"
              ? "Create New Appointment"
              : "Update Appointment"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Appointment"
                      : "Update Appointment"
                  }
                  btnTitle={params?.type === "create" ? "Create" : "Update"}
                  onClick={
                    params?.type === "create"
                      ? CreateAppointment
                      : UpdateAppointment
                  }
                  cancelFunc={() => navigate(-1)}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    {params?.type === "create" ? (
                      <Grid item xs={12}>
                        <CreateDropDown
                          value={selectedPatient}
                          setValue={(e) => {
                            setselectedPatient(e);
                            const patient = allAssignedPatients.find(
                              (patient) => patient?.patients?.name === e
                            )?.patients?.id;
                            setpatientId(patient);
                          }}
                          title="Choose Patient"
                          Icon={PersonOutlineOutlined}
                          data={patientData || []}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          sx={{
                            height: "50px",
                            borderRadius: "7px",
                            backgroundColor: "#FAFAFA",
                            elevation: 0,
                            fontFamily: "FiraSans",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: colors.text,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EBEFF5",
                              height: "55px",
                              p: 0,
                            },
                            "& input::placeholder": {
                              color: colors.text,
                              opacity: 1,
                              fontFamily: "FiraSans",
                              height: "50px",
                            },
                            ".MuiSvgIcon-root": {
                              fill: "black !important",
                            },
                            width: "100%",
                          }}
                          value={scheduledOn}
                          onChange={handleStartDateChange}
                          PopperProps={{
                            placement: "top-end",
                          }}
                          renderInput={(props) => (
                            <DemoContainer {...props} sx={{ height: "50px" }} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ScheduleAppointmentWithPatient;

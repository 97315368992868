import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Snackbar,
  IconButton,
  Avatar,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AccountCircle,
  Close,
  EmailOutlined,
  HelpOutline,
  MailOutline,
  Password,
  PersonOutline,
  PersonOutlined,
  PhoneIphoneOutlined,
  PhoneOutlined,
  Wc,
  WorkOffOutlined,
  WorkOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import MenuDrawer from "../../../components/MenuDrawer";
import AppBar from "../../../components/AppBar";
import {
  CreateCustomInput,
  CreateDropDown,
  CreateHeader,
  CustomFormInput,
} from "../../../components/CreatePagesComponents";
import axiosInstance, { BASE_URL } from "../../../constants/config";
import { useSelector } from "react-redux";
import { genders } from "../../../assets/data/DummyData";
import axios from "axios";

const CreateDoctor = () => {
  const params = useParams();

  const [confirmation, setconfirmation] = useState(false);

  const [doctorData, setdoctorData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    type: "",
    password: "",
    gender: "",
    avatar: "",
  });

  const [avatarLink, setavatarLink] = useState("");
  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };

  function dataURItoBlob(dataURI) {
    let formData = new FormData();
    formData.append("file", dataURI);
    setloading(true);
    axios
      .post(`${BASE_URL}/aws/file?email=axon-health`, formData)
      .then((res) => {
        setloading(false);
        setdoctorData((prevData) => ({
          ...prevData,
          avatar: res.data?.url,
        }));
        setavatarLink(res.data?.url);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setconfirmation(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useSelector((state) => state.user);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const CreateDoctor = async () => {
    if (
      doctorData.fullName === "" ||
      doctorData.email === "" ||
      doctorData.password === ""
    ) {
      seterror(true);
      seterrorMsg("Please fill all the fields");
    } else {
      setloading(true);
      await axiosInstance
        .post(
          `${BASE_URL}/user/createUserDoctor`,
          {
            user: {
              name: doctorData.fullName,
              password: doctorData.password,
              mobile: doctorData.mobile,
              email: doctorData.email,
              verified: true,
              dob: null,
              gender: doctorData.gender,
              avatar: doctorData.avatar,
              createdBy: userData?.id,
              customerId: userData?.customerId,
              doctorType: doctorData.type,
              siteId: userData?.siteId,
            },
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setloading(false);
          setconfirmation(true);
          setTimeout(() => {
            navigate("/supervisor/doctors");
          }, 2000);
          setdoctorData({
            fullName: "",
            email: "",
            mobile: "",
            type: "",
            password: "",
            gender: "",
            avatar: "",
          });
        })
        .catch((e) => {
          setloading(false);
        });
    }
  };

  const UpdateDoctor = async () => {
    setloading(true);
    await axios
      .post(
        `${BASE_URL}/user/updateUser`,
        {
          userId: location?.state?.id,
          name: doctorData.fullName,
          mobile: doctorData.mobile,
          email: doctorData.email,
          gender: doctorData.gender,
          avatar: doctorData.avatar,
          siteId: userData?.siteId,
          doctorType: doctorData.type,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setloading(false);
        setconfirmation(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        GetDoctorById();
      })
      .catch((e) => {
        setloading(false);
      });
  };

  const GetDoctorById = async () => {
    setloading(true);
    await axios
      .get(`${BASE_URL}/user/getSpecificUser?userId=${location?.state?.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setloading(false);
        const singleDoctor = res.data?.specificUser;
        setdoctorData((prevData) => ({
          ...prevData,
          fullName: singleDoctor?.name,
          email: singleDoctor?.email,
          mobile: singleDoctor?.mobile,
          gender: singleDoctor?.gender,
          avatar: singleDoctor?.avatar,
          type: singleDoctor?.doctorType,
        }));
      })
      .catch((e) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (params?.type === "update") {
      GetDoctorById();
    }
  }, []);

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer supervisor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={confirmation}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          params?.type === "update"
            ? "Doctor updated successfully!"
            : "Doctor created successfully!"
        }
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar
          title={
            params?.type === "create" ? "Create New Doctor" : "Update Doctor"
          }
        />

        {/* Main Grid */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            {/* Inner Box */}
            <Box bgcolor="#fff" width="100%" sx={{ borderRadius: "20px" }}>
              <Box
                mb={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                }}
              >
                {/* Header Box */}
                <CreateHeader
                  title={
                    params?.type === "create"
                      ? "Create New Doctor"
                      : "Update Doctor"
                  }
                  btnTitle={params?.type === "create" ? "Create" : "Update"}
                  onClick={
                    params?.type === "create" ? CreateDoctor : UpdateDoctor
                  }
                  cancelFunc={() => navigate(-1)}
                />

                {/* Form */}
                <Box px={15} py={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          mb: 2,
                        }}
                      >
                        {doctorData?.avatar ? (
                          <Avatar
                            alt="Remy Sharp"
                            sx={{
                              width: 125,
                              height: 125,
                              borderRadius: 360,
                              backgroundColor: "transparent",
                              border: "2px solid #EBEFF5",
                              p: 0.5,
                              cursor: "pointer",
                            }}
                            onClick={handleBrowseClick}
                          >
                            <img
                              src={doctorData?.avatar}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt="Avatar"
                            />
                          </Avatar>
                        ) : (
                          <>
                            {avatarLink ? (
                              <Avatar
                                alt="Remy Sharp"
                                src={avatarLink}
                                sx={{
                                  width: 125,
                                  height: 125,
                                  border: "2px solid #EBEFF5",
                                  p: 2,
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                                onClick={handleBrowseClick}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                                onClick={handleBrowseClick}
                              >
                                <AccountCircle sx={{ fontSize: 100 }} />
                              </Avatar>
                            )}
                          </>
                        )}
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => {
                            dataURItoBlob(e.target.files[0]);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormInput
                        value={doctorData.fullName}
                        setValue={(value) =>
                          setdoctorData((prevData) => ({
                            ...prevData,
                            fullName: value,
                          }))
                        }
                        placeholder="Full Name*"
                        Icon={PersonOutlined}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={doctorData.email}
                        setValue={(value) =>
                          setdoctorData((prevData) => ({
                            ...prevData,
                            email: value,
                          }))
                        }
                        placeholder="Email*"
                        Icon={MailOutline}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomFormInput
                        value={doctorData.mobile}
                        setValue={(value) =>
                          setdoctorData((prevData) => ({
                            ...prevData,
                            mobile: value,
                          }))
                        }
                        placeholder="Mobile Number"
                        Icon={PhoneIphoneOutlined}
                      />
                    </Grid>
                    {params?.type !== "update" && (
                      <Grid item xs={12}>
                        <CustomFormInput
                          password
                          value={doctorData.password}
                          setValue={(value) =>
                            setdoctorData((prevData) => ({
                              ...prevData,
                              password: value,
                            }))
                          }
                          placeholder={"Password*"}
                          Icon={Password}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <CreateDropDown
                        value={doctorData.type}
                        setValue={(value) =>
                          setdoctorData((prevData) => ({
                            ...prevData,
                            type: value,
                          }))
                        }
                        title="Doctor Type"
                        Icon={HelpOutline}
                        data={[
                          {
                            label: "Therapist",
                            value: "Therapist",
                          },
                          {
                            label: "Counselor",
                            value: "Counselor",
                          },
                          {
                            label: "Psychologist",
                            value: "Psychologist",
                          },
                          {
                            label: "Psychiatric Nurse Practitioner",
                            value: "Psychiatric Nurse Practitioner",
                          },
                          {
                            label: "Occupational Therapist",
                            value: "Occupational Therapist",
                          },
                          {
                            label: "Art Therapist",
                            value: "Art Therapist",
                          },
                          {
                            label: "Mental Health Counselor",
                            value: "Mental Health Counselor",
                          },
                          {
                            label: "Psychiatric",
                            value: "Psychiatric",
                          },
                          {
                            label: "Psychiatrist Assistant",
                            value: "Psychiatrist Assistant",
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CreateDropDown
                        value={doctorData.gender}
                        setValue={(value) =>
                          setdoctorData((prevData) => ({
                            ...prevData,
                            gender: value,
                          }))
                        }
                        title={"Gender"}
                        data={genders}
                        Icon={Wc}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateDoctor;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers, getAllCustomerSites } from "../../../redux/dispatchers/vendor.dispatcher";
import MenuDrawer from "../../../components/MenuDrawer";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  useMediaQuery,
  Snackbar,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../constants/config";
import { colors } from "../../../theme/theme";
import AppBar from "../../../components/AppBar";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar";
import { StyledDataGrid } from "../../../components/StyledSearchDataGrid";
import { EditOutlined, MoreVert, SendOutlined, VisibilityOutlined } from "@mui/icons-material";
import { GreenModal } from "../../../components/ConfirmationModal";

const Request = () => {
    const navigate = useNavigate();
    const smallScreen = useMediaQuery("(max-width:650px)");

  const { userData  } = useSelector((state) => state.user);

  
    const [siteId, setsiteId] = useState("");
    const [loading, setloading] = useState(false);
    const [snackbarState, setsnackbarState] = useState(false);
    const [siteStatus, setsiteStatus] = useState("");
    const [allSites, setAllSites] = useState("");
    const [updatedSiteStatus, setupdatedSiteStatus] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customerId, setcustomerId] = useState();
  const [requestPopup, setrequestPopup] = useState(false);
  const [requestSent, setrequestSent] = useState(false);

    const [search, setSearch] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const location = useLocation();
    const dispatch = useDispatch();
//    const { allSites } = useSelector((state) => state.vendor);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const customerSitesColumns = [    
        {
          field: "site_name",
          headerName: "Site Name",
          flex: 3,
          minWidth: smallScreen ? 500 : 0,
          renderCell: (params) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  displag: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/vendor/customer-sites/profile", {
                    state: { id: params.row.id },
                  })
                }
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: colors.text,
                    fontFamily: "FiraSans",
                  }}
                >
                  {params.value}
                </Typography>
              </Box>
            </div>
          ),
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1.5,
            align: "center",
            minWidth: smallScreen ? 200 : 100,
            renderCell: (params) => (
              <>
              {
                params?.row?.requests?.length==0 ?
                        <Button
                        variant="contained"
                        sx={{
                        height: "40px",
                        width: "175px",
                        backgroundColor: "#138418",
                        textTransform: "none",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: "#138418",
                        },
                        //mt: 2,
                        }}
                        onClick={() => {
                        setrequestPopup(true);
                        setsiteId(params?.row?.id)
                        setcustomerId(params?.row?.customer?.id)
                        }}
                    >
                        Send Request
                        <SendOutlined sx={{ ml: 1, fontSize: "20px" }} />
                    </Button>
                    :params?.row?.requests[0]?.status==='approved'?
                    <IconButton
                  onClick={() =>
                    navigate("/vendor/customer-sites/profile", {
                      state: { id: params.row.id },
                    })
                  }
                >
                  <EditOutlined sx={{ height: "20px", width: "20px" }} />
                </IconButton>:'You have already requested'
              }
                {/* <IconButton
                  onClick={() =>
                    navigate("/vendor/customer-sites/update", {
                      state: { id: params.row.id },
                    })
                  }
                >
                  <EditOutlined sx={{ height: "20px", width: "20px" }} />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    handleClick(e);
                    setsiteId(params.row.id);
                    setsiteStatus(params.row.status);
                  }}
                >
                  <MoreVert sx={{ height: "20px", width: "20px" }} />
                </IconButton> */}
              </>
            ),
          },
      
    ]

    const RequestAccess = async () => {
        setloading(true);
        await axios
          .post(
            `${BASE_URL}/request/createRequest`,
            {
              siteId: siteId,
              status: "pending",
              customerId: customerId,
            },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            setloading(false);
            setsnackbarState(true);
            setrequestSent(true);
            setrequestPopup(false);
            //CheckRequestAccess();
          })
          .catch((err) => {
            setloading(false);
          });
      };
      const getAllSites = async () => {
        setloading(true);
        axios.get(
            `${BASE_URL}/site/getAllSitesWithoutCustomerId?requestedBy=${userData?.id}`,
            {
              withCredentials: true,
            }
          ).then((res) => {   
            setloading(false);
            setAllSites(res?.data?.allSites);
            // const filteredSites = allSites?.filter((val) =>
            //     val?.address
            //       ?.toLocaleLowerCase()
            //       ?.includes(search?.toLocaleLowerCase())
            //   )
                 
          }).catch((err) => {
            setloading(false);
          });;
      }
    useEffect(() => {
        // dispatch(getAllCustomerSites());
        // dispatch(getAllCustomers());
        getAllSites();    
      }, []);
  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer vendor />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <GreenModal
        open={requestPopup}
        onClose={() => setrequestPopup(false)}
        onClick={() => {
          // setHasAccess(true);
          // setsnackbarState(true);
          // setconfirmation(false);
          RequestAccess();
        }}
        title="Need request access"
        mainText="Requesting access to troubleshoot the customer's site."
        subText="Please ensure that you’ll have access only if the customer grants you access."
      />

      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <AppBar title="Request" />
        <Grid container spacing={2} p={2}>
          {/* First Box */}
          <Grid item xs={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                width: "100%",
                overflowX: "auto",
              }}
            >
              <TitleWithSearchBar
                title="Request"
                noBtn={true}
              />

              <StyledDataGrid
                rows={
                    allSites || []
                }
                columns={customerSitesColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                sx={{
                  borderRadius: "12px",
                  border: "none",
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "70vh",
                  },
                }}
                getRowId={(row) => row?.id}
                pageSizeOptions={[5, 10, { value: allSites?.length, label: 'All' }]}
                getRowClassName={(params) =>
                  params.row.id % 2 === 0 ? "even" : "odd"
                }
                rowSelectionModel={[]}
              />
            </Box>
          </Grid>
        </Grid>
        </Box>
    </Box>
  )
}

export default Request
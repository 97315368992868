import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  loading: false,
};

const clearAdmin = createAction("clearAdmin");

const siteReq = createAction("allCustomerSitesRequest");
const siteSuccess = createAction("allCustomerSitesSuccess");
const siteFail = createAction("allCustomerSitesFailure");

const supervisorReq = createAction("allSupervisorRequest");
const supervisorSuccess = createAction("allSupervisorSuccess");
const supervisorFail = createAction("allSupervisorFailure");

const formReq = createAction("allFormsRequest");
const formSuccess = createAction("allFormsSuccess");
const formFail = createAction("allFormsFailure");

const questionReq = createAction("allQuestionsRequest");
const questionSuccess = createAction("allQuestionsSuccess");
const questionFail = createAction("allQuestionsFailure");

const activityReq = createAction("allActivitiesRequest");
const activitySuccess = createAction("allActivitiesSuccess");
const activityFail = createAction("allActivitiesFailure");

const doctorReq = createAction("allDoctorsRequest");
const doctorSuccess = createAction("allDoctorsSuccess");
const doctorFail = createAction("allDoctorsFailure");

export const adminReducer = createReducer(initialState, (builder) => {
  builder
    // site by Id
    .addCase(siteReq, (state, action) => {
      state.loading = true;
    })
    .addCase(siteSuccess, (state, action) => {
      state.loading = false;
      state.siteData = action.payload.allSites;
    })
    .addCase(siteFail, (state, action) => {
      state.loading = false;
    })

    // supervisor
    .addCase(supervisorReq, (state, action) => {
      state.loading = true;
    })
    .addCase(supervisorSuccess, (state, action) => {
      state.loading = false;
      state.allSupervisors = action.payload.allSupervisors;
    })
    .addCase(supervisorFail, (state, action) => {
      state.loading = false;
    })

    // form
    .addCase(formReq, (state, action) => {
      state.loading = true;
    })
    .addCase(formSuccess, (state, action) => {
      state.loading = false;
      state.allForms = action.payload.allForm;
    })
    .addCase(formFail, (state, action) => {
      state.loading = false;
    })

    // question
    .addCase(questionReq, (state, action) => {
      state.loading = true;
    })
    .addCase(questionSuccess, (state, action) => {
      state.loading = false;
      state.allQuestions = action.payload.allQuetions;
    })
    .addCase(questionFail, (state, action) => {
      state.loading = false;
    })

    // activity
    .addCase(activityReq, (state, action) => {
      state.loading = true;
    })
    .addCase(activitySuccess, (state, action) => {
      state.loading = false;
      state.allActivities = action.payload.allActivities;
    })
    .addCase(activityFail, (state, action) => {
      state.loading = false;
    })

    // doctor
    .addCase(doctorReq, (state, action) => {
      state.loading = true;
    })
    .addCase(doctorSuccess, (state, action) => {
      state.loading = false;
      state.allDoctors = action.payload.allDoctors;
    })
    .addCase(doctorFail, (state, action) => {
      state.loading = false;
    })

    // clear admin
    .addCase(clearAdmin, (state, action) => {
      state.siteData = null;
      state.allSupervisors = null;
      state.allForms = null;
      state.allQuestions = null;
      state.allActivities = null;
    });
});
